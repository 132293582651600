import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { saveAs } from 'file-saver';
import { resolveFileName } from '../../../helper/download-helper';
import { FileHalItem, FileHalPage } from '../hal/file.hal.item';
import { HalCrudServiceImpl } from '../hal/hal.crud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { File } from '../interfaces/dtos/file';
import { Id } from '../interfaces/dtos/Id';

export interface FileService extends DsvsSearchableCrudEntityService<File> {
  downloadFile(fileId: Id): any;

  downloadFileUrl(path: string, success?, error?): any;
}

@Injectable({
  providedIn: 'root'
})
export class FileServiceImpl
  extends HalCrudServiceImpl<File, FileHalItem, FileHalPage>
  implements FileService {

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, private http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'file', collection: 'files'},
      FileHalPage,
      FileHalItem);
  }

  downloadFile(fileId: Id): any {
    // TODO: USE 'REAL' HAL-CLIENT_IMPL

    // execute only called if has Subscriber ;)
    this.execute<HttpResponse<Blob>>(
      v1Client => {

        const url = (<any>v1Client).apiClient.startUrl
          + '/' + this.relation.collection
          + '/' + fileId
          + '/download';

        return this.http.get(url,
          {responseType: 'blob', observe: 'response'}
        );
      }
    ).subscribe(
      (res) => saveAs(res.body, resolveFileName(res.headers)),
      (err) => console.error('Could not download file!')
    );
  }

  downloadFileUrl(path: string, success?, error?): any {
    return this.http.get(path,
      {responseType: 'blob', observe: 'response'}
    ).subscribe(
      (res) => {
        if (success) {
          success(res);
        }
        saveAs(res.body, resolveFileName(res.headers));
      },
      (err) => {
        if (error) {
          error(err);
        }
        console.error('Could not download file!');
        throw err;
      }
    );
  }
}
