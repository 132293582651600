import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { DsvsPermissionService } from '@dsvs/dsvs-shared-ui-lib';
import { ZapPermissions } from '../enums/zap-permissions.enum';

@Injectable()
export class FIReleaseDateActivateGuard implements CanActivate {

  constructor(private permissionService: DsvsPermissionService) {
  }

  canActivate(): boolean {
    return this.permissionService.hasPermission([ZapPermissions.FES_FIRELEASEDATE_OVERVIEW_READ]);
  }
}
