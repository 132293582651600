import { Component, OnInit } from '@angular/core';
import { WorkflowActionHistoryComponent } from '@dsvs/workflow-generator-ui-lib';

@Component({
  selector: 'fes-fes-workflow-action-devversion-add-history',
  templateUrl: './fes-workflow-action-devversion-add-history.component.html',
  styleUrls: ['./fes-workflow-action-devversion-add-history.component.scss']
})
export class FesWorkflowActionDevversionAddHistoryComponent extends WorkflowActionHistoryComponent implements OnInit {

  files: any[] = [];

  constructor(
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  payloadChanged(payload) {
    if (payload) {
      this.files = [{
        displayName: 'Entwicklerversion ' + payload.version,
        id: payload.id
      }];
    }
  }
}
