import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {createActiveOptions, createMajorMinor, SelectOptions} from '../../../../../../../helper/select-helper';
import {VersionDocument} from '../../../../../../shared/interfaces/dtos/documents/files/version-document';
import {Version} from '../../../../../../shared/interfaces/dtos/version';
import {XdpData} from '../../../../../../shared/interfaces/dtos/xdpdata';
import {FormServiceImpl} from '../../../../../../shared/services/form.service';

@Component({
  selector: 'fes-add-version-formular',
  templateUrl: './add-version-formular.component.html',
  styleUrls: ['./add-version-formular.component.css']
})
export class AddVersionFormularComponent implements OnInit {

  addVersionForm: FormGroup;
  activationOptions: SelectOptions[];
  majorMinorOptions: SelectOptions[];

  _versionDocument: VersionDocument = null;
  xdpData: XdpData;
  version: Version;
  isFirstVersion = false;

  @Input() fileType;

  @Input() set versionDocument(value: VersionDocument) {
    this._versionDocument = value;
    this.xdpData = value.xdpData;
    this.version = value.version;
    this.isFirstVersion = value.firstVersion;
    this.initForm(value, value.xdpData, value.version);
  }

  get versionDocument(): VersionDocument {
    return this._versionDocument;
  }

  constructor(private formService: FormServiceImpl) {
  }

  ngOnInit() {
    this.activationOptions = createActiveOptions();
    this.majorMinorOptions = createMajorMinor();
  }

  initForm(versionDocument: VersionDocument, xdpData: XdpData, version: Version) {
    if (this.addVersionForm === undefined) {
      this.createAddVersionForm(versionDocument);
    }
    if (typeof this.addVersionForm !== 'undefined' && this.addVersionForm != null) {
      if (versionDocument != null) {
        this.addVersionForm.patchValue(
          {
            parentVersion: versionDocument.parentVersion ? versionDocument.parentVersion : '-',
            increaseMajor: versionDocument.increaseMajor ? versionDocument.increaseMajor : false
          });
      }
      if (version != null) {
        this.addVersionForm.patchValue(
          {comment: version.comment ? version.comment : ''});
      }
      if (xdpData != null) {
        this.addVersionForm.patchValue(
          {
            version: xdpData.version ? xdpData.version : '-'
          });
      }

      if (versionDocument.newForm || (this.isFirstVersion && this.fileType === 'XDP')) {
        if (versionDocument.formular && versionDocument.formular.id/* && versionDocument.parentVersion*/) {
          this.setNextVersion(versionDocument);

        } else {
          this.addVersionForm.patchValue(
            {
              nextVersion: (xdpData && xdpData.version) ? xdpData.version : '-'
            });
        }

        this.addVersionForm.controls.increaseMajor.enable();
        this.addVersionForm.controls.nextVersion.enable();
      } else {
        this.setNextVersion(versionDocument);
      }

      if (this.isFirstVersion) {
        this.addVersionForm.patchValue(
          {comment: 'Initialisierung'}
        );
        // this.addVersionForm.controls.comment.disable();
      }
    }
  }

  private setNextVersion(versionDocument: VersionDocument) {
    this.formService.nextVersion(
      versionDocument.formular.id,
      versionDocument.xdpData.version,
      (!this.isFirstVersion) ? versionDocument.version.version : null,
      this.addVersionForm.controls.increaseMajor.value
    ).subscribe(
      nextVersion => {
        this.addVersionForm.patchValue(
          {
            nextVersion: nextVersion.nextVersion
          });
      }
    );
  }

  changeMinorMajor($event) {
    this.setNextVersion(this._versionDocument);
  }

  createAddVersionForm(versionDocument: VersionDocument) {
    this.addVersionForm = new FormGroup(
      {
        nextVersion: new FormControl('', Validators.required),
        parentVersion: new FormControl(''),
        version: new FormControl(''),
        increaseMajor: new FormControl(false),
        comment: new FormControl('', Validators.required)
      });
    this.addVersionForm.controls.parentVersion.disable();
    this.addVersionForm.controls.version.disable();

  }
}
