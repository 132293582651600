import { Component, Input } from '@angular/core';

@Component({
  selector: 'fes-field-not-present-error',
  template: `
    <p-message *ngIf="errorMessage != null" [severity]="'error'" [text]="errorMessage"></p-message>`
})
export class FieldNotPresentErrorComponent {

  @Input() fieldName: string;
  @Input() fieldValue: string;
  @Input() missingFields: string[];

  get errorMessage() {
    if (this.missingFields.indexOf(this.fieldName) !== -1) {
      return 'Dieses Feld (' + this.fieldName + ') wird im XDP neu angelegt mit dem Wert "' + this.fieldValue + '"';
    } else {
      return null;
    }
  }
}
