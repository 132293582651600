import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DsvsBreadcrumbService} from '@dsvs/dsvs-shared-ui-lib';
import {ActionContextHalItem, CrewHalItem, RoleHalPage, RoleUserHalItem, UserHalItem, UserHalPage, WorkflowRole} from '@dsvs/workflow-generator-dto';
import {WorkflowActionDataDto, WorkflowCrewServiceImpl, WorkflowGeneratorConfigurationService, WorkflowGeneratorRoutingService, WorkflowPermissions, WorkflowRoleServiceImpl, WorkflowRoleUser, WorkflowRoleUserServiceImpl, WorkflowUserServiceImpl} from '@dsvs/workflow-generator-ui-lib';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable} from 'rxjs';
import {ProcessHalItem} from '../../../../shared/hal/process.hal.item';
import {WGContextHalItem} from '../../../../shared/hal/wfg/wgcontext.hal.item';
import {MeServiceImpl} from '../../../../shared/services/me.service';
import {FesWorkflowActionComponent} from '../fes-workflow-action';

enum CrewEditActionType {
  ADD_ROLE_USER = 'ADD_ROLE_USER',
  REMOVE_ROLE_USER = 'REMOVE_ROLE_USER',
  UPDATE_ROLE_USER = 'UPDATE_ROLE_USER'
}

@Component({
  selector: 'fes-workflow-action-crew-edit',
  templateUrl: './fes-workflow-action-crew-edit.component.html',
  styleUrls: ['./fes-workflow-action-crew-edit.component.scss']
})
export class FesWorkflowActionCrewEditComponent extends FesWorkflowActionComponent implements OnInit {

  processHalItem: ProcessHalItem = null;
  activeRolesHalPage: RoleHalPage = null;

  /** Working object */
  crew: CrewHalItem;

  usersSelected: UserHalItem[] = [];
  groupSelected = {
    data: null
  };
  mainUserSelected: any;

  /** role dropdown */
  roles: WorkflowRole[];
  roleSelected: WorkflowRole;

  /** delete dialog */
  showDeleteDialog = false;
  roleUserToDelete: any;

  isEditable = false;

  roleId = null;
  mainUserId = null;
  crewId = null;
  userIds = [];
  crewEditActionType: CrewEditActionType = null;
  roleUserId = null;

  me = null;

  showCreateModal = false;
  newRoleUser = {};

  constructor(
    messageService: MessageService,
    private route: ActivatedRoute,
    private wfgRouting: WorkflowGeneratorRoutingService,
    private crewService: WorkflowCrewServiceImpl,
    public roleService: WorkflowRoleServiceImpl,
    public userService: WorkflowUserServiceImpl,
    private breadcrumbService: DsvsBreadcrumbService,
    private confirmationService: ConfirmationService,
    private roleUserService: WorkflowRoleUserServiceImpl,
    private meService: MeServiceImpl,
    private configService: WorkflowGeneratorConfigurationService
  ) {
    super(messageService);
  }

  ngOnInit() {

    this.isEditable = this.configService.hasPermission(WorkflowPermissions.CREW_UPDATE);
    this.meService.get().subscribe(
      me => {
        this.me = me;
      }
    );
    this.loadCrew();
  }

  loadCrew() {
    (<WGContextHalItem>this.context).process.async.subscribe(
      processHalItem => {
        this.processHalItem = processHalItem;

        this.processHalItem.activeroles.async.subscribe(
          activeRolesHalPage =>
            this.activeRolesHalPage = activeRolesHalPage
        );

        if (this.processHalItem.product.async) {
          this.processHalItem.product.async.subscribe(
            productHalItem => {
              if (productHalItem.category.async) {
                productHalItem.category.async.subscribe(
                  categoryHalItem => {
                    if (categoryHalItem.crew.async) {
                      categoryHalItem.crew.async.subscribe(
                        crewHalItem => {
                          this.crew = crewHalItem;
                        }
                      );
                    }
                  }
                );
              }
            }
          );
        }
      }
    );
  }

  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
    this.resetData();
    this.crew.roleusers.reload();
  }

  disableControls() {
    this.isEditable = false;
  }

  enableControls() {
    this.isEditable = true;
  }

  isValid(): boolean {
    return !!this.crewEditActionType;
  }

  collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      crewEditActionType: this.crewEditActionType,
      roleId: this.roleId,
      crewId: this.crewId,
      mainUserId: this.mainUserId,
      roleUserId: this.roleUserId,
      userIds: this.userIds
    };
  }

  resetData(): void {
    this.crewEditActionType = null;
    this.roleId = null;
    this.mainUserId = null;
    this.crewId = null;
    this.roleUserId = null;
    this.usersSelected = [];
    this.userIds = [];
  }

  public onAddRoleUser() {
    this.crewEditActionType = CrewEditActionType.ADD_ROLE_USER;
    this.roleId = this.roleSelected.id;
    this.crewId = this.crew.data.id;
    this.mainUserId = this.mainUserSelected.id;
    this.userIds = this.usersSelected.map(a => a.data.id);

    this.saveAction();
  }

  public onRemoveRoleUserDialog(roleUser) {
    this.roleUserToDelete = roleUser;
    this.showDeleteDialog = true;
  }

  public onRemoveRoleUser(roleUser) {
    this.crewEditActionType = CrewEditActionType.REMOVE_ROLE_USER;
    this.roleUserId = roleUser.data.id;
    this.showDeleteDialog = false;
    this.saveAction();
  }

  onUpdateRoleUser2User = (value, component): Observable<any> => {
    const roleUserHalItem = component.model;
    this.userIds = component.modelValue.map(a => a.data.id);
    return this.updateRoleUser(roleUserHalItem);
  };

  onUpdateRoleUser2Role = (value, component): Observable<any> => {
    const roleUserHalItem = component.model;
    roleUserHalItem.data.roleId = value.data.id;
    this.userIds = null;
    return this.updateRoleUser(roleUserHalItem);
  };

  onUpdateRoleUser2MainUser = (value, component): Observable<any> => {
    const roleUserHalItem = component.model;
    roleUserHalItem.data.mainUserId = value.data.id;
    this.userIds = null;
    return this.updateRoleUser(roleUserHalItem);
  };

  onResetRoleUser2MainUser(roleUserHalItem): Observable<any> {
    roleUserHalItem.data.mainUserId = null;
    this.userIds = null;
    return this.updateRoleUser(roleUserHalItem);
  }

  updateRoleUser(roleUserHalItem: RoleUserHalItem) {
    this.crewEditActionType = CrewEditActionType.UPDATE_ROLE_USER;
    this.mainUserId = roleUserHalItem.data.mainUserId;
    this.roleId = roleUserHalItem.data.roleId;
    this.crewId = roleUserHalItem.data.crewId;
    this.roleUserId = roleUserHalItem.data.id;
    this.saveAction();
    return Observable.of(null);
  }

  public isUserRoleAddButtonActive(): boolean {
    return !!(this.usersSelected && this.roleSelected);
  }

  fetchColorsForUsers = (user, component) => {
    if (user.data && user.data.id === this.me.data.id) {
      return '#b50f00';
    } else {
      return null;
    }
  };

  canDelete(role) {
    if (role && this.activeRolesHalPage.content) {
      return this.activeRolesHalPage.content.find(a => a.data.id === role.id) != null;
    } else {
      return false;
    }
  }

  public resetMainUserSelected() {
    this.mainUserSelected = null;
  }

  fetchRoles = ($event): Observable<any> => {
    return this.roleService.search($event.query);
  };

  fetchUsers = ($event): Observable<any> => {
    return this.userService.search($event.query);
  };

  fetchSelectedUsers = ($event): Observable<UserHalItem[]> => {
    return Observable.of(this.usersSelected.filter(a => a.data.displayName.toLowerCase().indexOf($event.query.toLowerCase()) > -1));
  };

  fetchSelectedRowUsers = ($event, component): Observable<UserHalPage> => {
    return component.model.users.async;
  };

  reloadRoleUsers() {
    this.crew.roleusers.reload();
  }

  reloadRoleUsersOfNew(event) {
    this.usersSelected = event.users;
    this.groupSelected = event.group;
  }

  saveRole = (value, component): Observable<any> => {
    component.model.data.roleId = value.data.id;
    return component.model.save();
  };

  saveRoleOfNew = (value, component): Observable<any> => {
    this.roleSelected = value.data;
    return Observable.of(null);
  };

  saveMainUser = (value, component): Observable<any> => {
    component.model.data.mainUserId = value.data.id;
    return component.model.save();
  };

  saveMainUserOfNew = (value, component): Observable<any> => {
    this.mainUserSelected = value.data;
    return Observable.of(null);
  };

  public onUserRoleAddButtonClicked() {
    /** add combination */

    this.roleUserService.create(
      <WorkflowRoleUser>{
        roleId: this.roleSelected.id,
        crewId: this.crew.data.id,
        groupId: (this.groupSelected && this.groupSelected.data) ? this.groupSelected.data.id : null,
        mainUserId: this.mainUserSelected ?
          (this.mainUserSelected.data ? this.mainUserSelected.data.id : this.mainUserSelected.id) : null,
        id: null,
        displayName: null
      })
      .subscribe((result: RoleUserHalItem) => {

        this.usersSelected.forEach(user => {
          result.users.add(user).subscribe(
            () => {
              this.roleSelected = null;
              this.usersSelected = [];
              this.mainUserSelected = null;
            }, error2 => {
              console.error(error2);
            });
        });
        this.showCreateModal = false;
        this.crew.roleusers.reload();
      }, error2 => {
        console.error('error while saving new relation - {}', error2);
      });
  }

  /**
   *
   * @param roleUsers
   */
  public onDeleteRow(roleUsers) {
    this.roleUserToDelete = roleUsers;
    this.showDeleteDialog = true;
  }

}
