import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalRelationItem } from '@dsvs/hal-client';
import { ProcessStepState } from '../interfaces/dtos/processstepstate';

export class ProcessStepStateHalItem extends HalItemImpl<ProcessStepState> {
  constructor(data: ProcessStepState, client: HalClient, factory: HalClientFactory) {
    super(data, client, factory);

  }
}

export class ProcessStepStateRelationItem extends HalRelationItem<ProcessStepState, ProcessStepStateHalItem> {
  constructor(relation: string,
    item: HalItem<any>) {
    super(relation, item, ProcessStepStateHalItem);
  }
}
