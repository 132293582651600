import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { RoleRelationItem } from '@dsvs/workflow-generator-dto';
import { GlobalRoleUser } from '../interfaces/dtos/globalroleuser';
import { UserRelationPage } from './user.hal.item';

export class GlobalRoleUserHalItem extends HalItemImpl<GlobalRoleUser> {
  constructor(data: GlobalRoleUser,
              client: HalClient,
              factory: HalClientFactory) {
    super(data, client, factory);
    this.role = new RoleRelationItem('role', this);
    this.users = new UserRelationPage('users', this);
    this.adminusers = new UserRelationPage('adminusers', this, 'users');
  }

  readonly role: RoleRelationItem;
  readonly users: UserRelationPage;
  readonly adminusers: UserRelationPage;
}

export class GlobalRoleUserHalPage extends HalPageImpl<GlobalRoleUser, GlobalRoleUserHalItem> {

}

export class GlobalRoleUserRelationPage extends HalRelationPage<GlobalRoleUser, GlobalRoleUserHalItem, GlobalRoleUserHalPage> {
  constructor(relation: string,
              item: HalItem<any>
  ) {
    super(relation, item, GlobalRoleUserHalPage, GlobalRoleUserHalItem);
  }
}

export class GlobalRoleUserRelationItem extends HalRelationItem<GlobalRoleUser, GlobalRoleUserHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, GlobalRoleUserHalItem);
  }
}
