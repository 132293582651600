import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormularInformationService {

  public showWorkspaceFormsOnly = false;
  public formUrl = '/forms/';

  constructor() { }

}
