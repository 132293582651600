import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'fes-process-list-number',
  templateUrl: './process-list-number.component.html',
  styleUrls: ['./process-list-number.component.scss']
})
export class ProcessListNumberComponent implements OnInit, OnDestroy {

  @Input()
  name: string;

  @Input()
  number: number;
  //
  //
  // @Input()
  // workflow: WorkflowHalItem;
  //
  // @Input()
  // activeStatus: any;
  //
  // _status: StateHalItem[] = [];
  // activeIndex: number;

  constructor(/*private workflowStateService: WorkflowStateServiceImpl*/) {
  }

  ngOnInit() {/*
    this.workflowStateService.getForWorkflowId(this.workflow.data.id).subscribe(
      workflowStateHalItem => {
        this._status = workflowStateHalItem.content;
        if (this.activeStatus && this._status) {
          this.activeIndex = this._status.findIndex(state => state.data.id === this.activeStatus.data.id) + 1;
        }
      }, error => {
        console.error(error);
      });*/
  }

  ngOnDestroy(): void {
  }
}
