import { Component, Input } from '@angular/core';
import { VersionHalItem } from '../../hal/version.hal.item';
import { FesStatusComponent } from '../fes-status/fes-status.component';

@Component({
  selector: 'fes-version-status',
  templateUrl: './fes-version-status.component.html',
  styleUrls: ['./fes-version-status.component.scss']
})
export class FesVersionStatusComponent {

  color: string = FesStatusComponent.COLOR_UNKNOWN;
  title: string = null;
  _version: VersionHalItem = null;

  @Input() set version(version: VersionHalItem) {
    this._version = version;

    if (version.data.deleted) {
      this.color = FesStatusComponent.COLOR_RED;
      this.title = 'Version gelöscht';
    } else if (version.data.active) {
      this.color = FesStatusComponent.COLOR_GREEN;
      this.title = 'Aktive Version';
    } else {
      this.color = FesStatusComponent.COLOR_YELLOW;
      this.title = 'Inaktive Version';
    }
  }

  get version(): VersionHalItem {
    return this._version;
  }

}
