import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { createSelectOptions, SelectOptions } from '../../../../../../../helper/select-helper';
import { EDynamisationTypes } from '../../../../../../shared/interfaces/dtos/enums/EDynamisationTypes';
import { ESignatureLevel } from '../../../../../../shared/interfaces/dtos/enums/ESignatureLevel';
import { Formular } from '../../../../../../shared/interfaces/dtos/formular';
import { XdpData } from '../../../../../../shared/interfaces/dtos/xdpdata';

@Component({
  selector: 'fes-add-xdp-formular',
  templateUrl: './add-xdp-formular.component.html',
  styleUrls: ['./add-xdp-formular.component.scss']
})
export class AddXdpFormularComponent implements OnInit {

  _xdpData: XdpData;

  @Input()
  isFirstVersion: boolean;

  @Input()
  readOnly = false;

  @Input()
  formular: Formular = null;

  @Input() set xdpData(value: XdpData) {
    this._xdpData = value;
    this.initForm();
  }

  get xdpData(): XdpData {
    return this._xdpData;
  }

  dynamisationOptions: SelectOptions[];
  signatureLevelOptions: SelectOptions[];

  xdpDataForm: FormGroup;
  @Input() missingFields: string[] = [];

  ngOnInit() {
    this.dynamisationOptions = createSelectOptions(EDynamisationTypes);
    this.signatureLevelOptions = createSelectOptions(ESignatureLevel);
  }

  initForm() {
    if (this.xdpDataForm === undefined) {
      this.createAddXDPForm();
    }
    if (typeof this.xdpDataForm !== 'undefined' && this.xdpDataForm != null) {
      if (this._xdpData != null) {
        this.xdpDataForm.setValue(
          {
            articleNumber: this._xdpData.articleNumber,
            title: this._xdpData.title,
            fassung: this._xdpData.fassung,
            countCopy: this._xdpData.countCopy,
            version: this._xdpData.version ? this._xdpData.version : '-',
            dynamisation: this._xdpData.dynamisation,
            owner: this._xdpData.owner,
            shape: this._xdpData.shape,
            attachment: this._xdpData.attachment,
            copyRight: this._xdpData.copyRight ? this._xdpData.copyRight : '',
            signature: this._xdpData.signature
          });
      } else {
        this.xdpDataForm.patchValue(
          {
            articleNumber: this.formular.articleNumber,
            version: '0.1.0'
          });
      }
    }
    if (this.readOnly) {
      this.xdpDataForm.disable();
    }

    this.xdpDataForm.controls.version.disable();

  }

  createAddXDPForm() {
    this.xdpDataForm = new FormGroup(
      {
        articleNumber: new FormControl('', Validators.required),
        version: new FormControl(''),
        countCopy: new FormControl('',
          Validators.compose(
            [
              Validators.required,
              Validators.pattern(/^-?(0|[1-9]\d*)?$/)
            ]
          )
        ),
        dynamisation: new FormControl('', Validators.required),
        attachment: new FormControl(false, Validators.required),
        signature: new FormControl(false, Validators.required),
        // signatureLevel: new FormControl('', Validators.required,),
        fassung: new FormControl(''),
        title: new FormControl(''),
        shape: new FormControl(''),
        owner: new FormControl(''),
        copyRight: new FormControl('')
      });
    this.xdpDataForm.controls.articleNumber.disable();
    this.xdpDataForm.controls.signature.disable();
    this.xdpDataForm.controls.version.enable();
  }
}
