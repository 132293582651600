import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/primeng';

@Component({
  selector: 'fes-xdp-file-upload',
  templateUrl: './xdp-file-upload.component.html',
  styleUrls: ['./xdp-file-upload.component.scss']
})
export class XdpFileUploadComponent implements OnInit {

  @Input() isValidating = false;
  @Input() isLoading = false;
  @Input() required = false;
  @Input() accept = '.xdp,.XDP,.pdf,.PDF,.docx,.DOCX,.dotx,.DOTX';
  @Output() fileChanged = new EventEmitter<any>();
  @Output() fileRemoved = new EventEmitter<any>();
  @ViewChild('fileuploader') fileuploader: FileUpload;

  errors = [];

  maxFileSize = '10000000';

  uploadFileForm: FormGroup;

  ngOnInit() {
    this.createUploadForm();
  }

  createUploadForm() {
    this.uploadFileForm = new FormGroup(
      {
        versionFile: new FormControl([], Validators.required)
      });
  }

  onFileChange(target) {
    this.errors = [];
    if (target.files.length > 0) {
      const file = target.files[0];
      if (this.checkFileNames(file.name)) {
        if (this.maxFileSize > file.size) {
          this.uploadFileForm.get('versionFile').setValue(file);

          if (this.fileChanged != null) {
            this.fileChanged.emit(file);
          }
        } else {
          this.onFileRemoved();
          this.errors.push({
            severity: 'error',
            summary: file.name + ': Falsche Dateigröße',
            detail: 'Maximale Dateigröße: ' + this.maxFileSize + ' byte'
          });
        }
      } else {
        this.onFileRemoved();
        this.errors.push({
          severity: 'error',
          summary: file.name + ': Falscher Dateityp',
          detail: 'Erlaubte Dateiendungen: ' + this.accept
        });
      }
    }
  }

  checkFileNames(fileName) {
    let isValid = false;
    this.accept.split(',').forEach(
      extension => {
        if (fileName.includes(extension)) {
          isValid = true;
        }
      }
    );
    return isValid;
  }

  onFileRemoved() {
    this.uploadFileForm.reset();
    this.fileRemoved.emit();
  }

}
