import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'fes-token-delete-dialog',
  templateUrl: './token-delete-dialog.component.html',
  styleUrls: ['./token-delete-dialog.component.scss']
})
export class TokenDeleteDialogComponent implements OnInit {

  dialogText = '';

  @Input()
  dialogDescription = '';
  @Input()
  dialogTitle = '';

  securityTokenInput = '';

  isTokenDeleteDialogActive = false;

  @Output()
  DeleteButtonClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  CancelButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  /** Zeigt einen TokenDeleteDialog an*/
  showTokenDeleteDialog() {
    this.isTokenDeleteDialogActive = true;
  }

  /** Setzt den TokenDeleteDialog auf seinen Ausgangswert zurück und schließt ihn */
  resetAndCloseTokenDeleteDialog() {
    this.securityTokenInput = '';
    this.isTokenDeleteDialogActive = false;
  }

  /** Feuert ein DeleteEvent mit eingegeben Security Token */
  onDelete() {
    this.DeleteButtonClicked.emit(this.securityTokenInput);
  }

}
