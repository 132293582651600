import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsPage, DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HalCrudServiceImpl } from '../hal/hal.crud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { TagHalItem, TagHalPage } from '../hal/tag.hal.item';
import { ETagCategory } from '../interfaces/dtos/enums/ETagCategory';
import { Tag } from '../interfaces/dtos/tag';
import { TagCategory } from '../interfaces/dtos/tagcategory';

export interface TagService extends DsvsSearchableCrudEntityService<Tag> {
  categories(): Observable<any>;

  getTagsForCategories(categories: ETagCategory[], searchTerm: string, options: DsvsPage): Observable<TagHalPage>;

  filter(searchTerm: string, options?: DsvsPage, filterTerm?: string): Observable<TagHalPage>;
}

@Injectable({
  providedIn: 'root'
})
export class TagServiceImpl
  extends HalCrudServiceImpl<Tag, TagHalItem, TagHalPage> implements TagService {

  private backend: string = environment.backend;
  private categoryCache: TagCategory[] = null;
  private categoryObservable: Observable<TagCategory[]> = null;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, private http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'tag', collection: 'tags'},
      TagHalPage,
      TagHalItem);
  }

  categories(): Observable<TagCategory[]> {
    return this.http.get<TagCategory[]>(
      this.backend + this.relation.collection + '/categories'
    ).map(categories => {
        this.categoryCache = categories;
        return categories;
      }
    );
  }

  filter(searchTerm: string, options?: DsvsPage, filterTerm?: string): Observable<TagHalPage> {
    return super.search(searchTerm, options, {name: 'filter', data: {'filters': filterTerm}});
  }

  getTagsForCategories(
    categories: ETagCategory[],
    searchTerm: string = '',
    options: DsvsPage = <DsvsPage>{
      sort: [],
      size: 10,
      page: 0
    }
  ): Observable<TagHalPage> {
    return this.filter(
      '',
      options,
      JSON.stringify(
        categories.map(
          category => ({
            field: 'category',
            filterBy: {
              label: category,
              value: category
            }
          })
        )
      )
    );
  }

  getColorForTagId(tagid: string): string {
    if (!this.categoryCache) {
      if (this.categoryObservable == null) {
        this.categoryObservable = this.categories();
        this.categoryObservable.subscribe(categories => this.categoryCache = categories);
      }
    } else {
      for (const category of this.categoryCache) {
        if (category.id === tagid) {
          return category.color;
        }
      }
    }
  }

  getColorForTag(tag: TagCategory) {
    return tag.color;
  }

}
