import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userCircle'
})
export class UserCirclePipe implements PipeTransform {

  transform(value: string): string {
    return value.split(' ').slice(0, 2).map(s => s.substring(0, 1)).join('');
  }
}
