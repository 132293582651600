import {Base} from './base';
import {File} from './file';

/* Sachakten Objektv*/
export class ExternRecord extends Base {
  constructor(
    public displayName: string,
    public formularId: string,
    public file: File
  ) {
    super();
  }
}
