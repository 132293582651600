import {Base} from './base';
import {EFormularTypes} from './enums/EFormularTypes';
import {Id} from './Id';

export class Formular extends Base {
  constructor(
    public displayName: string,
    public articleNumber: string,
    public formularType: EFormularTypes,
    public description: string,
    public tags: string[],
    public activeVersionId: Id,
    public crewId: Id,
    public deleted: boolean
  ) {
    super();
  }
}
