import { EDocumentTypes } from '../enums/EDocumentTypes';
import { EFileTypes } from '../enums/EFileTypes';
import { ValidationData } from './validation-data';

export abstract class ValidationDocument {

  protected constructor(
    public type: EDocumentTypes,
    public fileType: EFileTypes,
    public validationData: ValidationData
  ) {
  }
}
