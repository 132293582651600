import {Component, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DsvsBreadcrumbService, DsvsPermissionService, DsvsSettings, DsvsTableColumn, DsvsToolbarItem, InlineEditComponent, TableComponent} from '@dsvs/dsvs-shared-ui-lib';
import {RoleHalItem, UserHalItem} from '@dsvs/workflow-generator-dto';
import {WorkflowRoleServiceImpl, WorkflowStateServiceImpl} from '@dsvs/workflow-generator-ui-lib';
import {MenuItem, MessageService} from 'primeng/api';
import {ZapPermissions} from '../../../../enums/zap-permissions.enum';
import {ProcessHalItem, ProcessHalPage} from '../../../shared/hal/process.hal.item';
import {FesUserSettingsService} from '../../../shared/services/fes-user-settings.service';
import {ProcessServiceImpl} from '../../../shared/services/process.service';
import {UserServiceImpl} from '../../../shared/services/user.service';
import {Observable} from 'rxjs';

enum FilterChangeState {
  CUSTOM = 'Custom',
  MYROLE = 'MyRole',
  MINE = 'Mine',
  TODO = 'Todo'
}

@Component({
  selector: 'fes-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessListComponent implements OnInit, OnDestroy {

  @ViewChild('table') table: TableComponent;

  processes: ProcessHalPage = null;

  loading = false;

  processTableColums: DsvsTableColumn[];
  first = 0;
  items: MenuItem[];

  showCreateModal = false;
  showSearchInput = false;

  selectedUserFilter: UserHalItem[] = [];
  users = [];
  selectedRoleFilter: RoleHalItem[] = [];
  roles = [];
  selectedStateFilter = [];
  states = [];
  filter = {
    roles: [],
    users: [],
    states: [],
    mine: null,
    todo: null,
    myRole: null
  };

  public JSON = JSON;

  conflictingProcesses: ProcessHalItem[] = [];

  public ZapPermissions = ZapPermissions;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private sharedSettings: DsvsSettings,
              private processService: ProcessServiceImpl,
              private breadcrumbService: DsvsBreadcrumbService,
              private permissionService: DsvsPermissionService,
              public roleService: WorkflowRoleServiceImpl,
              public stateService: WorkflowStateServiceImpl,
              public userService: UserServiceImpl,
              private zone: NgZone,
              private fesSettings: FesUserSettingsService,
              private dsvsSettings: DsvsSettings,
              private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.initializeTable();
    this.initToolbar();
    this.getRoute();

    this.fesSettings.onActiveWorkspaceChanged().subscribe(result => {
      this.onReloadData();
    });
  }

  ngOnDestroy() {
    this.breadcrumbService.removeToolbarItems(this.getToolbarItems());
  }

  initToolbar(): void {
    this.breadcrumbService.setMenuItems([
      {label: 'Aufträge'}
    ]);
    this.breadcrumbService.setToolbarItems(this.getToolbarItems());
    this.breadcrumbService.setToolbarVisible(true);
  }

  getToolbarItems(): DsvsToolbarItem[] {
    return [
      {
        id: 'RESET_FILTERS',
        icon: 'close',
        disabled: false,
        tooltip: 'Filter zurücksetzen',
        callback: () => this.filterReset()
      },
      {
        id: 'CREATE_PROCESS',
        icon: 'note_add',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_PROCESS_CREATE]),
        tooltip: 'Auftrag erstellen',
        callback: () => this.showCreateDialog()
      },
      {
        id: 'REFRESH',
        icon: 'refresh',
        disabled: false,
        tooltip: 'Aktualisieren',
        callback: () => this.onReloadData()
      }
    ];
  }

  getRoute() {
    if (this.activatedRoute.snapshot.routeConfig.path === 'create') {
      this.showCreateDialog();
    }
  }

  initializeTable() {
    this.processTableColums = this.getColumns();
  }

  onFilterSelectChange(value, component: InlineEditComponent): Observable<any> {
    if (component.model.filter.todo || component.model.filter.mine) {
      component.model.onReloadData();
    } else {
      component.model.filterChange(FilterChangeState.CUSTOM);
    }
    return Observable.of(null);
  }

  onReloadData() {
    this.loading = true;

    const searchTerm = this.table.searchValue;
    const page = this.table.pageRequest;

    if (page && page.sort && page.sort.length === 0) {
      page.sort = [{property: 'lastModifiedDate', order: 'DESC'}];
    }

    if (this.selectedRoleFilter.length > 0) {
      this.filter['roles'] = this.selectedRoleFilter.map(r => {
        return r.data.id;
      });
    } else {
      this.filter['roles'] = [];
    }

    if (this.selectedStateFilter.length > 0) {
      this.filter['states'] = this.selectedStateFilter.map(r => {
        return r.data.id;
      });
    } else {
      this.filter['states'] = [];
    }

    if (this.selectedUserFilter.length > 0) {
      this.filter['users'] = this.selectedUserFilter.map(r => {
        return r.data.id;
      });
    } else {
      this.filter['users'] = [];
    }

    this.processService.searchByFilter(searchTerm, this.filter, page).subscribe(result => {
      this.loading = true;

      this.zone.run(() => {
        this.processes = result;
        this.loading = false;

      });
    }, error => {
      this.loading = false;
      console.error(error);
      this.messageService.add({
        severity: 'error',
        summary: error.data.logref,
        detail: error.data.message
      });
    });

  }

  private getColumns(): DsvsTableColumn[] {
    return [
      {field: '', header: '', sort: false, filter: '', hidden: false, format: 'WARNING', width: '2%'},
      {field: '', header: '', sort: false, filter: '', hidden: false, format: 'CHANGETYPE', width: '2%'},
      {field: '', header: '', sort: false, filter: '', hidden: false, format: 'ORIGIN', width: '4%'},
      {
        field: 'displayName',
        header: 'Auftrag',
        sort: true,
        filter: '',
        hidden: false,
        format: 'NAME',
        width: '13%',
        centered: true
      },
      {field: '', header: 'Formular', sort: false, filter: '', hidden: false, format: 'PRODUCT', width: '12%', centered: true},
      {field: '', header: 'Status', sort: false, filter: '', hidden: false, format: 'STEPNAME', width: '17%'},
      {
        field: 'returndate',
        header: 'Rückgabe',
        sort: true,
        filter: '',
        hidden: false,
        format: 'RETURNDATE',
        width: '10%',
        centered: true
      },
      {field: '', header: 'Dyn.', sort: false, filter: '', hidden: false, format: 'DYNAMISATION', width: '6%', centered: true},
      {field: '', header: 'Gruppe', sort: false, filter: '', hidden: false, format: 'GROUP', width: '5%', centered: true},
      {field: '', header: 'Serviceupdate', sort: false, filter: '', hidden: false, format: 'SU', width: '10%'},
      {field: '', header: 'Bearbeiter', sort: false, filter: '', hidden: false, format: 'EDITORS', width: '9%'},
      {field: 'lastModifiedDate', header: 'Datum', sort: true, filter: '', hidden: false, format: 'DATE_TIME', width: '10%'}
      // {field: '', header: '', sort: false, filter: '', hidden: false, width: '10%', format: 'ACTION'}
    ];
  }

  onProcessDetail(process: any) {
    if (process && process.data && process.data.id) {
      this.router.navigate(['/processes/' + process.data.id]);
    } else if (process.id) {
      this.router.navigate(['/processes/' + process.id]);
    }
  }

  showCreateDialog() {
    this.showCreateModal = true;
  }

  showSearch() {
    this.showSearchInput = true;
  }

  hideSearch() {
    this.showSearchInput = false;
    this.onReloadData();
  }

  getConflictingProcesses(processes: ProcessHalItem[]) {
    this.conflictingProcesses = processes;
  }

  checkConflict(rowData) {
    if (this.conflictingProcesses) {
      for (let i = 0; i < this.conflictingProcesses.length; i++) {
        if (this.conflictingProcesses[i].data.id === rowData.data.id) {
          return true;
        }
      }
    }
    return false;
  }

  filterReset() {
    this.selectedStateFilter = [];
    this.filterChange(FilterChangeState.CUSTOM);
  }

  filterMyRole() {
    this.filterChange(FilterChangeState.MYROLE);
  }

  filterMine() {
    this.filterChange(FilterChangeState.MINE);

  }

  filterToDo() {
    this.filterChange(FilterChangeState.TODO);
  }

  filterChange(state: FilterChangeState) {

    if (state === FilterChangeState.CUSTOM) {
      this.filter.todo = null;
      this.filter.mine = null;
      this.filter.myRole = null;
    }

    if (state === FilterChangeState.MINE || state === FilterChangeState.TODO) {
      this.selectedRoleFilter = [];
      this.selectedStateFilter = [];
      this.selectedUserFilter = [];
    }

    if (state === FilterChangeState.MYROLE) {

      if (this.filter.myRole) {
        this.filter.myRole = null;
      } else {
        this.filter.myRole = {role: this.getRoleFromSettings()};
      }
      this.filter.todo = null;
      this.filter.mine = null;

    }

    if (state === FilterChangeState.MINE) {

      if (this.filter.mine) {
        this.filter.mine = null;
      } else {
        this.filter.mine = {role: this.getRoleFromSettings()};
      }
      this.filter.todo = null;
      this.filter.myRole = null;

    }

    if (state === FilterChangeState.TODO) {
      if (this.filter.todo) {
        this.filter.todo = null;
      } else {
        this.filter.todo = {role: this.getRoleFromSettings()};
      }
      this.filter.mine = null;
      this.filter.myRole = null;
    }

    this.onReloadData();

  }

  getRoleFromSettings(): string {
    return this.dsvsSettings.getOptionValue('WFG_ROLE', null);
  }

  // TODO: bitte in eine PIPE + ggf. Helper auslagern
  checkIfDateIsNear(checkDate: Date): number {
    const currentDate = new Date();
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);
    checkDate = new Date(checkDate);
    const diffInTime = currentDate.getTime() - checkDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    if (checkDate <= currentDate) {
      return -1;
    } else {
      if (diffInDays >= -3) {
        return Math.floor(-diffInDays);
      } else {
        return null;
      }
    }
  }

}
