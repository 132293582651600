import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { Scanpool } from '../interfaces/dtos/scanpool';

export class ScanpoolHalItem extends HalItemImpl<Scanpool> {
  constructor(data: Scanpool,
    client: HalClient,
    factory: HalClientFactory) {
    super(data, client, factory);
  }

}

export class ScanpoolHalPage extends HalPageImpl<Scanpool, ScanpoolHalItem> {

}

export class ScanpoolRelationPage extends HalRelationPage<Scanpool, ScanpoolHalItem, ScanpoolHalPage> {
  constructor(relation: string,
    item: HalItem<any>) {
    super(relation, item, ScanpoolHalPage, ScanpoolHalItem);
  }
}

export class ScanpoolRelationItem extends HalRelationItem<Scanpool, ScanpoolHalItem> {
  constructor(relation: string,
    item: HalItem<any>) {
    super(relation, item, ScanpoolHalItem);
  }
}
