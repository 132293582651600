import {Inject, Injectable} from '@angular/core';
import { DsvsPage, DsvsSearchableReadEntityService } from '@dsvs/dsvs-shared-ui-lib';
import {HalClient} from '@dsvs/hal-client';
import {HalReadServiceImpl} from '../hal/hal.read.service';
import {HalRelation} from '../hal/hal.relation';
import {FES_V1_CLIENT_TOKEN} from '../hal/hal.token';
import {UserHalItem, UserHalPage} from '../hal/user.hal.item';
import {User} from '../interfaces/dtos/user';
import {Observable} from 'rxjs/index';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

export interface UserService extends DsvsSearchableReadEntityService<User> {
  syncUsers(): Observable<any>;

  searchUserByRole(searchTerm: string, filter: any, options?: DsvsPage): Observable<any>;
}

@Injectable({
              providedIn: 'root'
            })
export class UserServiceImpl
  extends HalReadServiceImpl<User, UserHalItem, UserHalPage>
  implements UserService {

  private backend: string = environment.backend;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, private http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'user', collection: 'users'},
      UserHalPage,
      UserHalItem);
  }

  syncUsers(): Observable<any> {
    return this.http.put(
      this.backend + this.relation.collection + '/sync', null
    );
  }

  searchUserByRole(searchTerm: string, filter: any, options?: DsvsPage): Observable<UserHalPage> {
    return super.search(searchTerm, options, {name: 'role', data: {filters: filter}});
  }
}
