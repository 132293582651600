import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StepContextHalItem } from '@dsvs/workflow-generator-dto';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { VersionHalItem } from '../../../../shared/hal/version.hal.item';

@Component({
  selector: 'fes-process-detail-contact',
  templateUrl: './process-detail-contact.component.html',
  styleUrls: ['./process-detail-contact.component.scss']
})
export class ProcessDetailContactComponent implements OnInit, OnDestroy {

  @Input()
  process: ProcessHalItem;

  @Input()
  version: VersionHalItem;

  @Input()
  currentStepContext: StepContextHalItem;

  hoveredIndex: number;
  hoveredRole: any;


  mainRoleUsers: Map<string, string> = new Map<string, string>();

  roleUsers: Array<string> = new Array<string>();

  constructor() {
  }

  ngOnInit() {
    this.process.mainroleuser.async.subscribe(
      mainRoleUsers => {
        mainRoleUsers.content.forEach(
          mainRoleUser => {
            mainRoleUser.role.async.subscribe(
              mainRole => {
                mainRoleUser.mainuser.async.subscribe(
                  mainUser => {
                    this.mainRoleUsers.set(mainRole.data.id, mainUser.data.id);
                  }
                );
              }
            );
          }
        );
      }
    );
  }

  ngOnDestroy(): void {
  }

  isMainRoleUser(role, user) {
    if (role.data && user.data) {
      return this.mainRoleUsers.get(role.data.id) === user.data.id;
    } else {
      return false;
    }
  }

  hover(hoverIndex: number, role: any) {
    this.hoveredIndex = hoverIndex;
    this.hoveredRole = role;
  }

  hoverOut() {
    this.hoveredIndex = undefined;
    this.hoveredRole = undefined;
  }

  sortByRoles() {
    // const sorted = this.process.roleusers.sync.content.sort((a, b) => a.role.sync.data.displayName > b.role.sync.data.displayName
    // ? 1 : a.role.sync.data.displayName === b.role.sync.data.displayName ? 0 : -1);
    // return sorted;

    //this.process.roleusers.async.subscribe( value => value.content.forEach( ) )
    let roleUserHalItems = this.process.roleusers.sync.content;
    return roleUserHalItems.sort((a, b) => a.role.sync.data.displayName > b.role.sync.data.displayName
      ? 1 : a.role.sync.data.displayName === b.role.sync.data.displayName ? 0 : -1);
  }

}
