import { Formular } from '../../formular';
import { Version } from '../../version';
import { XdpData } from '../../xdpdata';
import { ValidationData } from '../validation-data';

export class VersionDocument extends ValidationData {

  constructor(formular: Formular,
              xdpData: XdpData,
              version: Version,
              missingFields: string[],
              increaseMajor: boolean,
              public newForm: boolean,
              public firstVersion: boolean,
              public parentVersion: string,
              public parentFassung: string
  ) {
    super(formular, xdpData, version, missingFields, increaseMajor);
  }

}
