import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableReadEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DevVersionHalItem, DevVersionHalPage } from '../hal/devversion.hal.item';
import { HalReadServiceImpl } from '../hal/hal.read.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { DevVersion } from '../interfaces/dtos/dev-version';
import { EFileTypes } from '../interfaces/dtos/enums/EFileTypes';

export interface DevVersionService extends DsvsSearchableReadEntityService<DevVersion> {

  cancelDevVersion(devVersionId: string, cancelComment: string): Observable<DevVersion>;

  validateDevVersion(devVersionId: string): Observable<DevVersion>;

  downloadDevVersion(devVersionId: String, fileType: EFileTypes): Observable<HttpResponse<Blob>>;

  createDevVersion(devVersionId: string, downloadComment: string): Observable<DevVersion>;

  releaseDevVersion(devVersionId: string,
                    releaseComment: string,
                    increaseMajor: boolean): Observable<DevVersion>;

  uploadDevVersion(version: DevVersion, versionFile: any): Observable<DevVersion>;
}

@Injectable({
  providedIn: 'root'
})
export class DevVersionServiceImpl
  extends HalReadServiceImpl<DevVersion, DevVersionHalItem, DevVersionHalPage>
  implements DevVersionService {

  private backend: string = environment.backend;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, private http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'devversion', collection: 'devversions'},
      DevVersionHalPage,
      DevVersionHalItem
    );
  }

  cancelDevVersion(devVersionId: string, cancelComment: string): Observable<DevVersion> {
    const formdata: FormData = new FormData();
    formdata.append('cancelComment', cancelComment);

    return this.http.post<DevVersion>(
      this.backend + this.relation.collection + `/${devVersionId}/cancel`,
      formdata
    );
  }

  validateDevVersion(devVersionId: string): Observable<DevVersion> {
    return this.http.get<DevVersion>(
      this.backend + this.relation.collection + `/${devVersionId}/validate`
    );
  }

  deleteDevVersion(devVersionId: string): Observable<void> {
    return this.http.delete<void>(
      this.backend + this.relation.collection + `/${devVersionId}`
    );
  }

  downloadDevVersion(devVersionId: String, fileType: EFileTypes = EFileTypes.XDP): Observable<HttpResponse<Blob>> {
    return this.http.get(
      this.backend + this.relation.collection + `/${devVersionId}/download?fileType=${fileType}`,
      {responseType: 'blob', observe: 'response'}
    );
  }

  createDevVersion(devVersionId: string, tag?: string): Observable<DevVersion> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');

    const formdata: FormData = new FormData();
    if (tag) {
      formdata.append('tag', tag);
    }
    return this.http.post<DevVersion>(
      this.backend + this.relation.collection + `/${devVersionId}/create`,
      formdata,
      {headers}
    );
  }

  releaseDevVersion(devVersionId: string,
                    releaseComment: string,
                    increaseMajor: boolean): Observable<DevVersion> {
    const formdata: FormData = new FormData();
    formdata.append('releaseComment', new Blob([releaseComment], {type: 'application/json'}));

    return this.http.post<DevVersion>(
      this.backend + this.relation.collection + `/${devVersionId}/release?increaseMajor=${increaseMajor}`,
      formdata
    );
  }

  uploadDevVersion(devVersion: DevVersion, versionFile: any): Observable<DevVersion> {
    const formdata: FormData = new FormData();
    formdata.append('devVersion', new Blob([JSON.stringify(devVersion)], {type: 'application/json'}));
    formdata.append('versionFile', versionFile);

    return this.http.post<DevVersion>(this.backend + this.relation.collection, formdata, {});
  }

  states(): Observable<any> {
    return this.http.get<Map<string, string>>(
      this.backend + this.relation.collection + '/states'
    ).map(states => {
        return Object.keys(states).map(
          key => (
            {label: states[key], value: key}
          ));
      }
    );
  }

}
