import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DsvsFormHelper } from '@dsvs/dsvs-shared-ui-lib';
import { DevVersionHalItem } from '../../../../shared/hal/devversion.hal.item';
import { TagHalPage } from '../../../../shared/hal/tag.hal.item';
import { VersionHalItem } from '../../../../shared/hal/version.hal.item';
import { ETagCategory } from '../../../../shared/interfaces/dtos/enums/ETagCategory';
import { TagServiceImpl } from '../../../../shared/services/tag.service';
import { DevVersionChange } from '../../DevVersionChange';

@Component(
  {
    selector: 'fes-dev-version-download-modal',
    templateUrl: './dev-version-download-modal.component.html',
    styleUrls: ['./dev-version-download-modal.component.scss']
  }
)
export class DevVersionDownloadModalComponent implements OnInit, OnDestroy {

  downloadDevVersionForm: FormGroup;

  _tags: TagHalPage;

  suggestions: string[] = [];

  _selectedVersion: DevVersionHalItem | VersionHalItem;

  @Input()
  set selectedVersion(selectedVersion: DevVersionHalItem | VersionHalItem) {
    this._selectedVersion = selectedVersion;
    if (this.downloadDevVersionForm) {
      if (this.selectedVersion && this.selectedVersion.data && (<any>this.selectedVersion.data).tag) {
        this.downloadDevVersionForm.controls['tag'].setValue((<any>this.selectedVersion.data).tag);
      } else {
        this.downloadDevVersionForm.controls['tag'].setValue('');
      }
    }
  }

  get selectedVersion() {
    return this._selectedVersion;
  }

  @Input()
  visible = false;

  @Input()
  existingVersions = '';

  @Output()
  displayChange = new EventEmitter();

  @Output()
  downloadDevVersion = new EventEmitter<DevVersionChange>();

  constructor(private tagService: TagServiceImpl) {
  }

  onClose() {
    this.resetForms();
    this.displayChange.emit(false);
  }

  ngOnDestroy() {
    this.displayChange.unsubscribe();
  }

  ngOnInit() {
    this.createDownloadDevVersionForm();
    this.search('');
  }

  search(searchTerm: string) {
    this.tagService.getTagsForCategories([ETagCategory.TEMP_DEV_TASK], searchTerm)
    .subscribe((result) => {
      this.suggestions = result.content.map(item => item.data.name);
      this._tags = result;
    });
  }

  checkState(tagName) {
    if (tagName && tagName.length > 0 && this._tags && this._tags.content) {
      return this._tags.content.find(item => item.data.name === tagName);
    } else {
      return null;
    }
  }

  onCancelDownloadDevVersionClicked($event: Event) {
    this.visible = false;
    this.downloadDevVersionForm.reset();
    this.onClose();
  }

  onDownloadDevVersionClicked($event: Event) {
    if (this.downloadDevVersionForm.valid) {
      this.downloadDevVersion.emit({
          tag: this.downloadDevVersionForm.get('tag').value
        }
      );
    } else {
      DsvsFormHelper.validateAllFormFields(this.downloadDevVersionForm);
    }
  }

  createDownloadDevVersionForm() {
    this.downloadDevVersionForm = new FormGroup(
      {
        tag: new FormControl('', Validators.required)
      });
  }

  resetForms() {
    this.downloadDevVersionForm.reset();
  }

}
