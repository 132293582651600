/* tslint:disable:max-line-length */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Routes} from '@angular/router';
import {DsvsAuthenticationModule, DsvsHalAsyncLoaderModule, DsvsInlineEditModule, DsvsSharedModule, DsvsTableModule} from '@dsvs/dsvs-shared-ui-lib';
import {WorkflowActionContextServiceImpl, WorkflowCategoryServiceImpl, WorkflowContextServiceImpl, WorkflowGeneratorModule, WorkflowModule, WorkflowStepContextServiceImpl} from '@dsvs/workflow-generator-ui-lib';
import {ButtonModule} from 'primeng/button';
import {ChipsModule} from 'primeng/chips';
import {DialogModule} from 'primeng/dialog';
import {
  AccordionModule,
  AutoCompleteModule,
  CardModule,
  CheckboxModule,
  DropdownModule,
  FieldsetModule,
  InputSwitchModule,
  InputTextareaModule,
  InputTextModule,
  MessageModule,
  MultiSelectModule,
  PaginatorModule,
  PanelModule,
  ProgressSpinnerModule,
  SidebarModule,
  SplitButtonModule,
  TabViewModule,
  TooltipModule
} from 'primeng/primeng';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {FesWorkflowModule} from '../fes-workflow/fes-workflow.module';
import {FormularModule} from '../formular/formular.module';
import {SharedModule} from '../shared/shared.module';
import {ProcessCreateFormComponent} from './components/process-create/process-create-form/process-create-form.component';
import {ProcessCreateWorkflowComponent} from './components/process-create/process-create-workflow/process-create-workflow.component';
import {ProcessCreateComponent} from './components/process-create/process-create.component';
import {ProcessDetailActionComponent} from './components/process-detail/process-detail-actions/process-detail-action.component';
import {ProcessDetailContactComponent} from './components/process-detail/process-detail-contact/process-detail-contact.component';
import {ProcessDetailDetailsComponent} from './components/process-detail/process-detail-details/process-detail-details.component';
import {ProcessDetailDownloadComponent} from './components/process-detail/process-detail-download/process-detail-download.component';
import {ProcessDetailHistoryComponent} from './components/process-detail/process-detail-history/process-detail-history.component';
import {ProcessDetailJobComponent} from './components/process-detail/process-detail-job/process-detail-job.component';
import {ProcessDetailManuscriptsComponent} from './components/process-detail/process-detail-manuscripts/process-detail-manuscripts.component';
import {ProcessDetailNoteComponent} from './components/process-detail/process-detail-note/process-detail-note.component';
import {ProcessDetailRequirementComponent} from './components/process-detail/process-detail-requirement/process-detail-requirement.component';
import {ProcessDetailShippingComponent} from './components/process-detail/process-detail-shipping/process-detail-shipping.component';
import {ProcessDetailSidebarComponent} from './components/process-detail/process-detail-sidebar/process-detail-sidebar.component';
import {ProcessDetailStepActionsComponent} from './components/process-detail/process-detail-step-actions/process-detail-step-actions.component';
import {ProcessDetailComponent} from './components/process-detail/process-detail.component';
import {ProcessListComponent} from './components/process-list/process-list.component';
import {ProcessResolver} from './resolver/process-resolver';
import {ProcessListWarningComponent} from './components/process-list/process-list-warning/process-list-warning.component';
import {ProcessListNumberComponent} from './components/process-list/process-list-number/process-list-number.component';
import {ProcessDetailStepCommentComponent} from './components/process-detail/process-detail-step-comment/process-detail-step-comment.component';
import {ProcessDetailManuscriptsDetailComponent} from './components/process-detail/process-detail-manuscripts/process-detail-manuscripts-detail/process-detail-manuscripts-detail.component';
import {ProcessDetailManuscriptsIconComponent} from './components/process-detail/process-detail-manuscripts/process-detail-manuscripts-icon/process-detail-manuscripts-icon.component';
import {UpdateProcessDetailService} from './components/process-detail/process-detail-update.service';

/* tslint:enable:max-line-length */

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    /** PrimeNG **/
    TabViewModule,
    PanelModule,
    CardModule,
    ButtonModule,
    TableModule,
    FieldsetModule,
    InputTextModule,
    InputTextareaModule,
    DropdownModule,
    AccordionModule,
    InputSwitchModule,
    ChipsModule,
    SidebarModule,
    CheckboxModule,
    DialogModule,
    AutoCompleteModule,
    MessageModule,
    ProgressSpinnerModule,
    StepsModule,
    PaginatorModule,
    MultiSelectModule,
    TooltipModule,
    SplitButtonModule,
    /** Dsvs-Shared **/
    DsvsSharedModule,
    DsvsTableModule,
    DsvsInlineEditModule,
    DsvsAuthenticationModule,
    DsvsTableModule,
    DsvsHalAsyncLoaderModule,
    /** Workflow **/
    WorkflowModule,
    WorkflowGeneratorModule, // TODO: remove this if workflowgenerator has shared libs for services
    /** Fes **/
    FesWorkflowModule,
    FormularModule, // TODO: Remove if Formular search and other shared are moved to shared lib
    SharedModule

  ],
  declarations: [
    /** Process-Create **/
    ProcessCreateComponent,
    /** Process-Detail **/
    ProcessDetailComponent,
    ProcessDetailDetailsComponent,
    ProcessDetailSidebarComponent,
    ProcessDetailRequirementComponent,
    ProcessDetailShippingComponent,
    ProcessDetailStepActionsComponent,
    ProcessDetailContactComponent,
    ProcessDetailHistoryComponent,
    ProcessDetailJobComponent,
    ProcessDetailActionComponent,
    ProcessDetailNoteComponent,
    ProcessDetailDownloadComponent,
    ProcessDetailStepCommentComponent,
    ProcessDetailManuscriptsComponent,
    ProcessDetailManuscriptsDetailComponent,
    ProcessDetailManuscriptsIconComponent,
    /** Process-List **/
    ProcessListComponent,
    ProcessCreateWorkflowComponent,
    ProcessCreateFormComponent,
    ProcessListWarningComponent,
    ProcessListNumberComponent
  ],
  providers: [
    ProcessResolver,
    {provide: WorkflowActionContextServiceImpl, useClass: WorkflowActionContextServiceImpl},
    {provide: WorkflowContextServiceImpl, useClass: WorkflowContextServiceImpl},
    {provide: WorkflowStepContextServiceImpl, useClass: WorkflowStepContextServiceImpl},
    {provide: WorkflowCategoryServiceImpl, useClass: WorkflowCategoryServiceImpl},
    UpdateProcessDetailService

  ],
  exports: [ProcessListWarningComponent, ProcessListNumberComponent]

})
export class ProcessModule {

  static getRoutes(): Routes {
    return [
      {path: 'create', component: ProcessListComponent},
      {path: 'update', component: ProcessListComponent},
      {path: 'delete', component: ProcessListComponent},
      {
        path: ':id', component: ProcessDetailComponent, resolve: {
          process: ProcessResolver
        }
      },
      {path: '', component: ProcessListComponent}
    ];
  }
}
