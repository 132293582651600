import { Component, Input, OnInit, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DsvsSettings } from '@dsvs/dsvs-shared-ui-lib';

import {
  ActionContextHalItem,
  CrewHalItem,
  StepContextHalItem,
  UserHalItem, UserHalPage,
  WorkflowActionDataDto, WorkflowActionExtensionService,
  WorkflowRoleUserServiceImpl
} from '@dsvs/workflow-generator-ui-lib';

import { MessageService } from 'primeng/api';
import { Observable, Subject, Subscription } from 'rxjs';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { WGContextHalItem } from '../../../../shared/hal/wfg/wgcontext.hal.item';
import { ProcessServiceImpl } from '../../../../shared/services/process.service';
import { UserServiceImpl } from '../../../../shared/services/user.service';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

/**
 * Class zur abbildung auf Frontendseite der Logik, die hinter der Action JuristAuswahl steht.
 * Formsbereitstellung, Datenbereitstellung, Datenverarbeitung und DatenSpeicherung
 */
@Component({
  selector: 'fes-fes-workflow-action-jurist-select',
  templateUrl: './fes-workflow-action-jurist-select.component.html',
  styleUrls: ['./fes-workflow-action-jurist-select.component.scss']
})
export class FesWorkflowActionJuristSelectComponent
  extends FesWorkflowActionComponent
    implements OnInit {

  selectedJurist: UserHalItem = null;
  selectJurist: UserHalItem = null;

  process: ProcessHalItem;

  @Output()
  juriste: string;

  roleUserId = null;

  crew: CrewHalItem;

  keyvalue: string = 'JUR';
  roleValue: string;


  mainRoleUsers: Map<string, string> = new Map<string, string>();

  private Jurist_select:string;
  usersHal: UserHalItem[] = [];

  constructor(
             public userService:  UserServiceImpl,
             private processService: ProcessServiceImpl,
             private roleUserService: WorkflowRoleUserServiceImpl,
             private dsvsSettings: DsvsSettings,
             private workflowActionExtensionService: WorkflowActionExtensionService,
             messagingService: MessageService) {
    super(messagingService);

  }

  /**
   * Initialisierung der Action.
   */
  ngOnInit() {
    this.loadProcess();
  }

  /**
   * Bündelt alle Daten in einer Datentransferklasse
   */
  collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      juristId: this.selectJurist,
      roleUserId: this.roleUserId,
      juristName: this.juriste
    };
  }

  /***
   * Gibt beim Klick auf einen User dessen Id zurück
   */
  isValid(): boolean {
      return !!(this.selectJurist);
  }


  /**
   *  Zeigt alle User die unter der Rolle Jurist dem Prozess zugewiesen sind
   *  view: 'role',
   *  viewData: JSON.stringify({role: 'JURISTEN'})
   * @param $event
   */
  public fetchJuristen = ($event): Observable<any> => {
    // gibt mir der role hinter der key
    const  data = new Subject<any>();
     this.rolevalueFromRoleKey(event).subscribe( result =>{
       this.juristDataFromRoleValue(result).subscribe( result1 =>{
         this.usersHal  = result1;
         data.next(this.usersHal);  // hier war den Fehler. Data muss direkt weitergegeben werden
       });
      // data.next(this.usersHal);
    });
    return data.asObservable();
  }

  /**
   * holt den RoleValue from Key
   */
  private rolevalueFromRoleKey= ($event): Observable<any> =>{
    const success = new Subject<any>();
     this.processService.getRoleFromIdentifierkey(this.keyvalue, this.process.data.id).subscribe(
      result =>{
        this.roleValue = result[0];
        success.next(this.roleValue);
      }
    );
     return success.asObservable();
  };

  /**
   * Abfrage den Juristen
   * @param role
   */
  private juristDataFromRoleValue(role:string): Observable<any>{
    const succes = new Subject<UserHalPage>();
    (<ProcessHalItem>this.process).users.setParams({
        view: 'role',
        viewData: JSON.stringify({role: this.roleValue}),
        size: 100
      }).async.subscribe(
        result => {
          // @ts-ignore
          succes.next(result.content)
        });
    return succes.asObservable();
  };



  /**
   * Selektiert einen Jurist aus der Liste, welche alle dem Prozess zugewiesenen Juristen enthält.
   *
   * @param value
   * @param component
   * @constructor
   */
  OnJuristselect = (value, component):  Observable<any> => {
   const roleUserItem = component.model;
    this.selectJurist = component.modelValue.id;
    roleUserItem.data.mainUserId = this.selectJurist;
    this.roleUserId = roleUserItem.data.activeMainUserId;
    this.selectedJurist = roleUserItem.data.mainUserId;
    (<string>this.juriste) = value.value.displayName;

    this.process.save();
    this.saveAction();
    return Observable.of(this.selectJurist);
  };

  /**
   * Läd alle dem Prozess zugewiesenen Juristen
   */
  loadProcess() {

    this.context.process.async.subscribe(pro => {
      (<ProcessHalItem>this.process) = pro;

      if (this.juriste == null) {
        this.processService.getMainUserRole(pro.getData().id, this.keyvalue).subscribe(
          result =>{
            this.Jurist_select = result[0];
          }
        );
        pro.mainroleuser.async.subscribe(
          mainusers => {
            mainusers.content.forEach(
              mainuser => mainuser.mainuser.async.subscribe(
                curUsr =>{
                  if(curUsr.getData().displayName == this.Jurist_select){
                    (<string>this.juriste) = curUsr.data.displayName
                  }
                }
              )
            )
          }
        )
      }

    });
    return this.juriste;
  }

  /**
   * Aktualisiert den Context und Inhalt der JusristenComponente nach erfolgreicher Action
   * @param actionContext
   */
  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
    this.loadProcess();
  }

  onError(error: any) {
    super.onError(error);
  }

}
