import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../../environments/environment';
import { FesUserSettingsService } from '../services/fes-user-settings.service';

@Injectable()
export class GlobalworkspaceHeaderInterceptor implements HttpInterceptor {

  constructor(private fesUserSettings: FesUserSettingsService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    let clonedRequest = req;

    // only add if request is to our backend
    if (req.url.indexOf(environment.backend) !== -1 || req.url.indexOf(environment.wgurl) !== -1) {

      const workspaceId = this.fesUserSettings.getActiveWorkspaceId();

      if (workspaceId) {
        const headers = req.headers
          .set(
            'X-Workspace-Id',
            workspaceId
          );
        clonedRequest = req.clone({
          headers: headers
        });
      }
    }

    return next.handle(clonedRequest);
  }
}
