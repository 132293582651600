import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../../../../../services/communication-service';
import { ActionType } from '../../../../fes-workflow/enums/action-type.enum';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { FileServiceImpl } from '../../../../shared/services/file.service';

@Component({
  selector: 'fes-process-detail-requirement',
  templateUrl: './process-detail-requirement.component.html',
  styleUrls: ['./process-detail-requirement.component.scss']
})
export class ProcessDetailRequirementComponent implements OnInit, OnDestroy {

  @Input()
  process: ProcessHalItem;

  numberOfRequirements = 3;

  private actionSubscriptionRef: Subscription = null;

  constructor(private fileService: FileServiceImpl, private communicationService: CommunicationService) {
  }

  ngOnInit() {
    this.actionSubscriptionRef = this.communicationService.messages.subscribe(
      (message) => {
        if (
          message.scope === 'ACTION'
          && message.message === ActionType.RequirementSelect
          || message.message === ActionType.RequirementAdd
        ) {
          this.process.requirements.reload();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.actionSubscriptionRef.unsubscribe();
  }

  downloadFile(fileId) {
    this.fileService.downloadFileUrl(fileId);
  }

  showAllRequirements() {
    this.numberOfRequirements = 2000;
  }

}
