import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { Workspace } from '../interfaces/dtos/workspace';
import { FormularRelationPage } from './formular.hal.item';
import { ServiceupdateRelationPage } from './serviceupdate.hal.item';
import { UserRelationPage } from './user.hal.item';

export class WorkspaceHalItem extends HalItemImpl<Workspace> {
  constructor(data: Workspace,
              client: HalClient,
              factory: HalClientFactory) {
    super(data, client, factory);
    this.forms = new FormularRelationPage('forms', this);
    this.users = new UserRelationPage('users', this);
    this.serviceupdates = new ServiceupdateRelationPage('serviceupdates', this);
  }

  readonly forms: FormularRelationPage;
  readonly users: UserRelationPage;
  readonly serviceupdates: ServiceupdateRelationPage;
}

export class WorkspaceHalPage extends HalPageImpl<Workspace, WorkspaceHalItem> {

}

export class WorkspaceRelationPage extends HalRelationPage<Workspace, WorkspaceHalItem, WorkspaceHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, WorkspaceHalPage, WorkspaceHalItem);
  }
}

export class WorkspaceRelationItem extends HalRelationItem<Workspace, WorkspaceHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, WorkspaceHalItem);
  }
}
