import { Component, OnInit } from '@angular/core';
import { DsvsBreadcrumbService, DsvsPermissionService, DsvsTableColumn, DsvsToolbarItem } from '@dsvs/dsvs-shared-ui-lib';
import { Page } from '@dsvs/hal-client';
import { MessageService } from 'primeng/components/common/messageservice';
import { ZapPermissions } from '../../../../enums/zap-permissions.enum';
import { RequirementHalItem } from '../../../shared/hal/requirement.hal.item';
import { Requirement } from '../../../shared/interfaces/dtos/requirement';
import { FesUserSettingsService } from '../../../shared/services/fes-user-settings.service';
import { FormServiceImpl } from '../../../shared/services/form.service';
import { RequirementServiceImpl } from '../../../shared/services/requirement.service';

export enum RequirementActions {
  CREATE_REQUIREMENT = 'CREATE_REQUIREMENT'
}

@Component({
  selector: 'fes-requirements-search',
  templateUrl: './requirements-search.component.html',
  styleUrls: ['./requirements-search.component.scss']
})
export class RequirementsSearchComponent implements OnInit {

  loading = false;

  editRequirement: Requirement;
  deleteRequirementItem: RequirementHalItem;
  showEditModal = false;
  showDeleteModal = false;
  isNew = false;

  canDelete = false;

  requirements: any;

  reqTableColums: DsvsTableColumn[];

  constructor(private requirementService: RequirementServiceImpl,
              private formService: FormServiceImpl,
              private messageService: MessageService,
              private breadcrumbService: DsvsBreadcrumbService,
              private permissionService: DsvsPermissionService,
              private fesSettings: FesUserSettingsService) {
  }

  ngOnInit() {
    this.initToolbar();
    this.reqTableColums = this.getColumns();

    this.fesSettings.onActiveWorkspaceChanged().subscribe(result => {
      this.onReloadData('');
    });

    this.canDelete = this.permissionService.hasPermission([ZapPermissions.FES_REQUIREMENT_DELETE]);
  }

  initToolbar(): void {
    this.breadcrumbService.setMenuItems([
      {label: 'Anforderungen'}
    ]);
    this.breadcrumbService.setToolbarItems(this.getToolbarItems());
    this.breadcrumbService.setToolbarVisible(true);
  }

  getToolbarItems(): DsvsToolbarItem[] {
    return [
      {
        id: RequirementActions.CREATE_REQUIREMENT,
        icon: 'note_add',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_REQUIREMENT_CREATE]),
        tooltip: 'Neue Anforderung',
        callback: () => this.showCreateDialog()
      }
    ];
  }

  showCreateDialog() {
    this.showEditModal = true;
    this.isNew = true;
  }

  private getColumns(): DsvsTableColumn[] {
    return [
      {field: 'forms', header: 'Formularnummer', sort: true, filter: '', hidden: false, width: '15%'},
      {field: 'displayName', header: 'Kurzbeschreibung', sort: true, filter: '', hidden: false, width: '30%'},
      {field: 'createdByName', header: 'Einsteller', sort: true, filter: '', hidden: false, width: '15%'},
      {field: 'state', header: 'Status', sort: true, filter: '', hidden: false, width: '10%'},
      {field: 'process', header: 'Auftrag', sort: true, filter: '', hidden: false, width: '10%'},
      {field: 'lastModifiedDate', header: 'Datum', sort: true, filter: '', hidden: false, width: '10%', format: 'DATE'},
      {field: '', header: 'Aktion', width: '10%', format: 'ACTION'}
    ];
  }

  onReloadData(searchTerm: string, page?: Page) {
    this.loading = true;
    this.requirements = null;
    this.requirementService.search(searchTerm, page).subscribe(result => {
      this.requirements = result;
      this.loading = false;
    }, error2 => {
      this.loading = false;
      console.error(error2);
    });
  }

  onDetail(object: Requirement) {
    this.editRequirement = object;
    this.isNew = false;
    this.showEditModal = true;
  }

  deleteClick(obj) {
    this.deleteRequirementItem = obj;
    this.showDeleteModal = true;
  }

  deleteRequirement() {
    this.requirementService.delete(this.deleteRequirementItem.data).subscribe(
      result => {
        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: '\"' + this.deleteRequirementItem.data.displayName + '\" wurde gelöscht.'
        });
        this.showDeleteModal = false;
        this.deleteRequirementItem = null;
        this.onReloadData('');
      }, error2 => {
        console.error(error2);
      });
  }

  receiveShowEditModal($event) {
    this.showEditModal = $event;
    this.onReloadData('');
  }

}
