import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DsvsBreadcrumbService, DsvsPermissionService, DsvsTableColumn, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {Page} from '@dsvs/hal-client';
import {CategoryHalItem, CrewHalItem} from '@dsvs/workflow-generator-dto';
import {Observable} from 'rxjs';
import {ZapPermissions} from '../../../../../enums/zap-permissions.enum';
import {FormularHalItem, FormularHalPage} from '../../../../shared/hal/formular.hal.item';
import {WorkspaceHalItem, WorkspaceHalPage} from '../../../../shared/hal/workspace.hal.item';
import {EGroupTypes} from '../../../../shared/interfaces/dtos/enums/EGroupTypes';
import {ETagCategory} from '../../../../shared/interfaces/dtos/enums/ETagCategory';
import {FesUserSettingsService} from '../../../../shared/services/fes-user-settings.service';
// import {ETagCategory, getColorForTag} from '../../../../shared/interfaces/dtos/enums/ETagCategory';
import {FormServiceImpl} from '../../../../shared/services/form.service';
import {TagServiceImpl} from '../../../../shared/services/tag.service';
import {WorkspaceServiceImpl} from '../../../../shared/services/workspace.service';
import {FormularInformationService} from '../list/formular-information.service';

@Component({
  selector: 'fes-formular-list-workspace',
  templateUrl: './formular-list-workspace.component.html',
  styleUrls: ['./formular-list-workspace.component.scss']
})
export class FormularListWorkspaceComponent implements OnInit {
  public ZapPermissions = ZapPermissions;
  loading = false;

  forms: FormularHalPage;

  reqTableColums: DsvsTableColumn[];

  displayedWorkspace: WorkspaceHalItem;
  displayedCrew: CrewHalItem;
  displayedCategory: CategoryHalItem;

  isWorkspaceEditable = false;
  isTagEditable = false;
  isGroupEditable = false;

  constructor(
    private formService: FormServiceImpl,
    private tagService: TagServiceImpl,
    private breadcrumbService: DsvsBreadcrumbService,
    public workspaceService: WorkspaceServiceImpl,
    private router: Router,
    private permissionService: DsvsPermissionService,
    private settings: FesUserSettingsService,
    private fesSettings: FesUserSettingsService,
    private formInformationService: FormularInformationService
  ) {
  }

  ngOnInit() {

    this.isWorkspaceEditable = this.permissionService.hasPermission([ZapPermissions.FES_FORM_WORKSPACE_UPDATE]);
    this.isTagEditable = this.permissionService.hasPermission([ZapPermissions.FES_FORM_TAG_UPDATE]);
    this.isGroupEditable = this.permissionService.hasPermission([ZapPermissions.FES_FORM_FIGROUP_UPDATE]);

    this.reqTableColums = this.getColumns();

    this.fesSettings.onActiveWorkspaceChanged().subscribe(result => {
      this.reloadData('');
    });
  }

  fetchColorsForTags = (tag, component) => {
    if (tag && tag.data && tag.data.category) {
      return this.tagService.getColorForTagId(tag.data.category);
    } else {
      return null;
    }

  };

  fetchTags = (event) => {
    return this.tagService.getTagsForCategories(
      [
        ETagCategory.OTHER,
        ETagCategory.PRODUCT,
        ETagCategory.PRODUCT_VERSION,
        ETagCategory.WORKING_GROUP,
        ETagCategory.TICKET_CATEGORY,
        ETagCategory.FI_APPLICATION,
        ETagCategory.TEMP_DEV_TASK,
        ETagCategory.PROJEKT
      ],
      event.query
    );
  };

  fetchGroups = (event): Observable<any[]> => {
    return Observable.of(
      [
        {label: '-', value: EGroupTypes.NONE},
        {label: EGroupTypes.G1, value: EGroupTypes.G1},
        {label: EGroupTypes.G2, value: EGroupTypes.G2}
      ]);
  };

  private getColumns(): DsvsTableColumn[] {
    return [
      {field: 'active', header: '', format: 'ACTIVE_VERSION_ACTIVE', sort: true, width: '5%'},
      {field: 'articleNumber', header: 'Nummer', sort: true, width: '10%', centered: true},
      {field: 'title', header: 'Titel', format: 'ACTIVE_VERSION', width: '50%'},
      {field: 'fassung', header: 'Fassung', format: 'ACTIVE_VERSION', width: '10%', centered: true},
      {field: 'version', header: 'Version', format: 'ACTIVE_VERSION', width: '5%', centered: true},
      {field: 'displayName', header: 'Verantwortliche', format: 'CREW', width: '10%', centered: true},
      {field: 'group', header: 'Gruppe', format: 'GROUP', width: '10%', centered: true}
    ];
  }

  onDetail(formularHalItem: any) {
    this.formInformationService.showWorkspaceFormsOnly = true;
    this.router.navigate(['/forms/' + formularHalItem.id]);
  }

  reloadData(searchTerm: string, page?: Page) {
    this.loading = true;
    if (page && page.sort.length === 0) {
      page.sort = [{property: 'articleNumber', order: 'ASC'}];
    }
    this.formService.searchCurrentWorkspace(searchTerm, this.settings.getActiveWorkspaceId(), page).subscribe(result => {
        this.forms = result;
        this.loading = false;
      },
      err => {
        this.loading = false;
        console.error(err);
      });
  }

  fetchWorkspaces = ($event, inlineEdit): Observable<WorkspaceHalPage> => {
    return this.workspaceService.search($event.query);
  };

  updateWorkspace(workspaceHalItem: WorkspaceHalItem, component: InlineEditComponent): Observable<WorkspaceHalItem> {
    const form = component.model;
    return workspaceHalItem.forms.add(form);
  }

  updateTags(value, component: InlineEditComponent): Observable<WorkspaceHalItem> {
    const tags = component.modelValue;
    return component.model.tags.update(tags);
  }

  updateGroup(value, component: InlineEditComponent): Observable<EGroupTypes> {
    component.model.activeVersion.sync.data.group = component.modelValue;
    return component.model.activeVersion.sync.save(component.model.activeVersion.sync.data);
  }

  saveRow = (value, component: InlineEditComponent): Observable<FormularHalItem> => {
    return component.model.save();
  };

  receiveHideWorkspaceModal($event) {
    this.displayedWorkspace = null;
  }

  receiveHideCrewModal($event) {
    this.displayedCrew = null;
  }

  receiveHideCategroyModal($event) {
    this.displayedCategory = null;
  }

  showCrew(event: MouseEvent, crew: CrewHalItem) {
    event.stopPropagation();
    this.displayedCrew = crew;
  }

}

