import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HalFcrudServiceImpl } from '../hal/hal.fcrud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { ManuscriptHalItem, ManuscriptHalPage } from '../hal/manuscript.hal.item';
import { Id } from '../interfaces/dtos/Id';
import { Manuscript } from '../interfaces/dtos/manuscript';

export interface ManuscriptService extends DsvsSearchableCrudEntityService<Manuscript> {
}

@Injectable({
  providedIn: 'root'
})
export class ManuscriptServiceImpl extends HalFcrudServiceImpl<Manuscript, ManuscriptHalItem, ManuscriptHalPage> implements ManuscriptService {

  private backend: string = environment.backend;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'manuscript', collection: 'manuscripts'},
      http,
      ManuscriptHalPage,
      ManuscriptHalItem
    );
  }

  canEdit(entityId: Id): Observable<boolean> {
    return this.http.get<boolean>(this.backend + this.relation.collection + '/' + entityId + '/canEdit', {});
  }

}
