import { Component, Input, NgZone, OnInit } from '@angular/core';
import { DsvsTableColumn } from '@dsvs/dsvs-shared-ui-lib';
import { Page } from '@dsvs/hal-client';
import { WorkflowType } from '@dsvs/workflow-generator-dto';
import { WorkflowHalPage, WorkflowServiceImpl } from '@dsvs/workflow-generator-ui-lib';
import { CreationType, ProcessCreateWizzardItemComponent } from '../process-create-wizzard-item.component';

enum ProcessCreateFormErrors {
  SELECT_WORKFLOW = 'SELECT_WORKFLOW'
}

@Component({
  selector: 'fes-process-create-workflow',
  templateUrl: './process-create-workflow.component.html',
  styleUrls: ['./process-create-workflow.component.scss']
})
export class ProcessCreateWorkflowComponent
  extends ProcessCreateWizzardItemComponent
  implements OnInit {

  loading: boolean;
  cols: DsvsTableColumn[];
  workflows: WorkflowHalPage;
  errors = [];

  @Input() processTypes: WorkflowType[];

  constructor(private workflowService: WorkflowServiceImpl, private zone: NgZone) {
    super();

    this.cols = [
      {field: 'displayName', header: 'Bezeichnung'},
      {field: 'type', header: 'Typ', format: 'WORKFLOW_TYPE'}
    ];
  }

  ngOnInit() {
    this.onReloadData('');
  }

  hasError(errorType: string) {
    return this.errors.find(err => err === errorType) != null;
  }

  displayErrors(): void {
    if (!this.wizzardData.workflow) {
      this.errors.push('SELECT_WORKFLOW');
    }
  }

  onReloadData(searchTerm: string, page?: Page) {
    this.loading = true;

    let categoryId = null;
    if (this.wizzardData.creationType === CreationType.SELECT) {
      categoryId = this.wizzardData.form.data.categoryId;
    } else {
      categoryId = null;
    }

    // TODO: move this to workflow-ui-lib with implementation in workflowService as separate method
    this.workflowService.search(searchTerm, page,
      {
        name: 'category',
        data: {
          processtypes: this.processTypes,
          categoryid: categoryId,
          productid: (this.wizzardData.form) ? this.wizzardData.form.data.id : null
        }
      }).subscribe(
      result => {
        this.zone.run(() => {
          this.loading = false;
          this.workflows = result;
        });
      }, error2 => {
        this.loading = false;
        console.error(error2);
      });
  }

  isValid(): boolean {
    return (this.wizzardData.workflow != null);
  }

}
