import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {ActionContextHalItem, ProductHalItem, WorkflowHalPage} from '@dsvs/workflow-generator-dto';
import {WorkflowActionDataDto, WorkflowServiceImpl} from '@dsvs/workflow-generator-ui-lib';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable, of as observableOf} from 'rxjs';
import {ProcessHalItem} from '../../../../shared/hal/process.hal.item';
import {FormServiceImpl} from '../../../../shared/services/form.service';
import {FesWorkflowActionComponent} from '../fes-workflow-action';

@Component({
  selector: 'fes-workflow-action-formular-create',
  templateUrl: './fes-workflow-action-formular-create.component.html',
  styleUrls: ['./fes-workflow-action-formular-create.component.scss']
})
export class FesWorkflowActionFormularCreateComponent extends FesWorkflowActionComponent implements OnInit {

  formularExisting = '';
  formularExists = false;
  formularCreated = false;
  loading = false;

  processHalItem: ProcessHalItem;
  formularHalItem: ProductHalItem;

  constructor(
    public workflowService: WorkflowServiceImpl,
    private formularService: FormServiceImpl,
    messageService: MessageService
  ) {
    super(messageService);
  }

  ngOnInit() {
    this.createUploadForm();
    this.loadFormular();
  }

  loadFormular() {
    this.context.process.async.subscribe(
      processHalItem => {
        this.processHalItem = processHalItem;
        this.processHalItem.product.reload();
        this.processHalItem.product.async.subscribe(
          formularHalItem => {
            this.formularHalItem = formularHalItem;
            this.formularHalItem.workflows.async.subscribe(
              workflowHalPage => {
                if (this.formularHalItem.data.dataId) {
                  this.inputForm.patchValue({articleNumber: this.formularHalItem.data.displayName});
                  this.inputForm.controls.articleNumber.disable();
                  this.formularCreated = true;
                }
                if (workflowHalPage.content && workflowHalPage.content.length > 0) {
                  this.inputForm.patchValue({workflows: workflowHalPage.content});
                  this.formularCreated = true;
                }
              }
            );

          }
        );
      });
  }

  createUploadForm() {
    this.inputForm = new FormGroup(
      {
        articleNumber: new FormControl('', Validators.required),
        workflows: new FormControl([], Validators.required)
      });
  }

  resetError() {
    this.formularExisting = '';
    this.formularExists = false;
  }

  checkForm() {
    if (
      this.inputForm.controls.articleNumber
      && this.inputForm.controls.articleNumber.value
      && this.inputForm.controls.articleNumber.value.length > 0
    ) {
      this.loading = true;
      this.formularService.search(this.inputForm.controls.articleNumber.value).subscribe(
        formularHalPage => {
          this.loading = false;
          if (formularHalPage && formularHalPage.content && formularHalPage.content.length > 0) {
            this.formularExisting = formularHalPage.content.map(a => a.data.displayName).join(', ');
            if (formularHalPage.content.find(a => a.data.articleNumber === this.inputForm.controls.articleNumber.value)) {
              this.formularExists = true;
            }
          } else {
            this.formularExisting = '';
            this.formularExists = false;
          }
        }
      );
    }
  }

  onSuccess(actionContextHalItem: ActionContextHalItem) {
    super.onSuccess(actionContextHalItem);
    this.loadFormular();
  }

  fetchWorkflows = ($event): Observable<WorkflowHalPage> => {
    return this.workflowService.search($event.query);
  };

  updateWorkflows = (value, component: InlineEditComponent) => {
    this.inputForm.patchValue({'workflows': component.modelValue});
    return observableOf(component.modelValue);
  };

  collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      articleNumber: this.inputForm.value.articleNumber,
      workflowIds: this.inputForm.value.workflows.map(a => a.data.id)
    };
  }

  add() {
    this.saveAction();
  }

}
