import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DsvsInlineEditModule, DsvsSharedModule, DsvsTableModule} from '@dsvs/dsvs-shared-ui-lib';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {PanelModule} from 'primeng/panel';
import {ConfirmDialogModule, SplitButtonModule} from 'primeng/primeng';
import {SharedModule} from '../shared/shared.module';
import {FiReleaseDatesDetailComponent} from './components/fi-release-dates-detail/fi-release-dates-detail.component';
import {FiReleaseDatesSearchComponent} from './components/fi-release-dates-search/fi-release-dates-search.component';
import {FiReleaseDatesComponent} from './components/fi-release-dates.component';

@NgModule({
  declarations: [
    FiReleaseDatesComponent,
    FiReleaseDatesDetailComponent,
    FiReleaseDatesSearchComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PanelModule,
    ButtonModule,
    DialogModule,
    DsvsTableModule,
    DsvsSharedModule,
    DsvsInlineEditModule,
    ConfirmDialogModule,
    SplitButtonModule
  ]
})
export class FiReleaseDatesModule {
}
