import { Component, OnInit } from '@angular/core';
import { DsvsBreadcrumbService } from '@dsvs/dsvs-shared-ui-lib';
import { Message } from 'primeng/api';

@Component({
  selector: 'fes-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  /* Messages */
  msgs: Message[] = [];

  constructor(private breadcrumbService: DsvsBreadcrumbService) {
  }

  ngOnInit() {
    /** Items in der Breadcrumb-Bar initialisieren (Breadcrumb-Verlauf und Werkzeugleiste) */
    this.breadcrumbService.setMenuItems([]);
    this.breadcrumbService.setToolbarVisible(false);
  }
}
