/* tslint:disable:max-line-length */
import { Injectable } from '@angular/core';
import { WorkflowActionExtension, WorkflowActionExtensionService } from '@dsvs/workflow-generator-ui-lib';
import {
    FesWorkflowActionChangetypeChangeHistoryComponent
} from '../components/actions/fes-workflow-action-changetype-change/fes-workflow-action-changetype-change-history/fes-workflow-action-changetype-change-history.component';
import {
    FesWorkflowActionChangetypeChangeComponent
} from '../components/actions/fes-workflow-action-changetype-change/fes-workflow-action-changetype-change.component';
import {
    FesWorkflowActionCommentAddHistoryComponent
} from '../components/actions/fes-workflow-action-comment-add/fes-workflow-action-comment-add-history/fes-workflow-action-comment-add-history.component';
import {
    FesWorkflowActionCommentAddComponent
} from '../components/actions/fes-workflow-action-comment-add/fes-workflow-action-comment-add.component';
import {
    FesWorkflowActionCompletiondateexpectedHistoryComponent
} from '../components/actions/fes-workflow-action-completiondateexpected/fes-workflow-action-completiondateexpected-history/fes-workflow-action-completiondateexpected-history.component';
import {
    FesWorkflowActionCompletiondateexpectedComponent
} from '../components/actions/fes-workflow-action-completiondateexpected/fes-workflow-action-completiondateexpected.component';
import {
    FesWorkflowActionCrewEditHistoryComponent
} from '../components/actions/fes-workflow-action-crew-edit/fes-workflow-action-crew-edit-history/fes-workflow-action-crew-edit-history.component';
import {
    FesWorkflowActionCrewEditComponent
} from '../components/actions/fes-workflow-action-crew-edit/fes-workflow-action-crew-edit.component';
import {
    FesWorkflowActionDeliverydateHistoryComponent
} from '../components/actions/fes-workflow-action-deliverydate/fes-workflow-action-deliverydate-history/fes-workflow-action-deliverydate-history.component';
import {
    FesWorkflowActionDeliverydateComponent
} from '../components/actions/fes-workflow-action-deliverydate/fes-workflow-action-deliverydate.component';
import {
    FesWorkflowActionDeliverydateexpectedHistoryComponent
} from '../components/actions/fes-workflow-action-deliverydateexpected/fes-workflow-action-deliverydateexpected-history/fes-workflow-action-deliverydateexpected-history.component';
import {
    FesWorkflowActionDeliverydateexpectedComponent
} from '../components/actions/fes-workflow-action-deliverydateexpected/fes-workflow-action-deliverydateexpected.component';
import {
    FesWorkflowActionDevelopmentdateexpectedHistoryComponent
} from '../components/actions/fes-workflow-action-developmentdateexpected/fes-workflow-action-developmentdateexpected-history/fes-workflow-action-developmentdateexpected-history.component';
import {
    FesWorkflowActionDevelopmentdateexpectedComponent
} from '../components/actions/fes-workflow-action-developmentdateexpected/fes-workflow-action-developmentdateexpected.component';
import {
    FesWorkflowActionDevversionAddHistoryComponent
} from '../components/actions/fes-workflow-action-devversion-add/fes-workflow-action-devversion-add-history/fes-workflow-action-devversion-add-history.component';
import {
    FesWorkflowActionDevversionAddComponent
} from '../components/actions/fes-workflow-action-devversion-add/fes-workflow-action-devversion-add.component';
import {
    FesWorkflowActionDevversionFinalizeComponent
} from '../components/actions/fes-workflow-action-devversion-finalize/fes-workflow-action-devversion-finalize.component';
import {
    FesWorkflowActionDevversionFinalizeHistoryComponent
} from '../components/actions/fes-workflow-action-devversion-finalize/fes-workflow-action-process-devversion-finalize-history/fes-workflow-action-devversion-finalize-history.component';
import {
    FesWorkflowActionDmsNumberHistoryComponent
} from '../components/actions/fes-workflow-action-dms-number/fes-workflow-action-dms-number-history/fes-workflow-action-dms-number-history.component';
import {
    FesWorkflowActionDmsNumberComponent
} from '../components/actions/fes-workflow-action-dms-number/fes-workflow-action-dms-number.component';
import {
    FesWorkflowActionFinalVersionSelectHistoryComponent
} from '../components/actions/fes-workflow-action-final-version-select/fes-workflow-action-final-version-select-history/fes-workflow-action-final-version-select-history.component';
import {
    FesWorkflowActionFinalVersionSelectComponent
} from '../components/actions/fes-workflow-action-final-version-select/fes-workflow-action-final-version-select.component';
import {
    FesWorkflowActionFireleasedateSelectHistoryComponent
} from '../components/actions/fes-workflow-action-fireleasedate-select/fes-workflow-action-fireleasedate-select-history/fes-workflow-action-fireleasedate-select-history.component';
import {
    FesWorkflowActionFireleasedateSelectComponent
} from '../components/actions/fes-workflow-action-fireleasedate-select/fes-workflow-action-fireleasedate-select.component';
import {
    FesWorkflowActionFormularCreateComponent
} from '../components/actions/fes-workflow-action-formular-create/fes-workflow-action-formular-create.component';
import {
    FesWorkflowActionFormularCreateHistoryComponent
} from '../components/actions/fes-workflow-action-formular-create/fes-workflow-action-requirement-add-history/fes-workflow-action-formular-create-history.component';
import {
    FesWorkflowActionGroupchangeHistoryComponent
} from '../components/actions/fes-workflow-action-groupchange/fes-workflow-action-groupchange-history/fes-workflow-action-groupchange-history.component';
import {
    FesWorkflowActionGroupchangeComponent
} from '../components/actions/fes-workflow-action-groupchange/fes-workflow-action-groupchange.component';
import {
    FesWorkflowActionJuristModeratorComponent
} from '../components/actions/fes-workflow-action-jurist-select/fes-workflow-action-jurist-moderator/fes-workflow-action-jurist-moderator.component';
import {
    FesWorkflowActionJuristSelectComponent
} from '../components/actions/fes-workflow-action-jurist-select/fes-workflow-action-jurist-select.component';
import {
    FesWorkflowActionNoteAddHistoryComponent
} from '../components/actions/fes-workflow-action-note-add/fes-workflow-action-note-add-history/fes-workflow-action-note-add-history.component';
import {
    FesWorkflowActionNoteAddComponent
} from '../components/actions/fes-workflow-action-note-add/fes-workflow-action-note-add.component';
import {
  FesWorkflowActionOsplusActivationdateSelectHistoryComponent
} from '../components/actions/fes-workflow-action-osplus-activationdate-select/fes-workflow-action-osplus-activationdate-select-history/fes-workflow-action-osplus-activationdate-select-history.component';
import {
  FesWorkflowActionOsplusActivationdateSelectComponent
} from '../components/actions/fes-workflow-action-osplus-activationdate-select/fes-workflow-action-osplus-activationdate-select.component';
import {
    FesWorkflowActionProcessAddManuscriptHistoryComponent
} from '../components/actions/fes-workflow-action-process-add-manuscript/fes-workflow-action-process-add-file-history/fes-workflow-action-process-add-manuscript-history.component';
import {
    FesWorkflowActionProcessAddManuscriptComponent
} from '../components/actions/fes-workflow-action-process-add-manuscript/fes-workflow-action-process-add-manuscript.component';
import {
    FesWorkflowActionRequirementAddHistoryComponent
} from '../components/actions/fes-workflow-action-requirement-add/fes-workflow-action-requirement-add-history/fes-workflow-action-requirement-add-history.component';
import {
    FesWorkflowActionRequirementAddComponent
} from '../components/actions/fes-workflow-action-requirement-add/fes-workflow-action-requirement-add.component';
import {
    FesWorkflowActionRequirementSelectHistoryComponent
} from '../components/actions/fes-workflow-action-requirement-select/fes-workflow-action-requirement-select-history/fes-workflow-action-requirement-select-history.component';
import {
    FesWorkflowActionRequirementSelectComponent
} from '../components/actions/fes-workflow-action-requirement-select/fes-workflow-action-requirement-select.component';
import {
    FesWorkflowActionReturndateHistoryComponent
} from '../components/actions/fes-workflow-action-returndate/fes-workflow-action-returndate-history/fes-workflow-action-returndate-history.component';
import {
    FesWorkflowActionReturndateComponent
} from '../components/actions/fes-workflow-action-returndate/fes-workflow-action-returndate.component';
import {
    FesWorkflowActionServiceupdateSelectHistoryComponent
} from '../components/actions/fes-workflow-action-serviceupdate-select/fes-workflow-action-serviceupdate-select-history/fes-workflow-action-serviceupdate-select-history.component';
import {
    FesWorkflowActionServiceupdateSelectComponent
} from '../components/actions/fes-workflow-action-serviceupdate-select/fes-workflow-action-serviceupdate-select.component';
import {
    FesWorkflowActionTemplateStyleSelectHistoryComponent
} from '../components/actions/fes-workflow-action-template-style-select/fes-workflow-action-template-style-select-history/fes-workflow-action-template-style-select-history.component';
import {
    FesWorkflowActionTemplateStyleSelectComponent
} from '../components/actions/fes-workflow-action-template-style-select/fes-workflow-action-template-style-select.component';
import {
    FesWorkflowActionVersionSelectHistoryComponent
} from '../components/actions/fes-workflow-action-version-select/fes-workflow-action-version-select-history/fes-workflow-action-version-select-history.component';
import {
    FesWorkflowActionVersionSelectComponent
} from '../components/actions/fes-workflow-action-version-select/fes-workflow-action-version-select.component';
import { ActionType } from '../enums/action-type.enum';

/* tslint:enable:max-line-length */

@Injectable({
    providedIn: 'root'
})
export class FesWorkflowActionService {

    constructor(private workflowActionExtensionService: WorkflowActionExtensionService) {
  }

  registerActions() {

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.RequirementAdd,
        title: 'Anforderung erstellen',
        actionComponent: FesWorkflowActionRequirementAddComponent,
        actionHistoryComponent: FesWorkflowActionRequirementAddHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.DevVersionUpload,
        title: 'Entwicklerversion',
        actionComponent: FesWorkflowActionDevversionAddComponent,
        actionHistoryComponent: FesWorkflowActionDevversionAddHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.CrewEdit,
        title: 'Crew bearbeiten',
        actionComponent: FesWorkflowActionCrewEditComponent,
        actionHistoryComponent: FesWorkflowActionCrewEditHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.FormularCreate,
        title: 'Formular erstellen',
        actionComponent: FesWorkflowActionFormularCreateComponent,
        actionHistoryComponent: FesWorkflowActionFormularCreateHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.DevVersionFinalization,
        title: 'Entwicklerversion finalisieren',
        actionComponent: FesWorkflowActionDevversionFinalizeComponent,
        actionHistoryComponent: FesWorkflowActionDevversionFinalizeHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.VersionSelect,
        title: 'Version wählen',
        actionComponent: FesWorkflowActionVersionSelectComponent,
        actionHistoryComponent: FesWorkflowActionVersionSelectHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.ServiceupdateSelect,
        title: 'Serviceupdate wählen',
        actionComponent: FesWorkflowActionServiceupdateSelectComponent,
        actionHistoryComponent: FesWorkflowActionServiceupdateSelectHistoryComponent
      }
    );
    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.RequirementSelect,
        title: 'Anforderung auswählen',
        actionComponent: FesWorkflowActionRequirementSelectComponent,
        actionHistoryComponent: FesWorkflowActionRequirementSelectHistoryComponent
      }
    );
    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.ProcessAddManuscript,
        title: 'Manuskript hinzufügen',
        actionComponent: FesWorkflowActionProcessAddManuscriptComponent,
        actionHistoryComponent: FesWorkflowActionProcessAddManuscriptHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.NoteAdd,
        title: 'Notiz hinzufügen',
        actionComponent: FesWorkflowActionNoteAddComponent,
        actionHistoryComponent: FesWorkflowActionNoteAddHistoryComponent
      }
    );
    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.CommentAdd,
        title: 'Schrittkommentar hinzufügen',
        actionComponent: FesWorkflowActionCommentAddComponent,
        actionHistoryComponent: FesWorkflowActionCommentAddHistoryComponent
      }
    );
    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.ReturnDate,
        title: 'Rückgabedatum',
        actionComponent: FesWorkflowActionReturndateComponent,
        actionHistoryComponent: FesWorkflowActionReturndateHistoryComponent
      }
    );
    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.ExpectedDeliveryDate,
        title: '',
        actionComponent: FesWorkflowActionDeliverydateexpectedComponent,
        actionHistoryComponent: FesWorkflowActionDeliverydateexpectedHistoryComponent
      }
    );
    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.ExpectedDevelopmentStartDate,
        title: '',
        actionComponent: FesWorkflowActionDevelopmentdateexpectedComponent,
        actionHistoryComponent: FesWorkflowActionDevelopmentdateexpectedHistoryComponent
      }
    );
    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.FirstDeliveryDate,
        title: '',
        actionComponent: FesWorkflowActionDeliverydateComponent,
        actionHistoryComponent: FesWorkflowActionDeliverydateHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.ExpectedCompletionDate,
        title: '',
        actionComponent: FesWorkflowActionCompletiondateexpectedComponent,
        actionHistoryComponent: FesWorkflowActionCompletiondateexpectedHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.ChangetypeChange,
        title: '',
        actionComponent: FesWorkflowActionChangetypeChangeComponent,
        actionHistoryComponent: FesWorkflowActionChangetypeChangeHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.DmsNumber,
        title: '',
        actionComponent: FesWorkflowActionDmsNumberComponent,
        actionHistoryComponent: FesWorkflowActionDmsNumberHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.FiReleaseDateSelect,
        title: '',
        actionComponent: FesWorkflowActionFireleasedateSelectComponent,
        actionHistoryComponent: FesWorkflowActionFireleasedateSelectHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.GroupChange,
        title: '',
        actionComponent: FesWorkflowActionGroupchangeComponent,
        actionHistoryComponent: FesWorkflowActionGroupchangeHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.FinalVersionSelect,
        title: '',
        actionComponent: FesWorkflowActionFinalVersionSelectComponent,
        actionHistoryComponent: FesWorkflowActionFinalVersionSelectHistoryComponent
      }
    );

      this.workflowActionExtensionService.extend(
          <WorkflowActionExtension>{
              identifier: ActionType.JuristSelect,
              title: 'Zuständigen Jurist auswählen',
              actionComponent: FesWorkflowActionJuristSelectComponent,
              actionHistoryComponent: FesWorkflowActionJuristModeratorComponent
          }
      );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.TemplateStyleSelect,
        title: 'Vorlagenart auswählen',
        actionComponent: FesWorkflowActionTemplateStyleSelectComponent,
        actionHistoryComponent: FesWorkflowActionTemplateStyleSelectHistoryComponent
      }
    );

    this.workflowActionExtensionService.extend(
      <WorkflowActionExtension>{
        identifier: ActionType.OSPLUSACTIVATIONDATE_SELECT,
        title: 'OSPlus Aktivierungsdatum auswählen',
        actionComponent: FesWorkflowActionOsplusActivationdateSelectComponent,
        actionHistoryComponent: FesWorkflowActionOsplusActivationdateSelectHistoryComponent
      }
    );

  }
}
