import {Component, OnDestroy, OnInit} from '@angular/core';
import {DsvsBreadcrumbService, DsvsPermissionService} from '@dsvs/dsvs-shared-ui-lib';

export enum TagActions {
  CREATE_TAG = 'CREATE_TAG',
}

@Component({
  selector: 'fes-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnDestroy {

  constructor(
    private breadcrumbService: DsvsBreadcrumbService,
    private permissionService: DsvsPermissionService
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.setMenuItems([
      {label: 'Tags'}
    ]);
    this.breadcrumbService.setToolbarVisible(true);
  }

  ngOnDestroy() {
  }
}
