import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DsvsFormHelper } from '@dsvs/dsvs-shared-ui-lib';

@Component(
  {
    selector: 'fes-dev-version-cancel-modal',
    templateUrl: './dev-version-cancel-modal.component.html',
    styleUrls: ['./dev-version-cancel-modal.component.scss']
  }
)
export class DevVersionCancelModalComponent implements OnInit, OnDestroy {

  cancelDevVersionForm: FormGroup;

  @Input()
  visible = false;

  @Output()
  displayChange = new EventEmitter();

  @Output()
  cancelDevVersion = new EventEmitter<string>();

  constructor() {
  }

  onClose() {
    this.resetForms();
    this.displayChange.emit(false);
  }

  ngOnDestroy() {
    this.displayChange.unsubscribe();
  }

  ngOnInit() {
    this.createCancelDevVersionForm();
  }

  onCancelCancelDevVersionClicked($event: Event) {
    this.visible = false;
    this.onClose();
  }

  onCancelDevVersionClicked($event: Event) {
    if (this.cancelDevVersionForm.valid) {
      this.cancelDevVersion.emit(this.cancelDevVersionForm.get('cancelComment').value);
    } else {
      DsvsFormHelper.validateAllFormFields(this.cancelDevVersionForm);
    }
  }

  createCancelDevVersionForm() {
    this.cancelDevVersionForm = new FormGroup(
      {
        cancelComment: new FormControl('', Validators.required)
      });
  }

  resetForms() {
    this.cancelDevVersionForm.reset();
  }

}
