import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { FiReleaseDateHalItem, FiReleaseDateHalPage } from '../hal/fiReleaseDateHalItem';
import { HalFcrudServiceImpl } from '../hal/hal.fcrud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { FiReleaseDate } from '../interfaces/dtos/fireleasedate';

export interface FiReleaseDateService extends DsvsSearchableCrudEntityService<FiReleaseDate> {

}

@Injectable({
  providedIn: 'root'
})
export class FiReleaseDateServiceImpl
  extends HalFcrudServiceImpl<FiReleaseDate, FiReleaseDateHalItem, FiReleaseDateHalPage>
  implements FiReleaseDateService {

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'fireleasedate', collection: 'fireleasedates'},
      http,
      FiReleaseDateHalPage,
      FiReleaseDateHalItem
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class FireleasedateServiceMock extends DsvsSearchableCrudEntityServiceMock<FiReleaseDate> implements FiReleaseDateService {

  constructor() {
    super();

    this.data = [];

    for (let i = 1; i <= 100; i++) {
      this.data.push(
        <FiReleaseDate>{
          id: String(i),
          displayName: 'FIReleaseDate ' + i
        }
      );
    }

  }

}
