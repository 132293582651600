import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InlineEditComponent } from '@dsvs/dsvs-shared-ui-lib';
import { ActionContextHalItem } from '@dsvs/workflow-generator-dto';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { EChangeType } from '../../../../shared/interfaces/dtos/enums/EChangeType';
import { ProcessServiceImpl } from '../../../../shared/services/process.service';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-fes-workflow-action-changetype-change',
  templateUrl: './fes-workflow-action-changetype-change.component.html',
  styleUrls: ['./fes-workflow-action-changetype-change.component.scss']
})
export class FesWorkflowActionChangetypeChangeComponent extends FesWorkflowActionComponent implements OnInit {

  selectedChangetype: EChangeType;
  allGroups = [
    {label: 'Neu', value: EChangeType.NEU},
    {label: 'Aktualisierung', value: EChangeType.AKTUALISIERUNG},
    {label: 'Fassungsänderung', value: EChangeType.FASSUNGSAENDERUNG},
    {label: 'Löschung', value: EChangeType.LOESCHUNG}
  ];

  constructor(messageService: MessageService, private processService: ProcessServiceImpl) {
    super(messageService);
  }

  ngOnInit() {
    this.inputForm = new FormGroup(
      {
        // group: new FormControl([], Validators.required)
      });

    this.loadProcess();
  }

  public collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      changetype: this.selectedChangetype
    };
  }

  fetchChangetypes = (event): Observable<any[]> => {
    return Observable.of(this.allGroups);
  };

  updateChangetype = (value, component: InlineEditComponent): Observable<EChangeType> => {
    this.saveAction();

    return Observable.of(component.modelValue);
  };

  loadProcess() {
    this.context.process.async.subscribe(pro => {
      // this.processService.getById(pro.data.id).subscribe(process => {
      this.selectedChangetype = EChangeType[pro.data.changetype];
      // });
    });

  }

  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
    this.loadProcess();
  }

}
