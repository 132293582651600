import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InlineEditComponent } from '@dsvs/dsvs-shared-ui-lib';
import { isNil } from 'lodash';
import { MessageService } from 'primeng/components/common/messageservice';
import { Observable } from 'rxjs';
import { getExceptionMessages } from '../../../../../helper/exception-helper';
import { ProcessHalItem, ProcessHalPage } from '../../../../shared/hal/process.hal.item';
import { ProjectHalItem } from '../../../../shared/hal/project.hal.item';
import { UserHalItem, UserHalPage } from '../../../../shared/hal/user.hal.item';
import { Project } from '../../../../shared/interfaces/dtos/project';
import { FileServiceImpl } from '../../../../shared/services/file.service';
import { ProcessServiceImpl } from '../../../../shared/services/process.service';
import { ProjectServiceImpl } from '../../../../shared/services/project.service';
import { UserServiceImpl } from '../../../../shared/services/user.service';

@Component({
  selector: 'fes-projects-detail',
  templateUrl: './projects-detail.component.html',
  styleUrls: ['./projects-detail.component.scss']
})
export class ProjectsDetailComponent implements OnInit {

  project: Project;
  file: any;
  _projectHalItem: ProjectHalItem;
  processes: ProcessHalItem[] = [];
  users: UserHalItem[] = [];

  isNew = false;

  @Input()
  set projectHalItem(projectHalItem: ProjectHalItem) {
    if (projectHalItem) {
      this.project = projectHalItem.data;
      this._projectHalItem = projectHalItem;
    }
  }

  get projectHalItem(): ProjectHalItem {
    return this._projectHalItem;
  }

  @Output() showEditModalEvent = new EventEmitter<boolean>();
  @Output() success = new EventEmitter<ProjectHalItem>();

  constructor(
    private projectService: ProjectServiceImpl,
    private processService: ProcessServiceImpl,
    private fileService: FileServiceImpl,
    private userService: UserServiceImpl,
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    if (isNil(this._projectHalItem)) {
      this.isNew = true;
      this.project = <Project>{
        id: '',
        displayName: '',
        name: '',
        title: '',
        description: ''
      };
    }
  }

  fetchProcesses = ($event, inlineEdit): Observable<ProcessHalPage> => {
    return this.processService.searchProjectAvailable($event.query);
  };

  addProcess = (value, component: InlineEditComponent): Observable<ProcessHalPage> => {
    this.processes = component.modelValue;
    if (!this.isNew) {
      return this.updateProcesses(this.processes);
    } else {
      return Observable.of(null);
    }
  };

  updateProcesses(processes: ProcessHalItem[]): Observable<ProcessHalPage> {
    return this._projectHalItem.updateRelations('processes', processes.map(o => o.data.id));
  }

  fetchUsers = ($event, inlineEdit): Observable<UserHalPage> => {
    return this.userService.search($event.query);
  };

  addUser = (value, component: InlineEditComponent): Observable<UserHalPage> => {
    this.users = component.modelValue;
    if (!this.isNew) {
      return this.updateUsers(this.users);
    } else {
      return Observable.of(null);
    }
  };

  updateUsers(users: UserHalItem[]): Observable<UserHalPage> {
    return this._projectHalItem.updateRelations('users', users.map(o => o.data.id));
  }

  addFile(file) {
    this.file = file;
    if (!this.isNew) {
      this.projectService.uploadFile(this.project, this.file).subscribe(result => {
          this.projectHalItem.reload();
        },
        (err) => {
          getExceptionMessages(err).forEach(
            message => this.messageService.add(message)
          );
        }
      );
    }
  }

  onDownloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  onFileRemoved() {
    // this.fileRemoved.emit();
  }

  save = () => {
    if (this.isNew) {
      return this.projectService.create(this.project).subscribe(
        projectHalItem => {

          this._projectHalItem = projectHalItem;

          this.updateProcesses(this.processes).subscribe(
            processHalPage => {

              this.updateUsers(this.users).subscribe(
                userHalPage => {

                  if (this.file) {

                    this.projectService.uploadFile(projectHalItem.data, this.file).subscribe(
                      file => {
                        this.onCreationSuccess(projectHalItem);
                      },
                      (err) => {
                        getExceptionMessages(err).forEach(
                          message => this.messageService.add(message)
                        );
                      }
                    );
                  } else {
                    this.onCreationSuccess(projectHalItem);
                  }
                }
              );
            }
          );

        }, error => {
          console.error('Error creating project - {}', error);
          this.cancelModal();
        });
    } else {
      return this._projectHalItem.save(this.project);
    }
  };

  onCreationSuccess(projectHalItem: ProjectHalItem) {
    this.cancelModal();
    this.success.emit(projectHalItem);
    this.messageService.add({
      severity: 'success',
      summary: 'Erfolg',
      detail: 'Die Auftragssammlung wurde erstellt.'
    });
  }

  cancelModal() {
    this.showEditModalEvent.emit(false);
  }
}
