import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EFileTypes } from '../../../../../../shared/interfaces/dtos/enums/EFileTypes';
import { Formular } from '../../../../../../shared/interfaces/dtos/formular';

@Component({
  selector: 'fes-add-form-formular',
  templateUrl: './add-form-formular.component.html',
  styleUrls: ['./add-form-formular.component.scss']
})
export class AddFormFormularComponent implements OnInit {

  addFormularForm: FormGroup;

  _formular: Formular;

  @Input() fileType: EFileTypes;

  @Input() isNewForm = false;

  @Input() set formular(value: Formular) {
    this.initForm(value);
    this._formular = value;
  }

  get formular() {
    return this._formular;
  }

  ngOnInit(): void {

    this.createAddFormularForm();

  }

  initForm(formVersionDTO: Formular) {
    if (this.addFormularForm === undefined) {
      this.createAddFormularForm();
    }
    if (formVersionDTO != null && typeof this.addFormularForm !== 'undefined' && this.addFormularForm != null) {
      this.addFormularForm.setValue(
        {
          articleNumber: formVersionDTO.articleNumber ? formVersionDTO.articleNumber : '',
          description: formVersionDTO.description ? formVersionDTO.description : ''
          // tags: formVersionDTO.tags != null ? formVersionDTO.tags : [],
        });
    }
  }

  createAddFormularForm() {
    if (!this.addFormularForm) {
      this.addFormularForm = new FormGroup(
        {
          articleNumber: new FormControl(''),
          description: new FormControl('')
          // tags: new FormControl([]),
        }
      );
      if (!this.isNewForm || this.fileType === 'XDP') {
        this.addFormularForm.controls.articleNumber.disable();
      }
    }
    // if (!this.isNewForm) {
    // this.addFormularForm.controls.tags.disable();
    // }
  }
}
