import { Formular } from '../formular';
import { Version } from '../version';
import { XdpData } from '../xdpdata';

export abstract class ValidationData {

  protected constructor(
    public formular: Formular,
    public xdpData: XdpData,
    public version: Version,
    public missingFields: string[],
    public increaseMajor: boolean
  ) {
  }

}


