import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CommunicationService} from '../../../../../services/communication-service';
import {ActionType} from '../../../../fes-workflow/enums/action-type.enum';
import {ProcessHalItem} from '../../../../shared/hal/process.hal.item';
import {VersionHalItem} from '../../../../shared/hal/version.hal.item';
import {UpdateProcessDetailService} from '../process-detail-update.service';

@Component({
  selector: 'fes-process-detail-shipping',
  templateUrl: './process-detail-shipping.component.html',
  styleUrls: ['./process-detail-shipping.component.scss']
})
export class ProcessDetailShippingComponent implements OnInit, OnDestroy {

  @Input()
  process: ProcessHalItem;

  @Input()
  version: VersionHalItem;

  private actionSubscriptionRef: Subscription = null;

  constructor(private communicationService: CommunicationService,
              private updateProcessService: UpdateProcessDetailService) {

  }

  ngOnInit() {
    this.updateProcessService.trigger$.subscribe(() => {
      this.process.reload();
    });

    this.actionSubscriptionRef = this.communicationService.messages.subscribe(
      (message) => {
        if (message.scope === 'ACTION') {
          if (message.message === ActionType.DmsNumber) {
            this.process.reload();
          }
        }
      });

  }

  ngOnDestroy(): void {
    this.actionSubscriptionRef.unsubscribe();
  }
}
