import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsPage, DsvsSearchableCrudEntityService, DsvsSearchableCrudEntityServiceMock } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HalCrudServiceImpl } from '../hal/hal.crud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { ProcessHalItem, ProcessHalPage } from '../hal/process.hal.item';
import { UserHalPage } from '../hal/user.hal.item';
import { Id } from '../interfaces/dtos/Id';
import { Process, ProcessCreateDTO } from '../interfaces/dtos/process';

export interface ProcessService extends DsvsSearchableCrudEntityService<Process> {

  searchProjectAvailable(searchTerm: string, options?: DsvsPage): Observable<ProcessHalPage>;

  searchServiceupdateAvailable(searchTerm: string, options?: DsvsPage): Observable<ProcessHalPage>;

  searchByFilter(searchTerm: string, filter: any, options?: DsvsPage): Observable<ProcessHalPage>;

  searchByState(searchTerm: string, states: String[], options?: DsvsPage): Observable<any>;

  searchUserByRole(searchTerm: string, filter: any, options?: DsvsPage): Observable<any>;
}

@Injectable({
  providedIn: 'root'
})
export class ProcessServiceImpl extends HalCrudServiceImpl<Process, ProcessHalItem, ProcessHalPage> implements ProcessService {

  private backend: string = environment.backend;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, private http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'process', collection: 'processes'},
      ProcessHalPage,
      ProcessHalItem);
  }

  searchProjectAvailable(searchTerm: string, options?: DsvsPage): Observable<ProcessHalPage> {
    return super.search(searchTerm, options, {name: 'project-available'});
  }

  searchServiceupdateAvailable(searchTerm: string, options?: DsvsPage): Observable<ProcessHalPage> {
    return super.search(searchTerm, options, {name: 'serviceupdate-available'});
  }

  searchByFilter(searchTerm: string, filter: any, options?: DsvsPage): Observable<ProcessHalPage> {
    return super.search(searchTerm, options, {name: 'filtered', data: {filters: filter}});
  }

  searchUserByRole(searchTerm: string, filter: any, options?: DsvsPage): Observable<ProcessHalPage> {
    return super.search(searchTerm, options, {name: 'role', data: {filters: filter}});
  }

  searchByResultVersionId(resultversionid: string): Observable<ProcessHalPage> {
    return super.search(null, null, {name: 'byresultversionid', data: {resultversionid: resultversionid}});
  }

  searchByState(searchTerm: string, states: String[], options?: DsvsPage): Observable<ProcessHalPage> {
    options = <DsvsPage>{
      sort: [],
      size: 2000,
      page: 0
    };
    return super.search(searchTerm, options, {name: 'filtered-by-state', data: {'states': states}});
  }

  createProcess(processData: ProcessCreateDTO): Observable<any> {
    // TODO: not returning HALItem
    return this.http.post<any>(this.backend + this.relation.collection + '/withdata', processData, {});
  }

  canCreateScanpool(entityId: Id): Observable<boolean> {
    return this.http.get<boolean>(this.backend + this.relation.collection + '/' + entityId + '/cancreatescanpool', {});
  }

  getRoleFromIdentifierkey(key_role: string, entityId: Id ):Observable<string[]>{
    let headers = new HttpHeaders();
    headers.append('Content-Type',' application/json; charset=UTF-8');
    return this.http.get<string[]>(this.backend + this.relation.collection + '/' + entityId + '/' + key_role , {});
  }

  getMainUserRole(processId: Id, key_role: string):Observable<string[]>{
    return this.http.get<string[]>(this.backend + this.relation.collection + '/roles/' + key_role + '/' + processId , {});
  }

}

@Injectable({
  providedIn: 'root'
})
export class ProcessServiceMock extends DsvsSearchableCrudEntityServiceMock<Process> implements ProcessService {

  constructor() {
    super();

    this.data = [];

    for (let i = 1; i <= 100; i++) {
      this.data.push(
        <Process>{
          id: String(i),
          displayName: 'Process ' + i,
          workflowContextId: 'aa212-3abb-1212-33'
        }
      );
    }

  }

  searchProjectAvailable(searchTerm: string, options?: DsvsPage): Observable<any> {
    return Observable.of([]);
  }

  searchServiceupdateAvailable(searchTerm: string, options?: DsvsPage): Observable<any> {
    return Observable.of([]);
  }

  searchByFilter(searchTerm: string, filter: any, options?: DsvsPage): Observable<any> {
    return Observable.of([]);
  }

  searchByState(searchTerm: string, states: String[], options?: DsvsPage): Observable<any> {
    return Observable.of([]);
  }

  searchUserByRole(searchTerm: string, filter: any, options?: DsvsPage): Observable<any> {
    return Observable.of([]);
  }
}
