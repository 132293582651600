import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ProcessHalItem} from '../../../../shared/hal/process.hal.item';
import {ProcessServiceImpl} from '../../../../shared/services/process.service';

@Component({
  selector: 'fes-process-list-warning',
  templateUrl: './process-list-warning.component.html',
  styleUrls: ['./process-list-warning.component.scss']
})
export class ProcessListWarningComponent implements OnInit, OnDestroy {

  @Input()
  item: ProcessHalItem;

  @Input()
  productName: string;

  @Output()
  hover: EventEmitter<ProcessHalItem[]> = new EventEmitter<ProcessHalItem[]>();

  conflictingProcesses: ProcessHalItem[];
  conflictingProcessesString = '';

  constructor(private processService: ProcessServiceImpl) {
  }

  ngOnInit() {
    this.checkConflictingProducts();
  }

  ngOnDestroy(): void {
  }

  checkConflictingProducts() {

    this.processService.searchByState(
      this.productName,
      [
        'Erstellung',
        'FI Integration',
        'Bereitstellung',
        'Zur Veröffentlichung',
        'Freigabe',
        'Umsetzung'
      ]
    ).subscribe(result => {
      this.conflictingProcesses = result.content;

      for (let i = 0; i < this.conflictingProcesses.length; i++) {
        this.conflictingProcessesString += i > 0 ? ', ' + this.conflictingProcesses[i].data.displayName
          : this.conflictingProcesses[i].data.displayName;
      }

    }, error => {
      console.error(error);
    });
  }

  hoverIn() {
    this.hover.emit(this.conflictingProcesses);
  }

  hoverOut() {
    this.hover.emit([]);
  }
}
