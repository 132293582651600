import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  DsvsPermissionService,
  DsvsSearchEvent,
  DsvsSettings,
  DsvsTableColumn,
  InlineEditComponent
} from '@dsvs/dsvs-shared-ui-lib';
import { Page } from '@dsvs/hal-client';
import { MenuItem, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { ZapPermissions } from '../../../../../../enums/zap-permissions.enum';
import { TokenDeleteDialogComponent } from '../../../../../shared/components/token-delete-dialog/token-delete-dialog.component';
import { ExternRecordHalItem, ExternRecordHalPage } from '../../../../../shared/hal/externrecord.hal.item';
import { FormularHalItem } from '../../../../../shared/hal/formular.hal.item';
import { ExternRecord } from '../../../../../shared/interfaces/dtos/extern-record';
import { ExternRecordServiceImpl } from '../../../../../shared/services/extern-record.service';

@Component({
  selector: 'fes-extern-record-table',
  templateUrl: './extern-record-table.component.html',
  styleUrls: ['./extern-record-table.component.scss']
})
export class ExternRecordTableComponent implements OnInit {

  /** Flags */
  isLoading = false;

  /* Tabellen Variabeln */
  tableColumnOptions: DsvsTableColumn[] = [];

  /* Komponenten Logik Variabeln */
  @ViewChild('tokendeletedialog')
  tokenDeleteDialog: TokenDeleteDialogComponent;
  _exRecToDelete: ExternRecord;
  halPage: ExternRecordHalPage;
  maxRecordNameLength = 52;
  downloadingExternRecordId = '';
  actualPageSize: number;
  actualPageNumber = 0;

  exRecordHalPage: ExternRecordHalPage;
  /* wird für rollback bei zu langem Dateinamen benötigt */
  actualTablePage: Page;
  /* Referenzen auf Zugriffsrechte */
  ZapPermissions = ZapPermissions;
  @Input()
  formularHalItem: FormularHalItem;

  constructor(
    private fesSettings: DsvsSettings,
    private externRecordService: ExternRecordServiceImpl,
    private messageService: MessageService,
    private permissionService: DsvsPermissionService
  ) {
  }

  /** gibt die zu löschende Sachakte zurück */
  getExRecToDelete(): ExternRecord {
    return this._exRecToDelete;
  }

  /** Setzt die zu löschende Sachakte sowie den Text des TokenDeleteDialogs */
  setExRecToDelete(exRecToDelete: ExternRecord): void {
    this._exRecToDelete = exRecToDelete;
    this.tokenDeleteDialog.dialogText = this.getTokenDeleteDialogText();

  }

  ngOnInit() {

    this.actualPageSize = this.fesSettings.getOptionValue('DTROWS_FES', 10);
    this.loadVersions();
    /* Definiert die Daten und das Aussehen der einzelnen Columns */
    this.tableColumnOptions = [
      {field: 'displayName', header: 'Name', sort: true, filter: '', hidden: false, format: 'EDITABLE', width: '50%'},
      {field: 'createdDate', header: 'Datum', sort: true, filter: '', hidden: false, width: '35%', format: 'DATE'},
      {field: 'id', header: '', sort: false, filter: '', hidden: false, width: '15%', format: 'ACTION'},
    ];
  }

  /**
   * Lädt die Tabellendaten mit den mitgegebenen page Parametern.
   * Startet eine Abfrage für das Neusetzen der ExternRecordsRelation Parameter und setzt die Antwort des Endpunkts als
   * Quelle für die Tabellendaten
   *
   * @param page Ein PageObjekt mit Informationen zur Sortierung, Nummer und Anzahl an Elementen der Tabellenseite
   * */
  loadVersions(page?: DsvsSearchEvent) {
    this.isLoading = true;
    if (page) {
      this.actualPageSize = page.size;
      this.actualPageNumber = page.number;
    }
    this.formularHalItem.externrecords
    .setParams({
      sort: page ? page.sort.map(curr => curr.property + ',' + curr.order.toLocaleLowerCase()) : '',
      size: page ? page.size : this.actualPageSize,
      page: page ? page.number : this.actualPageNumber
    })
    .async.subscribe(
      externrecords => {
        this.formularHalItem.externrecords.reload();
        this.isLoading = false;
        this.exRecordHalPage = externrecords;
      },
      err => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }


  /** Ruft einen TokenDeleteDialog auf */
  onDelete(object) {
    this.setExRecToDelete(object.getData());

    this.tokenDeleteDialog.showTokenDeleteDialog();
  }

  /** Speichert Änderungen welche an der Sachakte vorgenommen wurden */
  saveRow = (value, component: InlineEditComponent): Observable<ExternRecordHalItem> => {
    /* Referenziert den übergebenen ExternRecord welcher die Änderungen enthält */
    const updatedExRec: ExternRecord = component.model.getData();

    /* Prüft die Länge des Deitamens und zeigt ggf eine Fehlermeldung and und detzt die InlineEditkomponente zurück*/
    if (updatedExRec.displayName.length > this.maxRecordNameLength) {

      /* Zeigt Fehlernachricht an */
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Der Dateiname darf nicht länger als ' + this.maxRecordNameLength + ' Zeichen sein.'
      });

      /* Zurücksetzen der InlineEditKomponente */
      // TODO: Niki Über inline edit den namen auf ausgangswert zurücksetzen anstatt daten neu zu laden, gleiche wie
      //  Funktionalität wie bei drücken des x buttons beim Ändern
      this.loadVersions();
      return Observable.of(null);
    }

    /* Führt ein Update des ExternenRecords im Backend durch  und zeigt ggf. eine Fehlermeldung an */
    this.externRecordService.update(updatedExRec).subscribe(() => {
    }, () => {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Fehler beim Bearbeiten.'
      });
    });

    /* Absolut keine Ahnung wurde von anderer Tabellenimplementation übernommen */
    return component.model.save();
  };

  /** Lädt den ExternRecord mit der angegebenen Id vom Backend und gibt eine Erfolgs-/ Fehlermedung aus */
  onDownload(externRecordId: string) {
    this.downloadingExternRecordId = externRecordId;
    this.externRecordService.downloadExternRecordFile(externRecordId, () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: 'Die Datei wurde erfolgreich Heruntergeladen.'
        });
        this.downloadingExternRecordId = '';

      },
      err => {
        console.error(err);
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: 'Fehler beim Herunterladen.'
        });
        this.downloadingExternRecordId = '';
      });


  }

  /** Definiert die Elemente des Splitbuttons */
  getSplitButtonItems(halItem: any): MenuItem[] {
    const res: MenuItem[] = [{
      label: 'Herunterladen',
      icon: 'fas fas-download',
      command: () => {
        this.onDownload(halItem.data.id);
      }
    }];

    if (this.permissionService.hasPermission([ZapPermissions.FES_SACHAKTE_DELETE])) {
      res.push({
        label: 'Löschen',
        icon: 'fas fas-trash',
        command: () => {
          this.onDelete(halItem);
        }
      });
    }
    return res;
  }


  /** Löscht ein ExternRecord im Backend mit zugehörigem File */
  deleteExternRecord(deleteToken: string) {
    /* Prüft ob ein Sicherheitstoken eingegeben wurde */
    if (deleteToken === '') {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Geben Sie zum Löschen ihren Benutzernamen ein.'
      });
      return;
    }
    this.isLoading = true;
    /* Löscht das File des ExternRecords im Backend */
    this.externRecordService.deleteExternRecordFile(this.getExRecToDelete().id, deleteToken).subscribe(
      () => {
        /* Löscht den ExternRecord im Backend */
        this.externRecordService.delete(this.getExRecToDelete().id, deleteToken).subscribe(
          () => {

            /* Aktualisiert die Einträge der Tabelle */
            this.loadVersions();

            this.messageService.add({
              severity: 'success',
              summary: 'Erfolg',
              detail: 'Die Sachakte wurde erfolgreich gelöscht.'
            });

            /* Setzt den TokenDeleteDialog zurück und schließt ihn */
            this.tokenDeleteDialog.resetAndCloseTokenDeleteDialog();


          }, err => this.handleDeleteError(err)
        );
      }, error => this.handleDeleteError(error));


  }

  /** Erstellt den Text, welcher im TokenDeleteDialog angezeigt werden soll */
  private getTokenDeleteDialogText() {
    return 'Sachakte ' + this.getExRecToDelete().displayName + ' wirklich löschen?';
  }

  /** Prüft den zurückgegebenen Fehlerstatus und zeigt bei einem 403 eine Unauthorisiert Message
   * über den Messageservice an. Ansonsten wird eine generische Fehlermeldung angezeigt
   * */
  private handleDeleteError(error: any) {
    this.isLoading = false;
    if (error.status === 403) {
      this.messageService.add({
        severity: 'error',
        summary: 'Unauthorisiert',
        detail: 'Falsches Token eingegeben.'
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Fehler beim löschen.'
      });
    }

  }

}
