import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DsvsSharedModule} from '@dsvs/dsvs-shared-ui-lib';
import {HalClientModule} from '@dsvs/hal-client';
import {
  ConfirmDialogModule,
  DialogModule,
  FileUploadModule,
  ProgressSpinnerModule,
  SplitButtonModule,
  TooltipModule
} from 'primeng/primeng';
import {FesAutocompleteDialogComponent} from './components/fes-autocomplete-dialog/fes-autocomplete-dialog.component';
import {FesAutocompleteDialogModule} from './components/fes-autocomplete-dialog/fes-autocomplete-dialog.module';
import {FesDevversionStatusComponent} from './components/fes-devversion-status/fes-devversion-status.component';
import {FesDiffModalComponent} from './components/fes-diff-modal/fes-diff-modal.component';
import {FesFormularStatusComponent} from './components/fes-formular-status/fes-formular-status.component';
import {FesStatusComponent} from './components/fes-status/fes-status.component';
import {FesVersionStatusComponent} from './components/fes-version-status/fes-version-status.component';
import {XdpFileDownloadComponent} from './components/xdp-file-download/xdp-file-download.component';
import {XdpFileUploadComponent} from './components/xdp-file-upload/xdp-file-upload.component';
import {DeletedNamePipe} from './pipes/deleted-name.pipe';
import {DevStatusPipe} from './pipes/dev-status.pipe';
import {DynamisationPipe} from './pipes/dynamisation.pipe';
import {FileTypesPipe} from './pipes/file-types.pipe';
import {GroupNamePipe} from './pipes/group-name.pipe';
import {WorkflowtypePipe} from './pipes/process-type.pipe';
import {ServiceupdateStatusPipe} from './pipes/serviceupdate-status.pipe';
import {SignatureLevelPipe} from './pipes/signature-level.pipe';
import {TagCategoryPipe} from './pipes/tag-category.pipe';
import {UserCirclePipe} from './pipes/user-circle.pipe';
import {DevVersionServiceImpl} from './services/dev-version.service';
import {FileServiceImpl} from './services/file.service';
import {FormServiceImpl} from './services/form.service';
import {MeServiceImpl} from './services/me.service';
import {ProcessServiceImpl} from './services/process.service';
import {ProjectServiceImpl} from './services/project.service';
import {RequirementServiceImpl} from './services/requirement.service';
import {ServiceupdateServiceImpl} from './services/serviceupdate.service';
import {UserServiceImpl} from './services/user.service';
import {VersionServiceImpl} from './services/version.service';
import {WorkspaceServiceImpl} from './services/workspace.service';
import {FesVariableMaxFileSizeUploaderComponent} from './components/fes-variable-max-file-size-uploader/fes-variable-max-file-size-uploader.component';
import {TokenDeleteDialogComponent} from './components/token-delete-dialog/token-delete-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    SplitButtonModule,
    ProgressSpinnerModule,
    HalClientModule,
    DialogModule,
    ReactiveFormsModule,
    DsvsSharedModule,
    FesAutocompleteDialogModule,
    TooltipModule,
    ConfirmDialogModule,
    FormsModule
  ],
  declarations: [
    DynamisationPipe,
    FileTypesPipe,
    SignatureLevelPipe,
    DevStatusPipe,
    DeletedNamePipe,
    GroupNamePipe,
    TagCategoryPipe,
    UserCirclePipe,
    ServiceupdateStatusPipe,
    WorkflowtypePipe,
    XdpFileDownloadComponent,
    FesFormularStatusComponent,
    FesVersionStatusComponent,
    FesDevversionStatusComponent,
    FesStatusComponent,
    FesDiffModalComponent,
    XdpFileUploadComponent,
    FesVariableMaxFileSizeUploaderComponent,
    TokenDeleteDialogComponent
  ],
  exports: [
    DynamisationPipe,
    DevStatusPipe,
    FileTypesPipe,
    SignatureLevelPipe,
    DeletedNamePipe,
    TagCategoryPipe,
    GroupNamePipe,
    UserCirclePipe,
    ServiceupdateStatusPipe,
    WorkflowtypePipe,
    XdpFileDownloadComponent,
    FesAutocompleteDialogComponent,
    FesFormularStatusComponent,
    FesVersionStatusComponent,
    FesDevversionStatusComponent,
    FesStatusComponent,
    FesDiffModalComponent,
    XdpFileUploadComponent,
    FesVariableMaxFileSizeUploaderComponent,
    TokenDeleteDialogComponent
  ],
  providers: [
    {provide: FormServiceImpl, useClass: FormServiceImpl},
    {provide: VersionServiceImpl, useClass: VersionServiceImpl},
    {provide: DevVersionServiceImpl, useClass: DevVersionServiceImpl},
    {provide: ProcessServiceImpl, useClass: ProcessServiceImpl},
    {provide: FileServiceImpl, useClass: FileServiceImpl},
    {provide: RequirementServiceImpl, useClass: RequirementServiceImpl},
    {provide: ServiceupdateServiceImpl, useClass: ServiceupdateServiceImpl},
    {provide: WorkspaceServiceImpl, useClass: WorkspaceServiceImpl},
    {provide: UserServiceImpl, useClass: UserServiceImpl},
    {provide: MeServiceImpl, useClass: MeServiceImpl},
    {provide: ProjectServiceImpl, useClass: ProjectServiceImpl}
  ]
})
export class SharedModule {
}
