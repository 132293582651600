import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import { MessageService, SelectItem } from 'primeng/api';
import { EFormularTypes } from '../../../../shared/interfaces/dtos/enums/EFormularTypes';
import { Formular } from '../../../../shared/interfaces/dtos/formular';
import { FormService, FormServiceImpl } from '../../../../shared/services/form.service';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-workflow-action-template-style-select',
  templateUrl: './fes-workflow-action-template-style-select.component.html',
  styleUrls: ['./fes-workflow-action-template-style-select.component.scss']
})
export class FesWorkflowActionTemplateStyleSelectComponent extends FesWorkflowActionComponent implements OnInit {

  formId: string;
  formService: FormServiceImpl;
  selectableFormTypes : SelectItem[];

  static readonly FORMULAR_LABEL = 'Formular';
  static readonly TEXTMODUL_LABEL = 'Textbaustein';




  constructor(messageService: MessageService, formService: FormServiceImpl) {
    super(messageService);

    this.formService = formService;
    this.selectableFormTypes = [
      {label: FesWorkflowActionTemplateStyleSelectComponent.FORMULAR_LABEL,value: EFormularTypes.FORMULAR},
      {label: FesWorkflowActionTemplateStyleSelectComponent.TEXTMODUL_LABEL,value: EFormularTypes.TEXTMODUL}
    ]
  }


  ngOnInit() {
    this.inputForm = new FormGroup(
      {
        selectedType: new FormControl(null, Validators.required)
      });
    this.loadProcess();
  }

  collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      formularType: this.inputForm.get('selectedType').value.toString()
    };
  }


  loadProcess() {
    this.context.process.async.subscribe(pro => {
      pro.product.async.subscribe(product => {
        this.formId = product.getData().dataId;
        this.formService.getById(this.formId).subscribe(formRequestResult => {
            this.inputForm.setValue({selectedType: formRequestResult.data.formularType});
          }
        );
      });
    });
  }

  isValid(): boolean {
    return this.inputForm.valid;
  }



  onError(error: any) {
    this.isProcessing = true;
    this.formService.getById(this.formId).subscribe(formRequestResult => {
        this.inputForm.setValue({selectedType: formRequestResult.data.formularType});
        super.onError(error);
        this.isProcessing = false;
      }
    );
  }

}
