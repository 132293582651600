import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionContextHalItem } from '@dsvs/workflow-generator-dto';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/api';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-fes-workflow-action-dms-number',
  templateUrl: './fes-workflow-action-dms-number.component.html',
  styleUrls: ['./fes-workflow-action-dms-number.component.scss']
})
export class FesWorkflowActionDmsNumberComponent extends FesWorkflowActionComponent implements OnInit {

  constructor(messageService: MessageService) {
    super(messageService);
  }

  ngOnInit() {
    this.inputForm = new FormGroup(
      {
        dmsNumber: new FormControl('', Validators.required)
      });

    this.loadProcess();
  }

  public collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      dmsNumber: this.inputForm.controls.dmsNumber.value
    };
  }

  loadProcess() {
    this.context.process.async.subscribe(
      processHalItem => {
        this.inputForm.patchValue({dmsNumber: processHalItem.data.dmsNumber});
      });
  }

  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
    this.context.process.reload();
    this.loadProcess();
  }

  resetForm() {
  }

  add() {
    this.saveAction();
  }

}
