import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HalFcrudServiceImpl } from '../hal/hal.fcrud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { ScanpoolHalItem, ScanpoolHalPage } from '../hal/scanpool.hal.item';
import { Id } from '../interfaces/dtos/Id';
import { Scanpool } from '../interfaces/dtos/scanpool';

export interface ScanpoolService extends DsvsSearchableCrudEntityService<Scanpool> {
  assign(scanpool: Scanpool, targetId: Id, type: any): Observable<ScanpoolHalItem>;

  downloadWithBarcode(scanpool: Scanpool): Observable<ScanpoolHalItem>;
}

@Injectable({
  providedIn: 'root'
})
export class ScanpoolServiceImpl
  extends HalFcrudServiceImpl<Scanpool, ScanpoolHalItem, ScanpoolHalPage>
  implements ScanpoolService {

  private backend: string = environment.backend;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'scanpool', collection: 'scanpools'},
      http,
      ScanpoolHalPage,
      ScanpoolHalItem
    );
  }

  assign(scanpool: Scanpool, targetId: Id, type: any): Observable<ScanpoolHalItem> {

    return this.http.put<ScanpoolHalItem>(
      this.backend + this.relation.collection + '/' + scanpool.id + '/assign/' + targetId + '?target=' + type,
      null
    );
  }

  downloadWithBarcode(scanpool: Scanpool): Observable<any> {

    return this.downloadFile(
      this.backend + this.relation.collection + '/' + scanpool.id + '/barcode'
    );
  }

}
