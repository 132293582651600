import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DsvsHalAsyncLoaderModule, DsvsInlineEditModule, DsvsSharedModule, DsvsTableModule } from '@dsvs/dsvs-shared-ui-lib';
import { WorkflowGeneratorModule } from '@dsvs/workflow-generator-ui-lib';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import {
  MessageModule,
  MessagesModule,
  ProgressSpinnerModule,
  SplitButtonModule,
  StepsModule,
  TooltipModule
} from 'primeng/primeng';
import { SharedModule } from '../shared/shared.module';
import { ServiceupdateActivateComponent } from './components/activate/serviceupdate-activate.component';
import { ServiceupdateDetailComponent } from './components/create/serviceupdate-detail.component';
import { ServiceupdateG2PacketingComponent } from './components/g2-packeting/serviceupdate-g2-packeting.component';
import { ServiceupdateLockComponent } from './components/lock/serviceupdate-lock.component';
import { ServiceupdateSearchProcessListComponent } from './components/search/search-process-list/serviceupdate-search-process-list.component';
import { ServiceupdateSearchComponent } from './components/search/serviceupdate-search.component';
import { ServiceupdatesComponent } from './components/serviceupdates.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    PanelModule,
    StepsModule,
    DialogModule,
    SplitButtonModule,
    ButtonModule,
    MessagesModule,
    ProgressSpinnerModule,
    WorkflowGeneratorModule,
    MessageModule,
    DsvsTableModule,
    DsvsSharedModule,
    DsvsInlineEditModule,
    DsvsHalAsyncLoaderModule,
    TooltipModule
  ],
  declarations: [
    ServiceupdateSearchComponent,
    ServiceupdateSearchProcessListComponent,
    ServiceupdateDetailComponent,
    ServiceupdateActivateComponent,
    ServiceupdateG2PacketingComponent,
    ServiceupdatesComponent,
    ServiceupdateLockComponent
  ],
  exports: [ServiceupdateDetailComponent, ServiceupdateSearchProcessListComponent]
})
export class ServiceupdatesModule {

  static getRoutes(): Routes {
    return [
      {path: 'search', component: ServiceupdateSearchComponent},
      {path: 'create', component: ServiceupdateDetailComponent},
      {path: '', component: ServiceupdatesComponent}
    ];
  }
}
