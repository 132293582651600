import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { Requirement } from '../interfaces/dtos/requirement';
import { FormularRelationPage } from './formular.hal.item';
import { ProcessRelationItem } from './process.hal.item';

export class RequirementHalItem extends HalItemImpl<Requirement> {
  constructor(data: Requirement,
              client: HalClient,
              factory: HalClientFactory) {
    super(data, client, factory);
    this.forms = new FormularRelationPage('forms', this);
    this.process = new ProcessRelationItem('process', this);
  }

  readonly forms: FormularRelationPage;
  readonly process: ProcessRelationItem;
}

export class RequirementHalPage extends HalPageImpl<Requirement, RequirementHalItem> {

}

export class RequirementRelationPage extends HalRelationPage<Requirement, RequirementHalItem, RequirementHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, RequirementHalPage, RequirementHalItem);
  }
}

export class RequirementRelationItem extends HalRelationItem<Requirement, RequirementHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, RequirementHalItem);
  }
}
