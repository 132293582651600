import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DsvsHalAsyncLoaderModule, DsvsInlineEditModule, DsvsSharedModule, DsvsTableModule } from '@dsvs/dsvs-shared-ui-lib';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { SharedModule } from '../shared/shared.module';
import { ScanpoolAssignComponent } from './components/assign/scanpool-assign.component';
import { ScanpoolDetailComponent } from './components/detail/scanpool-detail.component';
import { ScanpoolComponent } from './components/scanpool.component';
import { ScanpoolSearchComponent } from './components/search/scanpool-search.component';
import { ScanpoolStateNamePipe } from './pipes/scanpool-state-name.pipe';
import { ScanpoolTargetTypeNamePipe } from './pipes/scanpool-target-type-name.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    PanelModule,
    DialogModule,
    ButtonModule,
    DsvsTableModule,
    DsvsSharedModule,
    DsvsInlineEditModule,
    DsvsHalAsyncLoaderModule
  ],
  declarations: [
    ScanpoolComponent,
    ScanpoolSearchComponent,
    ScanpoolAssignComponent,
    ScanpoolStateNamePipe,
    ScanpoolTargetTypeNamePipe,
    ScanpoolDetailComponent
  ]
})
export class ScanpoolModule {

  static getRoutes(): Routes {
    return [
      {path: 'scanpool', component: ScanpoolSearchComponent},
      // {path: 'detail', component: RequirementsDetailComponent},
      {path: '', component: ScanpoolSearchComponent}
    ];
  }
}

