import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { germanCalenderTemplate } from '@dsvs/dsvs-shared-ui-lib';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import * as moment from 'moment';
import { MessageService } from 'primeng/components/common/messageservice';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { WGContextHalItem } from '../../../../shared/hal/wfg/wgcontext.hal.item';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-fes-workflow-action-deliverydate',
  templateUrl: './fes-workflow-action-deliverydate.component.html',
  styleUrls: ['./fes-workflow-action-deliverydate.component.scss']
})
export class FesWorkflowActionDeliverydateComponent extends FesWorkflowActionComponent implements OnInit {

  date: string;
  process: ProcessHalItem;
  actionDone = false;
  de = germanCalenderTemplate;

  constructor(messageService: MessageService) {
    super(messageService);
  }

  ngOnInit() {
    this.inputForm = new FormGroup(
      {
        date: new FormControl(null, Validators.required)
      });
    this.loadProcess();
  }

  loadProcess() {
    (<WGContextHalItem>this.context).process.async.subscribe(
      process => {
        if (process && process.data.deliverydate) {
          this.inputForm.setValue({date: new Date(process.data.deliverydate)});
          this.actionDone = true;
        }
      }
    );
  }

  public collectActionData(): WorkflowActionDataDto {
    const date = moment(this.inputForm.value.date).format('YYYY-MM-DD');
    return <WorkflowActionDataDto>{
      date: date
    };
  }

  public dateSelected(value) {
    this.inputForm.value.date = value;
    this.saveAction();
  }

}
