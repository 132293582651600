import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { Serviceupdate } from '../interfaces/dtos/serviceupdate';
import { FiReleaseDateRelationItem } from './fiReleaseDateHalItem';
import { ProcessRelationPage } from './process.hal.item';
import { WorkspaceRelationItem } from './workspace.hal.item';

export class ServiceupdateHalItem extends HalItemImpl<Serviceupdate> {
  constructor(data: Serviceupdate,
              client: HalClient,
              factory: HalClientFactory) {
    super(data, client, factory);
    this.processes = new ProcessRelationPage('processes', this);
    this.workspace = new WorkspaceRelationItem('workspace', this);
    this.fiReleaseDate = new FiReleaseDateRelationItem('fireleasedate', this);
  }

  readonly processes: ProcessRelationPage;
  readonly workspace: WorkspaceRelationItem;
  readonly fiReleaseDate: FiReleaseDateRelationItem;

}

export class ServiceupdateHalPage extends HalPageImpl<Serviceupdate, ServiceupdateHalItem> {

}

export class ServiceupdateRelationPage extends HalRelationPage<Serviceupdate, ServiceupdateHalItem, ServiceupdateHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, ServiceupdateHalPage, ServiceupdateHalItem);
  }
}

export class ServiceupdateRelationItem extends HalRelationItem<Serviceupdate, ServiceupdateHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, ServiceupdateHalItem);
  }
}
