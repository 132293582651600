import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsPage, DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { resolveFileName } from '../../../helper/download-helper';
import { HalFcrudServiceImpl } from '../hal/hal.fcrud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { ServiceupdateHalItem, ServiceupdateHalPage } from '../hal/serviceupdate.hal.item';
import { Id } from '../interfaces/dtos/Id';
import { Serviceupdate } from '../interfaces/dtos/serviceupdate';
import { ServiceupdateService } from './serviceupdate.service';

export interface ServiceupdateService extends DsvsSearchableCrudEntityService<Serviceupdate> {
  searchForProcess(searchTerm: string, processId: string, options?: DsvsPage): Observable<ServiceupdateHalPage>;

  downloadExport(serviceupdateId: String): void;

  activate(serviceupdateId: Id, options?: DsvsPage): Observable<ServiceupdateHalItem>;

  lock(serviceupdateId: Id, lock: boolean, options?: DsvsPage): Observable<ServiceupdateHalItem>;

  downloadG2Packet(serviceupdateIds: Id[], options?: DsvsPage): Observable<HttpResponse<Blob>>;
}

@Injectable({
  providedIn: 'root'
})
export class ServiceupdateServiceImpl
  extends HalFcrudServiceImpl<Serviceupdate, ServiceupdateHalItem, ServiceupdateHalPage>
  implements ServiceupdateService {

  private backend: string = environment.backend;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'serviceupdate', collection: 'serviceupdates'},
      http,
      ServiceupdateHalPage,
      ServiceupdateHalItem);
  }

  searchForProcess(searchTerm: string, processId: string, options?: DsvsPage): Observable<ServiceupdateHalPage> {
    return super.search(searchTerm, options, {name: 'for-process', data: {'processId': processId}});
  }

  downloadExport(serviceupdateId: String): void {
    this.http.get(
      this.backend + this.relation.collection + `/${serviceupdateId}/export`,
      {responseType: 'blob', observe: 'response'}
    ).subscribe(
      (res) => saveAs(res.body, resolveFileName(res.headers)),
      (err) => console.error('Could not download file!')
    );
  }

  activate(serviceupdateId: Id, options?: DsvsPage): Observable<ServiceupdateHalItem> {
    return this.http.post<ServiceupdateHalItem>(
      this.backend + this.relation.collection + `/${serviceupdateId}/activate`,
      null
    );
  }

  validateAddProcess(serviceupdateId: Id, processId: Id): Observable<boolean> {
    return this.http.post<boolean>(
      this.backend + this.relation.collection + `/${serviceupdateId}/validateaddprocess/${processId}`,
      null
    );
  }

  lock(serviceupdateId: Id, lock: boolean, options?: DsvsPage): Observable<ServiceupdateHalItem> {

    return this.http.post<ServiceupdateHalItem>(
      this.backend + this.relation.collection + `/${serviceupdateId}/lock?state=${lock}`,
      null
    );
  }

  downloadG2Packet(serviceupdateIds: Id[], options?: DsvsPage): Observable<HttpResponse<Blob>> {
    let params = new HttpParams();
    serviceupdateIds.forEach(
      serviceupdateId => {
        params = params.append('id', serviceupdateId);
      }
    );
    return this.http.get(
      this.backend + this.relation.collection + `/g2Package`,
      {responseType: 'blob', observe: 'response', params: params}
    );
  }
}
