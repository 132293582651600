import { Component, Input, OnInit } from '@angular/core';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';

@Component({
  selector: 'fes-serviceupdate-search-process-list',
  templateUrl: './serviceupdate-search-process-list.component.html',
  styleUrls: ['./serviceupdate-search-process-list.component.scss']
})
export class ServiceupdateSearchProcessListComponent implements OnInit {

  @Input() processes: ProcessHalItem[] = [];

  processInStateCount = 0;

  constructor() {
  }

  ngOnInit() {
    this.processInStateCount = 0;
    for (let i = 0; i < this.processes.length; i++) {
      this.processes[i].stepstate.async.subscribe(result => {
        if (result.data.statusNumber >= 4) {
          this.processInStateCount++;
        }
      });
    }
  }

}
