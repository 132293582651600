import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { Project } from '../interfaces/dtos/project';
import { ProcessRelationPage } from './process.hal.item';
import { UserRelationPage } from './user.hal.item';
import { WorkspaceRelationItem } from './workspace.hal.item';

export class ProjectHalItem extends HalItemImpl<Project> {
  constructor(data: Project, client: HalClient, factory: HalClientFactory) {
    super(data, client, factory);
    this.workspace = new WorkspaceRelationItem('workspace', this);
    this.processes = new ProcessRelationPage('processes', this);
    this.responsibleUsers = new UserRelationPage('users', this);
    /* tslint:disable:no-use-before-declare */
    this.children = new ProjectRelationPage('projects', this);
    /* tslint:enable:no-use-before-declare */

  }

  readonly workspace: WorkspaceRelationItem;
  readonly processes: ProcessRelationPage;
  readonly responsibleUsers: UserRelationPage;
  readonly children: ProjectRelationPage;
}

export class ProjectHalPage extends HalPageImpl<Project, ProjectHalItem> {

}

export class ProjectRelationPage extends HalRelationPage<Project, ProjectHalItem, ProjectHalPage> {
  constructor(
    relation: string,
    item: HalItem<any>
  ) {
    super(relation, item, ProjectHalPage, ProjectHalItem);
  }
}

export class ProjectRelationItem extends HalRelationItem<Project, ProjectHalItem> {
  constructor(
    relation: string,
    item: HalItem<any>
  ) {
    super(relation, item, ProjectHalItem);
  }
}
