import { Injectable } from '@angular/core';
import { DsvsAuthorizationService } from '@dsvs/dsvs-shared-ui-lib';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/do';

@Injectable({
  providedIn: 'root'
})
export class FesUserSettingsService {

  static readonly ACTIVE_WORKFLOW_KEY = 'activeWorkspace';

  private onActiveWorkspaceChangedSubject = new Subject<string>();

  constructor(private authService: DsvsAuthorizationService) {

    authService.onLogout().subscribe(() => {
      sessionStorage.removeItem(FesUserSettingsService.ACTIVE_WORKFLOW_KEY);

    });
  }

  public getActiveWorkspaceId(): string {
    return sessionStorage.getItem(FesUserSettingsService.ACTIVE_WORKFLOW_KEY);
  }

  public setActiveWorkspaceId(workspaceId: string) {
    if (!workspaceId) {
      sessionStorage.removeItem(FesUserSettingsService.ACTIVE_WORKFLOW_KEY);
    } else {
      sessionStorage.setItem(FesUserSettingsService.ACTIVE_WORKFLOW_KEY, workspaceId);
    }
    this.onActiveWorkspaceChangedSubject.next(this.getActiveWorkspaceId());
  }

  public onActiveWorkspaceChanged(): Observable<string> {
    return this.onActiveWorkspaceChangedSubject.asObservable();
  }

}
