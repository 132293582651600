export enum ETagCategory {

  PRODUCT = 'PRODUCT',
  PRODUCT_VERSION = 'PRODUCT_VERSION',
  PROJEKT = 'PROJEKT',
  WORKING_GROUP = 'WORKING_GROUP',
  TICKET_CATEGORY = 'TICKET_CATEGORY',
  FI_APPLICATION = 'FI_APPLICATION',
  TEMP_DEV_TASK = 'TEMP_DEV_TASK',
  OTHER = 'OTHER'
}

//
// export const ETagCategoryColors = {
//   PRODUCT: '#ff8549',
//   PRODUCT_VERSION: '#b50f00',
//   PROJEKT: '#ff3a47',
//   WORKING_GROUP: '#0738ff',
//   TICKET_CATEGORY: '#8d33ff',
//   FI_APPLICATION: '#00d12c',
//   TEMP_DEV_TASK: '#ffff4c',
//   OTHER: '#5186ff'
// };
//
// export function getColorForTag(tag: ETagCategory) {
//   return ETagCategoryColors[tag];
// }
