import {Component, OnInit} from '@angular/core';
import {DsvsBreadcrumbService, DsvsPermissionService, DsvsTableColumn, DsvsToolbarItem, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {Page} from '@dsvs/hal-client';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {Observable} from 'rxjs';
import {ZapPermissions} from '../../../../enums/zap-permissions.enum';
import {FiReleaseDateHalItem, FiReleaseDateHalPage} from '../../../shared/hal/fiReleaseDateHalItem';
import {FiReleaseDateServiceImpl} from '../../../shared/services/fi-release-date.service';
import {EGroupTypes} from '../../../shared/interfaces/dtos/enums/EGroupTypes';

export enum FIReleaseDateActions {
  CREATE_FIRELEASEDATE = 'CREATE_FIRELEASEDATE'
}

@Component({
  selector: 'fes-fi-release-dates-search',
  templateUrl: './fi-release-dates-search.component.html',
  styleUrls: ['./fi-release-dates-search.component.scss']
})
export class FiReleaseDatesSearchComponent implements OnInit {

  isLoading = false;
  isNew = false;
  isEditable = false;
  isDeletable = false;

  tableColumnOptions: DsvsTableColumn[] = [];

  editFiReleaseDate: FiReleaseDateHalItem;
  showEditModal = false;
  data: FiReleaseDateHalPage = null;

  groups = [EGroupTypes.NONE,
    EGroupTypes.G1,
    EGroupTypes.G2];

  constructor(private FIReleaseDateService: FiReleaseDateServiceImpl,
              private messageService: MessageService,
              private breadcrumbService: DsvsBreadcrumbService,
              private permissionService: DsvsPermissionService,
              private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit() {

    this.isEditable = this.permissionService.hasPermission([ZapPermissions.FES_FIRELEASEDATE_UPDATE]);
    this.isDeletable = this.permissionService.hasPermission([ZapPermissions.FES_FIRELEASEDATE_DELETE]);

    this.onReloadData('');
    this.initToolbar();
    this.tableColumnOptions = [
      {field: 'displayName', header: 'Name', sort: true, filter: '', hidden: false, format: 'EDITABLE', width: '50%'},
      {field: 'releaseDate', header: 'Datum', sort: true, filter: '', hidden: false, width: '20%', format: 'DATE'},
      {field: 'groupTypes', header: 'Gruppe', sort: false, filter: '', hidden: false, width: '25%', format: 'GROUP'},
      {field: '', header: '', sort: false, filter: '', hidden: false, width: '5%', format: 'ACTION'},
    ];
  }

  initToolbar(): void {
    this.breadcrumbService.setMenuItems([
      {label: 'FI-Termine'}
    ]);
    this.breadcrumbService.setToolbarItems(this.getToolbarItems());
    this.breadcrumbService.setToolbarVisible(true);
  }

  getToolbarItems(): DsvsToolbarItem[] {
    return [
      {
        id: FIReleaseDateActions.CREATE_FIRELEASEDATE,
        icon: 'note_add',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_FIRELEASEDATE_CREATE]),
        tooltip: 'Neuer FI-Termin',
        callback: () => this.showCreateDialog()
      }
    ];
  }

  showCreateDialog() {
    this.showEditModal = true;
    this.isNew = true;
  }

  onReloadData(searchTerm: string, page?: Page) {
    this.isLoading = true;
    this.FIReleaseDateService.search(searchTerm, page).subscribe(result => {
      this.data = result;
      this.isLoading = false;
    }, error2 => {
      console.error(error2);
    });
  }

  receiveShowEditModal($event) {
    this.showEditModal = $event;

    this.onReloadData('');
  }

  onDetail(object) {
    this.editFiReleaseDate = object;
    this.isNew = false;
    this.showEditModal = true;
  }

  onDelete(object) {
    if (this.isDeletable) {
      this.confirmationService.confirm({
        message: 'Den FI-Termin sicher löschen?',
        header: 'Löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Löschen',
        rejectLabel: 'Abbrechen',
        accept: () => {
          this.FIReleaseDateService.delete(object.data).subscribe(
            res => {
              this.messageService.add({
                severity: 'success',
                summary: 'Erfolg',
                detail: 'Der FI-Termin wurde gelöscht.'
              });

              this.onReloadData('');
            }, error1 => {
              this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Fehler beim löschen.'
              });
            }
          );
        }
      });
    }
  }

  saveRow = (value, component: InlineEditComponent): Observable<FiReleaseDateHalItem> => {
    return component.model.save();
  };

  fetchGroups = (event): Observable<any[]> => {
    return Observable.of(this.groups);
  };

  updateGroup = (value, component: InlineEditComponent): Observable<any> => {
    return this.FIReleaseDateService.update(component.model.data);
  };

  getSplitButtonItems(halItem: any): MenuItem[] {
    const menuItems: MenuItem[] = [
      {
        label: 'Bearbeiten', icon: 'fas fas-pencil', command: () => {
          this.onDetail(halItem);
        }
      },
      {
        label: 'Löschen',
        icon: 'fas fas-trash',
        disabled: !this.isDeletable,
        command: () => {
          this.onDelete(halItem);
        }
      }
    ];

    return menuItems;
  }

}
