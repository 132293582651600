import { Pipe, PipeTransform } from '@angular/core';
import { ESignatureLevel } from '../interfaces/dtos/enums/ESignatureLevel';

@Pipe({
  name: 'signatureLevel'
})
export class SignatureLevelPipe implements PipeTransform {

  /** Pipe unm den
   * @param {string} value
   * @returns {string}
   */
  transform(value: ESignatureLevel): string {
    switch (value) {
      case ESignatureLevel.ELECTRONIC : {
        return 'Elektronisch';
      }
      case ESignatureLevel.NONE: {
        return 'Keines';
      }
      case ESignatureLevel.OTHER : {
        return 'Sonstiges';
      }
    }
  }

}
