import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { FiReleaseDate } from '../interfaces/dtos/fireleasedate';
import { ServiceupdateRelationPage } from './serviceupdate.hal.item';

export class FiReleaseDateHalItem extends HalItemImpl<FiReleaseDate> {
  constructor(data: FiReleaseDate,
    client: HalClient,
    factory: HalClientFactory) {
    super(data, client, factory);
    this.serviceupdates = new ServiceupdateRelationPage('serviceupdates', this);
  }

  readonly serviceupdates: ServiceupdateRelationPage;
}

export class FiReleaseDateHalPage extends HalPageImpl<FiReleaseDate, FiReleaseDateHalItem> {

}

export class FiReleaseDateRelationPage extends HalRelationPage<FiReleaseDate, FiReleaseDateHalItem, FiReleaseDateHalPage> {
  constructor(relation: string,
    item: HalItem<any>) {
    super(relation, item, FiReleaseDateHalPage, FiReleaseDateHalItem);
  }
}

export class FiReleaseDateRelationItem extends HalRelationItem<FiReleaseDate, FiReleaseDateHalItem> {
  constructor(relation: string,
    item: HalItem<any>) {
    super(relation, item, FiReleaseDateHalItem);
  }
}
