import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fes-fes-workflow-action-completiondateexpected-history',
  templateUrl: './fes-workflow-action-completiondateexpected-history.component.html',
  styleUrls: ['./fes-workflow-action-completiondateexpected-history.component.scss']
})
export class FesWorkflowActionCompletiondateexpectedHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
