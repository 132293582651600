import {
  HalClient,
  HalClientFactory,
  HalItem,
  HalItemImpl,
  HalPageImpl,
  HalRelationItem,
  HalRelationPage
} from '@dsvs/hal-client';
import {CategoryRelationItem, CrewRelationItem} from '@dsvs/workflow-generator-dto';
import {Formular} from '../interfaces/dtos/formular';
import {DevVersionRelationPage} from './devversion.hal.item';
import {TagRelationPage} from './tag.hal.item';
import {VersionRelationItem, VersionRelationPage} from './version.hal.item';
import {WorkspaceRelationItem} from './workspace.hal.item';
import {ExternRecordRelationPage} from './externrecord.hal.item';

export class FormularHalItem extends HalItemImpl<Formular> {
  readonly category: CategoryRelationItem;
  readonly crew: CrewRelationItem;
  readonly workspace: WorkspaceRelationItem;
  readonly activeVersion: VersionRelationItem;
  readonly versions: VersionRelationPage;
  readonly devversions: DevVersionRelationPage;
  readonly externrecords: ExternRecordRelationPage;
  readonly tags: TagRelationPage;

  constructor(
    data: Formular,
    client: HalClient,
    factory: HalClientFactory
  ) {
    super(data, client, factory);

    this.tags = new TagRelationPage('tags', this);
    this.crew = new CrewRelationItem('crew', this);
    this.category = new CategoryRelationItem('category', this);
    this.workspace = new WorkspaceRelationItem('workspace', this);
    this.versions = new VersionRelationPage('versions', this);
    this.activeVersion = new VersionRelationItem('activeVersion', this);
    this.devversions = new DevVersionRelationPage('devversions', this);
    this.externrecords = new ExternRecordRelationPage('externrecords', this);
  }
}

export class FormularHalPage extends HalPageImpl<Formular, FormularHalItem> {

}

export class FormularRelationPage extends HalRelationPage<Formular, FormularHalItem, FormularHalPage> {
  constructor(
    relation: string,
    item: HalItem<any>
  ) {
    super(relation, item, FormularHalPage, FormularHalItem);
  }
}

export class FormularRelationItem extends HalRelationItem<Formular, FormularHalItem> {
  constructor(
    relation: string,
    item: HalItem<any>
  ) {
    super(relation, item, FormularHalItem);
  }
}
