import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {versionComparator} from '@dsvs/dsvs-shared-ui-lib';
import {Subscription} from 'rxjs';
import {CommunicationService} from '../../../../../services/communication-service';
import {ActionType} from '../../../../fes-workflow/enums/action-type.enum';
import {DevVersionHalItem, DevVersionHalPage} from '../../../../shared/hal/devversion.hal.item';
import {ManuscriptHalItem} from '../../../../shared/hal/manuscript.hal.item';
import {ProcessHalItem} from '../../../../shared/hal/process.hal.item';
import {VersionHalItem} from '../../../../shared/hal/version.hal.item';
import {EDevelopmentState} from '../../../../shared/interfaces/dtos/enums/EDevelopmentState';
import {FileServiceImpl} from '../../../../shared/services/file.service';
import {ProcessServiceImpl} from '../../../../shared/services/process.service';
import {UpdateProcessDetailService} from '../process-detail-update.service';

@Component({
  selector: 'fes-process-detail-download',
  templateUrl: './process-detail-download.component.html',
  styleUrls: ['./process-detail-download.component.scss']
})
export class ProcessDetailDownloadComponent implements OnInit, OnDestroy {

  _processHalItem;

  @Input()
  set process(processHalItem: ProcessHalItem) {
    this._processHalItem = processHalItem;
    this.reloadLatestDevVersion();
  }

  get process() {
    return this._processHalItem;
  }

  public latestDevVersion: DevVersionHalItem = null;

  @Input() showManuscripts: boolean;
  @Input() showAllDevVersions: boolean;
  @Input() showReturndate = false;

  private actionSubscriptionRef: Subscription = null;

  @Input() isModal: boolean;
  public showInfoDialog: boolean;
  public canCreateScanpool: boolean;

  infoVersion: VersionHalItem | DevVersionHalItem;

  constructor(
    private communicationService: CommunicationService,
    private fileService: FileServiceImpl,
    private processService: ProcessServiceImpl,
    private updateProcessService: UpdateProcessDetailService
  ) {
  }

  ngOnInit() {
    this.updateProcessService.trigger$.subscribe(() => {
      this.process.reload();
      this.reloadLatestDevVersion();
    });
    this.actionSubscriptionRef = this.communicationService.messages.subscribe(
      (message) => {

        if (message.scope === 'ACTION') {

          switch (message.message) {
            case ActionType.VersionSelect:
            case ActionType.DevVersionUpload: {
              this.reloadLatestDevVersion();
              this.process.baseversion.reload();
              break;
            }
            case ActionType.ProcessAddManuscript: {
              this.process.manuscripts.reload();
              break;
            }
            case ActionType.DevVersionFinalization: {
              this.process.resultversion.reload();
              break;
            }

          }
        }

      });

    this.processService.canCreateScanpool(this._processHalItem.data.id).subscribe(result => {
      this.canCreateScanpool = result;
    });
  }

  private reloadLatestDevVersion() {
    if (this.process.data.productId) {
      this.process.devversions.reload();
      this.process.devversions.setParams({size: 2000}).async.subscribe(
        devVersionHalPage => {
          this.latestDevVersion = this.findLatestDevVersion(devVersionHalPage);
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.actionSubscriptionRef.unsubscribe();
  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  findLatestDevVersion(devVersions: DevVersionHalPage): DevVersionHalItem {
    return devVersions.content
    .filter(
      devVersion => devVersion.data.state === EDevelopmentState.UPLOADED || devVersion.data.state === EDevelopmentState.RELEASED)
    .sort((a, b) => versionComparator(a.data.version, b.data.version))[0];
  }

  updateManuscript(manuscriptHalItem: ManuscriptHalItem) {
    manuscriptHalItem.save();
  }

  showInfo(item: VersionHalItem | DevVersionHalItem) {
    this.showInfoDialog = true;
    this.infoVersion = item;
  }

  onCloseInfoDialog() {
    this.showInfoDialog = false;
  }

  // TODO: bitte in eine PIPE + ggf. Helper auslagern
  checkIfDateIsNear(checkDate: Date): number {
    const currentDate = new Date();
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);
    checkDate = new Date(checkDate);
    const diffInTime = currentDate.getTime() - checkDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    if (checkDate <= currentDate) {
      return -1;
    } else {
      if (diffInDays >= -3) {
        return Math.floor(-diffInDays);
      } else {
        return null;
      }
    }
  }

}
