import { Component, OnInit } from '@angular/core';
import { DsvsBreadcrumbService, DsvsSettings } from '@dsvs/dsvs-shared-ui-lib';
import { RepositoryService } from '../../../services/repository-service';

export enum FormularActions {
  ADD_VERSION = 'ADD_VERSION',
  REFRESH_FORMS = 'REFRESH_FORMS',
  DELETE_FORM = 'DELETE_FORM',
  RESTORE_FORM = 'RESTORE_FORM'
}

@Component({
  selector: 'fes-formular',
  templateUrl: './formular.component.html',
  styleUrls: ['./formular.component.css']
})
export class FormularComponent implements OnInit {

  constructor(
    private repositoryService: RepositoryService,
    private fesSettings: DsvsSettings,
    private breadcrumbService: DsvsBreadcrumbService
  ) {
  }

  ngOnInit(): void {
    this.initToolbar();
  }

  initToolbar(): void {
    this.breadcrumbService.setMenuItems([
      {label: 'Formulare'}
    ]);
  }
}
