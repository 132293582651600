import { Component, Input, OnInit } from '@angular/core';
import { ProductHalItem } from '@dsvs/workflow-generator-dto';
import { DevVersionHalItem } from '../../../../shared/hal/devversion.hal.item';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { VersionHalItem } from '../../../../shared/hal/version.hal.item';
import { WGStepContextHalItem } from '../../../../shared/hal/wfg/wgstepcontext.hal.item';

@Component({
  selector: 'fes-process-detail-job',
  templateUrl: './process-detail-job.component.html',
  styleUrls: ['./process-detail-job.component.scss']
})
export class ProcessDetailJobComponent implements OnInit {

  @Input()
  version: VersionHalItem;

  @Input()
  product: ProductHalItem;

  @Input()
  process: ProcessHalItem;

  @Input()
  currentStepContext: WGStepContextHalItem;

  @Input()
  latestDevVersion: DevVersionHalItem;

  constructor() { }

  ngOnInit() {
  }

}
