import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DsvsPermissionService, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {isNil} from 'lodash';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable} from 'rxjs';
import {ZapPermissions} from '../../../../../enums/zap-permissions.enum';
import {FormularHalItem, FormularHalPage} from '../../../../shared/hal/formular.hal.item';
import {UserHalItem, UserHalPage} from '../../../../shared/hal/user.hal.item';
import {WorkspaceHalItem} from '../../../../shared/hal/workspace.hal.item';
import {Workspace} from '../../../../shared/interfaces/dtos/workspace';
import {FormServiceImpl} from '../../../../shared/services/form.service';
import {UserServiceImpl} from '../../../../shared/services/user.service';
import {WorkspaceServiceImpl} from '../../../../shared/services/workspace.service';

@Component({
  selector: 'fes-workspaces-detail',
  templateUrl: './workspaces-detail.component.html',
  styleUrls: ['./workspaces-detail.component.scss']
})
export class WorkspacesDetailComponent implements OnInit {

  workspace: Workspace;
  _workspaceHalItem: WorkspaceHalItem;
  newWorkspace: any;

  forms: FormularHalItem[] = [];
  users: UserHalItem[] = [];

  isNew = false;
  isEditable = false;

  @Input()
  set workspaceHalItem(workspaceHalItem: WorkspaceHalItem) {
    if (workspaceHalItem) {
      this.workspace = workspaceHalItem.data;
      this._workspaceHalItem = workspaceHalItem;
    }
  }

  get workspaceHalItem(): WorkspaceHalItem {
    return this._workspaceHalItem;
  }

  @Output() showEditModalEvent = new EventEmitter<boolean>();

  constructor(private workspaceService: WorkspaceServiceImpl,
    public formService: FormServiceImpl,
    public userService: UserServiceImpl,
    private messageService: MessageService,
    private permissionService: DsvsPermissionService) {
  }

  ngOnInit() {

    this.isEditable = this.isNew || this.permissionService.hasPermission([ZapPermissions.FES_WORKSPACE_UPDATE]);

    if (isNil(this._workspaceHalItem)) {
      this.isNew = true;
      this.workspace = <Workspace>{
        id: '',
        displayName: '',
        name: '',
        title: '',
        description: '',
        includeFis: false
      };
    }
  }

  onIncludeFisChanged() {
    this.save();
  }

  fetchForms = ($event, inlineEdit): Observable<FormularHalPage> => {
    return this.formService.search($event.query);
  };

  fetchUsers = ($event, inlineEdit): Observable<UserHalPage> => {
    return this.userService.search($event.query);
  };

  addForms = (value, component: InlineEditComponent): Observable<FormularHalPage> => {
    this.forms = component.modelValue;
    if (!this.isNew) {
      return this.updateForms(this.forms);
    } else {
      return Observable.of(null);
    }
  };

  updateForms(forms: FormularHalItem[]): Observable<FormularHalPage> {
    return this._workspaceHalItem.forms.update(forms);
  }

  addUser = (value, component: InlineEditComponent): Observable<UserHalPage> => {
    this.users = component.modelValue;
    if (!this.isNew) {
      return this.updateUsers(this.users);
    } else {
      return Observable.of(null);
    }
  };

  updateUsers(users: UserHalItem[]): Observable<UserHalPage> {
    return this._workspaceHalItem.users.update(users);
  }

  save = () => {
    if (this.isNew) {
      return this.workspaceService.create(this.workspace).subscribe(
        workspaceHalItem => {

          this._workspaceHalItem = workspaceHalItem;

          this.updateForms(this.forms).subscribe(
            formularHalPage => {

              this.updateUsers(this.users).subscribe(
                userHalPage => {

                  this.onCreationSuccess(workspaceHalItem);
                }
              );
            });
        }, error => {
          console.error('Error creating workspace - {}', error);
          this.cancelModal();
        });
    } else {
      return this._workspaceHalItem.save(this.workspace);
    }
  };

  onCreationSuccess(workspaceHalItem: WorkspaceHalItem) {
    this.cancelModal();
    this.messageService.add({
      severity: 'success',
      summary: 'Erfolg',
      detail: 'Der Workspace wurde erstellt.'
    });
  }

  cancelModal() {
    this.showEditModalEvent.emit(false);
  }

}
