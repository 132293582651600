import {Component, OnInit} from '@angular/core';
import {DsvsTableColumn} from '@dsvs/dsvs-shared-ui-lib';
import {ProductHalItem, ProductHalPage} from '@dsvs/workflow-generator-dto';
import {WorkflowProductServiceImpl} from '@dsvs/workflow-generator-ui-lib';
import {Observable, of as observableOf} from 'rxjs';
import {ProcessServiceImpl} from '../../../../shared/services/process.service';
import {CreationType, ProcessCreateWizzardItemComponent} from '../process-create-wizzard-item.component';

interface ProcessCreateFormError {
  type: ProcessCreateFormErrorType;
  message: string;
}

enum ProcessCreateFormErrorType {
  SELECT_CREATIONTYPE = 'SELECT_CREATIONTYPE',
  SELECT_FORMULAR = 'SELECT_FORMULAR',
  PROCESS_EXISTS_FOR_FORM = 'PROCESS_EXISTS_FOR_FORM'
}

@Component({
  selector: 'fes-process-create-form',
  templateUrl: './process-create-form.component.html',
  styleUrls: ['./process-create-form.component.scss']
})
export class ProcessCreateFormComponent extends ProcessCreateWizzardItemComponent implements OnInit {

  loading: boolean;
  cols: DsvsTableColumn[];

  errors: ProcessCreateFormError[] = [];

  constructor(
    private processService: ProcessServiceImpl,
    public productService: WorkflowProductServiceImpl
  ) {
    super();

    this.cols = [
      {field: 'displayName', header: 'Artikelnummer'}
    ];
  }

  ngOnInit() {

    this.wizzardData.creationType = CreationType.SELECT; // FES2-1229

  }

  toggleRadio($event, selection) {
    this.wizzardData.creationType = selection;
    this.errors = [];
    this.wizzardData.form = null;
  }

  fetchFormulars = ($event, inlineEdit): Observable<ProductHalPage> => {
    return this.productService.searchProcessable($event.query);
  };

  saveFormular = (productHalItem: ProductHalItem): Observable<ProductHalItem> => {
    this.wizzardData.form = productHalItem;
    this.errors = [];
    this.checkiIfProcessForForm(productHalItem.data.displayName);
    return observableOf(productHalItem);
  };

  checkiIfProcessForForm(articleNumber: string) {
    this.processService.searchByState(
      articleNumber,
      [
        'Erstellung',
        'FI Integration',
        'Bereitstellung',
        'Zur Veröffentlichung',
        'Freigabe',
        'Umsetzung'
      ]
    ).subscribe(
      processHalPage => {
        if (processHalPage.content && processHalPage.content.length > 0) {
          this.errors.push({
            type: ProcessCreateFormErrorType.PROCESS_EXISTS_FOR_FORM,
            message: 'Für dieses Formular ' +
              (processHalPage.content.length === 1
                  ? 'existiert bereits ein Auftrag: '
                  : 'existieren bereits Aufträge: '
              )
              + processHalPage.content.map(a => a.data.displayName).join(',')
          });
        }
      }
    );
  }

  displayErrors(): void {
    if (this.wizzardData.creationType) {
      if (this.wizzardData.creationType === CreationType.SELECT && !this.wizzardData.form) {
        this.errors.push({
          type: ProcessCreateFormErrorType.SELECT_FORMULAR,
          message: 'Bitte Formular wählen'
        });
      }
    } else {
      this.errors.push({
        type: ProcessCreateFormErrorType.SELECT_CREATIONTYPE,
        message: 'Bitte Auftragsart wählen'
      });
    }
  }

  hasError(errorType: string): ProcessCreateFormError {
    return this.errors.find(err => err.type === errorType);
  }

  isValid(): boolean {
    if (this.wizzardData.creationType) {
      if (this.wizzardData.creationType === CreationType.SELECT) {
        return this.wizzardData.form != null;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
