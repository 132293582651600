import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkflowActionContextServiceImpl, WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/components/common/messageservice';
import { FileUpload, Message } from 'primeng/primeng';
import { FormularHalItem } from '../../../../shared/hal/formular.hal.item';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { RequirementHalItem, RequirementHalPage } from '../../../../shared/hal/requirement.hal.item';
import { VersionHalItem } from '../../../../shared/hal/version.hal.item';
import { WGContextHalItem } from '../../../../shared/hal/wfg/wgcontext.hal.item';
import { EScanpoolSource } from '../../../../shared/interfaces/dtos/enums/EScanpoolSource';
import { EScanpoolTarget } from '../../../../shared/interfaces/dtos/enums/EScanpoolTarget';
import { Scanpool } from '../../../../shared/interfaces/dtos/scanpool';
import { RequirementServiceImpl } from '../../../../shared/services/requirement.service';
import { ScanpoolServiceImpl } from '../../../../shared/services/scanpool.service';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-workflow-action-requirement-add',
  templateUrl: './fes-workflow-action-requirement-add.component.html',
  styleUrls: ['./fes-workflow-action-requirement-add.component.scss']
})
export class FesWorkflowActionRequirementAddComponent extends FesWorkflowActionComponent implements OnInit {

  successMessage = null;
  requirements: RequirementHalPage;
  process: ProcessHalItem;
  loading = false;

  @Input() isValidating: boolean;
  @Input() errorMessages: Message[] = [];
  @Output() fileChanged = new EventEmitter<any>();
  @Output() fileRemoved = new EventEmitter<any>();
  @ViewChild('fileuploader') fileuploader: FileUpload;

  constructor(
    messageService: MessageService,
    private requirementService: RequirementServiceImpl,
    private actionContextService: WorkflowActionContextServiceImpl,
    private scanpoolService: ScanpoolServiceImpl
  ) {
    super(messageService);
  }

  ngOnInit() {
    this.createUploadForm();
    this.loadRequirements();
  }

  loadRequirements() {
    (<WGContextHalItem>this.context).process.async.subscribe(
      process => {
        this.process = process;
      }
    );
  }

  onSuccess(actionContextPage: any) {
    this.loading = true;
    this.successMessage = 'Anforderung kann nun unter "Anforderung wählen" ausgewählt werden.';

    if (this.inputForm.value.withScanpoolItem) {
      this.downloadScanpoolItemForActiveVersion(actionContextPage);
    } else {
      this.reset();
    }
  }

  private downloadScanpoolItemForActiveVersion(actionContextPage: any) {

    // We dont get a halItem here, so we have to retrieve it manually
    const actionContext = actionContextPage._embedded.actioncontexts[0];

    this.actionContextService.getById(actionContext.id).subscribe(
      actionContextHalItem => {

        actionContextHalItem.processedactions.async.subscribe(
          processedActionsHalPage => {

            const requirementId = this.getRequirementId(processedActionsHalPage);
            if (requirementId) {

              this.requirementService.getById(requirementId).subscribe(
                requirementHalItem => {
                  if (!requirementHalItem.forms) {
                    this.noFormsError();
                  } else {

                    requirementHalItem.forms.async.subscribe(
                      formularHalPage => {
                        if (formularHalPage.content.length > 0) {

                          // Currently we get several forms for a requirement-item (which is wrong)
                          const formular: FormularHalItem = formularHalPage.content[0];

                          if (!formular.data.activeVersionId) {
                            this.noActiveVersionError();
                          } else {

                            formular.activeVersion.async.subscribe(
                              activeVersionHalItem => {
                                if (activeVersionHalItem.data.id) {
                                  this.downloadVersionWithBarcode(activeVersionHalItem, requirementHalItem);
                                }
                                this.reset();
                              });
                          }
                        }
                      });
                  }
                }
              );
            } else {
              console.error('Requirement id not found.');
            }
          }
        );
      }
    );
  }

  private noActiveVersionError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Fehler',
      detail: 'Keine Aktive Version für Formular gefunden.'
    });
  }

  private noFormsError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Fehler',
      detail: 'Kein Formular gefunden.'
    });
  }

  private getRequirementId(processedActionsHalPage) {
    let requirementId = null;
    processedActionsHalPage.content.forEach(value => {
      const payload = JSON.parse(value.data.payload);
      if (payload.requirementId) {
        requirementId = payload.requirementId;
        return;
      }
    });
    return requirementId;
  }

  private reset() {
    this.loading = false;
    this.inputForm.reset();
    this.fileuploader.clear();
    this.process.requirements.reload();
  }

  createUploadForm() {
    this.inputForm = new FormGroup(
      {
        title: new FormControl('', Validators.required),
        description: new FormControl(''),
        file: new FormControl(null),
        withScanpoolItem: new FormControl(false)
      });
  }

  collectActionData(): WorkflowActionDataDto {
    if (this.inputForm.value.file != null) {
      return <WorkflowActionDataDto>{
        title: this.inputForm.value.title,
        description: this.inputForm.value.description,
        file: this.inputForm.value.file
      };
    } else {
      return <WorkflowActionDataDto>{
        title: this.inputForm.value.title,
        description: this.inputForm.value.description
      };
    }

  }

  onFileChange(target) {
    if (target.files.length > 0) {
      const file = target.files[0];
      this.inputForm.get('file').setValue(file);

      if (this.fileChanged != null) {
        this.fileChanged.emit();
      }
    }
  }

  onFileRemoved() {
    this.fileRemoved.emit();
  }

  downloadFile(path: string) {
    this.requirementService.downloadFile(path);
  }

  downloadVersionWithBarcode(versionHalItem: VersionHalItem, requirementHalItem: RequirementHalItem) {
    this.scanpoolService.create(<Scanpool>{
      source: EScanpoolSource.VERSION,
      sourceId: versionHalItem.data.id,
      target: EScanpoolTarget.REQUIREMENT,
      targetId: requirementHalItem.data.id
    }).subscribe(
      requirementHalItemResult => {
        this.scanpoolService.downloadWithBarcode(requirementHalItemResult.data);
      }
    );
  }

  isValid(): boolean {
    return this.inputForm.valid && (this.inputForm.value.description.length > 0 || this.inputForm.value.file);
  }

  add() {
    this.saveAction();
  }

}
