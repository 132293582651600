import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ProductHalPage, WorkflowHalPage } from '@dsvs/workflow-generator-dto';
import { WorkflowProductServiceImpl, WorkflowServiceImpl } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/components/common/messageservice';
import { Observable } from 'rxjs';
import { SelectOptions } from '../../../../../helper/select-helper';
import { ProjectHalItem } from '../../../../shared/hal/project.hal.item';
import { UserHalPage } from '../../../../shared/hal/user.hal.item';
import { ProcessData, ProjectForms } from '../../../../shared/interfaces/dtos/project';
import { ProcessServiceImpl } from '../../../../shared/services/process.service';
import { ProjectServiceImpl } from '../../../../shared/services/project.service';
import { RequirementServiceImpl } from '../../../../shared/services/requirement.service';
import { UserServiceImpl } from '../../../../shared/services/user.service';

const initialCurrentProcessData = {
  forms: [],
  workflow: null,
  processType: null
};

const initialProject = {
  id: '',
  displayName: '',
  name: '',
  shortName: '',
  title: '',
  description: '',
  users: [],
  processData: []
};

@Component({
  selector: 'fes-projects-create-with-forms',
  templateUrl: './projects-create-with-forms.component.html',
  styleUrls: ['./projects-create-with-forms.component.scss']
})
export class ProjectsCreateWithFormsComponent implements OnInit, OnDestroy {

  project: any;
  currentProcessData: any;

  processTypes: SelectOptions[] = [];

  @Output() showEditModalEvent = new EventEmitter<boolean>();
  @Output() success = new EventEmitter<ProjectHalItem>();

  constructor(private projectService: ProjectServiceImpl,
              private processService: ProcessServiceImpl,
              private workflowService: WorkflowServiceImpl,
              private productService: WorkflowProductServiceImpl,
              private userService: UserServiceImpl,
              private requirementService: RequirementServiceImpl,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.processTypes = [{value: 'CREATE', label: 'CREATE'}, {value: 'UPDATE', label: 'UPDATE'}, {
      value: 'DELETE',
      label: 'DELETE'
    }];
    this.resetData();
  }

  ngOnDestroy() {
    this.resetData();
  }

  fetchWorkflows = ($event): Observable<WorkflowHalPage> => {
    return this.workflowService.search($event.query);
  };

  fetchProducts = ($event): Observable<ProductHalPage> => {
    return this.productService.search($event.query);
  };

  fetchUsers = ($event): Observable<UserHalPage> => {
    return this.userService.search($event.query);
  };

  addProcess() {
    this.project.processData.push({...this.currentProcessData});
    this.currentProcessData = {...initialCurrentProcessData};
  }

  removeProcess(processData: ProcessData) {
    this.project.processData = this.project.processData.filter(data => data !== processData);
  }

  prepareData(data: any): ProjectForms {
    return <ProjectForms>{
      name: data.name,
      shortName: data.shortName,
      title: data.title,
      description: data.description,
      userIds: data.users.map(a => a.data.id),
      processData: data.processData.map(
        o => ({
          formIds: o.forms.map(b => b.data.id),
          workflowId: o.workflow.id,
          processType: o.processType
        }))
    };
  }

  save() {
    return this.projectService.createWithForms(this.prepareData(this.project)).subscribe(result => {
        this.cancelModal();
        this.success.emit(result);
        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: 'Die Auftragssammlung wurde erstellt.'
        });
      }, error => {
        console.error('Error creating project - {}', error);
        this.cancelModal();
      }
    );
  }

  resetData() {
    this.project = {...initialProject};
    this.project.processData = [];
    this.currentProcessData = {...initialCurrentProcessData};
  }

  cancelModal() {
    this.resetData();
    this.showEditModalEvent.emit(false);
  }
}
