import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Page } from '@dsvs/hal-client';
import { ActionContextHalItem } from '@dsvs/workflow-generator-dto';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/components/common/messageservice';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../../../../../services/communication-service';
import { FormularHalItem } from '../../../../shared/hal/formular.hal.item';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { RequirementHalItem } from '../../../../shared/hal/requirement.hal.item';
import { WGContextHalItem } from '../../../../shared/hal/wfg/wgcontext.hal.item';
import { FileServiceImpl } from '../../../../shared/services/file.service';
import { FormServiceImpl } from '../../../../shared/services/form.service';
import { RequirementServiceImpl } from '../../../../shared/services/requirement.service';
import { ActionType } from '../../../enums/action-type.enum';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-fes-workflow-action-requirement-select',
  templateUrl: './fes-workflow-action-requirement-select.component.html',
  styleUrls: ['./fes-workflow-action-requirement-select.component.scss']
})
export class FesWorkflowActionRequirementSelectComponent extends FesWorkflowActionComponent implements OnInit, OnDestroy {

  loading: boolean;
  selectedRequirements: RequirementHalItem[] = [];
  requirements: RequirementHalItem[] = [];
  formularHalItem: FormularHalItem;
  processHalItem: ProcessHalItem;
  private actionSubscriptionRef: Subscription = null;

  constructor(
    private requirementService: RequirementServiceImpl,
    private formularService: FormServiceImpl,
    private fileService: FileServiceImpl,
    messageService: MessageService,
    private communicationService: CommunicationService,
    private zone: NgZone
  ) {
    super(messageService);
  }

  checkIfRequirementIsSelected(requirement) {
    for (let i = 0; i < this.selectedRequirements.length; i++) {
      if (requirement.data.id === this.selectedRequirements[i].data.id) {
        return true;
      }
    }
    return false;
  }

  ngOnInit() {
    this.inputForm = new FormGroup({});
    this.loadForm();

    this.actionSubscriptionRef = this.communicationService.messages.subscribe(
      (message) => {
        if (
          message.scope === 'ACTION'
          && message.message === ActionType.RequirementAdd
        ) {
          this.onReloadData('', this.formularHalItem.data.id);
        }
      }
    );

  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  ngOnDestroy(): void {
    this.actionSubscriptionRef.unsubscribe();
  }

  loadForm() {
    this.context.product.async.subscribe(
      // TODO: Replace product by Formular?
      productHalItem => {
        this.formularService.getById(productHalItem.data.dataId).subscribe(
          formularHalItem => {
            this.formularHalItem = formularHalItem;
            this.onReloadData('', this.formularHalItem.data.id);
          }
        );
      }
    );
  }

  onReloadData(searchTerm: string, formId: string, page?: Page) {
    this.loading = true;
    this.requirementService.searchUnused(searchTerm, formId, page).subscribe(requirementHalPage => {
      this.zone.run(() => {
        (<WGContextHalItem>this.context).process.async.subscribe(
          processHalItem => {
            this.processHalItem = processHalItem;
            processHalItem.requirements.reload();
            processHalItem.requirements.setParams({searchTerm: searchTerm}).async.subscribe(
              processRequirementHalItems => {
                this.loading = false;
                this.selectedRequirements = processRequirementHalItems.content;

                // it happens on update that a refresh is to fast and requirements considered as "used" are wrongly returned.
                // For safety reasons we check for duplicates

                this.requirements = (requirementHalPage.content
                .concat(this.selectedRequirements))
                .sort(
                  (requirementA, requirementB) =>
                    <any>new Date(requirementB.data.createdDate) - <any>new Date(requirementA.data.createdDate)
                );
              }
            );
          });
      });
    }, err => {
      this.loading = false;
      console.error(err);
    });
  }

  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
    this.context.product.reload();
    this.processHalItem.requirements.reload();
    this.loadForm();
  }

  isValid(): boolean {
    return !!this.selectedRequirements;
  }

  collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      requirements: JSON.stringify(this.selectedRequirements.map(req => req.data.id))
    };
  }

  toggleCheckbox($event, requirement) {
    if ($event.target.checked) {
      this.selectedRequirements.push(requirement);
    } else {
      const result = this.selectedRequirements.filter(req => req.data.id !== requirement.data.id);
      this.selectedRequirements = result;
    }
    this.saveAction();
  }

}
