export function getFilterData(filter: any, filters = []): any[] {

  let filterValue;
  if (filter) {
    filterValue = filter;
    if (filter && filter.value) {
      filterValue = filter.value;
    } else if (filter.id) {
      filterValue = filter.id;
    }

    if (filters.length > 0) {
      let filterFound = false;
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].field === filterValue.field) {
          filters[i] = filterValue;
          filterFound = true;
        }
      }
      if (!filterFound) {
        filters.push(filterValue);
      }
    } else {
      filters.push(filterValue);
    }
  }
  return filters;
}
