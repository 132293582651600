import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fes-fi-release-dates',
  templateUrl: './fi-release-dates.component.html',
  styleUrls: ['./fi-release-dates.component.scss']
})
export class FiReleaseDatesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
