import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DsvsHalAsyncLoaderModule, DsvsInlineEditModule, DsvsSharedModule, DsvsTableModule } from '@dsvs/dsvs-shared-ui-lib';
import { WorkflowGeneratorModule } from '@dsvs/workflow-generator-ui-lib';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { FileUploadModule, SplitButtonModule } from 'primeng/primeng';
import { SharedModule } from '../shared/shared.module';
import { RequirementsDetailComponent } from './components/create/requirements-detail.component';
import { RequirementsComponent } from './components/requirements.component';
import { RequirementsSearchComponent } from './components/search/requirements-search.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    PanelModule,
    DialogModule,
    ButtonModule,
    SplitButtonModule,
    FileUploadModule,
    WorkflowGeneratorModule,
    DsvsTableModule,
    DsvsSharedModule,
    DsvsInlineEditModule,
    DsvsHalAsyncLoaderModule
  ],
  declarations: [
    RequirementsSearchComponent,
    RequirementsDetailComponent,
    RequirementsComponent
  ]
})
export class RequirementsModule {

  static getRoutes(): Routes {
    return [
      {path: 'search', component: RequirementsSearchComponent},
      {path: 'detail', component: RequirementsDetailComponent},
      {path: '', component: RequirementsComponent}
    ];
  }
}

