import { inject, InjectionToken } from '@angular/core';
import { HAL_CLIENT_FACTORY_TOKEN, HalClient } from '@dsvs/hal-client';
import { environment } from '../../../../environments/environment';

export const FES_V1_CLIENT_TOKEN = new InjectionToken<Promise<HalClient>>('Hal client for wg v1 api', {
  providedIn: 'root',
  factory: () => {
    const halClientFactory = inject(HAL_CLIENT_FACTORY_TOKEN);
    return halClientFactory
    .getClient(environment.backend_hal)
    .url('v1')
    .map(url => {
      return halClientFactory.getClient(url);
    })
    .take(1)
    .toPromise();
  }
});
