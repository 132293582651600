import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FileHalItem, FileHalPage } from '../hal/file.hal.item';
import { HalCrudServiceImpl } from '../hal/hal.crud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { EDiffType } from '../interfaces/dtos/enums/EDiffType';
import { File } from '../interfaces/dtos/file';

export interface DiffService extends DsvsSearchableCrudEntityService<File> {
  diff(sourceType: EDiffType, source: any, targetType: EDiffType, target: any): Observable<any>;
}

@Injectable({
  providedIn: 'root'
})
export class DiffServiceImpl
  extends HalCrudServiceImpl<File, FileHalItem, FileHalPage>
  implements DiffService {

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, private http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'diff', collection: 'diffs'},
      FileHalPage,
      FileHalItem
    );
  }

  diff(sourceType: EDiffType, source: any, targetType: EDiffType, target: any): Observable<any> {
    const url = environment.backend + this.relation.single + '/' + sourceType + '/' + targetType;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const formdata: FormData = new FormData();
    formdata.append('source', source);
    formdata.append('target', target);
    return this.http.post(url, formdata, {headers});
  }
}
