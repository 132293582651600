import {Component, OnDestroy, OnInit} from '@angular/core';
import {DsvsBreadcrumbService, DsvsPermissionService} from '@dsvs/dsvs-shared-ui-lib';

export enum WorkspaceActions {
  CREATE_WORKSPACE = 'CREATE_WORKSPACE',
}

@Component({
  selector: 'fes-workspaces',
  templateUrl: './workspaces.component.html',
  styleUrls: ['./workspaces.component.scss']
})
export class WorkspacesComponent implements OnInit, OnDestroy {

  constructor(
    private breadcrumbService: DsvsBreadcrumbService,
    private permissionService: DsvsPermissionService
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.setMenuItems([
      {label: 'Workspaces'}
    ]);
    this.breadcrumbService.setToolbarVisible(true);
  }

  ngOnDestroy() {
  }

}
