import { Component, OnInit } from '@angular/core';
import { WorkflowActionHistoryComponent } from '@dsvs/workflow-generator-ui-lib';

@Component({
  selector: 'fes-fes-workflow-action-changetype-change-history',
  templateUrl: './fes-workflow-action-changetype-change-history.component.html',
  styleUrls: ['./fes-workflow-action-changetype-change-history.component.scss']
})
export class FesWorkflowActionChangetypeChangeHistoryComponent extends WorkflowActionHistoryComponent implements OnInit {

}
