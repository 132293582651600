import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ProcessHalItem } from '../../shared/hal/process.hal.item';
import { ProcessServiceImpl } from '../../shared/services/process.service';

@Injectable()
export class ProcessResolver implements Resolve<ProcessHalItem> {

  constructor(private processService: ProcessServiceImpl) {

  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<ProcessHalItem> | Promise<ProcessHalItem> | ProcessHalItem {
    const id = route.params.id;

    if (id) {
      return this.processService.getById(id).take(1).map(result => {
        return result;
      });
    }
    return Observable.of(null);
  }
}
