import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deletedname'
})
export class DeletedNamePipe implements PipeTransform {

  /** Pipe um den deleted status darzustellen
   * @param {string} value
   * @returns {string}
   */
  transform(value: boolean): string {
    return value === true ? 'Inaktiv' : 'Aktiv';
  }
}
