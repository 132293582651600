import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InlineEditComponent } from '@dsvs/dsvs-shared-ui-lib';
import { ActionContextHalItem } from '@dsvs/workflow-generator-dto';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { EGroupTypes } from '../../../../shared/interfaces/dtos/enums/EGroupTypes';
import { Version } from '../../../../shared/interfaces/dtos/version';
import { VersionServiceImpl } from '../../../../shared/services/version.service';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-fes-workflow-action-groupchange',
  templateUrl: './fes-workflow-action-groupchange.component.html',
  styleUrls: ['./fes-workflow-action-groupchange.component.scss']
})
export class FesWorkflowActionGroupchangeComponent extends FesWorkflowActionComponent implements OnInit {

  // selectedGroup: EGroupTypes;
  allGroups = [
    {label: EGroupTypes.NONE, value: EGroupTypes.NONE},
    {label: EGroupTypes.G1, value: EGroupTypes.G1},
    {label: EGroupTypes.G2, value: EGroupTypes.G2}
  ];

  version: Version;

  constructor(messageService: MessageService, private versionService: VersionServiceImpl) {
    super(messageService);
  }

  ngOnInit() {
    this.inputForm = new FormGroup(
      {
        group: new FormControl([], Validators.required)
      });

    this.loadVersion();

  }

  loadVersion() {
    this.context.process.async.subscribe(process => {
      if (process.data.resultClientVersionId) {
        this.versionService.getById(process.data.resultClientVersionId).subscribe(version => {
          this.version = version.data;
          if (this.version.group) {
            this.inputForm.setValue({group: EGroupTypes[this.version.group]});
          }
        });
      }
    });
  }

  public collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      group: this.inputForm.get('group').value.toString()
    };
  }

  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
    this.loadVersion();
  }
}
