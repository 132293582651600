import { Pipe, PipeTransform } from '@angular/core';
import { EServiceupdateState } from '../interfaces/dtos/enums/EServiceupdateState';

@Pipe({
  name: 'serviceupdatestatus'
})
export class ServiceupdateStatusPipe implements PipeTransform {

  /** Pipe um den ServiceupdateStatus darzustellen
   * @param {string} value
   * @returns {string}
   */
  transform(value: String): string {
    switch (value) {
      case EServiceupdateState.CANCELED:
        return 'storniert';
      case EServiceupdateState.PUBLISHED:
        return 'veröffentlicht';
      case EServiceupdateState.UNPUBLISHED:
        return 'unveröffentlicht';
    }
  }
}
