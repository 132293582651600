import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DsvsBreadcrumbService, DsvsPermissionService, DsvsToolbarItem} from '@dsvs/dsvs-shared-ui-lib';
import {ZapPermissions} from '../../../../../enums/zap-permissions.enum';
import {RepositoryService} from '../../../../../services/repository-service';
import {FormularActions} from '../../formular.component';
import {FormularScreenComponent} from '../formular-screen.component';
import {FormularInformationService} from '../list/formular-information.service';

@Component({
  selector: 'fes-formular-search-screen',
  templateUrl: './formular-search-screen.component.html',
  styleUrls: ['./formular-search-screen.component.scss']
})
export class FormularSearchScreenComponent implements OnInit, OnDestroy {

  selectedFormularId: string = null;
  showCreateModal = false;

  activeIndex: number;

  canAddFiles = false;

  @ViewChild(FormularScreenComponent) formScreen: FormularScreenComponent;

  constructor(
    private repositoryService: RepositoryService,
    private router: Router,
    private breadcrumbService: DsvsBreadcrumbService,
    private route: ActivatedRoute,
    private permissionService: DsvsPermissionService,
    private formInformationService: FormularInformationService
  ) {

    this.router.events.subscribe(event => {
      this.selectedFormularId = this.route.snapshot.paramMap.get('id');
    });
    if (this.router.url.indexOf('administration') === -1) {
      this.formInformationService.showWorkspaceFormsOnly = true;
      this.formInformationService.formUrl = '/forms/';
    } else {
      this.formInformationService.showWorkspaceFormsOnly = false;
      this.formInformationService.formUrl = '/administration/forms/';
    }
  }

  ngOnInit(): void {
    this.selectedFormularId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbService.setToolbarItems(this.getToolbarItems());
    this.breadcrumbService.setToolbarVisible(true);

    this.canAddFiles = this.permissionService.hasPermission([ZapPermissions.FES_FORM_CREATE]);
  }

  ngOnDestroy(): void {
    this.breadcrumbService.removeToolbarItems(this.getToolbarItems());
  }

  getToolbarItems(): DsvsToolbarItem[] {
    if (this.permissionService.hasPermission([ZapPermissions.FES_FORM_CREATE])) {
      return [
        {
          id: FormularActions.ADD_VERSION,
          icon: 'note_add',
          disabled: false,
          callback: () => {
            this.showCreateModal = true;
          },
          tooltip: 'Neue Version hinzufügen'
        }
      ];
    } else {
      return [];
    }
  }

  uploadSuccess($event) {
    this.showCreateModal = false;
    this.breadcrumbService.raiseToolClickedEventById(FormularActions.REFRESH_FORMS);
    this.router.navigate(['forms/', $event.formular.id]);
    if (this.formScreen) {
      this.formScreen.refreshData();
    }
  }
}
