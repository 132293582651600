import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/components/common/messageservice';
import { FileUpload } from 'primeng/primeng';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-workflow-action-comment-add',
  templateUrl: './fes-workflow-action-comment-add.component.html',
  styleUrls: ['./fes-workflow-action-comment-add.component.scss']
})
export class FesWorkflowActionCommentAddComponent extends FesWorkflowActionComponent implements OnInit {

  @Output() fileChanged = new EventEmitter<any>();
  @Output() fileRemoved = new EventEmitter<any>();
  @ViewChild('fileuploader') fileuploader: FileUpload;

  constructor(messageService: MessageService) {
    super(messageService);
  }

  ngOnInit() {
    this.inputForm = new FormGroup(
      {
        comment: new FormControl(''),
        file: new FormControl(null)
      });
  }

  public collectActionData(): WorkflowActionDataDto {
    if (this.inputForm.value.file != null) {
      return <WorkflowActionDataDto>{
        comment: this.inputForm.value.comment,
        file: this.inputForm.value.file
      };
    } else {
      return <WorkflowActionDataDto>{
        comment: this.inputForm.value.comment
      };
    }
  }

  isValid(): boolean {
    return !!this.inputForm.value.comment;
  }

  onFileChange(target) {
    if (target.files.length > 0) {
      const file = target.files[0];
      this.inputForm.get('file').setValue(file);

      if (this.fileChanged != null) {
        this.fileChanged.emit();
      }
    }
  }

  onFileRemoved() {
    this.fileRemoved.emit();
  }

  resetForm() {
    this.fileuploader.clear();
    this.inputForm.reset();
  }

}
