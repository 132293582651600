export enum ActionType {
  RequirementAdd = 'WFA_REQUIREMENT_ADD',
  RequirementSelect = 'WFA_REQUIREMENT_SELECT',
  DevVersionUpload = 'WFA_DEVVERSION_UPLOAD',
  DevVersionFinalization = 'WFA_DEVVERSION_FINALIZATION',
  VersionSelect = 'WFA_VERSION_SELECT',
  ServiceupdateSelect = 'WFA_SERVICEUPDATE_SELECT',
  NoteAdd = 'WFA_NOTE_ADD',
  CommentAdd = 'WFA_COMMENT_ADD',
  ReturnDate = 'WFA_RETURN_DATE',
  ExpectedDeliveryDate = 'WFA_EXPECTED_DELIVERY_DATE',
  ExpectedDevelopmentStartDate = 'WFA_EXPECTED_DEVELOPMENT_START_DATE',
  FirstDeliveryDate = 'WFA_FIRST_DELIVERY_DATE',
  ExpectedCompletionDate = 'WFA_EXPECTED_COMPLETION_DATE',
  GroupChange = 'WFA_GROUP_CHANGE',
  ChangetypeChange = 'WFA_CHANGETYPE_CHANGE',
  DmsNumber = 'WFA_DMS_NUMBER',
  FiReleaseDateSelect = 'WFA_FIRELEASEDATE_SELECT',
  ProcessAddManuscript = 'WFA_PROCESS_ADD_MANUSCRIPT',
  FormularCreate = 'WFA_FORMULAR_CREATE',
  CrewEdit = 'WFA_EDIT_CREW',
  FinalVersionSelect = 'WFA_FINAL_VERSION_SELECT',
  JuristSelect = 'WFA_ADD_AUFTRAG_JURIST',
  TemplateStyleSelect = 'WFA_TEMPLATE_STYLE_SELECT',
  OSPLUSACTIVATIONDATE_SELECT = 'WFA_OSPLUSACTIVATIONDATE_SELECT'
}
