import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient, HalItem, HalItemConstructor, HalPage, HalPageConstructor } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { Base } from '../interfaces/dtos/base';
import { HalReadServiceImpl } from './hal.read.service';
import { HalRelation } from './hal.relation';

export class HalCrudServiceImpl<T extends Base, R extends HalItem<T>, U extends HalPage<T, R>>
  extends HalReadServiceImpl<T, R, U>
  implements DsvsSearchableCrudEntityService<T> {

  constructor(v1Client: Promise<HalClient>,
    relation: HalRelation,
    protected readonly pageConstructor: HalPageConstructor<T, R, U>,
    protected readonly itemConstructor: HalItemConstructor<T, R>) {
    super(v1Client, relation, pageConstructor, itemConstructor);
  }

  create(object: T): Observable<R> {
    return this.execute<R>((v1Client: HalClient) => v1Client
      .typedPost<T, R>(this.itemConstructor, object, this.relation.collection)
    );
  }

  update(object: T): Observable<R> {
    return this.execute<R>((v1Client: HalClient) => v1Client
      .typedPut<T, R>(this.itemConstructor, object, this.relation.single, <any>{entityId: object.id})
    );
  }

  delete(object: T): Observable<never> {
    return this.execute<never>((v1Client: HalClient) => <any>v1Client
      .delete(this.relation.single, <any>{entityId: object.id})
    );
  }
}
