import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { DsvsAuthorizationService, DsvsFormHelper } from '@dsvs/dsvs-shared-ui-lib';
import { MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { DevVersion } from '../../../../../../shared/interfaces/dtos/dev-version';
import { DevVersionDocument } from '../../../../../../shared/interfaces/dtos/documents/files/dev-version-document';
import { XdpData } from '../../../../../../shared/interfaces/dtos/xdpdata';
import { AddDevVersionFormularComponent } from '../../forms/add-dev-version-formular/add-dev-version-formular.component';
import { AddXdpFormularComponent } from '../../forms/add-xdp-formular/add-xdp-formular.component';
import { VersionModalComponent } from '../../version-modal.component';

@Component(
  {
    selector: 'fes-dev-version-create-modal',
    templateUrl: './devversion-create-modal.component.html',
    styleUrls: ['./devversion-create-modal.component.scss']
  }
)
export class DevversionCreateModalComponent extends VersionModalComponent<DevVersion, DevVersionDocument> implements OnDestroy {

  @Input()
  title = 'Entwicklerversion';

  @Input()
  submitText = 'Abschicken';

  @ViewChild(AddDevVersionFormularComponent) addDevVersionFormular: AddDevVersionFormularComponent;
  @ViewChild(AddXdpFormularComponent) addXdpFormular: AddXdpFormularComponent;

  constructor(
    private authorizationService: DsvsAuthorizationService,
    private messageService: MessageService
  ) {
    super();
  }

  initStepItems(): MenuItem[] {
    if (this.validationDocument) {
      return [
        {label: this.validationDocument.fileType + '-Daten Überprüfen'},
        {label: 'Neue Entwicklerversion'}
      ];
    } else {
      return [];
    }
  }

  onAddDevVersion(event: Event) {

    if (
      this.addDevVersionFormular.addDevVersionForm.status !== 'INVALID' &&
      this.addXdpFormular.xdpDataForm.status !== 'INVALID'
    ) {

      const devVersionData = this.addDevVersionFormular.addDevVersionForm.getRawValue();
      const xdpDataFormData = this.addXdpFormular.xdpDataForm.getRawValue();
      const userData = this.authorizationService.getUserData();
      const parentDevVersion = this.validationData.parentDevVersion;

      const xdpData = new XdpData(
        (this.xdpData && this.xdpData.articleNumber) ? this.xdpData.articleNumber : xdpDataFormData.articleNumber,
        xdpDataFormData.title,
        xdpDataFormData.fassung,
        xdpDataFormData.owner,
        (this.xdpData && this.xdpData.versionHash) ? this.xdpData.versionHash : null,
        devVersionData.version,
        xdpDataFormData.attachment,
        xdpDataFormData.dynamisation,
        xdpDataFormData.shape,
        xdpDataFormData.countCopy,
        xdpDataFormData.copyRight,
        xdpDataFormData.signature,
        (this.xdpData && this.xdpData.textModule) ? this.xdpData.textModule : null
      );

      let devVersion;
      if (parentDevVersion) {
        devVersion = parentDevVersion;

      } else {
        devVersion = new DevVersion(
          this.fileType,
          devVersionData.version,
          (this.xdpData && this.xdpData.articleNumber) ? this.xdpData.articleNumber : xdpDataFormData.articleNumber,
          (<any>userData.result.id),
          (this.version && this.version.id) ? this.version.id : null,
          (this.formular && this.formular.id) ? this.formular.id : null,
          null,
          null,
          null,
          null,
          (this.version && this.version.displayName) ? this.version.displayName : null,
          null,
          null,
          null,
          null,
          devVersionData.uploadComment,
          null,
          null,
          null,
          null,
          xdpData
        );
      }

      devVersion.displayName = devVersionData.version;
      devVersion.uploadComment = devVersionData.uploadComment;
      devVersion.id = this.validationData.devVersionId;
      devVersion.xdpData = xdpData;

      this.sendData.emit(devVersion);

    } else {
      this.messageService.add(
        {
          severity: 'error',
          summary: 'Formular-Fehler',
          detail: 'Das Formular enhält einen Fehler.'
        }
      );
    }
  }

  openNext(event: Event) {

    switch (this.selectedTab) {
      case 0 : {
        if (this.addXdpFormular && this.addXdpFormular.xdpDataForm.status !== 'INVALID') {
          this.selectedTab = this.selectedTab + 1;
        } else {
          DsvsFormHelper.validateAllFormFields(this.addXdpFormular.xdpDataForm);
        }
        break;
      }
      case 1 : {
        if (this.addDevVersionFormular && this.addDevVersionFormular.addDevVersionForm.status !== 'INVALID') {
          this.selectedTab = this.selectedTab + 1;
        } else {
          DsvsFormHelper.validateAllFormFields(this.addDevVersionFormular.addDevVersionForm);
        }
        break;
      }
    }
  }

  openLast(event: Event) {
    this.selectedTab = this.selectedTab - 1;
  }

  resetForms() {
    this.selectedTab = 0;
    if (this.addDevVersionFormular) {
      this.addDevVersionFormular.addDevVersionForm.reset();
    }
    if (this.addXdpFormular) {
      this.addXdpFormular.xdpDataForm.reset();
    }
  }
}
