import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of as observableOf } from 'rxjs';
import { createMajorMinor, SelectOptions } from '../../../../../../../helper/select-helper';
import { DevVersionHalPage } from '../../../../../../shared/hal/devversion.hal.item';
import { FormularHalItem, FormularHalPage } from '../../../../../../shared/hal/formular.hal.item';
import { getFilterData } from '../../../../../../shared/helper/FilterDataHelper';
import { ValidationDocument } from '../../../../../../shared/interfaces/dtos/documents/validation-document';
import { Id } from '../../../../../../shared/interfaces/dtos/Id';
import { DevVersionServiceImpl } from '../../../../../../shared/services/dev-version.service';
import { FormServiceImpl } from '../../../../../../shared/services/form.service';

export enum CreationType {
  CREATE = 'CREATE',
  SELECT = 'SELECT',
  DEVVERSION = 'DEVVERSION',
}

@Component({
  selector: 'fes-add-other-version-formular',
  templateUrl: './add-other-version-formular.component.html',
  styleUrls: ['./add-other-version-formular.component.scss']
})
export class AddOtherVersionFormularComponent implements OnInit {

  unknownDataForm: FormGroup;

  isNewSelection: CreationType;

  _validationDocument: ValidationDocument = null;
  majorMinorOptions: SelectOptions[];

  devVersions: DevVersionHalPage;

  @Input()
  allowedCreationTypes = [CreationType.CREATE, CreationType.SELECT, CreationType.DEVVERSION];

  @Output()
  creationTypeChanged = new EventEmitter<CreationType>();

  @Input()
  processId = null;

  @Input() set validationDocument(value: ValidationDocument) {
    this._validationDocument = value;
    this.initForm();
  }

  get validationDocument(): ValidationDocument {
    return this._validationDocument;
  }

  constructor(
    public formularService: FormServiceImpl,
    public devVersionService: DevVersionServiceImpl
  ) {
  }

  ngOnInit() {
    this.majorMinorOptions = createMajorMinor();
    this.isNewSelection = this.allowedCreationTypes[0];
  }

  toggleRadio($event, selection) {
    this.isNewSelection = selection;
    this.creationTypeChanged.emit(selection);
    this.unknownDataForm.reset();
  }

  loadDevVersions(formularId: Id) {
    // TODO: use relationService instead of "native" service
    let filters: any[] = [];

    filters = getFilterData(
      {
        field: 'state',
        filterBy: {label: 'NEW', value: 'NEW'}
      },
      filters);

    filters.push({
      field: 'formularId',
      filterBy: formularId
    });

    if (this.processId) {
      filters.push({
        field: 'processId',
        filterBy: this.processId
      });
    }

    const pageable = {
      size: 2000
    };

    this.devVersionService.filter(
      '',
      pageable,
      JSON.stringify(filters)
    ).subscribe(result => {
        this.devVersions = result;
      },
      err => {
        console.error(err);
      });
  }

  creationTypeAllowed(creationType: string): boolean {
    return this.allowedCreationTypes.includes(CreationType[creationType]);
  }

  fetchFormulars = ($event, inlineEdit): Observable<FormularHalPage> => {
    return this.formularService.search($event.query);
  };

  saveFormular = (formularHalItem: any): Observable<FormularHalItem> => {
    this.unknownDataForm.patchValue({formular: formularHalItem});
    this.loadDevVersions(formularHalItem.data.id);
    return observableOf(formularHalItem);
  };

  checkIfVersionIsSelected(version) {
    if (this.unknownDataForm.controls.version.value) {
      if (version.data.id === this.unknownDataForm.controls.version.value.data.id) {
        return true;
      }
    }
    return false;
  }

  toggleVersionRadio($event, version) {
    if ($event.target.checked) {
      this.unknownDataForm.patchValue({version: version});
    }
  }

  toggleDevVersionRadio($event, devVersion) {
    if ($event.target.checked) {
      this.unknownDataForm.patchValue({devVersion: devVersion});
    }
  }

  initForm() {
    if (this.unknownDataForm === undefined) {
      this.createAddVersionForm();
    }
    if (this.validationDocument &&
      this.validationDocument.validationData
      && this.validationDocument.validationData.formular) {
      this.saveFormular({
        data:
        this.validationDocument.validationData.formular
      });
    }
  }

  createAddVersionForm() {
    this.unknownDataForm = new FormGroup(
      {
        formular: new FormControl(null),
        version: new FormControl(null),
        devVersion: new FormControl(null)
      });
  }
}
