import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { EFileTypes } from '../modules/shared/interfaces/dtos/enums/EFileTypes';

// TODO: Halify

@Injectable()
export class RepositoryService {

  private backend: string = environment.backend;
  private endpoint = 'repository';

  constructor(private http: HttpClient) {
  }
  getAllowedFileTypes(): Observable<Map<EFileTypes, String>> {
    const headers = new HttpHeaders().set('content-type', 'application/json; charset=utf-8');
    return this.http.get<Map<EFileTypes, String>>(this.backend + this.endpoint + `filetypes`, {headers});
  }

  getTags(): Observable<string[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json; charset=utf-8');
    return this.http.get<string[]>(this.backend + this.endpoint + `tags`, {headers});
  }
}
