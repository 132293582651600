import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DsvsPermissionService, FileUploadComponent, InlineEditComponent } from '@dsvs/dsvs-shared-ui-lib';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { ZapPermissions } from '../../../../enums/zap-permissions.enum';
import { getExceptionMessages } from '../../../../helper/exception-helper';
import { FiReleaseDateHalItem } from '../../../shared/hal/fiReleaseDateHalItem';
import { EGroupTypes } from '../../../shared/interfaces/dtos/enums/EGroupTypes';
import { FiReleaseDate } from '../../../shared/interfaces/dtos/fireleasedate';
import { FiReleaseDateServiceImpl } from '../../../shared/services/fi-release-date.service';
import { FileServiceImpl } from '../../../shared/services/file.service';

@Component({
  selector: 'fes-fi-release-dates-detail',
  templateUrl: './fi-release-dates-detail.component.html',
  styleUrls: ['./fi-release-dates-detail.component.scss']
})
export class FiReleaseDatesDetailComponent implements OnInit {

  file: any;
  isEditable = false;

  @Input() isNew = false;

  fiReleaseDate: FiReleaseDate;
  _fiReleaseDateHalItem: FiReleaseDateHalItem;

  @Input()
  set fiReleaseDateHalItem(fiReleaseDateHalItem: FiReleaseDateHalItem) {
    if (fiReleaseDateHalItem) {
      this.fiReleaseDate = fiReleaseDateHalItem.data;
      this._fiReleaseDateHalItem = fiReleaseDateHalItem;
    }
  }

  get fiReleaseDateHalItem(): FiReleaseDateHalItem {
    return this._fiReleaseDateHalItem;
  }

  @Output() showEditModalEvent = new EventEmitter<boolean>();
  @ViewChild('fileuploader') fileuploader: FileUploadComponent;

  constructor(private fireleasedateService: FiReleaseDateServiceImpl,
              private messageService: MessageService,
              private fileService: FileServiceImpl,
              private permissionService: DsvsPermissionService
  ) {
  }

  ngOnInit() {
    this.isEditable = this.isNew || this.permissionService.hasPermission([ZapPermissions.FES_FIRELEASEDATE_UPDATE]);
    if (this.isNew) {
      this.fiReleaseDate = <FiReleaseDate>{
        displayName: '',
        releaseDate: null
      };
    }
  }

  create() {
    this.fireleasedateService.create(this.fiReleaseDate).subscribe(result => {

    }, error2 => {
      console.error(error2);
    });
  }

  saveData = (value, component): Observable<any> => {
    if (!this.isNew) {
      return this.fiReleaseDateHalItem.save();
    } else {
      return Observable.of(value);
    }

  };

  save() {
    if (this.isNew) {
      this.fireleasedateService.create(this.fiReleaseDate).subscribe(
        fiReleaseDateHalItem => {
          this.fiReleaseDateHalItem = fiReleaseDateHalItem;
          this.fiReleaseDate = fiReleaseDateHalItem.data;
          this.isNew = false;
          this.addFile(this.file);
          this.cancelModal();
        }, error2 => {
          console.error('error while creating fi-release-date - {}', error2);
          this.messageService.add({
            severity: 'error',
            summary: 'Erfolg',
            detail: 'Fehler beim erstellen.'
          });
        });
    }
  }

  addFile(file) {
    this.file = file;
    if (!this.isNew && this.file) {
      this.fireleasedateService.uploadFile(this.fiReleaseDate, this.file).subscribe(
        result => {
          this.file = null;
          this.fileuploader.resetUploader();
          this.fiReleaseDateHalItem.reload();
        },
        (err) => {
          getExceptionMessages(err).forEach(
            message => this.messageService.add(message)
          );
          this.fileuploader.resetUploader();
        }
      );
    }
  }

  deleteFile($event) {
    this.fireleasedateService.deleteFile($event.linkData.href).subscribe(
      fireleasedate => {
        this.fiReleaseDateHalItem.reload();
      }
    );
  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  cancelModal() {
    this.showEditModalEvent.emit(false);
  }

  fetchGroups = (event): Observable<any[]> => {
    return Observable.of(
      [
        EGroupTypes.G1,
        EGroupTypes.G2
      ]);
  };

  updateGroup = (value, component: InlineEditComponent): Observable<any> => {
    if (!this.isNew) {
      return this.fireleasedateService.update(component.model.data);
    } else {
      return Observable.of(null);
    }
  };
}
