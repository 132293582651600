import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { GlobalCrew } from '../interfaces/dtos/globalcrew';
import { GlobalRoleUserRelationPage } from './globalroleuser.hal.item';
import { WorkspaceRelationItem } from './workspace.hal.item';

export class GlobalCrewHalItem extends HalItemImpl<GlobalCrew> {
  constructor(data: GlobalCrew,
              client: HalClient,
              factory: HalClientFactory) {
    super(data, client, factory);
    this.globalroleusers = new GlobalRoleUserRelationPage('globalroleusers', this);
    this.workspace = new WorkspaceRelationItem('workspace', this);
  }

  readonly globalroleusers: GlobalRoleUserRelationPage;
  readonly workspace: WorkspaceRelationItem;
}

export class GlobalCrewHalPage extends HalPageImpl<GlobalCrew, GlobalCrewHalItem> {

}

export class GlobalCrewRelationPage extends HalRelationPage<GlobalCrew, GlobalCrewHalItem, GlobalCrewHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, GlobalCrewHalPage, GlobalCrewHalItem);
  }
}

export class GlobalCrewRelationItem extends HalRelationItem<GlobalCrew, GlobalCrewHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, GlobalCrewHalItem);
  }
}
