import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchCategoryName'
})
export class SearchCategoryNamePipe implements PipeTransform {

  /** Pipe um den den richtigen Namen der Kategorie zu resolven
   * @param {string} value
   * @returns {string}
   */
  transform(value: String): string {
    switch (value) {
      case 'FORMS':
        return 'Formulare';
      case 'PROCESSES':
        return 'Aufträge';
      case 'REQUIREMENTS':
        return 'Anforderungen';
      case 'SERVICE_UPDATES':
        return 'Serviceupdates';
      case 'TAGS':
        return 'Tags';
      case 'VERSIONS':
        return 'Versionen';
      default:
        return 'Unbekannt';
    }
  }
}
