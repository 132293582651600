import { Pipe, PipeTransform } from '@angular/core';
import { SelectOptions } from '../../../helper/select-helper';

@Pipe({
  name: 'tagcategory'
})
export class TagCategoryPipe implements PipeTransform {

  /** Pipe um den den richtigen Namen der Kategorie zu resolven
   * @param {string} value
   * @param {SelectOptions[]} categories
   * @returns {string}
   */
  transform(value: string, categories: SelectOptions[]): string {
    return categories.find(a => a.value === value).label;
  }
}
