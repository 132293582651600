import { Component, OnDestroy, OnInit } from '@angular/core';
import { DsvsBreadcrumbService, DsvsToolbarItem } from '@dsvs/dsvs-shared-ui-lib';

export enum ProjectActions {
  CREATE_PROJECT = 'CREATE_PROJECT',
  PROJECT_WITH_FORMS = 'PROJECT_WITH_FORMS',
}

@Component({
  selector: 'fes-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {

  constructor(
    private breadcrumbService: DsvsBreadcrumbService
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.setMenuItems([
      {label: 'Auftragssammlungen'}
    ]);
    this.breadcrumbService.setToolbarItems(this.getToolbarItems());
    this.breadcrumbService.setToolbarVisible(true);
  }

  ngOnDestroy() {
    this.breadcrumbService.removeToolbarItems(this.getToolbarItems());
  }

  getToolbarItems(): DsvsToolbarItem[] {
    // TODO: Add Permission!
    // disabled: !this.permissionService.hasPermission([ZapPermissions.FES_PROCESS_CREATE]),
    return [
      {
        id: ProjectActions.CREATE_PROJECT,
        icon: 'create_new_folder',
        tooltip: 'Neue Auftragssammlung',
        disabled: false
      }, {
        id: ProjectActions.PROJECT_WITH_FORMS,
        icon: 'repeat',
        tooltip: 'Auftragssammlung generieren',
        disabled: false
      }
    ];
  }
}
