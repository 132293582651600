import { Component, OnDestroy, OnInit } from '@angular/core';
import { DsvsBreadcrumbService, DsvsTableColumn, DsvsToolbarItem, InlineEditComponent } from '@dsvs/dsvs-shared-ui-lib';
import { Page } from '@dsvs/hal-client';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { ProjectHalItem, ProjectHalPage } from '../../../../shared/hal/project.hal.item';
import { FormServiceImpl } from '../../../../shared/services/form.service';
import { ProjectServiceImpl } from '../../../../shared/services/project.service';
import { ProjectActions } from '../projects.component';

@Component({
  selector: 'fes-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit, OnDestroy {

  loading = false;

  editProject: ProjectHalItem;
  parentProject: ProjectHalItem;
  expandedRows: any = {};

  projects: ProjectHalPage;

  reqTableColums: DsvsTableColumn[];

  toolbarSubscription: Subscription;
  showEditModal = false;
  showSubProjectEditModal = false;
  showCreateWithFormsModal = false;

  constructor(
    private projectService: ProjectServiceImpl,
    private formService: FormServiceImpl,
    private breadcrumbService: DsvsBreadcrumbService
  ) {
  }

  ngOnInit() {
    this.reqTableColums = this.getColumns();
    this.toolbarSubscription = this.breadcrumbService.toolClickedHandler.subscribe(item => {
      this.onToolBarItemSelected(item);
    });
  }

  ngOnDestroy(): void {
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
  }

  onToolBarItemSelected(item: DsvsToolbarItem) {
    switch (item.id) {
      case ProjectActions.CREATE_PROJECT: {
        this.onCreate();
        break;
      }
      case ProjectActions.PROJECT_WITH_FORMS: {
        this.onCreateWithForms();
        break;
      }
    }
  }

  private getColumns(): DsvsTableColumn[] {
    return [
      {field: '', width: '40px', header: '', sort: false, filter: '', hidden: false, format: 'SUB_PROJECT'},
      {field: 'name', header: 'Name', sort: true, filter: '', hidden: false, format: 'EDITABLE'},
      {field: 'shortName', header: 'Kürzel', sort: true, filter: '', hidden: false, format: 'EDITABLE'},
      {field: 'title', header: 'Bezeichnung', sort: true, filter: '', hidden: false, format: 'EDITABLE'},
      {field: 'description', header: 'Beschreibung', sort: true, filter: '', hidden: false, format: 'EDITABLE'},
      {field: 'deleted', header: 'Status', sort: true, filter: '', hidden: false, format: 'DELETED'},
      {field: 'createdByName', header: 'Ersteller', sort: true, filter: '', hidden: false},
      {field: 'lastModifiedDate', header: 'Datum', sort: true, filter: '', hidden: false, format: 'DATE'},
      {field: '', header: 'Aktionen', width: '180px', format: 'ACTION'}
    ];
  }

  getSplitButtonItems(projectHalItem: ProjectHalItem): MenuItem[] {
    const menuItems = [
      {
        label: 'Bearbeiten', icon: 'fa ui-icon-edit', command: () => {
          this.showDetail(projectHalItem);
        }
      }
    ];
    if (projectHalItem.data.deleted) {
      menuItems.push({
        label: 'Aktivieren', icon: 'fa ui-icon-check-circle', command: () => {
          this.updateTagDeleted(projectHalItem, false);
        }
      });
    } else {
      menuItems.push({
        label: 'Deaktivieren', icon: 'fa ui-icon-remove-circle', command: () => {
          this.updateTagDeleted(projectHalItem, true);
        }
      });
    }
    if (projectHalItem.data.parentId == null) {
      menuItems.push(
        {
          label: 'Teilsammlung hinzufügen', icon: 'fa ui-icon-create-new-folder', command: () => {
            this.addSubProject(projectHalItem);
          }
        });
    }

    return menuItems;
  }

  expandRow(rowIndex: number) {
    if (this.expandedRows[rowIndex] === 1) {
      this.expandedRows[rowIndex] = null;
    } else {
      this.expandedRows[rowIndex] = 1;
    }
  }

  addSubProject(projectHalItem: ProjectHalItem) {
    this.editProject = null;
    this.parentProject = projectHalItem;
    this.showSubProjectEditModal = true;
  }

  showDetail(projectHalItem: ProjectHalItem) {
    this.editProject = projectHalItem;
    this.showEditModal = true;
  }

  onCreate() {
    this.editProject = null;
    this.showEditModal = true;
  }

  onCreateWithForms() {
    this.showCreateWithFormsModal = true;
  }

  reloadData(searchTerm: string, page?: Page) {
    this.loading = true;
    this.projectService.rootProjects(searchTerm, page).subscribe(result => {
        this.projects = result;
        this.loading = false;
      },
      err => {
        this.loading = false;
        console.error(err);
      });
  }

  saveRow = (value, component: InlineEditComponent): Observable<ProjectHalItem> => {
    return component.model.save();
  };

  updateTagDeleted(projectHalItem: ProjectHalItem, deleted) {
    const project = projectHalItem.data;
    project.deleted = deleted;
    this.projectService.update(project).subscribe(
      result => {
        this.reloadData('');
      },
      err => {
        console.error(err);
      });
  }

  receiveShowCreateWithFormsModal($event) {
    this.showCreateWithFormsModal = $event;
  }

  createWithFormsSuccess($event) {
    this.reloadData('');
  }

  receiveShowEditModal($event) {
    this.showEditModal = $event;
    this.editProject = null;
    this.reloadData('');
  }

  subProjectCreationSuccess($event: ProjectHalItem) {
    // TODO: Use addRelation
    let ids = [];
    this.parentProject.children.async.subscribe(
      children => {
        ids = children.content.map(child => child.data.id);
        ids.push($event.data.id);
        this.parentProject.updateRelations('projects', ids).subscribe(
          () => {
            this.reloadData('');
            this.editProject = null;
            this.parentProject = null;
          }
        );
      }
    );
  }

  receiveShowSubProjectEditModal($event) {
    this.showSubProjectEditModal = $event;
  }

}
