import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InlineEditComponent } from '@dsvs/dsvs-shared-ui-lib';
import { Page } from '@dsvs/hal-client';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import { ViewParameter } from '@dsvs/workflow-generator-ui-lib/lib/hal';
import { MessageService } from 'primeng/components/common/messageservice';
import { Observable } from 'rxjs';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { ServiceupdateHalItem } from '../../../../shared/hal/serviceupdate.hal.item';
import { WGContextHalItem } from '../../../../shared/hal/wfg/wgcontext.hal.item';
import { ServiceupdateServiceImpl } from '../../../../shared/services/serviceupdate.service';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-workflow-action-serviceupdate-select',
  templateUrl: './fes-workflow-action-serviceupdate-select.component.html',
  styleUrls: ['./fes-workflow-action-serviceupdate-select.component.scss']
})
export class FesWorkflowActionServiceupdateSelectComponent extends FesWorkflowActionComponent implements OnInit {

  loading: boolean;
  cols: any[];
  processHalItem: ProcessHalItem;
  noServiceupdate = true;
  selectedServiceupdate: ServiceupdateHalItem = null;
  viewwwww: ViewParameter = null;
  selectedServiceupdateError = null;
  validationRequestSingleRequest = false;

  constructor(
    public serviceupdateService: ServiceupdateServiceImpl,
    messageService: MessageService
  ) {
    super(messageService);

    this.cols = [
      {field: 'displayName', header: 'Serviceupdate'},
      {field: 'title', header: 'Titel'}
    ];
  }

  ngOnInit() {
    this.inputForm = new FormGroup(
      {
        serviceupdateComment: new FormControl('', Validators.required)
      });
    this.onReloadData('');
  }

  onReloadData(searchTerm: string, page?: Page) {
    this.loading = true;
    (<WGContextHalItem>this.context).process.async.subscribe(
      process => {

        this.processHalItem = process;

        this.viewwwww = {name: 'for-process', data: {'processId': this.processHalItem.data.id}};

        if (process.data.serviceupdateComment) {
          this.inputForm.setValue({serviceupdateComment: process.data.serviceupdateComment});
        }

        process.serviceupdate.reload();

        if (process.serviceupdate.async) {
          process.serviceupdate.async.subscribe(serviceupdate => {
            if (serviceupdate && serviceupdate.data && serviceupdate.data.id) {
              this.noServiceupdate = false;
              this.selectedServiceupdate = serviceupdate;
            }
          });
        }
      }
    );
  }

  setServiceupdate = (value, component: InlineEditComponent) => {
    if (component.modelValue) {
      this.selectedServiceupdate = component.modelValue;
    }
    return Observable.of(component.modelValue);
  };

  fetchServiceupdates = ($event) => {
    return this.serviceupdateService.searchForProcess($event.query, this.processHalItem.data.id);
  };

  toggleRadio(event: any, value: boolean) {
    this.noServiceupdate = value;
    if (this.noServiceupdate === true) {
      this.selectedServiceupdate = null;
    }
  }

  isValid(): boolean {

    if (this.inputForm.valid) {

      if (!this.noServiceupdate) {
        if (!this.selectedServiceupdateError) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      serviceupdateId: this.getServiceupdateId(),
      serviceupdateComment: this.inputForm.value.serviceupdateComment
    };
  }

  getServiceupdateId(): string {
    if (this.selectedServiceupdate) {
      if (this.selectedServiceupdate.data) {
        return this.selectedServiceupdate.data.id;
      } else {
        return this.selectedServiceupdate['id'];
      }
    }
    return null;
  }

  disableControls() {
    this.loading = true;
  }

  enableControls() {
    this.loading = false;
  }

  resetForm() {
    this.selectedServiceupdate = null;
    this.inputForm.reset();
  }

  add() {
    this.saveAction();
  }

  onError(error: any) {

    if (error.error.logref === 'SU_SAME_PRODUCT_ALREADY_EXISTS') {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: error.error.message,
        life: 7500
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Aktion',
        detail: 'Fehler beim Ausführen der Aktion'
      });
    }
  }

  onServiceupdateSelected($event) {
    this.selectedServiceupdate = $event;

    if (!this.noServiceupdate && this.selectedServiceupdate) {

      if (!this.validationRequestSingleRequest) {
        this.validationRequestSingleRequest = true;
        this.serviceupdateService.validateAddProcess(this.getServiceupdateId(),
          this.processHalItem.data.id).subscribe(success => {
          this.selectedServiceupdateError = null;
          this.validationRequestSingleRequest = false;
        }, error => {
          this.selectedServiceupdateError = error;
          this.validationRequestSingleRequest = false;

        });
      }
    }
  }
}
