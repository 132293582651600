import { Component, Input } from '@angular/core';
import { VersionHalItem } from '../../../../shared/hal/version.hal.item';

@Component({
  selector: 'fes-version-detail',
  templateUrl: './version-detail.component.html',
  styleUrls: ['./version-detail.component.scss']
})
export class VersionDetailComponent {

  @Input() version: VersionHalItem;

  constructor() {}
}
