import { Pipe, PipeTransform } from '@angular/core';
import { EDynamisationTypes } from '../interfaces/dtos/enums/EDynamisationTypes';

@Pipe({
  name: 'dynamisation'
})
export class DynamisationPipe implements PipeTransform {

  /** Pipe unm den
   * @param {string} value
   * @returns {string}
   */
  transform(value: EDynamisationTypes): string {
    if (value === EDynamisationTypes.NONE) {
      return 'keiner';
    }
    return <string>value;
  }
}
