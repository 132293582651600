import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import {
  ActionContextHalPage,
  ActionHalItem,
  ActionHalPage,
  ContextHalItem,
  StepContextHalItem,
  TransitionHalPage
} from '@dsvs/workflow-generator-dto';
import { WorkflowStepContextServiceImpl } from '@dsvs/workflow-generator-ui-lib';
import { includes } from 'lodash';
import { CommunicationService } from '../../../../../services/communication-service';
import { ActionType } from '../../../../fes-workflow/enums/action-type.enum';
import { WGStepContextHalItem } from '../../../../shared/hal/wfg/wgstepcontext.hal.item';

const filteredActions = [
  ActionType.NoteAdd,
  ActionType.CommentAdd
];

@Component({
  selector: 'fes-process-detail-actions',
  templateUrl: './process-detail-action.component.html',
  styleUrls: ['./process-detail-action.component.scss']
})
export class ProcessDetailActionComponent implements OnInit, OnChanges {

  displayedActions: ActionHalItem[] = [];

  actionContexts: ActionContextHalPage;

  @Input()
  transitions: TransitionHalPage;

  @Input()
  actions: ActionHalPage;

  @Input()
  context: ContextHalItem;

  @Input()
  stepContext: StepContextHalItem;

  constructor(
    private communicationService: CommunicationService,
    private stepContextService: WorkflowStepContextServiceImpl
  ) {
  }

  ngOnInit() {
  }

  onActionSuccess($event: any) {
    this.reloadStepContext();

    // TODO: get correct element from Factory?
    if ($event
      && $event._embedded
      && $event._embedded.actioncontexts
      && $event._embedded.actioncontexts[0]
    ) {
      this.communicationService.publishMessage(
        'ACTION',
        $event._embedded.actioncontexts[0].alias,
        $event._embedded.actioncontexts[0]
      );
    } else {
      console.error('Got wrong item wrong factory.');
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    const actionChanges: SimpleChange = changes.actions;
    const actions: ActionHalPage = actionChanges ? actionChanges.currentValue : null;

    const transitionChanges: SimpleChange = changes.transitions;
    const transitions: TransitionHalPage = transitionChanges ? transitionChanges.currentValue : null;

    const stepContextChanges: SimpleChange = changes.stepContext;
    const stepContexts: WGStepContextHalItem = stepContextChanges ? stepContextChanges.currentValue : null;

    if (
      actionChanges
      && actions
      && actions !== actionChanges.previousValue
      && actions.content
    ) {
      this.displayedActions = [];
      actions.content.forEach(
        action => {
          if (!includes(filteredActions, action.data.alias)) {
            this.displayedActions.push(action);
          }
        }
      );
    }

    if (
      stepContextChanges
      && stepContexts
      && stepContexts.actioncontexts
    ) {
      this.loadActionContexts(stepContexts);
    }

    if (
      transitionChanges
      && transitions
      && transitions !== transitionChanges.previousValue
      && transitions.content
    ) {
      transitions.content.forEach(
        transition => {
          transition.actions.async.subscribe(
            transitionActions => {
              if (transitionActions.content) {
                this.displayedActions.forEach(
                  displayedAction => {
                    if (includes(transitionActions.content.map(a => a.data.alias), displayedAction.data.alias)) {
                      displayedAction.data.required = true;
                    }
                  }
                );
              }
            }
          );
        }
      );
    }
  }

  reloadStepContext() {
    this.stepContextService.getById(this.stepContext.data.id).subscribe(
      stepContextHalItem => {

        this.stepContext = stepContextHalItem;
        this.loadActionContexts(this.stepContext);
      }
    );
  }

  loadActionContexts(stepContextHalItem: StepContextHalItem) {
    stepContextHalItem.actioncontexts.setParams({
      sort: [],
      size: 0x7fffffff,
      page: 0
    }).async.subscribe(
      actionContexts => {
        this.actionContexts = actionContexts;
      }
    );
  }

  actionAlreadyPerformed(alias: string): boolean {
    if (this.actionContexts) {
      return (
        this.actionContexts.content.find(
          actionContext => actionContext.data.alias === alias
        ) != null
      );
    } else {
      return false;
    }
  }

}
