import { Pipe, PipeTransform } from '@angular/core';
import { EFileTypes } from '../interfaces/dtos/enums/EFileTypes';

@Pipe({
  name: 'fileTypes'
})
export class FileTypesPipe implements PipeTransform {

  /** Pipe unm den
   * @param {string} value
   * @returns {string}
   */
  transform(value: EFileTypes): string {
    switch (value) {
      case EFileTypes.WORD : {
        return 'Word';
      }
      case EFileTypes.XDP : {
        return 'XDP';
      }
    }
  }

}
