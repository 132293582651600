import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import {
  HalClient,
  Page,
  HAL_CLIENT_FACTORY_TOKEN,
  HalClientFactory,
  HalItem,
  HalPageImpl,
  HalItemImpl,
  HalPage
} from '@dsvs/hal-client';
import { FileHalItem, FileHalPage } from '../hal/file.hal.item';
import { HalCrudServiceImpl } from '../hal/hal.crud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { File } from '../interfaces/dtos/file';
import { Observable } from 'rxjs';
import { FormularHalPage, FormularHalItem } from '../hal/formular.hal.item';
import { ProcessHalPage, ProcessHalItem } from '../hal/process.hal.item';
import { ServiceupdateHalPage, ServiceupdateHalItem } from '../hal/serviceupdate.hal.item';
import { TemplateParameters } from '@dsvs/traverson-ng';

export interface SearchService extends DsvsSearchableCrudEntityService<File> {
  search(query: string): any;
}

interface SearchResultType {
  type: string;
}

export interface SearchResultMap {
  [key: string]: HalPage<any, HalItem<any>>;
}

@Injectable({
  providedIn: 'root'
})
export class SearchServiceImpl
  extends HalCrudServiceImpl<File, FileHalItem, FileHalPage>
  implements SearchService {

  constructor(
    @Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>,
    @Inject(HAL_CLIENT_FACTORY_TOKEN) private halClientFactory: HalClientFactory,
    private http: HttpClient
  ) {
    super(
      v1Client,
      <HalRelation>{single: 'search', collection: 'searches'},
      FileHalPage,
      FileHalItem);
  }

  search(query: string): Observable<any> {
    return this.searchByType(query, null);
  }

  searchByType(query: string, types: string[]): Observable<SearchResultMap> {
    const templateVars: TemplateParameters = {
      query,
      size: '3',
      page: 0
    };

    if (!!types && types.length > 0) {
      templateVars.type = types;
    }

    return this.execute((halClient: HalClient) => halClient.resource(
      'search',
      templateVars
    )).map((result: HalItem<any>) => {
      const resultsMap: SearchResultMap = {};
      const searchResults: SearchResultType[] = result.data.results;

      searchResults.forEach((searchResult) => {
        resultsMap[searchResult.type] = result.getTypedEmbeddedPage(
          HalPageImpl,
          HalItemImpl,
          searchResult.type,
          'content'
        );
      });

      return resultsMap;
    });
  }

  searchForms(query: string, page?: Page): Observable<any> {
    const size = page ? page.size.toString() : '5';
    const number = page ? page.number.toString() : 0;

    return this.execute((halClient: HalClient) => halClient.resource(
      'search',
      {
        query,
        type: ['FORMS'],
        size,
        page: number
      }
    )).map((result: HalItem<any>) => {
      return result.getTypedEmbeddedPage(FormularHalPage, FormularHalItem, 'FORMS', 'content');
    });
  }

  searchProcesses(query: string, page?: Page): Observable<ProcessHalPage> {
    const size = page ? page.size.toString() : '5';
    const number = page ? page.number.toString() : 0;

    return this.execute((halClient: HalClient) => halClient.resource(
      'search',
      {
        query,
        type: ['PROCESSES'],
        size,
        page: number
      }
    )).map((result: HalItem<any>) => {
      return result.getTypedEmbeddedPage(ProcessHalPage, ProcessHalItem, 'PROCESSES', 'content');
    });
  }

  searchServiceupdates(query: string, page?: Page): Observable<ServiceupdateHalPage> {
    const size = page ? page.size.toString() : '5';
    const number = page ? page.number.toString() : 0;

    return this.execute((halClient: HalClient) => halClient.resource(
      'search',
      {
        query,
        type: ['SERVICE_UPDATES'],
        size,
        page: number
      }
    )).map((result: HalItem<any>) => {
      return result.getTypedEmbeddedPage(ServiceupdateHalPage, ServiceupdateHalItem, 'SERVICE_UPDATES', 'content');
    });
  }

}
