import { DevVersion } from '../../dev-version';
import { EDevelopmentState } from '../../enums/EDevelopmentState';
import { Formular } from '../../formular';
import { Id } from '../../Id';
import { Version } from '../../version';
import { XdpData } from '../../xdpdata';
import { ValidationData } from '../validation-data';

export class DevVersionDocument extends ValidationData {

  constructor(formular: Formular,
              xdpData: XdpData,
              version: Version,
              missingFields: string[],
              increaseMajor: boolean,
              public state: EDevelopmentState,
              public userId: number,
              public devVersionId: Id,
              public uploadComment: string,
              public parentDevVersion: DevVersion
  ) {
    super(formular, xdpData, version, missingFields, increaseMajor);
  }

}
