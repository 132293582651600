import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsPage, DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HalFcrudServiceImpl } from '../hal/hal.fcrud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { ProjectHalItem, ProjectHalPage } from '../hal/project.hal.item';
import { Project, ProjectForms } from '../interfaces/dtos/project';

export interface ProjectService extends DsvsSearchableCrudEntityService<Project> {
  rootProjects(searchTerm: string, options?: DsvsPage): Observable<ProjectHalPage>;

  createWithForms(projectForms: ProjectForms): Observable<ProjectHalItem>;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectServiceImpl
  extends HalFcrudServiceImpl<Project, ProjectHalItem, ProjectHalPage>
  implements ProjectService {

  private backend: string = environment.backend;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, http: HttpClient) {

    super(
      v1Client,
      <HalRelation>{single: 'project', collection: 'projects'},
      http,
      ProjectHalPage,
      ProjectHalItem);
  }

  rootProjects(searchTerm: string, options?: DsvsPage): Observable<ProjectHalPage> {
    return super.search(searchTerm, options, {name: 'root'});
  }

  createWithForms(projectForms: ProjectForms): Observable<ProjectHalItem> {

    return this.http.post<ProjectHalItem>(this.backend + this.relation.collection + '/withforms', projectForms, {});

    // return super.execute<ProjectHalItem>((v1Client: HalClient) => v1Client
    //   .typedPost<Project, ProjectHalItem>(this.itemConstructor, projectForms, this.relation.collection + '/withforms')
    // );
  }

}
