import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DsvsBreadcrumbService } from '@dsvs/dsvs-shared-ui-lib';
import { FormularActions } from '../../formular.component';

@Component({
  selector: 'fes-formular-create',
  templateUrl: './formular-create-screen.component.html',
  styleUrls: ['./formular-create-screen.component.css']
})
export class FormularCreateScreenComponent implements OnInit {

  constructor(
    private breadcrumbService: DsvsBreadcrumbService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.setMenuItems([
      {label: 'Formulare', routerLink: ['/forms']},
      {label: 'Hinzufügen'}
    ]);
  }

  uploadSuccess($event) {
    this.breadcrumbService.raiseToolClickedEventById(FormularActions.REFRESH_FORMS);
    this.router.navigate(['forms/', $event.formular.id]);
  }

}
