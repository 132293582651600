import {DsvsSearchableReadEntityService} from '@dsvs/dsvs-shared-ui-lib';
import {ExternRecord} from '../interfaces/dtos/extern-record';
import {ExternRecordHalItem, ExternRecordHalPage} from '../hal/externrecord.hal.item';
import {Inject, Injectable} from '@angular/core';
import {FES_V1_CLIENT_TOKEN} from '../hal/hal.token';
import {HalClient} from '@dsvs/hal-client';
import {HttpClient} from '@angular/common/http';
import {HalRelation} from '../hal/hal.relation';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HalReadServiceImpl} from '../hal/hal.read.service';
import {saveAs} from 'file-saver';
import {resolveFileName} from '../../../helper/download-helper';

export interface ExternRecordService extends DsvsSearchableReadEntityService<ExternRecord> {
  create(externRecord: ExternRecord): Observable<ExternRecordHalItem>;

  update(externRecord: ExternRecord): Observable<ExternRecordHalItem>;

  delete(externRecordId: string, securityToken: string, success?, error?): Observable<ExternRecordHalItem>;

  uploadSingleFile(externRecord: ExternRecord, file: any): Observable<ExternRecord>;

  downloadExternRecordFile(externRecordId: string, success?, error?): any;

  deleteExternRecordFile(externRecordId: string, securityToken: string, success?, error?): Observable<ExternRecordHalItem>;
}

@Injectable({
  providedIn: 'root'
})


export class ExternRecordServiceImpl extends HalReadServiceImpl<ExternRecord, ExternRecordHalItem, ExternRecordHalPage>
  implements ExternRecordService {

  http: HttpClient;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'externrecord', collection: 'externrecords'},
      ExternRecordHalPage,
      ExternRecordHalItem
    );
    this.http = http;
  }

  /** Erstellt eine neue ExternRecordEntity im Backend */
  create(externRecord: ExternRecord): Observable<ExternRecordHalItem> {
    return this.execute<ExternRecordHalItem>((v1Client: HalClient) => v1Client
      .typedPost<ExternRecord, ExternRecordHalItem>(this.itemConstructor, externRecord, this.relation.collection)
    );
  }

  /** Updatet eine ExternRecordEntity im Backend */
  update(externRecord: ExternRecord): Observable<ExternRecordHalItem> {
    return this.execute<ExternRecordHalItem>((v1Client: HalClient) => v1Client
      .typedPut<ExternRecord, ExternRecordHalItem>(
        this.itemConstructor,
        externRecord,
        this.relation.single,
        <any>{entityId: externRecord.id}
      )
    );
  }

  /** Löscht eine ExternRecordEntity im Backend */
  delete(externRecordId: string, securityToken: string, success?, error?): Observable<ExternRecordHalItem> {
    const url: string = environment.backend + this.relation.collection + '/' + externRecordId;
    return this.http.delete<ExternRecordHalItem>(url, {headers: {'X-Extern-Record-Delete-Token': securityToken}});
  }

  // TODO: (Niki) url über HalClient beziehen anstatt selbst zusammen zu bauen
  /** Lädt ein File auf den Backend Server hoch */
  uploadSingleFile(externRecord: ExternRecord, file: any): Observable<ExternRecord> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post<ExternRecord>(
      environment.backend + this.relation.collection + '/' + externRecord.id + '/file',
      formdata,
      {}
    );
  }

  // TODO: (Niki) anstatt url selbst zusammen zu bauen den link über HalClient beziehen
  /** Lädt die Datei des ExternRecors mit der übergebenen Id herunter */
  downloadExternRecordFile(externRecordId: string, success?, error?): any {
    const url: string = environment.backend + this.relation.collection + '/' + externRecordId + '/file';
    return this.http.get(url, {responseType: 'blob', observe: 'response'}).subscribe(
      (res) => {
        if (success) {
          success(res);
        }
        saveAs(res.body, resolveFileName(res.headers));
      },
      (err) => {
        if (error) {
          error(err);
        }
        throw err;
      }
    );
  }

  // TODO: (Niki) anstatt url selbst zusammen zu bauen den link über HalClient beziehen
  /** Löscht die Datei des ExternRecors mit der übergebenen Id */
  deleteExternRecordFile(externRecordId: string, securityToken: string, success?, error?): Observable<ExternRecordHalItem> {
    const url: string = environment.backend + this.relation.collection + '/' + externRecordId + '/file';
    return this.http.delete<ExternRecordHalItem>(url, {headers: {'X-Extern-Record-Delete-Token': securityToken}});
  }


}
