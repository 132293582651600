import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DevVersion } from '../../../../../shared/interfaces/dtos/dev-version';
import { ValidationDocument } from '../../../../../shared/interfaces/dtos/documents/validation-document';
import { CreationType } from '../forms/add-other-version-formular/add-other-version-formular.component';

@Component(
  {
    selector: 'fes-formular-create-modal',
    templateUrl: './formular-create-modal.component.html',
    styleUrls: ['./formular-create-modal.component.scss']
  }
)

export class FormularCreateModalComponent {

  _validationDocument: ValidationDocument;

  @Input()
  allowedCreationTypes: CreationType[] = [CreationType.SELECT, CreationType.CREATE, CreationType.DEVVERSION];

  @Input()
  processId = null;

  @Input()
  title = 'Formular';

  @Input()
  submitText = 'Abschicken';

  @Input()
  visible = false;

  @Input()
  xdpReadOnly = false;

  @Input()
  set validationDocument(validationDocument: ValidationDocument) {
    this._validationDocument = validationDocument;
  }

  get validationDocument(): ValidationDocument {
    return this._validationDocument;
  }

  @Output()
  displayChange = new EventEmitter();

  @Output()
  sendData = new EventEmitter<ValidationDocument>();

  @Output()
  sendDevData = new EventEmitter<DevVersion>();

  constructor() {
  }

  onDisplayChange($event) {
    this.displayChange.emit($event);
  }

  onSendData(validationDocument: ValidationDocument) {
    this.visible = false;
    this.sendData.emit(validationDocument);
  }

  onUnknownData(validationDocument: ValidationDocument) {
    this.visible = true;
    this.validationDocument = validationDocument;
  }

  onSendDevVersionData(devVersion: DevVersion) {
    this.visible = false;
    this.sendDevData.emit(devVersion);
  }

}
