import {Component, OnInit} from '@angular/core';
import {DsvsBreadcrumbService, DsvsPermissionService, DsvsTableColumn, DsvsToolbarItem, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {Page} from '@dsvs/hal-client';
import {CrewHalItem, GroupHalItem, GroupHalPage} from '@dsvs/workflow-generator-dto';
import {WorkflowGroupServiceImpl} from '@dsvs/workflow-generator-ui-lib';
import {Observable} from 'rxjs';
import {ZapPermissions} from '../../../../../enums/zap-permissions.enum';
import {UserHalItem, UserHalPage} from '../../../../shared/hal/user.hal.item';
import {WorkspaceHalItem, WorkspaceHalPage} from '../../../../shared/hal/workspace.hal.item';
import {FormServiceImpl} from '../../../../shared/services/form.service';
import {UserServiceImpl} from '../../../../shared/services/user.service';
import {WorkspaceServiceImpl} from '../../../../shared/services/workspace.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'fes-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  public ZapPermissions = ZapPermissions;

  loading = false;

  users: UserHalPage;

  reqTableColums: DsvsTableColumn[];

  selectedRowDataForCrew: UserHalItem;

  displayedCrew: CrewHalItem;

  isWorkspaceEditable = false;
  isGroupEditable = false;
  isGroupReadable = false;

  showSearchInput = false;
  searchValue = '';
  pageSave: Page;

  constructor(private formService: FormServiceImpl,
              private userService: UserServiceImpl,
              private breadcrumbService: DsvsBreadcrumbService,
              public workspaceService: WorkspaceServiceImpl,
              private permissionService: DsvsPermissionService,
              private messageService: MessageService,
              public groupService: WorkflowGroupServiceImpl) {
  }

  ngOnInit() {

    this.isWorkspaceEditable = this.permissionService.hasPermission([ZapPermissions.FES_USER_WORKSPACE_UPDATE]);
    this.isGroupEditable = this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_GROUP_UPDATE]);
    this.isGroupReadable = this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_GROUP_READ]);

    this.reqTableColums = this.getColumns();
  }

  private getColumns(): DsvsTableColumn[] {
    if (this.isGroupReadable) {
      return [
        {field: 'zapId', header: 'Zap Id', width: '5%'},
        {field: 'displayName', header: 'Name', width: '20%'},
        {field: 'displayName', header: 'Workspaces', format: 'WORKSPACES', width: '25%'},
        {field: 'displayName', header: 'Gruppen', format: 'GROUPS', width: '25%'},
        {field: 'displayName', header: 'Ansprechpartner', format: 'CREWS', width: '25%'}
      ];
    } else {
      return [
        {field: 'zapId', header: 'Zap Id', width: '10%'},
        {field: 'displayName', header: 'Name', width: '30%'},
        {field: 'displayName', header: 'Workspaces', format: 'WORKSPACES', width: '30%'},
        {field: 'displayName', header: 'Ansprechpartner', format: 'CREWS', width: '30%'}
      ];
    }
  }

  reloadData(searchTerm: string, page?: Page) {
    this.pageSave = page;
    this.searchValue = searchTerm;
    this.loading = true;
    this.userService.search(searchTerm, page).subscribe(result => {
        this.users = result;
        this.loading = false;
      },
      err => {
        this.loading = false;
        console.error(err);
      });
  }

  fetchWorkspaces = ($event, inlineEdit): Observable<WorkspaceHalPage> => {
    return this.workspaceService.search($event.query);
  };

  updateWorkspace(workspaceHalItem: WorkspaceHalItem, component: InlineEditComponent): Observable<WorkspaceHalItem> {
    const user = <UserHalItem>component.model;
    const workspaces = <WorkspaceHalItem[]>component.modelValue;
    return user.workspaces.update(workspaces);
  }

  fetchGroups = ($event, inlineEdit): Observable<GroupHalPage> => {
    return this.groupService.search($event.query);
  };

  updateGroups($event, component: InlineEditComponent): Observable<WorkspaceHalItem> {
    const user = <UserHalItem>component.model;
    const groups = <GroupHalItem[]>component.modelValue;
    return user.groups.update(groups);
  }

  receiveHideCrewModal($event) {
    this.selectedRowDataForCrew = null;
  }

  receiveHideCrewDetailModal($event) {
    this.displayedCrew = null;
  }

  showCrewDialog(event) {
    if (event.constructor.name === UserHalItem.name) {
      this.selectedRowDataForCrew = event;
    } else if (event.constructor.name === CrewHalItem.name) {
      this.displayedCrew = event;
    }
  }

  getToolbarItems(): DsvsToolbarItem[] {
    return [
      {
        id: 'UPDATE_ZAPSYNC',
        icon: 'autorenew',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_USER_OVERVIEW_READ]),
        tooltip: 'Zap-Sync',
        callback: () => {
          this.userService.syncUsers().subscribe(result => {
            this.messageService.add({
              severity: 'success',
              summary: 'Erfolg',
              detail: 'Die Nutzer wurden aktualisiert.'
            });
            this.reloadData(this.searchValue, this.pageSave);
          }, error => {
            console.error(error);
          });
        }

      }
    ];
  }
}
