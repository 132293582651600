import {Component, OnDestroy, OnInit} from '@angular/core';
import {DsvsBreadcrumbService, DsvsPage, DsvsPermissionService, DsvsTableColumn, DsvsToolbarItem, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {MenuItem} from 'primeng/api';
import {Observable, Subscription} from 'rxjs';
import {ZapPermissions} from '../../../../../enums/zap-permissions.enum';
import {WorkspaceHalItem, WorkspaceHalPage} from '../../../../shared/hal/workspace.hal.item';
import {FormServiceImpl} from '../../../../shared/services/form.service';
import {WorkspaceServiceImpl} from '../../../../shared/services/workspace.service';
import {WorkspaceActions} from '../workspaces.component';

@Component({
  selector: 'fes-workspaces-list',
  templateUrl: './workspaces-list.component.html',
  styleUrls: ['./workspaces-list.component.scss']
})
export class WorkspacesListComponent implements OnInit, OnDestroy {

  loading = false;

  editWorkspace: WorkspaceHalItem;

  workspaces: WorkspaceHalPage;

  reqTableColums: DsvsTableColumn[];

  toolbarSubscription: Subscription;
  showEditModal = false;

  isEditable = false;

  constructor(
    private workspaceService: WorkspaceServiceImpl,
    private formService: FormServiceImpl,
    private breadcrumbService: DsvsBreadcrumbService,
    private permissionService: DsvsPermissionService
  ) {
  }

  ngOnInit() {

    this.isEditable = this.permissionService.hasPermission([ZapPermissions.FES_WORKSPACE_UPDATE]);

    this.reqTableColums = this.getColumns();
    // this.toolbarSubscription = this.breadcrumbService.toolClickedHandler.subscribe(item => {
    //   this.onToolBarItemSelected(item);
    // });
  }

  ngOnDestroy(): void {
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
  }

  onToolBarItemSelected(item: DsvsToolbarItem) {
    switch (item.id) {
      case WorkspaceActions.CREATE_WORKSPACE: {
        this.onCreate();
        break;
      }
    }
  }

  private getColumns(): DsvsTableColumn[] {
    return [
      {field: 'name', header: 'Name', sort: true, filter: '', hidden: false, format: 'EDITABLE'},
      {field: 'title', header: 'Kürzel', sort: true},
      {field: 'description', header: 'Beschreibung', sort: true, filter: '', hidden: false, format: 'EDITABLE'},
      {field: 'deleted', header: 'Status', sort: true, filter: '', hidden: false, format: 'DELETED'},
      {field: 'createdByName', header: 'Ersteller', sort: true, filter: '', hidden: false},
      {field: 'lastModifiedDate', header: 'Datum', sort: true, filter: '', hidden: false, format: 'DATE'},
      {field: '', header: 'Aktionen', width: '180px', format: 'ACTION'}
    ];
  }

  getSplitButtonItems(workspaceHalItem: WorkspaceHalItem): MenuItem[] {
    const menuItems: MenuItem[] = [
      {
        label: 'Bearbeiten', icon: 'fa ui-icon-edit', command: () => {
          this.onDetail(workspaceHalItem);
        }
      }
    ];
    if (workspaceHalItem.data.deleted) {
      menuItems.push({
        label: 'Aktivieren',
        icon: 'fa ui-icon-check-circle',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_WORKSPACE_ACTIVATE]),
        command: () => {
          this.updateTagDeleted(workspaceHalItem, false);
        }
      });
    } else {
      menuItems.push({
        label: 'Deaktivieren',
        icon: 'fa ui-icon-remove-circle',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_WORKSPACE_ACTIVATE]),
        command: () => {
          this.updateTagDeleted(workspaceHalItem, true);
        }
      });
    }
    return menuItems;
  }

  onDetail(workspaceHalItem: WorkspaceHalItem) {
    this.editWorkspace = workspaceHalItem;
    this.showEditModal = true;
  }

  onCreate() {
    this.editWorkspace = null;
    this.showEditModal = true;
  }

  reloadData(searchTerm: string, page?: DsvsPage) {
    this.loading = true;
    this.workspaceService.search(searchTerm, page).subscribe(result => {
        this.workspaces = result;
        this.loading = false;
      },
      err => {
        this.loading = false;
        console.error(err);
      });
  }

  saveRow = (value, component: InlineEditComponent): Observable<WorkspaceHalItem> => {
    return component.model.save();
  };

  updateTagDeleted(workspaceHalItem: WorkspaceHalItem, deleted) {
    const workspace = workspaceHalItem.data;
    workspace.deleted = deleted;
    this.workspaceService.update(workspace).subscribe(
      result => {
        this.reloadData('');
      },
      err => {
        console.error(err);
      });
  }

  receiveShowEditModal($event) {
    this.showEditModal = $event;
    this.editWorkspace = null;
    this.reloadData('');
  }

  getToolbarItems(): DsvsToolbarItem[] {
    return [
      {
        id: WorkspaceActions.CREATE_WORKSPACE,
        icon: 'create_new_folder',
        tooltip: 'Neuer Workspace',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_WORKSPACE_CREATE]),
        callback: () => {
          this.onCreate();
        }
      }
    ];
  }

}
