import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { germanCalenderTemplate } from '@dsvs/dsvs-shared-ui-lib';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { EGroupTypes } from '../../../../shared/interfaces/dtos/enums/EGroupTypes';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-fes-workflow-action-osplus-activationdate-select',
  templateUrl: './fes-workflow-action-osplus-activationdate-select.component.html',
  styleUrls: ['./fes-workflow-action-osplus-activationdate-select.component.scss']
})
export class FesWorkflowActionOsplusActivationdateSelectComponent  extends FesWorkflowActionComponent implements OnInit {


  date: any;
  process: ProcessHalItem;
  de = germanCalenderTemplate;
  minDateValue: Date;
  rerollDate : Date;
  isActive: boolean;

  constructor(messageService: MessageService) {
    super(messageService);
  }

  ngOnInit() {
    this.context.process.async.subscribe(process => {
      process.resultversion.async.subscribe(resultVersion => {
        this.isActive = resultVersion.data.group == EGroupTypes.G2;
      })
    })
    this.inputForm = new FormGroup({
        osPlusActivationDate: new FormControl(null)
      });
    this.loadProcess();
    this.minDateValue = new Date();
    this.minDateValue.setDate(this.minDateValue.getDate() + 1);
  }

  loadProcess() {
    this.context.process.async.subscribe(pro => {
      if (pro && pro.data.osPlusActivationDate) {
        this.inputForm.setValue({osPlusActivationDate: new Date(pro.data.osPlusActivationDate)});
      }
    });
  }

  public collectActionData(): WorkflowActionDataDto {
    const osPlusActivationDate = moment(this.inputForm.get('osPlusActivationDate').value).format('YYYY-MM-DD');
    return <WorkflowActionDataDto>{
      osPlusActivationDate: osPlusActivationDate
    };
  }

  onError(error: any) {
    super.onError(error);

    this.inputForm.setValue({osPlusActivationDate: this.rerollDate});
  }

  public dateSelected(value) {
    this.rerollDate = value;
    if (!value) {
      this.inputForm.reset('osPlusActivationDate');
    } else {
      this.inputForm.setValue({osPlusActivationDate: value});
    }
    this.saveAction();
  }


}
