import {Component, OnDestroy, OnInit} from '@angular/core';
import {DsvsBreadcrumbService, DsvsTableColumn, DsvsToolbarItem, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {Page} from '@dsvs/hal-client';
import {MessageService} from 'primeng/api';
import {Observable, Subscription} from 'rxjs';
import {GlobalCrewHalPage} from '../../../../shared/hal/globalcrew.hal.item';
import {GlobalRoleUserHalItem} from '../../../../shared/hal/globalroleuser.hal.item';
import {UserHalPage} from '../../../../shared/hal/user.hal.item';
import {FesUserSettingsService} from '../../../../shared/services/fes-user-settings.service';
import {GlobalCrewServiceImpl} from '../../../../shared/services/global-crew.service';
import {UserServiceImpl} from '../../../../shared/services/user.service';

@Component({
  selector: 'fes-globalcrews-list',
  templateUrl: './global-crews-list.component.html',
  styleUrls: ['./global-crews-list.component.scss']
})
export class GlobalCrewsListComponent implements OnInit, OnDestroy {

  loading = false;

  globalCrewHalPage: GlobalCrewHalPage;

  reqTableColums: DsvsTableColumn[];

  toolbarSubscription: Subscription;
  workspaceChangeSubscription: Subscription;

  tableColums: DsvsTableColumn[];

  constructor(
    private globalCrewService: GlobalCrewServiceImpl,
    private fesSettings: FesUserSettingsService,
    public userService: UserServiceImpl,
    private breadcrumbService: DsvsBreadcrumbService,
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.reloadData('');
    this.reqTableColums = this.getColumns();

    this.workspaceChangeSubscription = this.fesSettings.onActiveWorkspaceChanged().subscribe(
      result => {
        this.reloadData('');
      });

    this.tableColums = this.getColums();
  }

  private getColums(): DsvsTableColumn[] {
    return [
      {field: '', header: 'Rolle', format: 'ROLE', sort: false, width: '20%'},
      {field: '', header: 'Nutzer', format: 'USER', sort: false, width: '50%'},
      {field: '', header: 'Rollen-Admins', format: 'ROLEADMINS', sort: false, width: '30%'},
    ];
  }

  getToolbarItems(): DsvsToolbarItem[] {
    return [
      {
        id: 'REFRESH',
        icon: 'refresh',
        disabled: false,
        tooltip: 'Aktualisieren',
        callback: () => this.syncGlobalCrews()
      }
    ];
  }

  reloadData(searchTerm: string, page?: Page) {
    this.loading = true;
    this.globalCrewService.search(searchTerm, page).subscribe(result => {
        this.globalCrewHalPage = result;
        this.loading = false;
      },
      err => {
        this.loading = false;
        console.error(err);
      });
  }

  ngOnDestroy(): void {
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
    if (this.workspaceChangeSubscription) {
      this.workspaceChangeSubscription.unsubscribe();
    }
  }

  fetchAdminUsers = ($event): Observable<UserHalPage> => {
    return this.userService.search($event.query);
  };

  updateAdminUsers($event: any,
                   component: InlineEditComponent): Observable<GlobalRoleUserHalItem> {
    const users = component.modelValue;
    const globalRoleUserHalItem = component.model;
    return globalRoleUserHalItem.adminusers.update(users);
  }

  syncGlobalCrews() {
    this.globalCrewService.sync().subscribe(
      res => {
        this.messageService.add(
          {
            severity: 'success',
            summary: 'Erfolg',
            detail: 'Globale Crews erfolgreich synchronisiert'
          }
        );
        this.reloadData('');
      }
    );
  }

  private getColumns(): DsvsTableColumn[] {
    return [
      {field: 'displayName', header: 'Benutzer', format: 'USER'}
    ];
  }
}
