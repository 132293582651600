import { Component, OnInit } from '@angular/core';
import { WorkflowActionHistoryComponent } from '@dsvs/workflow-generator-ui-lib';
import { FesWorkflowActionTemplateStyleSelectComponent } from '../fes-workflow-action-template-style-select.component';

@Component({
  selector: 'fes-fes-workflow-action-template-style-select-history',
  templateUrl: './fes-workflow-action-template-style-select-history.component.html',
  styleUrls: ['./fes-workflow-action-template-style-select-history.component.scss']
})
export class FesWorkflowActionTemplateStyleSelectHistoryComponent extends WorkflowActionHistoryComponent implements OnInit {

  status: string;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  payloadChanged(payload) {
    if (payload) {
      let templateStyle: string;

      if (payload.templateStyle == "TEXTMODUL")
        templateStyle = FesWorkflowActionTemplateStyleSelectComponent.TEXTMODUL_LABEL;
      else if (payload.templateStyle == "FORMULAR")
        templateStyle = FesWorkflowActionTemplateStyleSelectComponent.FORMULAR_LABEL;

      this.status = `Vorlage wurde zu einem ${templateStyle} geändert.`;
    }
  }
}
