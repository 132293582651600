import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isNil } from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { ProcessHalPage } from '../../../shared/hal/process.hal.item';
import { RequirementHalPage } from '../../../shared/hal/requirement.hal.item';
import { ScanpoolHalItem } from '../../../shared/hal/scanpool.hal.item';
import { EScanpoolTarget } from '../../../shared/interfaces/dtos/enums/EScanpoolTarget';
import { Scanpool } from '../../../shared/interfaces/dtos/scanpool';
import { ProcessServiceImpl } from '../../../shared/services/process.service';
import { RequirementServiceImpl } from '../../../shared/services/requirement.service';
import { ScanpoolServiceImpl } from '../../../shared/services/scanpool.service';
import { ScanpoolTargetTypeNamePipe } from '../../pipes/scanpool-target-type-name.pipe';

@Component({
  selector: 'fes-scanpool-assign',
  templateUrl: './scanpool-assign.component.html',
  styleUrls: ['./scanpool-assign.component.scss']
})
export class ScanpoolAssignComponent {

  scanpool: Scanpool;

  selectedTargetEntity: any = null;
  selectedTargetType: EScanpoolTarget = null;

  _scanpoolHalItem: ScanpoolHalItem;

  allTargetTypes: MenuItem[] = Object.keys(EScanpoolTarget).map(
    k => ({
      'value': EScanpoolTarget[k as any],
      'label': ScanpoolTargetTypeNamePipe.prototype.transform(EScanpoolTarget[k as EScanpoolTarget])
    })
  );

  @Input()
  set scanpoolHalItem(scanpoolHalItem: any) {
    if (scanpoolHalItem) {
      this.scanpool = scanpoolHalItem.data;
      this.selectedTargetType = this.scanpool.target;
      this._scanpoolHalItem = scanpoolHalItem;
    }
  }

  get scanpoolHalItem(): any {
    return this._scanpoolHalItem;
  }

  @Output() showEditModalEvent = new EventEmitter<boolean>();

  constructor(
    private scanpoolService: ScanpoolServiceImpl,
    private processService: ProcessServiceImpl,
    private requirementService: RequirementServiceImpl,
    private messageService: MessageService
  ) {
  }

  fetchProcesses = ($event, inlineEdit): Observable<ProcessHalPage> => {
    return this.processService.search($event.query);
  };

  fetchRequirements = ($event, inlineEdit): Observable<RequirementHalPage> => {
    return this.requirementService.search($event.query);
  };

  fetchTargetTypes = ($event, inlineEdit): Observable<MenuItem[]> => {
    return Observable.of(this.allTargetTypes);
  };

  setTarget = (target) => {
    this.selectedTargetEntity = target.value;
    return Observable.of(target.value);
  };

  setTargetType = (targetType) => {
    this.selectedTargetType = targetType.value;
    return Observable.of(targetType);
  };

  cancelModal() {
    this.showEditModalEvent.emit(false);
  }

  assign() {
    this.scanpoolService.assign(this.scanpool, this.selectedTargetEntity.id, this.selectedTargetType).subscribe(
      scanpoolHalItem => {
        this.messageService.add({
          severity: 'success',
          summary: 'Scanpool zuweisen',
          detail: 'Scannpool erfolgreich zugewiesen.'
        });
        this.showEditModalEvent.emit(false);
      },
      error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Scanpool zuweisen',
          detail: 'Scannpool konnte nicht zugewiesen werden.'
        });
      }
    );
  }

}
