import { Component } from '@angular/core';

@Component({
  selector: 'fes-global-crews',
  templateUrl: './global-crews.component.html',
  styleUrls: ['./global-crews.component.scss']
})
export class GlobalCrewsComponent {

  constructor() {
  }
}
