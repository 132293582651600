export const environment = {
  production: true,
  scope: 'FES',
  zapurl: (<any>window).docker.zapUrl,
  apourl: (<any>window).docker.apoUrl,
  backend: '/api/v1/',
  backend_hal: '/api/',
  zapinit: (<any>window).docker.zapInit,
  wgurl: '/wg',
  loggerSystemId: 'fes2ui'
};
