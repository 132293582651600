/* tslint:disable:max-line-length */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DsvsInlineEditModule, DsvsSharedModule, DsvsTableModule } from '@dsvs/dsvs-shared-ui-lib';
import { WorkflowModule } from '@dsvs/workflow-generator-ui-lib';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';
import {
  AccordionModule,
  CalendarModule,
  CheckboxModule,
  ConfirmDialogModule,
  DropdownModule,
  FileUploadModule,
  InputTextareaModule,
  ProgressSpinnerModule,
  SplitButtonModule,
  TooltipModule
} from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { FormularModule } from '../formular/formular.module';
import { SharedModule } from '../shared/shared.module';
import {
  FesWorkflowActionChangetypeChangeHistoryComponent
} from './components/actions/fes-workflow-action-changetype-change/fes-workflow-action-changetype-change-history/fes-workflow-action-changetype-change-history.component';
import {
  FesWorkflowActionChangetypeChangeComponent
} from './components/actions/fes-workflow-action-changetype-change/fes-workflow-action-changetype-change.component';
import {
  FesWorkflowActionCommentAddHistoryComponent
} from './components/actions/fes-workflow-action-comment-add/fes-workflow-action-comment-add-history/fes-workflow-action-comment-add-history.component';
import {
  FesWorkflowActionCommentAddComponent
} from './components/actions/fes-workflow-action-comment-add/fes-workflow-action-comment-add.component';
import {
  FesWorkflowActionCompletiondateexpectedHistoryComponent
} from './components/actions/fes-workflow-action-completiondateexpected/fes-workflow-action-completiondateexpected-history/fes-workflow-action-completiondateexpected-history.component';
import {
  FesWorkflowActionCompletiondateexpectedComponent
} from './components/actions/fes-workflow-action-completiondateexpected/fes-workflow-action-completiondateexpected.component';
import {
  CrewDetailRoleUserComponent
} from './components/actions/fes-workflow-action-crew-edit/crew-detail-role-user/crew-detail-role-user.component';
import {
  FesWorkflowActionCrewEditHistoryComponent
} from './components/actions/fes-workflow-action-crew-edit/fes-workflow-action-crew-edit-history/fes-workflow-action-crew-edit-history.component';
import {
  FesWorkflowActionCrewEditComponent
} from './components/actions/fes-workflow-action-crew-edit/fes-workflow-action-crew-edit.component';
import {
  FesWorkflowActionDeliverydateHistoryComponent
} from './components/actions/fes-workflow-action-deliverydate/fes-workflow-action-deliverydate-history/fes-workflow-action-deliverydate-history.component';
import {
  FesWorkflowActionDeliverydateComponent
} from './components/actions/fes-workflow-action-deliverydate/fes-workflow-action-deliverydate.component';
import {
  FesWorkflowActionDeliverydateexpectedHistoryComponent
} from './components/actions/fes-workflow-action-deliverydateexpected/fes-workflow-action-deliverydateexpected-history/fes-workflow-action-deliverydateexpected-history.component';
import {
  FesWorkflowActionDeliverydateexpectedComponent
} from './components/actions/fes-workflow-action-deliverydateexpected/fes-workflow-action-deliverydateexpected.component';
import {
  FesWorkflowActionDevelopmentdateexpectedHistoryComponent
} from './components/actions/fes-workflow-action-developmentdateexpected/fes-workflow-action-developmentdateexpected-history/fes-workflow-action-developmentdateexpected-history.component';
import {
  FesWorkflowActionDevelopmentdateexpectedComponent
} from './components/actions/fes-workflow-action-developmentdateexpected/fes-workflow-action-developmentdateexpected.component';
import {
  FesWorkflowActionDevversionAddHistoryComponent
} from './components/actions/fes-workflow-action-devversion-add/fes-workflow-action-devversion-add-history/fes-workflow-action-devversion-add-history.component';

import {
  FesWorkflowActionDevversionAddComponent
} from './components/actions/fes-workflow-action-devversion-add/fes-workflow-action-devversion-add.component';
import {
  FesWorkflowActionDevversionFinalizeComponent
} from './components/actions/fes-workflow-action-devversion-finalize/fes-workflow-action-devversion-finalize.component';
import {
  FesWorkflowActionDevversionFinalizeHistoryComponent
} from './components/actions/fes-workflow-action-devversion-finalize/fes-workflow-action-process-devversion-finalize-history/fes-workflow-action-devversion-finalize-history.component';
import {
  FesWorkflowActionDmsNumberHistoryComponent
} from './components/actions/fes-workflow-action-dms-number/fes-workflow-action-dms-number-history/fes-workflow-action-dms-number-history.component';
import {
  FesWorkflowActionDmsNumberComponent
} from './components/actions/fes-workflow-action-dms-number/fes-workflow-action-dms-number.component';
import {
  FesWorkflowActionFinalVersionSelectHistoryComponent
} from './components/actions/fes-workflow-action-final-version-select/fes-workflow-action-final-version-select-history/fes-workflow-action-final-version-select-history.component';
import {
  FesWorkflowActionFinalVersionSelectComponent
} from './components/actions/fes-workflow-action-final-version-select/fes-workflow-action-final-version-select.component';
import {
  FesWorkflowActionFireleasedateSelectHistoryComponent
} from './components/actions/fes-workflow-action-fireleasedate-select/fes-workflow-action-fireleasedate-select-history/fes-workflow-action-fireleasedate-select-history.component';
import {
  FesWorkflowActionFireleasedateSelectComponent
} from './components/actions/fes-workflow-action-fireleasedate-select/fes-workflow-action-fireleasedate-select.component';
import {
  FesWorkflowActionFormularCreateComponent
} from './components/actions/fes-workflow-action-formular-create/fes-workflow-action-formular-create.component';
import {
  FesWorkflowActionFormularCreateHistoryComponent
} from './components/actions/fes-workflow-action-formular-create/fes-workflow-action-requirement-add-history/fes-workflow-action-formular-create-history.component';
import {
  FesWorkflowActionGroupchangeHistoryComponent
} from './components/actions/fes-workflow-action-groupchange/fes-workflow-action-groupchange-history/fes-workflow-action-groupchange-history.component';
import {
  FesWorkflowActionGroupchangeComponent
} from './components/actions/fes-workflow-action-groupchange/fes-workflow-action-groupchange.component';
import {
  FesWorkflowActionJuristModeratorComponent
} from './components/actions/fes-workflow-action-jurist-select/fes-workflow-action-jurist-moderator/fes-workflow-action-jurist-moderator.component';
import {
  FesWorkflowActionJuristSelectComponent
} from './components/actions/fes-workflow-action-jurist-select/fes-workflow-action-jurist-select.component';
import {
  FesWorkflowActionManuscriptAddFormulardetailsHistoryComponent
} from './components/actions/fes-workflow-action-manuscript-add-formulardetails/fes-workflow-action-manuscript-add-formulardetails-history/fes-workflow-action-manuscript-add-formulardetails-history.component';
import {
  FesWorkflowActionManuscriptAddFormulardetailsComponent
} from './components/actions/fes-workflow-action-manuscript-add-formulardetails/fes-workflow-action-manuscript-add-formulardetails.component';
import {
  FesWorkflowActionNoteAddHistoryComponent
} from './components/actions/fes-workflow-action-note-add/fes-workflow-action-note-add-history/fes-workflow-action-note-add-history.component';
import {
  FesWorkflowActionNoteAddComponent
} from './components/actions/fes-workflow-action-note-add/fes-workflow-action-note-add.component';
import {
  FesWorkflowActionProcessAddManuscriptHistoryComponent
} from './components/actions/fes-workflow-action-process-add-manuscript/fes-workflow-action-process-add-file-history/fes-workflow-action-process-add-manuscript-history.component';
import {
  FesWorkflowActionProcessAddManuscriptComponent
} from './components/actions/fes-workflow-action-process-add-manuscript/fes-workflow-action-process-add-manuscript.component';
import {
  FesWorkflowActionRequirementAddHistoryComponent
} from './components/actions/fes-workflow-action-requirement-add/fes-workflow-action-requirement-add-history/fes-workflow-action-requirement-add-history.component';
import {
  FesWorkflowActionRequirementAddComponent
} from './components/actions/fes-workflow-action-requirement-add/fes-workflow-action-requirement-add.component';
import {
  FesWorkflowActionRequirementSelectHistoryComponent
} from './components/actions/fes-workflow-action-requirement-select/fes-workflow-action-requirement-select-history/fes-workflow-action-requirement-select-history.component';
import {
  FesWorkflowActionRequirementSelectComponent
} from './components/actions/fes-workflow-action-requirement-select/fes-workflow-action-requirement-select.component';
import {
  FesWorkflowActionReturndateHistoryComponent
} from './components/actions/fes-workflow-action-returndate/fes-workflow-action-returndate-history/fes-workflow-action-returndate-history.component';
import {
  FesWorkflowActionReturndateComponent
} from './components/actions/fes-workflow-action-returndate/fes-workflow-action-returndate.component';
import {
  FesWorkflowActionServiceupdateSelectHistoryComponent
} from './components/actions/fes-workflow-action-serviceupdate-select/fes-workflow-action-serviceupdate-select-history/fes-workflow-action-serviceupdate-select-history.component';
import {
  FesWorkflowActionServiceupdateSelectComponent
} from './components/actions/fes-workflow-action-serviceupdate-select/fes-workflow-action-serviceupdate-select.component';
import {
  FesWorkflowActionTemplateStyleSelectHistoryComponent
} from './components/actions/fes-workflow-action-template-style-select/fes-workflow-action-template-style-select-history/fes-workflow-action-template-style-select-history.component';
import {
  FesWorkflowActionTemplateStyleSelectComponent
} from './components/actions/fes-workflow-action-template-style-select/fes-workflow-action-template-style-select.component';
import {
  FesWorkflowActionVersionSelectHistoryComponent
} from './components/actions/fes-workflow-action-version-select/fes-workflow-action-version-select-history/fes-workflow-action-version-select-history.component';
import {
  FesWorkflowActionVersionSelectComponent
} from './components/actions/fes-workflow-action-version-select/fes-workflow-action-version-select.component';
import { WorkflowTransitionDialogComponent } from './components/workflow-transition-dialog/workflow-transition-dialog.component';
import { FesWorkflowActionService } from './services/fes-workflow.service';
import { FesWorkflowActionOsplusActivationdateSelectComponent } from './components/actions/fes-workflow-action-osplus-activationdate-select/fes-workflow-action-osplus-activationdate-select.component';
import { FesWorkflowActionOsplusActivationdateSelectHistoryComponent } from './components/actions/fes-workflow-action-osplus-activationdate-select/fes-workflow-action-osplus-activationdate-select-history/fes-workflow-action-osplus-activationdate-select-history.component';

/* tslint:enable:max-line-length */

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    /* PrimeNG */
    ButtonModule,
    MessageModule,
    FileUploadModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    DialogModule,
    CardModule,
    CalendarModule,
    PanelModule,
    DropdownModule,
    TableModule,
    CheckboxModule,
    AccordionModule,
    SplitButtonModule,
    ConfirmDialogModule,
    TooltipModule,
    /* Dsvs */
    DsvsTableModule,
    /* Wfg */
    WorkflowModule,
    /* Formulare */
    FormularModule,
    /* Shared */
    SharedModule,
    DsvsSharedModule,
    DsvsInlineEditModule
  ],
  declarations: [
    FesWorkflowActionRequirementAddComponent,
    FesWorkflowActionDevversionAddComponent,
    FesWorkflowActionDevversionAddHistoryComponent,
    WorkflowTransitionDialogComponent,
    FesWorkflowActionRequirementAddHistoryComponent,
    FesWorkflowActionRequirementSelectComponent,
    FesWorkflowActionRequirementSelectHistoryComponent,
    FesWorkflowActionNoteAddComponent,
    FesWorkflowActionNoteAddHistoryComponent,
    FesWorkflowActionCommentAddComponent,
    FesWorkflowActionCommentAddHistoryComponent,
    FesWorkflowActionVersionSelectComponent,
    FesWorkflowActionVersionSelectHistoryComponent,
    FesWorkflowActionReturndateComponent,
    FesWorkflowActionReturndateHistoryComponent,
    FesWorkflowActionDeliverydateexpectedComponent,
    FesWorkflowActionDeliverydateexpectedHistoryComponent,
    FesWorkflowActionDevelopmentdateexpectedComponent,
    FesWorkflowActionDevelopmentdateexpectedHistoryComponent,
    FesWorkflowActionDeliverydateComponent,
    FesWorkflowActionDeliverydateHistoryComponent,
    FesWorkflowActionCompletiondateexpectedComponent,
    FesWorkflowActionCompletiondateexpectedHistoryComponent,
    FesWorkflowActionCompletiondateexpectedComponent,
    FesWorkflowActionCompletiondateexpectedHistoryComponent,
    FesWorkflowActionDevversionFinalizeComponent,
    FesWorkflowActionDevversionFinalizeHistoryComponent,
    FesWorkflowActionServiceupdateSelectComponent,
    FesWorkflowActionServiceupdateSelectHistoryComponent,
    FesWorkflowActionFireleasedateSelectComponent,
    FesWorkflowActionFireleasedateSelectHistoryComponent,
    FesWorkflowActionChangetypeChangeComponent,
    FesWorkflowActionChangetypeChangeHistoryComponent,
    FesWorkflowActionGroupchangeComponent,
    FesWorkflowActionGroupchangeHistoryComponent,
    FesWorkflowActionProcessAddManuscriptComponent,
    FesWorkflowActionProcessAddManuscriptHistoryComponent,
    FesWorkflowActionCrewEditComponent,
    FesWorkflowActionCrewEditHistoryComponent,
    FesWorkflowActionFormularCreateComponent,
    FesWorkflowActionFormularCreateHistoryComponent,
    FesWorkflowActionDmsNumberComponent,
    FesWorkflowActionDmsNumberHistoryComponent,
    CrewDetailRoleUserComponent,
    FesWorkflowActionFinalVersionSelectComponent,
    FesWorkflowActionFinalVersionSelectHistoryComponent,
    FesWorkflowActionJuristSelectComponent,
    FesWorkflowActionJuristModeratorComponent,
    FesWorkflowActionManuscriptAddFormulardetailsComponent,
    FesWorkflowActionManuscriptAddFormulardetailsHistoryComponent,
    FesWorkflowActionTemplateStyleSelectComponent,
    FesWorkflowActionTemplateStyleSelectHistoryComponent,
    FesWorkflowActionOsplusActivationdateSelectComponent,
    FesWorkflowActionOsplusActivationdateSelectHistoryComponent

  ],
  entryComponents: [
    FesWorkflowActionRequirementAddComponent,
    FesWorkflowActionDevversionAddComponent,
    FesWorkflowActionDevversionAddHistoryComponent,
    FesWorkflowActionRequirementAddHistoryComponent,
    FesWorkflowActionRequirementSelectComponent,
    FesWorkflowActionRequirementSelectHistoryComponent,
    FesWorkflowActionNoteAddComponent,
    FesWorkflowActionNoteAddHistoryComponent,
    FesWorkflowActionCommentAddComponent,
    FesWorkflowActionCommentAddHistoryComponent,
    FesWorkflowActionVersionSelectComponent,
    FesWorkflowActionVersionSelectHistoryComponent,
    FesWorkflowActionReturndateComponent,
    FesWorkflowActionReturndateHistoryComponent,
    FesWorkflowActionDeliverydateexpectedComponent,
    FesWorkflowActionDeliverydateexpectedHistoryComponent,
    FesWorkflowActionDevelopmentdateexpectedComponent,
    FesWorkflowActionDevelopmentdateexpectedHistoryComponent,
    FesWorkflowActionDeliverydateComponent,
    FesWorkflowActionDeliverydateHistoryComponent,
    FesWorkflowActionCompletiondateexpectedComponent,
    FesWorkflowActionCompletiondateexpectedHistoryComponent,
    FesWorkflowActionCompletiondateexpectedComponent,
    FesWorkflowActionCompletiondateexpectedHistoryComponent,
    FesWorkflowActionDevversionFinalizeComponent,
    FesWorkflowActionDevversionFinalizeHistoryComponent,
    FesWorkflowActionServiceupdateSelectComponent,
    FesWorkflowActionServiceupdateSelectHistoryComponent,
    FesWorkflowActionGroupchangeComponent,
    FesWorkflowActionGroupchangeHistoryComponent,
    FesWorkflowActionFireleasedateSelectComponent,
    FesWorkflowActionFireleasedateSelectHistoryComponent,
    FesWorkflowActionChangetypeChangeComponent,
    FesWorkflowActionChangetypeChangeHistoryComponent,
    FesWorkflowActionProcessAddManuscriptComponent,
    FesWorkflowActionProcessAddManuscriptHistoryComponent,
    FesWorkflowActionCrewEditComponent,
    FesWorkflowActionCrewEditHistoryComponent,
    FesWorkflowActionFormularCreateComponent,
    FesWorkflowActionFormularCreateHistoryComponent,
    FesWorkflowActionDmsNumberComponent,
    FesWorkflowActionDmsNumberHistoryComponent,
    FesWorkflowActionFinalVersionSelectComponent,
    FesWorkflowActionFinalVersionSelectHistoryComponent,
    FesWorkflowActionJuristSelectComponent,
    FesWorkflowActionJuristModeratorComponent,
    FesWorkflowActionManuscriptAddFormulardetailsComponent,
    FesWorkflowActionManuscriptAddFormulardetailsHistoryComponent,
    FesWorkflowActionTemplateStyleSelectComponent,
    FesWorkflowActionTemplateStyleSelectHistoryComponent,
    FesWorkflowActionOsplusActivationdateSelectComponent,
    FesWorkflowActionOsplusActivationdateSelectHistoryComponent
  ],
  providers: [
    FesWorkflowActionService
  ],
  exports: [
    FesWorkflowActionNoteAddComponent,
    WorkflowTransitionDialogComponent
  ]
})
export class FesWorkflowModule {
}
