import { Component, OnInit } from '@angular/core';
import { DsvsBreadcrumbService } from '@dsvs/dsvs-shared-ui-lib';

@Component({
  selector: 'fes-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor(
    private breadcrumbService: DsvsBreadcrumbService
  ) {
  }

  ngOnInit() {
    this.breadcrumbService.setMenuItems([
      {label: 'User'}
    ]);
    this.breadcrumbService.setToolbarVisible(true);
  }

}
