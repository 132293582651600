import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ConfirmationService, FileUpload, MessageService} from 'primeng/primeng';
import {Observable, of as observableOf} from 'rxjs';
import {DevVersionHalItem, DevVersionHalPage} from '../../hal/devversion.hal.item';
import {FormularHalItem} from '../../hal/formular.hal.item';
import {VersionHalItem, VersionHalPage} from '../../hal/version.hal.item';
import {EDiffType} from '../../interfaces/dtos/enums/EDiffType';
import {DiffServiceImpl} from '../../services/diff.service';
import {Page} from '@dsvs/hal-client';
import {DsvsPage} from '@dsvs/dsvs-shared-ui-lib';
import {FormServiceImpl} from '../../services/form.service';

@Component({
  selector: 'fes-diff-modal',
  templateUrl: './fes-diff-modal.component.html',
  styleUrls: ['./fes-diff-modal.component.scss']
})
export class FesDiffModalComponent implements OnInit {

  @Input() visible: false;

  @Input()
  source: DevVersionHalItem | VersionHalItem;

  @Input() sourceIsDevVersion = false;

  @Output()
  displayChange = new EventEmitter();

  @ViewChild('fileuploader') fileuploader: FileUpload;

  isNewSelection: EDiffType;

  target: any;

  formular: FormularHalItem;

  loading: boolean;
  versions: VersionHalPage;
  devVersions: DevVersionHalPage;
  pageSize = 4;
  versionsPage: Page = <Page>{
    sort: [],
    size: this.pageSize,
    page: 0,
    number: 0,
    totalElements: 0,
    totalPages: 0
  };
  devVersionsPage: Page = <Page>{
    sort: [],
    size: this.pageSize,
    page: 0,
    number: 0,
    totalElements: 0,
    totalPages: 0
  };

  constructor(
    private diffService: DiffServiceImpl,
    private messageService: MessageService,
    private formularService: FormServiceImpl,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit() {
    this.onReloadData('', this.versionsPage);
  }

  toggleRadio($event, selection) {
    this.isNewSelection = selection;
    this.target = null;
    if (this.fileuploader) {
      this.fileuploader.clear();
    }
  }

  setTarget = (target: any): Observable<any> => {
    this.target = target;
    return observableOf(target);
  };

  onFileChange(target) {
    if (target.files.length > 0) {
      this.setTarget(target.files[0]);
    }
  }

  startDiff($event) {
    const target = (this.isNewSelection === EDiffType.DEVVERSION || this.isNewSelection === EDiffType.VERSION) ?
      this.target.data.id : this.target;
    this.diffService.diff(
      this.sourceIsDevVersion ? EDiffType.DEVVERSION : EDiffType.VERSION,
      this.source.data.id,
      this.isNewSelection,
      target
    ).subscribe(
      succ => {
        this.confirmationService.confirm({
          header: 'Vergleichsdokumentation',
          message: 'Sobald der Vergleich abgeschlossen ist, ' +
            'werden Sie benachrichtigt und können die Dokumentation ' +
            'über den Menüpunkt "Benachrichtigungen" herunterladen.',
          acceptLabel: 'Ok',
          rejectVisible: false,
          key: 'diffModal',
          accept: () => {
            this.onClose();
          }
        });
      },
      err => {
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Vergleichsdokumentation',
            detail: 'Beim Vergleich ist ein Fehler aufgetreten.'
          }
        );
        this.onClose();
      }
    );
  }

  onClose() {
    this.target = null;
    if (this.fileuploader) {
      this.fileuploader.clear();
    }
    this.displayChange.emit(false);
  }

  onReloadData(searchTerm: string, page?: Page) {
    this.getFormVersions(this.source.data.formularId, searchTerm, page);
    this.getFormDevVersions(this.source.data.formularId, searchTerm, page);
  }

  getFormVersions(id: string, searchTerm: string, page?: DsvsPage) {
    if (id) {
      this.loading = true;
      this.formularService.getById(id).subscribe(
        (result) => {
          this.formular = result;
          if (this.formular.versions.async) {
            this.formular.versions
              .setParams({
                sort: ['displayName,DESC'],
                size: page ? page.size : 10,
                page: page ? page.number : 0,
                searchTerm: searchTerm
              })
              .async.subscribe(
              versions => {
                this.loading = false;
                this.versions = versions;
                this.versionsPage.totalElements = versions.page.totalElements;
                this.versionsPage.totalPages = versions.page.totalPages;
              }
            );
          }
        }
      );
    }
  }

  getFormDevVersions(id: string, searchTerm: string, page?: DsvsPage) {
    if (id) {
      this.loading = true;
      this.formularService.getById(id).subscribe(
        (result) => {
          this.formular = result;
          if (this.formular.devversions.async) {
            this.formular.devversions
              .setParams({
                sort: ['displayName,DESC'],
                size: page ? page.size : 10,
                page: page ? page.number : 0,
                searchTerm: searchTerm,
                view: 'state-not-new'
              })
              .async.subscribe(
              versions => {
                this.loading = false;
                this.devVersions = versions;
                this.devVersionsPage.totalElements = versions.page.totalElements;
                this.devVersionsPage.totalPages = versions.page.totalPages;
              }
            );
          }
        }
      );
    }
  }

  loadPreviousVersions() {
    this.versionsPage.number--;
    this.getFormVersions(this.source.data.formularId, '', this.versionsPage);
  }

  loadNextVersions() {
    this.versionsPage.number++;
    this.getFormVersions(this.source.data.formularId, '', this.versionsPage);
  }

  loadPreviousDevVersions() {
    this.devVersionsPage.number--;
    this.getFormDevVersions(this.source.data.formularId, '', this.devVersionsPage);
  }

  loadNextDevVersions() {
    this.devVersionsPage.number++;
    this.getFormDevVersions(this.source.data.formularId, '', this.devVersionsPage);
  }

}
