import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableReadEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { HalReadServiceImpl } from '../hal/hal.read.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { UserHalItem, UserHalPage } from '../hal/user.hal.item';
import { User } from '../interfaces/dtos/user';

export interface MeService extends DsvsSearchableReadEntityService<User> {

}

@Injectable({
  providedIn: 'root'
})
export class MeServiceImpl
  extends HalReadServiceImpl<User, UserHalItem, UserHalPage>
  implements MeService {

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>) {
    super(
      v1Client,
      <HalRelation>{single: 'me', collection: 'users'},
      UserHalPage,
      UserHalItem);
  }

  get(): Observable<UserHalItem> {
    return this.getById(null);
  }
}
