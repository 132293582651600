import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'fes-search-result-table',
  templateUrl: './search-result-table.component.html',
  styleUrls: ['./search-result-table.component.scss']
})
export class SearchResultTableComponent implements OnInit {

  @Input() name: string;
  @Input() type: string;
  @Input() amount: number;
  @Input() results: any[];
  @Input() selectedEntry: any;
  @Output() entrySelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() showAllSelected: EventEmitter<Boolean> = new EventEmitter<Boolean>();


  constructor() { }

  ngOnInit() {
  }

  selectEntry(entry: any) {
    if (!entry) {
      entry = {};
    }
    entry.type = this.type;
    this.entrySelected.emit(entry);
  }

  showAll(showAll: boolean) {
    this.showAllSelected.emit(showAll);
  }

}
