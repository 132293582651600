import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DevVersionDocument } from '../../../../../../shared/interfaces/dtos/documents/files/dev-version-document';

@Component({
  selector: 'fes-add-dev-version-formular',
  templateUrl: './add-dev-version-formular.component.html',
  styleUrls: ['./add-dev-version-formular.component.css']
})
export class AddDevVersionFormularComponent implements OnInit {

  UPLOAD_COMMENT_MAX_LENGTH = 512;

  addDevVersionForm: FormGroup;

  _xdpDevVersionDocument: DevVersionDocument = null;

  @Input() set xdpDevVersionDocument(value: DevVersionDocument) {
    this.initForm(value);
    this._xdpDevVersionDocument = value;
  }

  get xdpDevVersionDocument(): DevVersionDocument {
    return this._xdpDevVersionDocument;
  }

  ngOnInit() {
  }

  initForm(devVersionDocument: DevVersionDocument) {
    if (this.addDevVersionForm === undefined) {
      this.createAddVersionForm();
    }
    if (devVersionDocument != null && typeof this.addDevVersionForm !== 'undefined' && this.addDevVersionForm != null) {
      this.addDevVersionForm.patchValue(
        {
          version: (devVersionDocument.xdpData && devVersionDocument.xdpData.version) ? devVersionDocument.xdpData.version : '0.1.0',
          uploadComment: devVersionDocument.uploadComment ? devVersionDocument.uploadComment : ''
        });
    }
  }

  createAddVersionForm() {
    this.addDevVersionForm = new FormGroup(
      {
        version: new FormControl('', Validators.required),
        uploadComment: new FormControl('', Validators.required)
      });
    this.addDevVersionForm.controls.version.disable();
  }
}
