import {Component, OnInit} from '@angular/core';
import {Page} from '@dsvs/hal-client';
import {WorkflowActionDataDto} from '@dsvs/workflow-generator-ui-lib';
import {MessageService} from 'primeng/components/common/messageservice';
import {FormularHalItem} from '../../../../shared/hal/formular.hal.item';
import {ProcessHalItem} from '../../../../shared/hal/process.hal.item';
import {VersionHalItem, VersionHalPage} from '../../../../shared/hal/version.hal.item';
import {WGContextHalItem} from '../../../../shared/hal/wfg/wgcontext.hal.item';
import {FormServiceImpl} from '../../../../shared/services/form.service';
import {RequirementServiceImpl} from '../../../../shared/services/requirement.service';
import {FesWorkflowActionComponent} from '../fes-workflow-action';
import {DsvsPage} from '@dsvs/dsvs-shared-ui-lib';

@Component({
  selector: 'fes-workflow-action-version-select',
  templateUrl: './fes-workflow-action-version-select.component.html',
  styleUrls: ['./fes-workflow-action-version-select.component.scss']
})
export class FesWorkflowActionVersionSelectComponent extends FesWorkflowActionComponent implements OnInit {

  loading: boolean;

  cols: any[];

  formular: FormularHalItem;

  versions: VersionHalPage;

  formId: string;

  selectedVersion: VersionHalItem;

  processHalItem: ProcessHalItem;

  pageSize = 5;
  versionsPage: Page = <Page>{
    sort: [],
    size: this.pageSize,
    page: 0,
    number: 0,
    totalElements: 0,
    totalPages: 0
  };


  constructor(
    private requirementService: RequirementServiceImpl,
    private formularService: FormServiceImpl,
    messageService: MessageService
  ) {
    super(messageService);

    this.cols = [
      {field: 'version', header: 'Version'},
      {field: 'comment', header: 'Änderungshinweis zum Serviceupdate'},
      {field: 'createdByName', header: 'Erstellt von'}
    ];
  }

  ngOnInit() {

    this.context.product.async.subscribe(
      res => {
        this.formId = res.data.dataId;
        this.getForm(this.formId, '', this.versionsPage);
      }
    );
    this.loadSelectedVersion();
  }

  loadPreviousVersions() {
    this.versionsPage.number--;
    this.getForm(this.formId, '', this.versionsPage);
  }

  loadNextVersions() {
    this.versionsPage.number++;
    this.getForm(this.formId, '', this.versionsPage);
  }

  loadSelectedVersion() {
    (<WGContextHalItem>this.context).process.async.subscribe(
      process => {
        this.processHalItem = process;
        // this is actually a reset to always load the newest versions from server
        process.baseversion.reload();
        if (process.baseversion.async) {
          process.baseversion.async.subscribe(
            version => {
              this.selectedVersion = version;
            }
          );
        }
      }
    );
  }

  onReloadData(searchTerm: string, page?: Page) {
    this.getForm(this.formId, searchTerm, page);
  }

  getForm(id: string, searchTerm: string, page?: DsvsPage) {
    if (id) {
      this.loading = true;
      this.formularService.getById(id).subscribe(
        (result) => {
          this.formular = result;
          if (this.formular.versions.async) {
            this.formular.versions
            .setParams({
              sort: [],
              size: page ? page.size : 10,
              page: page ? page.number : 0,
              searchTerm: searchTerm
            })
            .async.subscribe(
              versions => {
                this.loading = false;
                this.versions = versions;
                this.versionsPage.totalElements = versions.page.totalElements;
                this.versionsPage.totalPages = versions.page.totalPages;
              }
            );
          }
        }
      );
    }
  }

  isValid(): boolean {
    return !!(this.selectedVersion);
  }

  collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      versionId: this.selectedVersion.data.id
    };
  }

  disableControls() {
    this.loading = true;
  }

  enableControls() {
    this.loading = false;
  }

  resetForm() {
    this.selectedVersion = null;
  }

  checkIfVersionIsSelected(version) {
    if (this.selectedVersion) {
      if (version.data.id === this.selectedVersion.data.id) {
        return true;
      }
    }
    return false;
  }

  toggleRadio($event, version) {
    if ($event.target.checked) {
      this.selectedVersion = version;
      this.saveAction();
    }
  }

}
