import { Component, OnInit } from '@angular/core';
import { WorkflowActionHistoryComponent } from '@dsvs/workflow-generator-ui-lib';
import {
  FesWorkflowActionTemplateStyleSelectComponent
} from '../../fes-workflow-action-template-style-select/fes-workflow-action-template-style-select.component';

@Component({
  selector: 'fes-fes-workflow-action-osplus-activationdate-select-history',
  templateUrl: './fes-workflow-action-osplus-activationdate-select-history.component.html',
  styleUrls: ['./fes-workflow-action-osplus-activationdate-select-history.component.scss']
})
export class FesWorkflowActionOsplusActivationdateSelectHistoryComponent extends WorkflowActionHistoryComponent implements OnInit {

  status: string = 'Aktivierungsdatum wurde zu ';

  constructor() {
    super()
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
