import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalCrewHalItem, GlobalCrewHalPage } from '../hal/globalcrew.hal.item';
import { HalFcrudServiceImpl } from '../hal/hal.fcrud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { GlobalCrew } from '../interfaces/dtos/globalcrew';

export interface GlobalCrewService extends DsvsSearchableCrudEntityService<GlobalCrew> {
  sync(): Observable<null>;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalCrewServiceImpl
  extends HalFcrudServiceImpl<GlobalCrew, GlobalCrewHalItem, GlobalCrewHalPage>
  implements GlobalCrewService {

  private backend: string = environment.backend;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'globalcrew', collection: 'globalcrews'},
      http,
      GlobalCrewHalPage,
      GlobalCrewHalItem
    );
  }

  sync(): Observable<null> {
    return this.http.put<null>(
      this.backend + this.relation.collection + '/sync',
      null
    );
  }
}
