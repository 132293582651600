import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { DsvsPermissionService } from '@dsvs/dsvs-shared-ui-lib';

@Injectable()
export class AdministrationActivateGuard implements CanActivate {

  constructor(private permissionService: DsvsPermissionService) {
  }

  canActivate(): boolean {
    // TODO: IMPLEMENT!!!!!
    return true;
    // return this.permissionService.hasPermission([ZapPermissions.FES_REQUIREMENT_READ]);
  }
}
