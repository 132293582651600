import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WorkflowProduct, WorkflowType } from '@dsvs/workflow-generator-dto';
import { WorkflowContextServiceImpl, WorkflowProductServiceImpl } from '@dsvs/workflow-generator-ui-lib';
import { MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { ProcessCreateDTO } from '../../../shared/interfaces/dtos/process';
import { ProcessServiceImpl } from '../../../shared/services/process.service';
import { ProcessCreateFormComponent } from './process-create-form/process-create-form.component';
import { CreationType, ProcessWizzardData } from './process-create-wizzard-item.component';
import { ProcessCreateWorkflowComponent } from './process-create-workflow/process-create-workflow.component';

@Component({
  selector: 'fes-process-create',
  templateUrl: './process-create.component.html',
  styleUrls: ['./process-create.component.scss']
})
export class ProcessCreateComponent implements OnInit, OnDestroy {

  @Input()
  visible = false;

  @Output()
  displayChange = new EventEmitter();

  @ViewChild(ProcessCreateFormComponent) formComponent: ProcessCreateFormComponent;
  @ViewChild(ProcessCreateWorkflowComponent) workflowComponent: ProcessCreateWorkflowComponent;

  loading = false;

  stepItems: MenuItem[];
  selectedTab = 0;

  wizzardData: ProcessWizzardData;

  processTypes: WorkflowType[] = [];

  constructor(
    private router: Router,
    private contextService: WorkflowContextServiceImpl,
    private productService: WorkflowProductServiceImpl,
    private processService: ProcessServiceImpl,
    private messageService: MessageService
  ) {
    this.wizzardData = {
      form: null,
      workflow: null,
      creationType: null
    };
  }

  ngOnInit() {
    this.initStepItems();
  }

  ngOnDestroy() {
    this.displayChange.unsubscribe();
  }

  onClose() {
    this.displayChange.emit(false);
  }

  private initStepItems() {
    this.stepItems = [
      {label: 'Formular'},
      {label: 'Workflow'}
    ];
  }

  onSendDataClicked(event: Event) {
    this.loading = true;

    if (this.workflowComponent) {
      if (this.workflowComponent.isValid()) {

        let product: WorkflowProduct = null;
        if (this.wizzardData.creationType === 'SELECT') {
          product = this.wizzardData.form.data;
        }

        const processData: ProcessCreateDTO = <ProcessCreateDTO>{
          product: product,
          workflowId: (this.wizzardData.workflow) ? this.wizzardData.workflow.data.id : null
        };

        this.processService.createProcess(processData).subscribe(processResult => {

          this.messageService.add(
            {
              severity: 'success',
              summary: 'Auftrag',
              detail: 'Der Auftrag wurde erfolgreich erstellt'
            });
          this.router.navigateByUrl('/processes/' + processResult.id);

        }, error2 => {
          console.error(error2);
          this.messageService.add(
            {
              severity: 'error',
              summary: 'Auftragserstellungsfehler',
              detail: error2.data && error2.data.errormessage ? error2.data.errormessage : error2.message
            }
          );
        });

      }
    }

  }

  lastStepValid(): boolean {
    return (this.workflowComponent) ? this.workflowComponent.isValid() : false;
  }

  openNext(event: Event) {
    if (this.selectedTab === 0) {
      {
        if (this.formComponent) {
          if (this.formComponent.isValid()) {
            if (this.wizzardData.creationType === CreationType.SELECT) {
              this.processTypes = ['UPDATE', 'DELETE'];
            } else if (this.wizzardData.creationType === CreationType.CREATE) {
              this.processTypes = ['CREATE'];
            }
            this.selectedTab = this.selectedTab + 1;
          } else {
            this.formComponent.displayErrors();
          }
        }
      }
    }
  }

  openPrevious(event: Event) {
    this.selectedTab = this.selectedTab - 1;
  }

  cancelModal() {
    this.onClose();
  }

}
