import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DsvsPage, DsvsTableColumn, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {ActionContextHalItem} from '@dsvs/workflow-generator-dto';
import {WorkflowActionContextServiceImpl, WorkflowActionDataDto} from '@dsvs/workflow-generator-ui-lib';
import {saveAs} from 'file-saver';
import {MenuItem} from 'primeng/api';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable, Subscription} from 'rxjs';
import {resolveFileName} from '../../../../../helper/download-helper';
import {CommunicationService} from '../../../../../services/communication-service';
import {CreationType} from '../../../../formular/components/formular/create/forms/add-other-version-formular/add-other-version-formular.component';
import {FormularCreateModalComponent} from '../../../../formular/components/formular/create/formular-create-modal/formular-create-modal.component';
import {XdpFileUploadComponent} from '../../../../shared/components/xdp-file-upload/xdp-file-upload.component';
import {DevVersionHalItem, DevVersionHalPage} from '../../../../shared/hal/devversion.hal.item';
import {ProcessHalItem} from '../../../../shared/hal/process.hal.item';
import {VersionHalItem} from '../../../../shared/hal/version.hal.item';
import {DevVersion} from '../../../../shared/interfaces/dtos/dev-version';
import {ValidationDocument} from '../../../../shared/interfaces/dtos/documents/validation-document';
import {EDevelopmentState} from '../../../../shared/interfaces/dtos/enums/EDevelopmentState';
import {EDocumentTypes} from '../../../../shared/interfaces/dtos/enums/EDocumentTypes';
import {DevVersionServiceImpl} from '../../../../shared/services/dev-version.service';
import {FileServiceImpl} from '../../../../shared/services/file.service';
import {FormServiceImpl} from '../../../../shared/services/form.service';
import {VersionServiceImpl} from '../../../../shared/services/version.service';
import {ActionType} from '../../../enums/action-type.enum';
import {FesWorkflowActionComponent} from '../fes-workflow-action';

enum DevVersionUploadActionType {
  ADD_DEVVERSION = 'ADD_DEVVERSION',
  NO_DEVVERSION = 'NO_DEVVERSION',
}

@Component({
  selector: 'fes-workflow-action-devversion-add',
  templateUrl: './fes-workflow-action-devversion-add.component.html',
  styleUrls: ['./fes-workflow-action-devversion-add.component.scss']
})
export class FesWorkflowActionDevversionAddComponent extends FesWorkflowActionComponent implements OnInit, OnDestroy {

  @ViewChild(XdpFileUploadComponent) xdpUploadForm: XdpFileUploadComponent;
  @ViewChild(FormularCreateModalComponent) formularCreateModal: FormularCreateModalComponent;

  devVersions: DevVersionHalPage;
  processHalItem: ProcessHalItem;
  baseVersion: VersionHalItem;
  formular: any;
  noDevVersionNeeded = false;

  allowedCreationTypes: CreationType[] = [CreationType.DEVVERSION];

  versionFile: any = null;
  isValidating = false;
  localErrors = [];
  devVersionUploadActionType: DevVersionUploadActionType = null;

  formularCreateModalVisible = false;
  validationDocument: ValidationDocument = null;

  devVersion: DevVersion = null;

  devVersionTableColums: DsvsTableColumn[];

  doneDevVersionUploadActionType = null;

  loading = true;
  page: DsvsPage = null;

  infoVersion: DevVersionHalItem;
  public showInfoDialog: boolean;

  private actionSubscriptionRef: Subscription = null;

  constructor(
    private versionService: VersionServiceImpl,
    private formularService: FormServiceImpl,
    private actionContextService: WorkflowActionContextServiceImpl,
    private devVersionService: DevVersionServiceImpl,
    private fileService: FileServiceImpl,
    messageService: MessageService,
    private communicationService: CommunicationService
  ) {
    super(messageService);
  }

  ngOnInit() {

    this.inputForm = new FormGroup(
      {});
    this.loadData();
    this.devVersionTableColums = [
      {field: 'version', header: 'Version'},
      {field: 'parentVersion', header: 'Vorgänger', format: 'PARENT_VERSION'},
      {field: 'state', header: 'Status', format: 'STATE'},
      {field: 'createdDate', header: 'Datum', format: 'DATE'},
      {field: '', header: 'Aktionen', format: 'ACTION', width: '180px'},
      {field: '', format: 'INFO', width: '40px'}
    ];
    this.subscribeActions();
  }

  ngOnDestroy(): void {
    this.actionSubscriptionRef.unsubscribe();
  }

  save = (value, component: InlineEditComponent): Observable<any> => {
    return component.model.save();
  };

  subscribeActions() {
    this.actionSubscriptionRef = this.communicationService.messages.subscribe(
      (message) => {
        if (message.scope === 'ACTION') {

          switch (message.message) {
            case ActionType.VersionSelect: {
              this.processHalItem.baseversion.reload();
              this.processHalItem.devversions.reload();
              this.loadData();
              break;
            }
          }
        }
      }
    );
  }

  pageChanged(page: DsvsPage) {
    this.page = page;
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.context.process.async.subscribe(
      processHalItem => {
        this.processHalItem = processHalItem;
        if (processHalItem.baseversion && processHalItem.baseversion.async) {
          processHalItem.baseversion.async.subscribe(
            versionHalItem => {
              this.baseVersion = versionHalItem;
              this.loading = false;
            }
          );
        }

        processHalItem.devversions.setParams({
          sort: ['version,DESC'],
          size: (this.page && this.page.size) ? this.page.size : 5,
          page: (this.page && this.page.number) ? this.page.number : 0
        }).async.subscribe(
          devVersionHalPage => {
            this.devVersions = devVersionHalPage;
            this.loading = false;
          }
        );
        if (processHalItem.product && processHalItem.product.async) {
          // TODO: Replace Product with formular
          processHalItem.product.async.subscribe(
            productHalItem => {
              this.formularService.getById(productHalItem.data.dataId).subscribe(
                formularHalItem => {
                  this.formular = formularHalItem;
                });
            }
          );
        }
      }
    );
    this.checkIfNoDevVersionNeeded();
  }

  private checkIfNoDevVersionNeeded() {
    this.context.currentstepcontext.async.subscribe(
      currentStepContextHalItem => {
        currentStepContextHalItem.actioncontexts.setParams({
          sort: ['lastModifiedDate,DESC'],
          size: 1,
          page: 0,
          view: 'byAlias',
          viewData: JSON.stringify({alias: this.action.data.alias})
        }).async.subscribe(
          actionContextHalPage => {
            const actionContextHalItem = actionContextHalPage.content[0];
            if (actionContextHalItem) {
              actionContextHalItem.processedactions.setParams({
                sort: ['lastModifiedDate,DESC'],
                size: 1,
                page: 0
              }).async.subscribe(
                processedActionHalPage => {
                  if (processedActionHalPage.content.length > 0) {
                    const processedActionHalItem = processedActionHalPage.content[0];
                    if (processedActionHalItem) {
                      try {
                        this.doneDevVersionUploadActionType = JSON.parse(
                          processedActionHalItem.data.payload
                        ).devVersionUploadActionType;

                        this.noDevVersionNeeded = this.doneDevVersionUploadActionType === DevVersionUploadActionType.NO_DEVVERSION;
                      } catch (e) {
                        console.error(e);
                      }
                    }
                  }
                }
              );
            }
          }
        );
      }
    );
  }

  canCreateDevVersion(devVersionHalItem: DevVersionHalItem) {
    return devVersionHalItem.data && devVersionHalItem.data.state && (
      devVersionHalItem.data.state !== EDevelopmentState.NEW &&
      devVersionHalItem.data.state !== EDevelopmentState.CANCELED);
  }

  getDevVersionSplitButtonItems(devVersionHalItem: DevVersionHalItem): MenuItem[] {
    return [
      {
        label: 'Entwicklerversion',
        icon: 'fa ui-icon-code',
        disabled: !this.canCreateDevVersion(devVersionHalItem),
        command: () => {
          if (this.isValidVersion(devVersionHalItem)) {
            this.onCreateDevelopmentVersionForDevelopmentVersionClicked(devVersionHalItem);
          }
        }
      }
    ];
  }

  getBaseVersionSplitButtonItems(versionHalItem: VersionHalItem): MenuItem[] {
    return [
      {
        label: 'Entwicklerversion',
        icon: 'fa ui-icon-code',
        command: () => {
          if (this.isValidVersion(versionHalItem)) {
            this.onCreateDevelopmentVersionClicked(versionHalItem);
          }
        }
      }
    ];
  }

  onCreateDevelopmentVersionForDevelopmentVersionClicked(devVersion: DevVersionHalItem) {
    this.devVersionService.createDevVersion(
      devVersion.data.id,
      this.processHalItem.data.displayName
    ).subscribe(
      devVersionHalItem => {
        this.processHalItem.devversions.add(<DevVersionHalItem>{data: devVersionHalItem});
        this.devVersionService.downloadDevVersion(devVersionHalItem.id, devVersionHalItem.fileType).subscribe(
          (res) => {
            this.downloadSuccess(res);
            this.communicationService.publishMessage('ACTION', ActionType.DevVersionUpload, devVersionHalItem);
            this.processHalItem.devversions.reload();
            this.loadData();
          },
          (error) => {
            this.downloadError(error);
          }
        );
      }
    );
  }

  onCreateDevelopmentVersionClicked(versionHalItem: VersionHalItem) {
    this.versionService.createDevVersion(
      versionHalItem.data.id,
      this.processHalItem.data.displayName
    ).subscribe(
      devVersionHalItem => {
        this.processHalItem.devversions.add(<DevVersionHalItem>{data: devVersionHalItem});
        this.devVersionService.downloadDevVersion(devVersionHalItem.id, devVersionHalItem.fileType).subscribe(
          (res) => {
            this.downloadSuccess(res);
            this.communicationService.publishMessage('ACTION', ActionType.DevVersionUpload, devVersionHalItem);
            this.processHalItem.devversions.reload();
            this.loadData();
          },
          (error) => {
            this.downloadError(error);
          }
        );
      }
    );
  }

  downloadSuccess(res) {
    saveAs(res.body, resolveFileName(res.headers));
  }

  downloadError(error: Error) {
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Fehler',
        detail: 'Beim Download ist ein Fehler aufgetreten.'
      }
    );
  }

  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
    this.reset();
    this.localErrors = [];
    this.processHalItem.devversions.reload();
    this.checkIfNoDevVersionNeeded();
    this.loadData();
  }

  onError(error: any) {
    super.onError(error);
    this.localErrors = [];
    this.reset();
  }

  private reset() {
    this.devVersion = null;
    this.versionFile = null;
    this.doneDevVersionUploadActionType = null;
    this.loading = false;
    this.devVersionUploadActionType = null;
    if (this.xdpUploadForm) {
      this.xdpUploadForm.fileuploader.clear();
      this.xdpUploadForm.uploadFileForm.reset();
    }
  }

  onDisplayChange(isVisible: boolean) {
    this.formularCreateModalVisible = isVisible;
    this.xdpUploadForm.fileuploader.clear();
    this.xdpUploadForm.uploadFileForm.reset();
  }

  canCheckNoDevVersionNeeded() {
    let canCheckNoCreate = false;
    if (this.devVersions) {
      this.devVersions.content.forEach(
        halItem => {
          if (this.isValidVersion(halItem.data)) {
            canCheckNoCreate = true;
          }
        }
      );
    }
    return canCheckNoCreate;
  }

  showInfo(item: DevVersionHalItem) {
    this.showInfoDialog = true;
    this.infoVersion = item;
  }

  onCloseInfoDialog() {
    this.showInfoDialog = false;
  }

  isValidVersion(halData: any) {

    if (halData && halData.state) {
      return halData.state === EDevelopmentState.UPLOADED || halData.state === EDevelopmentState.RELEASED;
    } else {
      return !!halData;
    }
  }

  onFileChanged() {
    this.versionFile = this.xdpUploadForm.uploadFileForm.value.versionFile;
    this.onGetXDPData(this.versionFile);
  }

  onGetXDPData(versionFile: any): void {
    this.isValidating = true;
    this.versionService.validateXdp(
      versionFile
    ).subscribe({
      next: this.onGetXDPDataSuccess.bind(this),
      error: this.onGetXDPDataError.bind(this)
    });
  }

  onGetXDPDataSuccess(validationDocument: ValidationDocument): void {
    this.isValidating = false;
    this.localErrors = [];
    this.validationDocument = validationDocument;
    if (!this.formular) {
      this.localErrors.push({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Kein Formular für Auftrag gefunden. Ein Formular ist zwingend erforderlich.'
      });
      this.reset();
    } else {
      this.validationDocument.validationData.formular = this.formular.data;
    }
    if (this.validationDocument.validationData.xdpData
      && this.validationDocument.validationData.xdpData.articleNumber !== this.formular.data.articleNumber) {
      this.localErrors.push({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Artikelnummer in Datei unterscheidet sich von Artikelnummer im Auftrag. Erwartet: '
          + this.formular.data.articleNumber
          + '. In Datei: '
          + this.validationDocument.validationData.xdpData.articleNumber
      });
      this.reset();
    }
    if (validationDocument.type === EDocumentTypes.VERSION) {
      this.localErrors.push({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Dies ist keine Entwicklerversion. Bitte eine Entwicklerversion hochladen.'
      });
      this.reset();
    }
    if (this.localErrors.length === 0) {
      // Could be created with new DevVersion() alternatetively
      const devVersion = {
        xdpData: null
      };
      (<any>devVersion).xdpData = validationDocument.validationData.xdpData;

      this.actionContextService.validateAction(
        this.context.data.id,
        this.action.data.id,
        {
          devVersion: devVersion
        }
      ).subscribe(
        res => this.formularCreateModalVisible = true,
        err => {
          this.localErrors.push({
            severity: 'error',
            summary: 'Fehler',
            detail: err.error.errormessage
          });
        }
      );
    }
  }

  onGetXDPDataError(error): void {

    this.xdpUploadForm.fileuploader.clear();
    this.xdpUploadForm.uploadFileForm.reset();

    this.isValidating = false;
    let errormessage = error && error.error && error.error.errormessage ? error.error.errormessage : null;
    if (!errormessage) {
      errormessage = error && error.error && error.error.message ? error.error.message : null;
    }
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Validierungsfehler',
        detail: errormessage ? errormessage : 'Beim validieren der Datei ist ein Fehler aufgetreten.'
      }
    );
  }

  onNoDevVersionRequired() {
    if (this.noDevVersionNeeded) {
      this.devVersionUploadActionType = DevVersionUploadActionType.NO_DEVVERSION;
      this.saveAction();
    }
  }

  onSendData(devVersion: DevVersion) {
    this.loading = true;
    this.formularCreateModalVisible = false;
    this.devVersion = devVersion;
    this.devVersionUploadActionType = DevVersionUploadActionType.ADD_DEVVERSION;
    this.saveAction();
  }

  collectActionData(): WorkflowActionDataDto {
    if (this.devVersion) {
      this.devVersion.createdDate = null;
    }
    return <WorkflowActionDataDto>{
      devVersionUploadActionType: this.devVersionUploadActionType,
      devVersion: this.devVersion,
      file: this.versionFile
    };
  }

  onFileRemoved() {
    this.devVersion = null;
    this.versionFile = null;
    this.localErrors = [];
  }

  isValid() {
    return this.localErrors.length === 0 && !!this.devVersionUploadActionType;
  }
}
