import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  DsvsAuthenticationModule,
  DsvsAuthInterceptor,
  DsvsBlobErrorHttpInterceptor,
  DsvsHalAsyncLoaderModule,
  DsvsInlineEditModule,
  DsvsLandingPageModule,
  DsvsLayoutModule,
  DsvsNotificationModule,
  DsvsSettingsModule,
  DsvsSharedModule,
  DsvsTrackingHeaderInterceptor
} from '@dsvs/dsvs-shared-ui-lib';
import { HAL_CONFIGURATION_TOKEN, HalConfiguration } from '@dsvs/hal-client';
import { WorkflowModule } from '@dsvs/workflow-generator-ui-lib';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { WorkspaceSelectComponent } from './components/workspace-select/workspace-select.component';
import { AdministrationActivateGuard } from './guards/administration.guard';
import { FIReleaseDateActivateGuard } from './guards/fireleasedate.guard';
import { FormActivateGuard } from './guards/form.guard';
import { ProcessActivateGuard } from './guards/process.guard';
import { RequirementActivateGuard } from './guards/requirement.guard';
import { ScanpoolActivateGuard } from './guards/scanpool.guard';
import { SearchActivateGuard } from './guards/search.guard';
import { ServiceupdateActivateGuard } from './guards/serviceupdate.guard';
import { AdministrationModule } from './modules/administration/administration.module';
import { FiReleaseDatesModule } from './modules/fi-release-dates/fi-release-dates.module';
import { FormularModule } from './modules/formular/formular.module';
import { HomeModule } from './modules/home/home.module';
import { ProcessModule } from './modules/process/process.module';
import { RequirementsModule } from './modules/requirements/requirements.module';
import { ScanpoolModule } from './modules/scanpool/scanpool.module';
import { SearchModule } from './modules/search/search.module';
import { ServiceupdatesModule } from './modules/serviceupdates/serviceupdates.module';
import { GlobalworkspaceHeaderInterceptor } from './modules/shared/interceptors/globalworkspace-header.interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { CommunicationService } from './services/communication-service';
import { RepositoryService } from './services/repository-service';

registerLocaleData(localeDE);

@NgModule({
  declarations: [
    AppComponent,
    WorkspaceSelectComponent
  ],
  imports: [
    /** Angular **/
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    /** PrimeNG */
    /** DSVS **/
    DsvsInlineEditModule,
    DsvsLayoutModule,
    DsvsNotificationModule,
    DsvsLandingPageModule,
    DsvsAuthenticationModule,
    DsvsSharedModule,
    DsvsSettingsModule,
    DsvsHalAsyncLoaderModule,
    /** Workflowgenerator **/
    WorkflowModule,
    /** FES **/
    AppRoutingModule,
    SharedModule,
    HomeModule,
    FormularModule,
    SearchModule,
    ProcessModule,
    RequirementsModule,
    ServiceupdatesModule,
    AdministrationModule,
    FiReleaseDatesModule,
    ScanpoolModule
  ],
  providers: [
    /** PrimeNG */
    /** DSVS **/
    {provide: LOCALE_ID, useValue: 'de'},
    [{
      provide: HTTP_INTERCEPTORS, useClass:
      DsvsAuthInterceptor, multi: true
    }],
    [{
      provide: HTTP_INTERCEPTORS, useClass:
      DsvsTrackingHeaderInterceptor, multi: true
    }],
    [{
      provide: HTTP_INTERCEPTORS, useClass:
      DsvsBlobErrorHttpInterceptor, multi: true
    }],
    [{
      provide: HTTP_INTERCEPTORS, useClass:
      GlobalworkspaceHeaderInterceptor, multi: true
    }],
    {provide: HAL_CONFIGURATION_TOKEN, useValue: <HalConfiguration>{rootUri: environment.wgurl}},
    /** FES **/
    RepositoryService,
    CommunicationService,
    ProcessActivateGuard,
    FormActivateGuard,
    AdministrationActivateGuard,
    RequirementActivateGuard,
    ServiceupdateActivateGuard,
    ScanpoolActivateGuard,
    FIReleaseDateActivateGuard,
    SearchActivateGuard
  ],
  entryComponents: [
    WorkspaceSelectComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
