import {Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'fes-search-result-item',
  templateUrl: './search-result-item.component.html',
  styleUrls: ['./search-result-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchResultItemComponent implements OnInit, OnChanges {

  @Input() positionPage: number;
  @Input() positionLine: number;
  @Input() text: string;
  @Input() searchValue: string;

  constructor() { }

  ngOnInit() {
    if (this.searchValue !== '') {
      this.text = this.text.replace(this.searchValue, '<span class="highlighted">' + this.searchValue + '</span>');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const searchValue: SimpleChange = changes.searchValue;
    this.text = this.text.replace(/<[^>]*>/g, '');
    if (this.searchValue !== '') {
      this.text = this.text.replace(searchValue.currentValue, '<span class="highlighted">' + searchValue.currentValue + '</span>');
    }
  }
}
