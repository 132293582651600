import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DsvsPage, DsvsTableColumn} from '@dsvs/dsvs-shared-ui-lib';
import {saveAs} from 'file-saver';
import {MenuItem} from 'primeng/api';
import {resolveFileName} from '../../../../helper/download-helper';
import {getExceptionMessages} from '../../../../helper/exception-helper';
import {ServiceupdateHalItem, ServiceupdateHalPage} from '../../../shared/hal/serviceupdate.hal.item';
import {Serviceupdate} from '../../../shared/interfaces/dtos/serviceupdate';
import {ServiceupdateServiceImpl} from '../../../shared/services/serviceupdate.service';

@Component({
  selector: 'fes-serviceupdate-g2-packeting',
  templateUrl: './serviceupdate-g2-packeting.component.html',
  styleUrls: ['./serviceupdate-g2-packeting.component.scss']
})
export class ServiceupdateG2PacketingComponent implements OnInit {

  loading = false;
  loadingPacket = false;
  isNew = false;

  @Input() showPacketingModal = false;

  g2Errors = [];

  @Output() showPacketingModalEvent = new EventEmitter<boolean>();

  selectedServiceupdates: ServiceupdateHalItem[] = [];

  tableColumnOptions: DsvsTableColumn[] = [];

  data: ServiceupdateHalPage;

  stepItems: MenuItem[];

  constructor(
    private serviceupdateService: ServiceupdateServiceImpl
  ) {
    this.onReloadData('');
  }

  ngOnInit() {
    this.tableColumnOptions = [
      {field: 'sequence', header: '#', format: 'SEQUENCENUMBER', width: '5%'},
      {field: 'displayName', sort: true, header: 'Serviceupdate', width: '15%'},
      {field: 'lastModifiedDate', header: 'Änderungsdatum', sort: true, format: 'DATE', width: '15%'},
      {field: 'displayName', header: 'G2-Termin', sort: true, format: 'G2DATE', width: '15%'},
      {field: 'title', header: 'Titel', sort: true, width: '35%'},
      {field: 'status', header: 'Active', sort: true, format: 'STATE', width: '15%'}
    ];
    this.stepItems = [
      {label: 'Serviceupdates wählen'},
      {label: 'Vorlagen überprüfen'},
      {label: 'XML überprüfen'}
    ];
  }

  onReloadData(searchTerm: string, page?: DsvsPage) {
    this.loading = true;
    this.serviceupdateService.search(searchTerm, page).subscribe(result => {
      this.data = result;
      this.loading = false;
    }, error2 => {
      this.loading = false;
      console.error(error2);
    });
  }

  createPacket() {
    this.loadingPacket = true;
    this.serviceupdateService.downloadG2Packet(
      this.selectedServiceupdates.map(a => a.data.id)
    ).subscribe(
      (res) => {
        this.loadingPacket = false;
        saveAs(res.body, resolveFileName(res.headers));
      },
      (error) => {
        this.loadingPacket = false;
        this.g2Errors = [];
        this.g2Errors.push(...getExceptionMessages(error));
      }
    );
  }

  cancelModal() {
    this.showPacketingModalEvent.emit(false);
  }

}
