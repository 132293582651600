import {HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage} from '@dsvs/hal-client';
import {CrewRelationPage, GroupRelationPage, RoleUserRelationPage} from '@dsvs/workflow-generator-dto';
import {User} from '../interfaces/dtos/user';
import {WorkspaceRelationPage} from './workspace.hal.item';

export class UserHalItem extends HalItemImpl<User> {
  constructor(data: User, client: HalClient, factory: HalClientFactory) {
    super(data, client, factory);

    this.workspaces = new WorkspaceRelationPage('workspaces', this);
    this.crews = new CrewRelationPage('crews', this);
    this.roleUsers = new RoleUserRelationPage('roleusers', this);
    this.groups = new GroupRelationPage('groups', this);
  }

  readonly workspaces: WorkspaceRelationPage;
  readonly crews: CrewRelationPage;
  readonly roleUsers: RoleUserRelationPage;
  readonly groups: GroupRelationPage;
}

export class UserHalPage extends HalPageImpl<User, UserHalItem> {

}

export class UserRelationPage extends HalRelationPage<User, UserHalItem, UserHalPage> {
  constructor(
    relation: string,
    item: HalItem<any>,
    embeddedName?: string
  ) {
    super(relation, item, UserHalPage, UserHalItem, null, embeddedName);
  }
}

export class UserRelationItem extends HalRelationItem<User, UserHalItem> {
  constructor(
    relation: string,
    item: HalItem<any>
  ) {
    super(relation, item, UserHalItem);
  }
}
