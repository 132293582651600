import {
  HalClient,
  HalClientFactory,
  HalItem,
  HalItemImpl,
  HalPageImpl,
  HalRelationItem,
  HalRelationPage
} from '@dsvs/hal-client';
import {FormularRelationItem} from './formular.hal.item';
import {ExternRecord} from '../interfaces/dtos/extern-record';

export class ExternRecordHalItem extends HalItemImpl<ExternRecord> {
  readonly formular: FormularRelationItem;

  constructor(data: ExternRecord, client: HalClient, factory: HalClientFactory) {
    super(data, client, factory);
    this.formular = new FormularRelationItem('form', this);
  }
}

export class ExternRecordHalPage extends HalPageImpl<ExternRecord, ExternRecordHalItem> {

}

export class ExternRecordRelationPage extends HalRelationPage<ExternRecord, ExternRecordHalItem, ExternRecordHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, ExternRecordHalPage, ExternRecordHalItem);
  }
}

export class ExternRecordRelationItem extends HalRelationItem<ExternRecord, ExternRecordHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, ExternRecordHalItem);
  }
}
