import {Injectable} from '@angular/core';
import {TagServiceImpl} from './tag.service';
import {DsvsPage} from '@dsvs/dsvs-shared-ui-lib';
import {Observable} from 'rxjs';
import {ViewParameter} from '../hal/hal.read.service';
import {TagHalPage} from '../hal/tag.hal.item';


@Injectable({
  providedIn: 'root'
})
export class TagWithoutDevTempServiceImpl
  extends TagServiceImpl {

  search(searchTerm: string, options?: DsvsPage, view?: ViewParameter): Observable<TagHalPage> {
    return super.search(searchTerm, options, view);
  }

}
