import { Component, Input } from '@angular/core';
import { StepCommentHalItem } from '../../../../shared/hal/stepcomment.hal.item';
import { FileServiceImpl } from '../../../../shared/services/file.service';

@Component({
  selector: 'fes-process-detail-step-comment',
  templateUrl: './process-detail-step-comment.component.html',
  styleUrls: ['./process-detail-step-comment.component.scss']
})
export class ProcessDetailStepCommentComponent {

  @Input()
  stepComment: StepCommentHalItem;

  constructor(private fileService: FileServiceImpl) {
  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

}
