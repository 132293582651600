import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GroupHalPage, UserHalPage } from '@dsvs/workflow-generator-dto';
import { WorkflowGroupServiceImpl, WorkflowRoleUserServiceImpl, WorkflowUserServiceImpl } from '@dsvs/workflow-generator-ui-lib';
import { Observable } from 'rxjs';

@Component({
  selector: 'fes-crew-detail-role-user',
  templateUrl: './crew-detail-role-user.component.html',
  styleUrls: ['./crew-detail-role-user.component.scss']
})
export class CrewDetailRoleUserComponent implements OnInit {

  @Input()
  isNew = false;

  @Input()
  isCreateDialog = false;

  @Input()
  isEditable = false;

  @Input()
  roleuser: any;

  @Input()
  newUsersArray = [];

  @Output() reloadRoleUsers = new EventEmitter<any>();

  newGroup = {};
  useGroup = false;

  /**
   *
   * @param groupService
   * @param userService
   * @param roleUserService
   */
  constructor(public groupService: WorkflowGroupServiceImpl,
              public userService: WorkflowUserServiceImpl,
              private roleUserService: WorkflowRoleUserServiceImpl) {
  }

  /**
   *
   */
  ngOnInit() {
    if (this.roleuser.group && this.roleuser.group.async) {
      this.roleuser.group.async.subscribe(result => {
        if (result.data.id) {
          this.useGroup = true;
        }
      });
    } else {
      if ((this.roleuser.data && this.roleuser.data.groupId) ||
        (this.roleuser.group && this.roleuser.group.data && this.roleuser.group.data.id)) {
        this.useGroup = true;
      }
    }
  }

  saveUsers = (value, component): Observable<any> => {
    if (!this.isCreateDialog) {
      const users = component.value;
      const roleuserHalItem = component.model;
      return roleuserHalItem.users.update(users);
    } else {
      return Observable.of(null);
    }
  };

  saveGroup = (value, component): Observable<any> => {
    if (!this.isCreateDialog) {
      component.model.data.groupId = value.data.id;

      component.model.save().subscribe(() => {
        component.model.users.reload();
      });
    } else {
      this.groupService.getById(value.data.id).subscribe(result => {
        result.users.setParams({size: 2000}).async.subscribe(userPage => {
          this.newUsersArray = userPage.content;
          this.reloadRoleUsers.emit({users: this.newUsersArray, group: result});
        });
      }, error => {
        console.error(error);
      });
    }

    return Observable.of(null);
  };

  /********************************************
   * Fetch data
   *******************************************/

  fetchUsers = ($event): Observable<UserHalPage> => {
    return this.userService.search($event.query);
  };

  fetchGroups = ($event): Observable<GroupHalPage> => {
    return this.groupService.search($event.query);
  };

  fetchSelectedRowUsersOfNew = ($event, component): Observable<UserHalPage> => {
    return component.model.users.async;
  };

  toggleUseGroup() {
    this.useGroup = !this.useGroup;
    if (!this.isCreateDialog) {
      if (!this.useGroup) {
        this.roleuser.data.groupId = null;
        this.roleUserService.update(this.roleuser.data).subscribe(() => {
          this.reloadRoleUsers.emit();
        }, error => {
          console.error(error);
        });
      }
    } else {
      this.newGroup = {};
    }
  }

}
