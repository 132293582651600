import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { File } from '../interfaces/dtos/file';

export class FileHalItem extends HalItemImpl<File> {
  constructor(data: File, client: HalClient, factory: HalClientFactory) {
    super(data, client, factory);
  }
}

export class FileHalPage extends HalPageImpl<File, FileHalItem> {

}

export class FileRelationPage extends HalRelationPage<File, FileHalItem, FileHalPage> {
  constructor(relation: string,
    item: HalItem<any>) {
    super(relation, item, FileHalPage, FileHalItem);
  }
}

export class FileRelationItem extends HalRelationItem<File, FileHalItem> {
  constructor(relation: string,
    item: HalItem<any>) {
    super(relation, item, FileHalItem);
  }
}
