import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { Tag } from '../interfaces/dtos/tag';

export class TagHalItem extends HalItemImpl<Tag> {
  constructor(data: Tag,
              client: HalClient,
              factory: HalClientFactory) {
    super(data, client, factory);
  }
}

export class TagHalPage extends HalPageImpl<Tag, TagHalItem> {

}

export class TagRelationPage extends HalRelationPage<Tag, TagHalItem, TagHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, TagHalPage, TagHalItem);
  }
}

export class TagRelationItem extends HalRelationItem<Tag, TagHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, TagHalItem);
  }
}
