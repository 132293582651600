import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {EFileTypes} from '../../interfaces/dtos/enums/EFileTypes';
import {Scanpool} from '../../interfaces/dtos/scanpool';
import {FileServiceImpl} from '../../services/file.service';
import {ScanpoolServiceImpl} from '../../services/scanpool.service';
import {DsvsPermissionService} from '@dsvs/dsvs-shared-ui-lib';
import {ZapPermissions} from '../../../../enums/zap-permissions.enum';

@Component({
  selector: 'fes-xdp-file-download',
  templateUrl: './xdp-file-download.component.html',
  styleUrls: ['./xdp-file-download.component.scss']
})
export class XdpFileDownloadComponent implements OnInit {

  // TODO: Add halItem as Type
  @Input()
  halItem: any;

  @Input()
  isDevVersion = false;

  @Input()
  title: string;

  @Input()
  splitButtons: MenuItem[] = [];

  @Input()
  scanpool = false;

  @Input()
  scanpoolData: Scanpool = null;

  @Input()
  params: string = null;

  @Input()
  iconOnly = false;

  @Output()
  download = new EventEmitter<string>();

  @Output()
  success = new EventEmitter<string>();

  @Output()
  error = new EventEmitter<string>();

  rendering = null;

  documentationVisible = false;

  constructor(private fileService: FileServiceImpl,
              private scanpoolService: ScanpoolServiceImpl,
              private permissionService: DsvsPermissionService
  ) {
  }

  ngOnInit() {
  }

  onDownloadPathClicked(path: string, fileType: EFileTypes = this.halItem.data.fileType, params = null) {
    let filePath = path + `?fileType=${fileType}`;
    if (params) {
      filePath += params;
    }
    this.rendering = path;
    this.download.emit(filePath);
    this.fileService.downloadFileUrl(
      filePath,
      () => {
        this.rendering = null;
        this.success.emit(filePath);
      },
      () => {
        this.rendering = null;
        this.error.emit(filePath);
      }
    );
  }

  getSplitButtonItems(halLink: any): MenuItem[] {
    const fileType = this.halItem.data.fileType;

    let menuItems = [];

    switch (fileType) {
      case EFileTypes.PDF:
        menuItems = this.getPDFMenuItems(halLink);
        break;
      case EFileTypes.XDP:
        menuItems = this.getXDPMenuItems(halLink);

        if (this.permissionService.hasPermission([ZapPermissions.FES_FORMCOMPARE_CREATE])) {
          menuItems.push({
            label: 'Dokumentation', icon: 'fa ui-icon-compare', command: () => {
              this.documentationVisible = true;
            }
          });
        }

        break;
    }

    if (this.scanpool) {
      menuItems.push(
        {
          label: 'Scanpool', icon: 'fa ui-icon-file-download', command: () => {
            this.scanpoolService.create(this.scanpoolData).subscribe(
              requirementHalItem => {
                this.scanpoolService.downloadWithBarcode(requirementHalItem.data);
              }
            );
          }
        }
      );
    }
    menuItems.push(...this.splitButtons);
    return menuItems;
  }

  getXDPMenuItems(halLink): MenuItem[] {
    return <MenuItem[]>[
      {
        label: 'XDP',
        icon: 'fa ui-icon-file-download',
        command: () => {
          this.onDownloadPathClicked(halLink, EFileTypes.XDP);
        }
      },
      {
        label: 'PDF', icon: 'fa ui-icon-file-download', command: () => {
          this.onDownloadPathClicked(halLink, EFileTypes.PDF);
        }
      },
      {
        label: 'PDF/A', icon: 'fa ui-icon-file-download', command: () => {
          this.onDownloadPathClicked(halLink, EFileTypes.PDF_A);
        }
      },
      {
        label: 'Flach', icon: 'fa ui-icon-file-download', command: () => {
          this.onDownloadPathClicked(halLink, EFileTypes.PDF_FLAT);
        }
      },
      {
        label: 'Muster', icon: 'fa ui-icon-file-download', command: () => {
          this.onDownloadPathClicked(halLink, EFileTypes.PDF_MUSTER);
        }
      },
      {
        label: 'DOCX', icon: 'fa ui-icon-file-download', command: () => {
          this.onDownloadPathClicked(halLink, EFileTypes.WORD);
        }
      }
    ];
  }

  getPDFMenuItems(halLink): MenuItem[] {
    return <MenuItem[]>[
      {
        label: 'PDF', icon: 'fa ui-icon-file-download', command: () => {
          this.onDownloadPathClicked(halLink, EFileTypes.PDF);
        }
      }
    ];
  }
}
