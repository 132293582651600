import { Location } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DsvsBreadcrumbService,
  DsvsPermissionService,
  DsvsSearchEvent,
  DsvsToolbarItem,
  InlineEditComponent
} from '@dsvs/dsvs-shared-ui-lib';
import { CrewHalItem } from '@dsvs/workflow-generator-dto';
import { WorkflowCrewServiceImpl } from '@dsvs/workflow-generator-ui-lib';
import { saveAs } from 'file-saver';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Observable } from 'rxjs';
import { ZapPermissions } from '../../../../enums/zap-permissions.enum';
import { resolveFileName } from '../../../../helper/download-helper';
import { RepositoryService } from '../../../../services/repository-service';
import { DevVersionHalItem, DevVersionHalPage } from '../../../shared/hal/devversion.hal.item';
import { VersionHalItem } from '../../../shared/hal/version.hal.item';
import { WorkspaceHalItem } from '../../../shared/hal/workspace.hal.item';
import { DevVersion } from '../../../shared/interfaces/dtos/dev-version';
import { ValidationDocument } from '../../../shared/interfaces/dtos/documents/validation-document';
import { EGroupTypes } from '../../../shared/interfaces/dtos/enums/EGroupTypes';
import { ETagCategory } from '../../../shared/interfaces/dtos/enums/ETagCategory';
// import { ETagCategory, getColorForTag } from '../../../shared/interfaces/dtos/enums/ETagCategory';
import { DevVersionServiceImpl } from '../../../shared/services/dev-version.service';
import { FormServiceImpl } from '../../../shared/services/form.service';
import { TagWithoutDevTempServiceImpl } from '../../../shared/services/tag-without-dev-temp.service';
import { TagServiceImpl } from '../../../shared/services/tag.service';
import { VersionServiceImpl } from '../../../shared/services/version.service';
import { DevVersionChange } from '../DevVersionChange';
import { FormularActions } from '../formular.component';
import { EDeleteType } from './delete/formular-delete.component';
import { FormularInformationService } from './list/formular-information.service';

@Component(
  {
    selector: 'fes-formular-screen',
    templateUrl: './formular-screen.component.html',
    styleUrls: ['./formular-screen.component.scss']
  }
)
export class FormularScreenComponent implements OnInit, OnDestroy {

  pageRequest: DsvsSearchEvent = {
    number: 0,
    size: 0,
    searchTerm: '',
    sort: [],
    totalElements: 0
  };
  formular: any; // No type, otherwise _loading is not accepted
  selectedVersion: any; // No type, otherwise _loading is not accepted
  selectedDevVersion: any; // No type, otherwise _loading is not accepted
  showCreateModal = false;
  showCrewModal = false;
  showExternRecordModal = false;
  showSearchModal = false;
  crew: CrewHalItem;
  devVersions: DevVersionHalPage;
  latestDevVersion: DevVersionHalItem = null;
  devVersionsLoading = false;
  formLoading = false;
  xdpReadOnly = false;
  formularInfoSticky = false;
  selectedInfoVersion: VersionHalItem | DevVersionHalItem = null;
  devVersionValidationDocument: ValidationDocument = null;
  existingDevVersions = '';
  downloadDevVersionModalVisible = false;
  downloadDevVersionForDevVersionModalVisible = false;
  cancelDevVersionModalVisible = false;
  publishDevVersionModalVisible = false;
  deleteFormModalVisible = false;
  tagFilter = '';
  categories = [
    ETagCategory.OTHER,
    ETagCategory.PRODUCT,
    ETagCategory.PRODUCT_VERSION,
    ETagCategory.WORKING_GROUP,
    ETagCategory.TICKET_CATEGORY,
    ETagCategory.FI_APPLICATION,
    ETagCategory.PROJEKT
  ];
  groups = [{label: EGroupTypes.NONE, value: EGroupTypes.NONE},
    {label: EGroupTypes.G1, value: EGroupTypes.G1},
    {label: EGroupTypes.G2, value: EGroupTypes.G2}];
  pagedTags: any = [];
  public ZapPermissions = ZapPermissions;
  public TagCategory = ETagCategory;
  isEditable = true; // TODO: welches Recht wird benötigt?

  constructor(
    private repositoryService: RepositoryService,
    private devVersionService: DevVersionServiceImpl,
    private formularService: FormServiceImpl,
    private versionService: VersionServiceImpl,
    private breadcrumbService: DsvsBreadcrumbService,
    private messageService: MessageService,
    public permissionService: DsvsPermissionService,
    private router: Router,
    public tagService: TagServiceImpl,
    public tagWithoutDevTempService: TagWithoutDevTempServiceImpl,
    private location: Location,
    private crewService: WorkflowCrewServiceImpl,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private formInformationService: FormularInformationService
  ) {
  }

  private _formId: string;

  get formId(): string {
    return this._formId;
  }

  @Input() set formId(id: string) {
    this.selectedVersion = undefined;
    this._formId = id;
    this.getForm(id);
  }

  ngOnInit(): void {
    this.initToolbar();

    this.isEditable = this.permissionService.hasPermission([ZapPermissions.FES_FORM_CREATE]);

    this.initAutocompleteData();

    this.tagFilter = JSON.stringify(
      this.categories.map(
        category => ({
          field: 'category',
          filterBy: {
            label: category,
            value: category
          }
        })
      )
    );
  }

  initAutocompleteData() {
    this.getPagedTags(this.pageRequest);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const verticalOffset = window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0;
    if (verticalOffset > 185) {
      this.formularInfoSticky = true;
    } else if (this.formularInfoSticky) {
      this.formularInfoSticky = false;
    }
  }

  ngOnDestroy(): void {
    this.breadcrumbService.removeToolbarItems(this.getToolbarItems());
  }

  initToolbar(): void {
    this.breadcrumbService.addToolbarItems(this.getToolbarItems());
    this.breadcrumbService.setToolbarVisible(true);
  }

  getToolbarItems(): DsvsToolbarItem[] {
    const items = [];
      items.push({
        id: FormularActions.REFRESH_FORMS,
        icon: 'attachment',
        tooltip: 'Sachakte hinzufügen',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_SACHAKTE_READ]),
        callback: () => {
          this.showExternRecordModal = true;
        },
        faicon: true
      });


    items.push({
      id: FormularActions.REFRESH_FORMS,
      icon: 'search',
      tooltip: 'Formular suchen',
      disabled: false,
      callback: () => {
        this.showSearchModal = true;
      },
      faicon: true
    });

    items.push({
      id: FormularActions.REFRESH_FORMS,
      icon: 'cloud_upload',
      tooltip: 'Neue Version hinzufügen',
      disabled: !this.permissionService.hasPermission([ZapPermissions.FES_FORM_CREATE]),
      callback: () => {
        this.showCreateModal = true;
      },
      faicon: true
    });

    items.push({
      id: FormularActions.REFRESH_FORMS,
      icon: 'person',
      tooltip: 'Crew anzeigen',
      disabled: false,
      callback: () => {
        this.showCrewModal = true;
      },
      faicon: true
    });

    items.push({
      id: FormularActions.REFRESH_FORMS,
      icon: 'refresh',
      tooltip: 'Formular aktualisieren',
      disabled: false,
      callback: () => this.refreshData(),
      faicon: true
    });

    if (this.permissionService.hasPermission([ZapPermissions.FES_FORM_DELETE])) {
      items.push({
        id: FormularActions.DELETE_FORM,
        icon: 'delete',
        tooltip: 'Formular löschen',
        disabled: false,
        callback: () => this.deleteForm(),
        faicon: true
      });

      if (this.formular && this.formular.data && this.formular.data.deleted) {

        items.push({
          id: FormularActions.RESTORE_FORM,
          icon: 'restore_from_trash',
          tooltip: 'Formular wiederherstellen',
          disabled: false,
          callback: () => this.restoreForm(),
          faicon: true
        });

      }
    }

    return items;
  }

  refreshData() {
    if (this._formId) {
      this.getForm(this._formId);
    }
  }

  deleteForm() {
    this.deleteFormModalVisible = true;
  }

  restoreForm() {
    this.confirmationService.confirm({
      header: 'Formular wiederherstellen',
      message: 'Soll das Formular wiederhergestellt werden?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      accept: () => {
        this.formularService.restoreForm(this.formular.data).subscribe(result => {
          this.refreshData();
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail: 'Das Formular ' + this.formular.data.articleNumber + ' konnte nicht wiederhergestellt werden'
          });
        });
      }
    });
  }

  onDisplayChangeDevDownload(isVisible: boolean) {
    this.downloadDevVersionModalVisible = isVisible;
    this.downloadDevVersionForDevVersionModalVisible = isVisible;
    this.existingDevVersions = '';
  }

  onDisplayChangeDevCancel(isVisible: boolean) {
    this.cancelDevVersionModalVisible = isVisible;
  }

  onDisplayChangeDevPublish(isVisible: boolean) {
    this.publishDevVersionModalVisible = isVisible;
  }

  onDisplayChangeDeleteForm(isVisible: boolean) {
    this.deleteFormModalVisible = isVisible;
  }

  onFormDeleted(type) {
    this.deleteFormModalVisible = false;
    if (type === EDeleteType.HARD) {
      this.router.navigate([this.formInformationService.formUrl + 'list']);
    } else {
      this.refreshData();
    }
  }

  getForm(id: string) {
    this.formLoading = true;
    this.formularService.getById(id).subscribe(
      (result) => {
        this.formLoading = false;
        this.formular = result;

        if (!this.selectedVersion) {
          if (this.formular.activeVersion && this.formular.activeVersion.async) {
            this.formular.activeVersion.async.subscribe(resultVersion => {
              this.selectedVersion = resultVersion;
            }, error => {
              console.error(error);
            });
          } else {
            this.formular.versions.async.subscribe(resultVersions => {
              if (resultVersions.content.length > 0) {
                this.selectedVersion = resultVersions.content[0];
              }
            });
          }
        }


        this.devVersionsLoading = true;
        this.formular.devversions.async.subscribe(
          (result2) => {
            this.devVersionsLoading = false;
            this.devVersions = result2;
            const highestVersion = result2.content.sort((a, b) => b.data.version.localeCompare(a.data.version))[0];
            this.latestDevVersion = highestVersion ? highestVersion : null;
          },
          (error) => this.devVersionsError(error)
        );

      },
      (error) => this.formLoadError(error)
    );
  }

  onCreateDevVersionRequestClicked(version: VersionHalItem) {
    this.selectedVersion = <VersionHalItem>{...version};
    this.downloadDevVersionModalVisible = true;
    this.selectedVersion.devversions.async.subscribe(
      (res) => {
        this.existingDevVersions = res.content.map(dev => dev.data.createdByName + ' (' + dev.data.version + ')').join(', ');
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onCreateDevVersionForDevVersionRequestClicked(devVersion: DevVersionHalItem) {
    this.selectedDevVersion = <DevVersionHalItem>{...devVersion};
    this.downloadDevVersionForDevVersionModalVisible = true;
    this.versionService.getById(
      devVersion.data.versionId
    ).subscribe(
      versionHalItem => {
        versionHalItem.devversions.async.subscribe(
          (res) => {

            this.existingDevVersions = res.content.map(dev => dev.data.createdByName + ' (' + dev.data.version + ')').join(', ');
          },
          (error) => {
            console.error(error);
          });
      });
  }

  onDeleteDevVersionRequestClicked(devVersion: DevVersionHalItem) {

    this.devVersionService.deleteDevVersion(devVersion.data.id).subscribe(result => {
      this.refreshData();
    }, error => {
      this.deleteError(error);
    });
  }

  onCancelDevVersionRequestClicked(devVersionHalItem: DevVersionHalItem) {
    this.selectedDevVersion = devVersionHalItem;
    this.cancelDevVersionModalVisible = true;
  }

  onPublishDevVersionRequestClicked(devVersionHalItem: DevVersionHalItem) {
    this.selectedDevVersion = devVersionHalItem;
    this.xdpReadOnly = true;
    this.devVersionService.validateDevVersion(
      devVersionHalItem.data.id
    ).subscribe({
      next: this.onGetXDPDataSuccess.bind(this),
      error: this.onGetXDPDataError.bind(this)
    });
  }

  onSendPublishData(validationDocument: ValidationDocument) {
    this.publishDevVersionModalVisible = false;
    this.onPublishVersionDocument(validationDocument);
  }

  onPublishVersionDocument(validationDocument: ValidationDocument): void {
    this.devVersionService.releaseDevVersion(
      this.selectedDevVersion.data.id,
      validationDocument.validationData.version.comment,
      validationDocument.validationData.increaseMajor
    ).subscribe({
      next: this.onAddVersionSuccess.bind(this),
      error: this.onAddVersionError.bind(this)
    });
  }

  onCancelDevVersionClicked(cancelComment: string) {
    const devVersion = this.selectedDevVersion;
    this.devVersionService.cancelDevVersion(
      devVersion.data.id,
      cancelComment
    )
      .subscribe(
        (res) => {
          this.cancelDevVersionModalVisible = false;
          this.selectedVersion = null;
          this.refreshData();
        },
        (error) => {
          this.cancelDevVersionModalVisible = false;
          this.selectedVersion = null;
        }
      );
  }

  onCreateDevVersionClicked(devVersionChange: DevVersionChange) {
    this.versionService.createDevVersion(
      this.selectedVersion.data.id,
      devVersionChange.tag
    ).subscribe(
      (res) => {
        this.downloadDevVersionModalVisible = false;
        this.selectedVersion = null;
        this.refreshData();
        this.createDevVersionSuccess(res);
      },
      (error) => {
        this.downloadDevVersionModalVisible = false;
        this.selectedVersion = null;
        this.onCreateDevVersionError(error);
      }
    );
  }

  onCreateDevVersionForDevVersionClicked(devVersionChange: DevVersionChange) {
    this.devVersionService.createDevVersion(
      this.selectedDevVersion.data.id,
      devVersionChange.tag
    )
      .subscribe(
        (res) => {
          this.downloadDevVersionForDevVersionModalVisible = false;
          this.selectedDevVersion = null;
          this.refreshData();
          this.createDevVersionSuccess(res);
        },
        (error) => {
          this.downloadDevVersionForDevVersionModalVisible = false;
          this.selectedDevVersion = null;
          this.onCreateDevVersionError(error);
        }
      );
  }

  onGetXDPDataSuccess(validationDocument: ValidationDocument): void {
    this.devVersionValidationDocument = validationDocument;
    this.publishDevVersionModalVisible = true;
  }

  onGetXDPDataError(error): void {
    const errormessage = error && error.error && error.error.errormessage ? error.error.errormessage : null;
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Validierungsfehler',
        detail: errormessage ? errormessage : 'Beim validieren der Datei ist ein Fehler aufgetreten.'
      }
    );
  }

  onAddVersionSuccess(result): void {
    this.refreshData();
    this.messageService.add(
      {
        severity: 'success',
        summary: 'Version-Upload',
        detail: 'Version erfolgreich angelegt.'
      }
    );
  }

  onCreateDevVersionError(error): void {
    const errormessage = error && error.error && error.error.errormessage ? error.error.errormessage : null;
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Version-Upload',
        detail: errormessage ? errormessage : 'Entwicklerversion konnte nicht angelegt werden'
      }
    );
  }

  onAddVersionError(error): void {

    const errormessage = error && error.error && error.error.errormessage ? error.error.errormessage : null;
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Upload-Fehler',
        detail: errormessage ? errormessage : 'Beim Upload der Version ist ein Fehler aufgetreten.'
      }
    );
  }

  createDevVersionSuccess(result: DevVersion) {
    this.devVersionService.downloadDevVersion(result.id, result.fileType).subscribe(
      (res) => {
        this.downloadSuccess(res);
      },
      (error) => {
        this.downloadError(error);
      }
    );
  }

  downloadSuccess(res) {
    saveAs(res.body, resolveFileName(res.headers));
  }

  downloadError(error: Error) {
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Fehler',
        detail: 'Beim Download ist ein Fehler aufgetreten.'
      }
    );
  }

  devVersionsError(error: Error) {
    this.devVersionsLoading = false;
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Fehler',
        detail: 'Beim Laden der Entwicklerversionen ist ein Fehler aufgetreten.'
      }
    );
  }

  deleteError(error: Error) {
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Fehler',
        detail: 'Beim Löschen ist ein Fehler aufgetreten.'
      }
    );
  }

  formLoadError(error: Error) {
    this.formLoading = false;
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Fehler',
        detail: 'Beim laden des Formulars ist ein Fehler aufgetreten.'
      }
    );
  }

  save = (value, component: InlineEditComponent): Observable<any> => {
    return this.formularService.update(this.formular.data);
  };

  selectVersion(event) {
    this.selectedVersion = event.value;
  }

  uploadSuccess($event) {
    this.showCreateModal = false;
    this.breadcrumbService.raiseToolClickedEventById(FormularActions.REFRESH_FORMS);
    this.router.navigate(['forms/', $event.formularId]);
    this.refreshData();
  }

  closeSearchModal() {
    this.showSearchModal = false;
    this.formId = this.route.snapshot.paramMap.get('id');
  }

  updateTags(value, component: InlineEditComponent): Observable<WorkspaceHalItem> {
    const tags = component.modelValue;
    return component.model.tags.update(tags);
  }

  fetchTags = (event) => {
    return this.tagService.getTagsForCategories(
      this.categories,
      event.query
    );
  };

  getPagedTags(event) {

    if (event.searchTerm) {
      this.pageRequest.searchTerm = event.searchTerm;
    } else {
      this.pageRequest.searchTerm = '';
    }

    if (event.number === 0) {
      this.pagedTags = [];
    }

    this.pageRequest.number = event.number;
    this.tagService.search(this.pageRequest.searchTerm, this.pageRequest).subscribe(result => {

      for (let i = 0; i < result.content.length; i++) {
        this.pagedTags.push(result.content[i]);
      }

      this.pageRequest = {
        number: result.page.number,
        size: result.page.size,
        sort: result.page.sort,
        totalElements: result.page.totalElements,
        searchTerm: this.pageRequest.searchTerm
      };
    });
  }

  fetchColorsForTags = (tag, component) => {
    if (tag && tag.data && tag.data.category) {
      return this.tagService.getColorForTagId(tag.data.category);
    } else {
      return null;
    }

  };

  fetchCrews = ($event, inlineEdit): Observable<any> => {
    return this.crewService.search($event.query);
  };

  selectCrew = (value, component): Observable<any> => {
    this.crew = component.modelValue;
    this.formular.data.crewId = this.crew.data.id;
    return this.save(null, null);
  };

  selectGroup(event) {
    this.versionService.update(this.selectedVersion.data).subscribe(() => {
      this.refreshData();
    }, error => {
      console.error(error);
    });
  }

  goBack() {
    this.location.back();
  }
}
