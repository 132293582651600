import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { StepContextRelationItem } from '@dsvs/workflow-generator-dto';
import { StepComment } from '../interfaces/dtos/stepcomment';

export class StepCommentHalItem extends HalItemImpl<StepComment> {
  constructor(data: StepComment,
              client: HalClient,
              factory: HalClientFactory) {
    super(data, client, factory);
    this.stepContext = new StepContextRelationItem('stepcontext', this);
  }

  readonly stepContext: StepContextRelationItem;
}

export class StepCommentHalPage extends HalPageImpl<StepComment, StepCommentHalItem> {

}

export class StepCommentRelationPage extends HalRelationPage<StepComment, StepCommentHalItem, StepCommentHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, StepCommentHalPage, StepCommentHalItem);
  }
}

export class StepCommentRelationItem extends HalRelationItem<StepComment, StepCommentHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, StepCommentHalItem);
  }
}
