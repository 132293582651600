import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DsvsPermissionService, FileUploadComponent, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {MenuItem} from 'primeng/api';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable, of as observableOf, Subject} from 'rxjs';
import {ZapPermissions} from '../../../../enums/zap-permissions.enum';
import {getExceptionMessages} from '../../../../helper/exception-helper';
import {FormularHalItem, FormularHalPage} from '../../../shared/hal/formular.hal.item';
import {RequirementHalItem} from '../../../shared/hal/requirement.hal.item';
import {VersionHalItem} from '../../../shared/hal/version.hal.item';
import {EScanpoolSource} from '../../../shared/interfaces/dtos/enums/EScanpoolSource';
import {EScanpoolTarget} from '../../../shared/interfaces/dtos/enums/EScanpoolTarget';
import {Requirement} from '../../../shared/interfaces/dtos/requirement';
import {Scanpool} from '../../../shared/interfaces/dtos/scanpool';
import {FesUserSettingsService} from '../../../shared/services/fes-user-settings.service';
import {FileServiceImpl} from '../../../shared/services/file.service';
import {FormServiceImpl} from '../../../shared/services/form.service';
import {RequirementServiceImpl} from '../../../shared/services/requirement.service';
import {ScanpoolServiceImpl} from '../../../shared/services/scanpool.service';
import {WorkspaceServiceImpl} from '../../../shared/services/workspace.service';

@Component({
  selector: 'fes-requirements-detail',
  templateUrl: './requirements-detail.component.html',
  styleUrls: ['./requirements-detail.component.scss']
})
export class RequirementsDetailComponent implements OnInit {

  file: any;
  requirement: Requirement;
  forms: FormularHalItem[] = [];
  _requirementHalItem: RequirementHalItem;
  isEditable = false;
  versionDownloadSplitButtonData: MenuItem[];
  activeVersion: VersionHalItem = null;

  @Input() isNew = false;

  @Input()
  set requirementHalItem(requirementHalItem: RequirementHalItem) {
    if (requirementHalItem) {
      this.requirement = requirementHalItem.data;
      this._requirementHalItem = requirementHalItem;
    }
  }

  get requirementHalItem(): RequirementHalItem {
    return this._requirementHalItem;
  }

  @Output() showEditModalEvent = new EventEmitter<boolean>();
  @ViewChild('fileuploader') fileuploader: FileUploadComponent;

  constructor(
    private requirementService: RequirementServiceImpl,
    private workspaceService: WorkspaceServiceImpl,
    private scanpoolService: ScanpoolServiceImpl,
    private settings: FesUserSettingsService,
    private messageService: MessageService,
    private formService: FormServiceImpl,
    private fileService: FileServiceImpl,
    private permissionService: DsvsPermissionService
  ) {
  }

  ngOnInit() {

    this.isEditable = this.isNew || this.permissionService.hasPermission([ZapPermissions.FES_REQUIREMENT_UPDATE]);

    if (this.isNew) {
      this.requirement = <Requirement>{
        displayName: '',
        description: ''
      };
      this.requirementHalItem = null;
      this._requirementHalItem = null;
    }

    this.loadVersions();

  }

  create() {
    this.requirementService.create(this.requirementHalItem.data).subscribe(
      result => {
        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: 'Die Anforderung wurde erstellt.'
        });
      }, error2 => {
        console.error(error2);
      });
  }

  fetchForms = ($event, inlineEdit): Observable<FormularHalPage> => {
    const subject: Subject<FormularHalPage> = new Subject<FormularHalPage>();
    this.workspaceService.getById(this.settings.getActiveWorkspaceId()).subscribe(
      workspace => {
        workspace.forms.setParams({
          searchTerm: $event.query
        }).async.subscribe(forms => {
          subject.next(forms);
        });
      });
    return subject;
  };

  selectForms = (value, component: InlineEditComponent): Observable<FormularHalPage> => {
    this.forms = component.modelValue;
    if (this.forms.length > 0) {
      if (!this.isNew) {
        return this.updateForms(this.forms);
      } else {
        return observableOf(null);
      }
    } else {
      this.messageService.add({
        severity: 'error',
        detail: 'Mindestens ein Formular muss gewählt werden.',
        summary: 'Fehler'
      });
      return observableOf(null);
    }
  };

  updateForms(forms: any): Observable<FormularHalPage> {
    if (forms) {
      const observable = new Subject<FormularHalPage>();
      this.requirementHalItem.updateRelations('forms', forms.map(o => o.data.id)).subscribe(
        result => {
          this.requirementHalItem.forms.reload();

          this.loadVersions();
          observable.next(result);
        }, error => {
          console.error(error);
        });
      return observable;
    } else {
      return observableOf();
    }
  }

  saveData = (value, component): Observable<any> => {
    if (this.isNew) {
      return observableOf(true);
    } else {
      return this._requirementHalItem.save(this.requirement);
    }
  };

  addFile(file) {
    this.file = file;
    if (!this.isNew) {
      this.requirementService.uploadFile(this.requirement, this.file).subscribe(
        result => {
          this.file = null;
          this.fileuploader.resetUploader();
          this.requirementHalItem.reload();
        },
        (err) => {
          getExceptionMessages(err).forEach(
            message => this.messageService.add(message)
          );
          this.fileuploader.resetUploader();
        }
      );
    }
  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  loadVersions() {

    if (this.isNew) {
      return;
    }

    const versionButtons: MenuItem[] = [];
    this.versionDownloadSplitButtonData = versionButtons;
    this.activeVersion = null;

    this.requirementHalItem.forms.async.subscribe(result => {
      if (result.content.length > 0) {
        const formular: FormularHalItem = result.content[0];
        formular.versions.async.subscribe(versions => {

          formular.activeVersion.async.subscribe(activeVersion => {
            if (activeVersion.data.id) {
              this.activeVersion = activeVersion;
            }
          });

          for (const version of versions.content) {
            versionButtons.push({
              label: version.data.displayName,
              icon: 'fa ui-icon-file-download',
              command: () => {
                this.downloadVersionWithBarcode(version);
              }
            });
          }
          this.versionDownloadSplitButtonData = versionButtons;
        });
      }
    });

    this.versionDownloadSplitButtonData = versionButtons;

  }

  deleteFile($event) {
    this.requirementService.deleteFile($event.linkData.href).subscribe(
      res => {
        this.requirementHalItem.reload();
      }
    );
  }

  downloadVersionWithBarcode(version: VersionHalItem) {
    this.scanpoolService.create(<Scanpool>{
      source: EScanpoolSource.VERSION,
      sourceId: version.data.id,
      target: EScanpoolTarget.REQUIREMENT,
      targetId: this.requirementHalItem.data.id
    }).subscribe(
      requirementHalItem => {
        this.scanpoolService.downloadWithBarcode(requirementHalItem.data);
      }
    );
  }

  downloadActiveVersionWithBarcode() {
    if (this.activeVersion) {
      this.downloadVersionWithBarcode(this.activeVersion);
    }
  }

  save = () => {
    if (this.forms.length > 0) {
      if (this.isNew) {
        return this.requirementService.create(this.requirement).subscribe(
          requirementHalItem => {

            this._requirementHalItem = requirementHalItem;

            this.updateForms(this.forms).subscribe(
              formularHalPage => {
                if (this.file) {
                  this.requirementService.uploadFile(requirementHalItem.data, this.file).subscribe(
                    file => {
                      this.onCreationSuccess();
                    },
                    (err) => {
                      getExceptionMessages(err).forEach(
                        message => this.messageService.add(message)
                      );
                      this.fileuploader.resetUploader();
                    }
                  );
                } else {
                  this.onCreationSuccess();
                }
              }
            );
          }, error => {
            console.error('Error creating workspace - {}', error);
            this.cancelModal();
          });
      } else {
        return this._requirementHalItem.save(this.requirement);
      }
    } else {
      this.messageService.add({
        severity: 'error',
        detail: 'Mindestens ein Formular muss gewählt werden.',
        summary: 'Fehler'
      });
    }
  };

  onCreationSuccess() {
    this.cancelModal();
    this.messageService.add({
      severity: 'success',
      summary: 'Erfolg',
      detail: 'Die Anforderung wurde erstellt.'
    });
  }

  cancelModal() {
    this.showEditModalEvent.emit(false);
  }
}
