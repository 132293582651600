import { Component, OnInit } from '@angular/core';
import { versionComparator } from '@dsvs/dsvs-shared-ui-lib';
import { ActionContextHalItem } from '@dsvs/workflow-generator-dto';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/components/common/messageservice';
import { Observable, Subscription } from 'rxjs';
import { CommunicationService } from '../../../../../services/communication-service';
import { DevVersionHalItem, DevVersionHalPage } from '../../../../shared/hal/devversion.hal.item';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { DevVersion } from '../../../../shared/interfaces/dtos/dev-version';
import { VersionDocument } from '../../../../shared/interfaces/dtos/documents/files/version-document';
import { ValidationDocument } from '../../../../shared/interfaces/dtos/documents/validation-document';
import { EDevelopmentState } from '../../../../shared/interfaces/dtos/enums/EDevelopmentState';
import { DevVersionServiceImpl } from '../../../../shared/services/dev-version.service';
import { ActionType } from '../../../enums/action-type.enum';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-workflow-action-devversion-finalize',
  templateUrl: './fes-workflow-action-devversion-finalize.component.html',
  styleUrls: ['./fes-workflow-action-devversion-finalize.component.scss']
})
export class FesWorkflowActionDevversionFinalizeComponent extends FesWorkflowActionComponent implements OnInit {

  publishModalVisible = false;
  loading = false;

  validationDocument: ValidationDocument;
  validationData: VersionDocument;
  validationResult: ValidationDocument = null;

  processHalItem: ProcessHalItem;

  devVersions: DevVersionHalPage;
  selectedDevVersion: DevVersion;
  releasedDevVersions: DevVersionHalItem[] = [];
  uploadedDevVersions: DevVersionHalItem[] = [];

  private actionSubscriptionRef: Subscription = null;

  constructor(
    messageService: MessageService,
    private devVersionService: DevVersionServiceImpl,
    private communicationService: CommunicationService
  ) {
    super(messageService);
  }

  ngOnInit() {
    this.loadData();
    this.subscribeActions();
  }

  loadData() {

    this.context.process.async.subscribe(
      processHalItem => {
        this.processHalItem = processHalItem;
        processHalItem.devversions.async.subscribe(
          devVersionHalPage => {
            this.devVersions = devVersionHalPage;
            this.uploadedDevVersions = this.findUploadedDevVersions(this.devVersions);
            if (this.uploadedDevVersions.length > 0) {
              this.selectedDevVersion = this.uploadedDevVersions[0].data;
            }
            this.releasedDevVersions = this.findReleasedDevVersions(this.devVersions);
          }
        );
      }
    );
  }

  subscribeActions() {
    this.actionSubscriptionRef = this.communicationService.messages.subscribe(
      (message) => {
        if (message.scope === 'ACTION') {

          switch (message.message) {
            case ActionType.DevVersionUpload: {
              this.processHalItem.devversions.reload();
              this.loadData();
              break;
            }
          }
        }
      }
    );
  }

  isValid() {
    return !!this.selectedDevVersion && !!this.validationData;
  }

  disableControls() {
    this.loading = true;
  }

  enableControls() {
    this.loading = false;
  }

  resetForm() {
    this.validationResult = null;
  }

  // Fake save for inline-edit
  save = () => {
    return Observable.of(this.selectedDevVersion);
  };

  findUploadedDevVersions(devVersions: DevVersionHalPage): DevVersionHalItem[] {
    return devVersions.content
    .filter(devVersionHalItem => devVersionHalItem.data.state === EDevelopmentState.UPLOADED)
    .sort((a, b) => versionComparator(a.data.version, b.data.version));
  }

  findReleasedDevVersions(devVersions: DevVersionHalPage): DevVersionHalItem[] {
    return devVersions.content
    .filter(devVersionHalItem => {
      return devVersionHalItem.data.state === EDevelopmentState.RELEASED;
    });
  }

  // 4) daten werden abgeschickt
  collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      devVersionId: this.selectedDevVersion.id,
      increaseMajor: this.validationData.increaseMajor,
      releaseComment: this.validationData.version.comment
    };
  }

  publishModalDisplayChange(isVisible: boolean) {
    this.publishModalVisible = isVisible;
  }

  // 3) das modal wird geschlossen, werte übernommen
  confirmModal(validationDocument: ValidationDocument) {
    this.validationDocument = validationDocument;
    this.validationData = (<VersionDocument>validationDocument.validationData);
    this.publishModalVisible = false;
  }

  resetDevVersion() {
    this.validationResult = null;
    this.validationDocument = null;
    this.validationData = null;
  }

  // 1) DevVersionId wird an Validate geschickt
  validateDevVersion(devVersion: DevVersion) {
    this.devVersionService.validateDevVersion(
      devVersion.id
    ).subscribe({
      next: this.validationSuccess.bind(this),
      error: this.validationError.bind(this)
    });
  }

  // 2) Als Result kommt ein validationDoc, wird gespeichert, modal angezeigt
  validationSuccess(result: ValidationDocument): void {
    this.validationResult = result;
    this.publishModalVisible = true;
  }

  validationError(error): void {
    const errormessage = error && error.error && error.error.errormessage ? error.error.errormessage : null;
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Validierungsfehler',
        detail: errormessage ? errormessage : 'Beim validieren der Datei ist ein Fehler aufgetreten.'
      }
    );
  }

  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
    this.resetDevVersion();
    this.selectedDevVersion = null;
    this.processHalItem.devversions.reload();
    this.loadData();
  }

  add() {
    this.saveAction();
  }
}
