import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActionHalItem, ActionHalPage, ContextHalItem} from '@dsvs/workflow-generator-dto';
import {WorkflowActionFactoryComponent, WorkflowActionServiceImpl} from '@dsvs/workflow-generator-ui-lib';
import {ActionType} from '../../../../fes-workflow/enums/action-type.enum';
import {WGStepContextHalItem} from '../../../../shared/hal/wfg/wgstepcontext.hal.item';
import {FileServiceImpl} from '../../../../shared/services/file.service';

@Component({
  selector: 'fes-process-detail-note',
  templateUrl: './process-detail-note.component.html',
  styleUrls: ['./process-detail-note.component.scss']
})
export class ProcessDetailNoteComponent implements OnInit {

  _currentStepContext: WGStepContextHalItem;
  _actionHalPage: ActionHalPage;

  noteAction: ActionHalItem;

  @ViewChild(WorkflowActionFactoryComponent) actionFactory: WorkflowActionFactoryComponent;

  @Input()
  set actions(actionHalPage: ActionHalPage) {
    this._actionHalPage = actionHalPage;
    if (actionHalPage) {
      this.findNoteAction(actionHalPage);
    }

    this.actionService.search(ActionType.NoteAdd, null, {name: 'byAlias'}).subscribe(result => {
      this.noteAction = result.content[0];
    }, error => {
      console.error(error);
    });
  }

  @Input()
  context: ContextHalItem;

  @Input()
  set currentStepContext(stepContext: WGStepContextHalItem) {
    this._currentStepContext = stepContext;
  }

  get currentStepContext(): WGStepContextHalItem {
    return this._currentStepContext;
  }

  constructor(private fileService: FileServiceImpl,
              private actionService: WorkflowActionServiceImpl) {
  }

  ngOnInit() {
  }

  findNoteAction(actionHalPage: ActionHalPage) {
    this.noteAction = actionHalPage.content.find(action => action.data.alias === ActionType.NoteAdd);
  }

  onDownloadClicked(id: string) {
    this.fileService.downloadFile(
      id
    );
  }

  onActionSuccess($event) {
    this._currentStepContext.notes.reload();
    this.actionFactory.resetActionData();
  }


}
