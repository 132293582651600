import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DsvsFormHelper } from '@dsvs/dsvs-shared-ui-lib';
import { ActionContextHalItem, ActionHalItem, StepContextHalItem } from '@dsvs/workflow-generator-dto';
import { WorkflowActionComponent } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/components/common/messageservice';
import { WGContextHalItem } from '../../../shared/hal/wfg/wgcontext.hal.item';

export abstract class FesWorkflowActionComponent extends WorkflowActionComponent<ActionHalItem,
  WGContextHalItem,
  StepContextHalItem> implements OnInit {

  inputForm: FormGroup;
  messageService: MessageService;

  public constructor(messageService: MessageService) {
    super();
    this.messageService = messageService;
  }

  ngOnInit() {

    this.inputForm = new FormGroup({});
  }

  validate() {
    DsvsFormHelper.validateAllFormFields(this.inputForm);
  }

  isValid(): boolean {
    if (this.inputForm) {
      return this.inputForm.valid;
    }
  }

  disableControls() {
    if (this.inputForm) {
      this.inputForm.disable();
    }
  }

  enableControls() {
    if (this.inputForm) {
      this.inputForm.enable();
    }
  }

  resetForm() {
    if (this.inputForm) {
      this.inputForm.reset();
    }
  }

  onSuccess(actionContext: ActionContextHalItem) {
    this.messageService.add({
      severity: 'success',
      summary: 'Aktion',
      detail: 'Aktion erfolgreich ausgeführt'
    });
  }

  onError(error: any) {
    this.messageService.add({
      severity: 'error',
      summary: 'Aktion',
      detail: 'Fehler beim Ausführen der Aktion'
    });
  }
}
