import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { DevVersion } from '../interfaces/dtos/dev-version';
import { FormularRelationItem } from './formular.hal.item';

export class DevVersionHalItem extends HalItemImpl<DevVersion> {
  constructor(data: DevVersion, client: HalClient, factory: HalClientFactory) {
    super(data, client, factory);
    this.formular = new FormularRelationItem('form', this);
  }

  readonly formular: FormularRelationItem;
}

export class DevVersionHalPage extends HalPageImpl<DevVersion, DevVersionHalItem> {

}

export class DevVersionRelationPage extends HalRelationPage<DevVersion, DevVersionHalItem, DevVersionHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, DevVersionHalPage, DevVersionHalItem);
  }
}

export class DevVersionRelationItem extends HalRelationItem<DevVersion, DevVersionHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, DevVersionHalItem);
  }
}
