import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {DsvsPage, DsvsPermissionService, DsvsTableColumn, FileUploadComponent, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable, Subject} from 'rxjs';
import {ZapPermissions} from '../../../../enums/zap-permissions.enum';
import {getExceptionMessages} from '../../../../helper/exception-helper';
import {FiReleaseDateHalItem} from '../../../shared/hal/fiReleaseDateHalItem';
import {ProcessHalItem, ProcessHalPage} from '../../../shared/hal/process.hal.item';
import {ServiceupdateHalItem} from '../../../shared/hal/serviceupdate.hal.item';
import {EServiceupdateState} from '../../../shared/interfaces/dtos/enums/EServiceupdateState';
import {Serviceupdate} from '../../../shared/interfaces/dtos/serviceupdate';
import {FiReleaseDateServiceImpl} from '../../../shared/services/fi-release-date.service';
import {FileServiceImpl} from '../../../shared/services/file.service';
import {ProcessServiceImpl} from '../../../shared/services/process.service';
import {ServiceupdateServiceImpl} from '../../../shared/services/serviceupdate.service';

@Component({
  selector: 'fes-serviceupdate-detail',
  templateUrl: './serviceupdate-detail.component.html',
  styleUrls: ['./serviceupdate-detail.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ServiceupdateDetailComponent implements OnInit {

  public ZapPermissions = ZapPermissions;

  @Input() isNew = false;
  @Input() showEditModal = false;

  @Output() showEditModalEvent = new EventEmitter<boolean>();

  serviceupdate: Serviceupdate;
  _serviceupdateHalItem: ServiceupdateHalItem;

  file: any;

  editProcesses = false;

  loading = false;
  processHalPage: ProcessHalPage;

  fiReleaseDate: FiReleaseDateHalItem;

  tableColumnOptions: DsvsTableColumn[] = [];

  EServiceupdateState = EServiceupdateState;

  isEditable = false;

  @ViewChild('fileuploader') fileuploader: FileUploadComponent;

  @Input()
  set serviceupdateHalItem(serviceupdateHalItem: ServiceupdateHalItem) {
    if (serviceupdateHalItem) {
      this.serviceupdate = serviceupdateHalItem.data;
      this._serviceupdateHalItem = serviceupdateHalItem;
      this.onReloadProcesses('');
    }
  }

  get serviceupdateHalItem(): ServiceupdateHalItem {
    return this._serviceupdateHalItem;
  }

  constructor(
    private serviceupdateService: ServiceupdateServiceImpl,
    public fiReleaseDateService: FiReleaseDateServiceImpl,
    private messageService: MessageService,
    private fileService: FileServiceImpl,
    private permissionService: DsvsPermissionService,
    private processService: ProcessServiceImpl
  ) {
  }

  ngOnInit() {
    this.isEditable = this.isNew || this.permissionService.hasPermission(
      [ZapPermissions.FES_SERVICEUPDATE_UPDATE]
    );

    if (this._serviceupdateHalItem && this._serviceupdateHalItem.data.status === EServiceupdateState.PUBLISHED) {
      this.isEditable = false;
    }

    if (this._serviceupdateHalItem == null || this.isNew) {
      this.serviceupdate = <Serviceupdate>{
        displayName: '',
        title: ''
      };
    }

    this.tableColumnOptions = [
      {field: 'group', header: ' ', format: 'TYPE', width: '5%'},
      {field: 'displayName', header: 'Formular', format: 'PRODUCT', width: '10%'},
      {field: 'displayName', header: 'Version / Fassung', format: 'RESULT_VERSION', width: '15%', centered: true},
      {field: 'displayName', header: 'Auftragsstatus', format: 'STEPNAME', width: '20%'},
      {field: 'displayName', header: 'Auftragsnummer', format: 'PROCESS_LINK', width: '10%'},
      {field: 'serviceupdateComment', header: 'Serviceupdatetext', format: 'EDITABLE', width: '30%'},
      {field: 'group', header: 'Gruppe', format: 'GROUP', width: '10%'}

    ];

  }

  editProcessesAllowed() {
    return this.serviceupdateHalItem
      && this.serviceupdateHalItem.data.locked
      && this.permissionService.hasPermission(
        [ZapPermissions.FES_SERVICEUPDATE_UPDATE]
      );
  }

  onReloadProcesses(searchTerm: string, page?: DsvsPage) {
    this.loading = true;
    this.serviceupdateHalItem.processes.reload();
    this.serviceupdateHalItem.processes
    .setParams({
      sort: [],
      size: page ? page.size : 10,
      page: page ? page.number : 0,
      query: searchTerm
    }).async.subscribe(
      result => {
        this.processHalPage = result;
        this.loading = false;
      }, error2 => {
        this.loading = false;
        console.error(error2);
      });
  }

  fetchProcesses = ($event, inlineEdit): Observable<ProcessHalPage> => {
    return this.processService.searchServiceupdateAvailable($event.query);
  };

  updateProcesses(value, component: InlineEditComponent): Observable<ProcessHalItem> {
    const serviceupdateHalItem = component.model;
    const processHalItems = component.modelValue;
    const sub: Subject<any> = new Subject();

    serviceupdateHalItem.processes.update(processHalItems).subscribe(value1 => {
      sub.next(value1);
    }, error1 => {
      console.error('error while updating serviceupdate - {}', error1);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: error1.data.message
      });
      sub.error(error1);

      serviceupdateHalItem.processes.reload();
    });
    return sub;
    /*
    const bla = serviceupdateHalItem.processes.update(processHalItems).subscribe(result => {
      return result;
    }, error1 => {
      console.error('error while updating serviceupdate - {}', error1);
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: error1.data.message
      });
      return null;
    });
    return bla;
     */
  }

  fetchFiReleaseDates = ($event, inlineEdit): Observable<any> => {
    return this.fiReleaseDateService.search($event.query);
  };

  selectFiReleaseDate = (value, component): Observable<any> => {
    this.fiReleaseDate = component.modelValue;
    this.serviceupdate.fiReleaseDateId = this.fiReleaseDate.data.id;
    if (this.isNew) {
      return Observable.of(null);
    } else {
      return (<FiReleaseDateHalItem>this.fiReleaseDate).serviceupdates.add(this._serviceupdateHalItem);
    }
  };

  saveData = (value, component): Observable<any> => {
    if (!this.isNew) {
      return this.serviceupdateHalItem.save();
    } else {
      return Observable.of(value);
    }
  };

  save() {
    if (this.isNew) {
      this.serviceupdateService.create(this.serviceupdate).subscribe(
        serviceupdateHalItem => {
          this.serviceupdateHalItem = serviceupdateHalItem;
          this.serviceupdate = serviceupdateHalItem.data;
          this.isNew = false;
          this.addFile(this.file, true);
        }, error2 => {
          console.error('error while creating serviceupdate - {}', error2);
          this.messageService.add({
            severity: 'error',
            summary: 'Erfolg',
            detail: 'Fehler beim erstellen.'
          });
        });
    }
  }

  deleteFile($event) {
    this.serviceupdateService.deleteFile($event.linkData.href).subscribe(
      requirement => {
        this.serviceupdateHalItem.reload();
      }
    );
  }

  addFile(file, closeModalAfterUpload) {
    this.file = file;
    if (!this.isNew && this.file) {
      this.serviceupdateService.uploadFile(this.serviceupdate, this.file).subscribe(
        result => {
          this.file = null;
          this.fileuploader.resetUploader();
          this.serviceupdateHalItem.reload();
          if (closeModalAfterUpload) {
            this.cancelModal();
          }
        },
        (err) => {
          getExceptionMessages(err).forEach(
            message => this.messageService.add(message)
          );
          this.fileuploader.resetUploader();
        }
      );
    }
  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  cancelModal() {
    this.showEditModal = false;
    this.showEditModalEvent.emit(false);
  }

  exportExcel() {
    this.serviceupdateService.downloadExport(this.serviceupdate.id);
  }

  saveRow = (value, component: InlineEditComponent): Observable<ServiceupdateHalItem> => {
    return component.model.save();
  };
}
