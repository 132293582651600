
export interface SelectOptions {
  value: any;
  label: string;
}

export function createSelectOptions(enumType): SelectOptions[] {
  return Object.keys(enumType).map(k => ({
  label: k,
  value: k
}));
}

export function createActiveOptions(): SelectOptions[] {
  return [
  {
    label: 'aktiv',
    value: true
  },
  {
    label: 'inaktiv',
    value: false
  }
];
}


export function createMajorMinor(): SelectOptions[] {
  return [
  {
    label: 'major',
    value: true
  },
  {
    label: 'minor',
    value: false
  }
];
}

