import { Base } from './base';
import { EDynamisationTypes } from './enums/EDynamisationTypes';

export class XdpData extends Base {

  constructor(
    public articleNumber: string,
    public title: string,
    public fassung: string,
    public owner: string,
    public versionHash: string,
    public version: string,
    public attachment: boolean,
    public dynamisation: EDynamisationTypes,
    public shape: string,
    public countCopy: number,
    public copyRight: string,
    public signature: boolean,
    public textModule: boolean
  ) {
    super();
  }
}
