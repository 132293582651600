import { Pipe, PipeTransform } from '@angular/core';
import { EDevelopmentState } from '../interfaces/dtos/enums/EDevelopmentState';

@Pipe({
  name: 'devstatus'
})
export class DevStatusPipe implements PipeTransform {

  /** Pipe um den Entwicklungstatus darzustellen
   * @param {string} value
   * @returns {string}
   */
  transform(value: String): string {
    switch (value) {
      case EDevelopmentState.NEW:
        return 'in Arbeit';
      case EDevelopmentState.UPLOADED:
        return 'hochgeladen';
      case EDevelopmentState.CANCELED:
        return 'storniert';
      case EDevelopmentState.RELEASED:
        return 'finalisiert';
    }
  }
}
