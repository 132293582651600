import { Pipe, PipeTransform } from '@angular/core';
import { EScanpoolState } from '../../shared/interfaces/dtos/enums/EScanpoolState';

@Pipe({
  name: 'scanpoolStateName'
})
export class ScanpoolStateNamePipe implements PipeTransform {

  transform(value: EScanpoolState): string {
    switch (value) {
      case EScanpoolState.ASSIGNED:
        return 'Zugewiesen';
      case EScanpoolState.UNASSIGNED:
        return 'Nicht zugewiesen';
      case EScanpoolState.VIRUS:
        return 'Virus';
      case EScanpoolState.CREATED:
        return 'Neu';
      default:
        return 'Unbekannt';
    }
  }
}
