/* tslint:disable:max-line-length */
import { Injectable } from '@angular/core';
import { WorkflowTypeMappingService } from '@dsvs/workflow-generator-ui-lib';
import { WGContextHalItem, WGContextHalPage } from '../../shared/hal/wfg/wgcontext.hal.item';
import { WGStepContextHalItem, WGStepContextHalPage } from '../../shared/hal/wfg/wgstepcontext.hal.item';

/* tslint:enable:max-line-length */

@Injectable({
  providedIn: 'root'
})
export class FesWorkflowTypeMappingService {
  constructor(private workflowTypeMappingService: WorkflowTypeMappingService) {

  }

  registerTypes() {
    this.workflowTypeMappingService.overwriteType(WorkflowTypeMappingService.WorkflowContext, WGContextHalItem, WGContextHalPage);
    this.workflowTypeMappingService.overwriteType(WorkflowTypeMappingService.WorkflowStepContext, WGStepContextHalItem,
      WGStepContextHalPage);
  }
}
