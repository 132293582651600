import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileUpload, MessageService } from 'primeng/primeng';

@Component({
  selector: 'fes-variable-max-file-size-uploader',
  templateUrl: './fes-variable-max-file-size-uploader.component.html',
  styleUrls: ['./fes-variable-max-file-size-uploader.component.scss']
})
export class FesVariableMaxFileSizeUploaderComponent {

  /* Input Variablen */
  @Input() messageService: MessageService;
  @Input() maxFileSize = '10000000';
  @Input() isValidating = false;
  @Input() isLoading = false;
  @Input() accept = '.xdp,.XDP,.pdf,.PDF,.docx,.DOCX,.dotx,.DOTX,.zip,.ZIP';

  /* Output Variabeln*/
  @Output() fileChanged = new EventEmitter<any>();
  @Output() fileRemoved = new EventEmitter<any>();

  /* Child Komponente */
  @ViewChild('fileuploader') fileuploader: FileUpload;


  /* Speicher für Fehlermeldungen welche innerhalb der Komponente angezeigt werden sollen  */
  errors = [];


  /** Lädt ein File und prüft sowohl das Fileformat, sowie die Größe des Files */
  onFileChange(target) {
    /* Setzt die Liste mit Fehlermeldungen zurück */
    this.errors = [];

    /* Bricht den Methodenaufruf ab, falls vom Fileuploader keine Datei übergeben wurde */
    if (target.files.length <= 0) {
      return;
    }

    /* Referenziert das Erste und in unserem Fall einzige File aus welches vom Uploader übergeben wird */
    const file = target.files[0];

    /* Prüft das Dateiformat */
    if (!this.checkFileNames(file.name)) {
      this.onFileRemoved();
      this.createErrorMessage(
        file.name + ': Falscher Dateityp',
        'Erlaubte Dateiendungen: ' + this.accept
      );
      return;
    }
    /* Prüft die Dateigröße */
    if (file.size > this.maxFileSize) {
      this.onFileRemoved();
      this.createErrorMessage(
        file.name + ': Falsche Dateigröße',
        'Maximale Dateigröße: ' + this.maxFileSize + ' byte'
      );
      return;
    }

    /* Feuert ein fileChanged Event mit der vom Uploader bereitgestellten Datei */
    if (this.fileChanged != null) {
      this.fileChanged.emit(file);
    }

  }

  /** Bringt den FileUploader in seinen Ursprungszustand */
  resetFileUploader() {
    this.fileuploader.clear();
  }

  /** Feuert ein FileRemoved Event */
  onFileRemoved() {
    this.fileRemoved.emit();
  }

  // TODO: (Niki) Evtl eine richtige formatsüberprufung einbauen, diese wurde von XDPFileUploadComponent übernommen
  /** Gibt true zurück, wenn der Uploader das Dateiformat unterstützt */
  private checkFileNames(fileName) {
    /* Ergebnis muss ausgelagert werden, da einfaches return true nur die anonyme innere Funktion verlassen würde
    und die foreach weiter aufgerufen würde */
    let isValid = false;
    /* Iteriert über alle akzeptierten Dateiformate und prüft ob diese im Dateinamen enthalten sind */
    this.accept.split(',').forEach(
      extension => {
        if (fileName.includes(extension)) {
          /* return true würde nur anonyme Funktion verlassen, siehe Kommentar zu isValid*/
          isValid = true;
        }

      }
    );
    return isValid;
  }

  /** Übergibt die Fehlermeldung an den externen Messageservice, falls kein externer Messageservice angemeldet
   * wurde, wird die Fehlermeldung in einem internen Fehlerfenster angezeigt */
  private createErrorMessage(summary: string, detail: string) {
    if (this.messageService) {
      this.messageService.add({
        severity: 'error',
        summary: summary,
        detail: detail
      });
    } else {
      this.errors.push({
        severity: 'error',
        summary: summary,
        detail: detail
      });
    }
  }
}
