import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DsvsPermissionService, DsvsTableColumn, InlineEditComponent, TableComponent} from '@dsvs/dsvs-shared-ui-lib';
import {HalItem, HalPage, Page} from '@dsvs/hal-client';
import {CrewHalItem} from '@dsvs/workflow-generator-dto';
import * as forOwn from 'lodash/forOwn';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ZapPermissions} from '../../../enums/zap-permissions.enum';
import {RepositoryService} from '../../../services/repository-service';
import {FiReleaseDateHalItem, FiReleaseDateHalPage} from '../../shared/hal/fiReleaseDateHalItem';
import {FormularHalPage} from '../../shared/hal/formular.hal.item';
import {ProcessHalPage} from '../../shared/hal/process.hal.item';
import {ServiceupdateHalPage} from '../../shared/hal/serviceupdate.hal.item';
import {WorkspaceHalItem, WorkspaceHalPage} from '../../shared/hal/workspace.hal.item';
import {EGroupTypes} from '../../shared/interfaces/dtos/enums/EGroupTypes';
import {FesUserSettingsService} from '../../shared/services/fes-user-settings.service';
import {FiReleaseDateServiceImpl} from '../../shared/services/fi-release-date.service';
import {FormServiceImpl} from '../../shared/services/form.service';
import {ProcessServiceImpl} from '../../shared/services/process.service';
import {RequirementServiceImpl} from '../../shared/services/requirement.service';
import {SearchResultMap, SearchServiceImpl} from '../../shared/services/search.service';
import {ServiceupdateServiceImpl} from '../../shared/services/serviceupdate.service';
import {VersionServiceImpl} from '../../shared/services/version.service';
import {WorkspaceServiceImpl} from '../../shared/services/workspace.service';

@Component({
  selector: 'fes-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @ViewChild('processTable') processTable: TableComponent;
  @ViewChild('serviceupdateTable') serviceupdateTable: TableComponent;
  @ViewChild('formsTable') formsTable: TableComponent;

  searchValue = '';
  searchFormsOnly = false;
  results: Map<String, any[]>;
  resultsAmounts: Map<String, number>;
  selectedResults: any[];

  forms: FormularHalPage;
  processes: ProcessHalPage;
  serviceupdates: ServiceupdateHalPage;

  tableColumnOptions: DsvsTableColumn[] = [];
  tableColumnOptionsForms: DsvsTableColumn[] = [];
  tableColumnOptionsProcesses: DsvsTableColumn[] = [];
  tableColumnOptionsServiceupdates: DsvsTableColumn[] = [];

  displayedCrew: CrewHalItem;

  isWorkspaceEditable = false;
  isGroupEditable = false;
  isG2Editable = true;

  selectedEntry: any;
  selectedType: string;
  selectedNavigationRoute: any[];

  showAllForms: boolean;
  showAllRequirements: boolean;
  searchUpdateSubject = new Subject<string>();

  constructor(private formService: FormServiceImpl, private route: ActivatedRoute,
    private searchService: SearchServiceImpl, private requirementService: RequirementServiceImpl,
    private serviceUpdateService: ServiceupdateServiceImpl, private processService: ProcessServiceImpl,
    private repositoryService: RepositoryService, private versionService: VersionServiceImpl,
    public workspaceService: WorkspaceServiceImpl, private permissionService: DsvsPermissionService,
    public fiReleaseDateService: FiReleaseDateServiceImpl,
    private router: Router,
    private fesSettings: FesUserSettingsService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.searchValue = params.value;
      this.updateSearch();
    });

    this.fesSettings.onActiveWorkspaceChanged().subscribe(result => {
      this.updateSearch();
    });

    this.tableColumnOptionsForms = [
      {field: 'active', header: '', format: 'ACTIVE_VERSION_ACTIVE', sort: true, width: '5%'},
      {field: 'articleNumber', header: 'Nummer', sort: true, width: '10%'},
      {field: 'title', header: 'Titel', format: 'ACTIVE_VERSION', width: '30%'},
      {field: 'fassung', header: 'Fassung', format: 'ACTIVE_VERSION', width: '15%', centered: true},
      {field: 'version', header: 'Version', format: 'ACTIVE_VERSION', width: '10%', centered: true},
      {field: 'displayName', header: 'Verantwortliche', format: 'CREW', width: '10%', centered: true},
      {field: 'group', header: 'Gruppe', format: 'GROUP', width: '10%', centered: true},
      {field: 'download', header: '', format: 'DOWNLOAD', width: '10%', centered: true}
    ];

    this.tableColumnOptionsProcesses = [
      {field: '', header: 'Typ', sort: true, filter: '', hidden: false, format: 'CHANGETYPE', width: '4%'},
      {field: 'displayName', header: 'Auftrag', sort: true, filter: '', hidden: false, format: 'NAME', width: '14%'},
      {field: '', header: 'Formular', sort: false, filter: '', hidden: false, format: 'PRODUCT', width: '12%'},
      {field: '', header: 'Status', sort: false, filter: '', hidden: false, format: 'STEPNAME', width: '20%'},
      {field: 'returndate', header: 'Rückgabe', sort: true, filter: '', hidden: false, format: 'RETURNDATE', width: '10%'},
      {field: '', header: 'Dyn.', sort: false, filter: '', hidden: false, format: 'DYNAMISATION', width: '6%'},
      {field: '', header: 'Serviceupdate', sort: false, filter: '', hidden: false, format: 'SU', width: '10%'},
      {field: '', header: 'Bearbeiter', sort: false, filter: '', hidden: false, format: 'EDITORS', width: '14%'},
      {field: 'lastModifiedDate', header: 'Datum', sort: true, filter: '', hidden: false, format: 'DATE_TIME', width: '10%'}
    ];

    this.tableColumnOptionsServiceupdates = [
      {field: 'locked', header: '', width: '5%', format: 'LOCKED'},
      {field: 'displayName', sort: true, header: 'Nummer', width: '10%'},
      {field: 'publicationDate', header: 'Veröffentlichung', sort: true, format: 'DATE', centered: true, width: '10%'},
      {field: 'displayName', header: 'G2-Termin', sort: true, format: 'G2DATE', width: '10%'},
      {field: 'title', header: 'Titel', sort: true, width: '45%'},
      {field: 'status', header: 'Status', sort: true, format: 'STATE', width: '10%'},
      {field: '', header: 'Aufträge', format: 'PROCESSES', centered: true, width: '10%'}
    ];

    this.isWorkspaceEditable = this.permissionService.hasPermission([ZapPermissions.FES_FORM_WORKSPACE_UPDATE]);
    this.isGroupEditable = this.permissionService.hasPermission([ZapPermissions.FES_FORM_FIGROUP_UPDATE]);
    this.isG2Editable = this.permissionService.hasPermission([ZapPermissions.FES_SERVICEUPDATE_UPDATE]);

    this.searchUpdateSubject.pipe(
      debounceTime(400),
      distinctUntilChanged())
    .subscribe(value => {
      this.updateSearch();
    });
  }

  onOpenDetailClick() {
    this.router.navigate(this.selectedNavigationRoute);
  }

  onEntrySelected(entry: any) {
    this.selectedType = entry.type;
    this.selectedResults = this.results.get(this.selectedType);

    this.tableColumnOptions = [
      {field: 'id', header: 'ID'},
      {field: 'displayName', header: 'Name'}
    ];

    if (entry.type === 'FORMS') {
      this.reloadFormsData(this.searchValue);
    } else if (entry.type === 'PROCESSES') {
      this.reloadProcessesData(this.searchValue);
    } else if (entry.type === 'SERVICE_UPDATES') {
      this.reloadServiceupdatesData(this.searchValue);
    }

  }

  onShowAllForms(showAll: boolean) {
    this.showAllForms = true;
  }

  onShowAllRequirements(showAll: boolean) {
    this.showAllRequirements = true;
  }

  updateSearch() {
    this.selectedEntry = null;

    let types = ['FORMS', 'PROCESSES', 'SERVICE_UPDATES'];

    if (this.searchFormsOnly) {
      types = ['FORMS'];
    }

    this.searchService.searchByType(this.searchValue, types).subscribe((result: SearchResultMap) => {
      const results = new Map();
      const resultsAmounts = new Map();

      forOwn(result, (value: HalPage<any, HalItem<any>>, key: string) => {
        results.set(key, value.content);
        resultsAmounts.set(key, value.getTotalElements());
      });

      this.results = results;
      this.resultsAmounts = resultsAmounts;
    }, error => {
      console.error(error);
    });

  }

  reloadFormsData(searchTerm: string, page?: Page) {
    this.searchService.searchForms(searchTerm, page).subscribe(result => {
      this.forms = result;
    }, err => {
      console.error(err);
    });
  }

  reloadProcessesData(searchTerm: string, page?: Page) {
    this.searchService.searchProcesses(searchTerm, page).subscribe(result => {
        this.processes = result;
      },
      err => {
        console.error(err);
      });
  }

  reloadServiceupdatesData(searchTerm: string, page?: Page) {
    this.searchService.searchServiceupdates(searchTerm, page).subscribe(result => {
        this.serviceupdates = result;
      },
      err => {
        console.error(err);
      });
  }

  fetchWorkspaces = ($event, inlineEdit): Observable<WorkspaceHalPage> => {
    return this.workspaceService.search($event.query);
  };

  updateWorkspace(workspaceHalItem: WorkspaceHalItem, component: InlineEditComponent): Observable<WorkspaceHalItem> {
    const form = component.model;
    return workspaceHalItem.forms.add(form);
  }

  fetchGroups = (event): Observable<any[]> => {
    return Observable.of(
      [
        {label: '-', value: EGroupTypes.NONE},
        {label: EGroupTypes.G1, value: EGroupTypes.G1},
        {label: EGroupTypes.G2, value: EGroupTypes.G2}
      ]);
  };

  updateGroup(value, component: InlineEditComponent): Observable<EGroupTypes> {
    component.model.activeVersion.sync.data.group = component.modelValue;
    return component.model.activeVersion.sync.save(component.model.activeVersion.sync.data);
  }

  showCrew(event: MouseEvent, crew: CrewHalItem) {
    event.stopPropagation();
    this.displayedCrew = crew;
  }

  receiveHideCrewModal($event) {
    this.displayedCrew = null;
  }

  fetchFiReleaseDates = ($event, inlineEdit): Observable<FiReleaseDateHalPage> => {
    return this.fiReleaseDateService.search($event.query);
  };

  updateFiReleaseDate = (value, component: InlineEditComponent): Observable<FiReleaseDateHalItem> => {
    return (<FiReleaseDateHalItem>value).serviceupdates.add(component.model);
  };

  toggleFormsOnly() {
    this.searchFormsOnly = !this.searchFormsOnly;
    this.updateSearch();
  }

}
