import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DsvsPermissionService} from '@dsvs/dsvs-shared-ui-lib';
import {getExceptionMessages} from '../../../../../helper/exception-helper';
import {MessageService} from 'primeng/api';
import {ExternRecord} from '../../../../shared/interfaces/dtos/extern-record';
import {ExternRecordServiceImpl} from '../../../../shared/services/extern-record.service';
import {ExternRecordTableComponent} from './extern-record-table/extern-record-table.component';
import {FesVariableMaxFileSizeUploaderComponent} from '../../../../shared/components/fes-variable-max-file-size-uploader/fes-variable-max-file-size-uploader.component';
import {ZapPermissions} from '../../../../../enums/zap-permissions.enum';
import {FormularHalItem} from '../../../../shared/hal/formular.hal.item';

@Component(
  {
    selector: 'fes-extern-record',
    templateUrl: './extern-record.component.html',
    styleUrls: ['./extern-record.component.scss']
  }
)

export class ExternRecordComponent implements OnInit {

  static readonly SYS_EXTERN_RECORD_DELETE_TOKEN = 'bf6xA0MGhjUjLFivguDCtbk3uvsiUqAM';
  /** Flags */
  isLoading = false;

  /* Referenzen auf Zugriffsrechte */
  ZapPermissions = ZapPermissions;
  @Input()
  formularHalItem: FormularHalItem;
  /* Komponenten Output */
  @Output() showEditModalEvent = new EventEmitter<boolean>();
  /* Kindelemente*/
  @ViewChild('fileuploader') fileuploader: FesVariableMaxFileSizeUploaderComponent;
  @ViewChild('exrectable') externRecordTable: ExternRecordTableComponent;


  constructor(private externRecordService: ExternRecordServiceImpl,
              private messageService: MessageService,
              public permissionService: DsvsPermissionService
  ) {
  }

  ngOnInit(): void {
  }

  /** Gibt eine Fehlermeldungen an den Messageservice weiter, welcher diese dann im oberen Rechten Bildschirmbereich
   * anzeigt und setzt die Uploadkomponente zurück */
  handleError(err: any) {
    getExceptionMessages(err).forEach(
      message => this.messageService.add(message)
    );
    this.fileuploader.resetFileUploader();
  }


  /** Erstellt eine neue ExternRecord Entity und lädt das zugehörige File auf den Server*/
  addFile(file: File) {
    /* Prüft ob von der Uploadkomponente ein File mitgegben wurde */
    if (
      file
    ) {
      /* Setzt den Komponentenstatus auf Laden*/
      this.isLoading = true;
      this.externRecordTable.isLoading = true;
      /* Erstellt einen neuen ExternRecord im Backend. Der Name des ExternRecord entspricht standardmäßig dem Namen
      des hochgeladenem Files, kann aber später geändert werden */
      const externRecord = new ExternRecord(file.name, this.formularHalItem.data.id, null);
      this.externRecordService.create(externRecord).subscribe(
        result => {
          const createdExternRecord = result.getData();
          /* lädt das File auf den Server und erstellt innerhalb der Datenbank die Verknúpfung zwischen ExternRecord
          und zugehörigem File */
          this.externRecordService.uploadSingleFile(createdExternRecord, file).subscribe(
            uploadResult => {
              this.reloadComponent();
            },
            (err) => {
              this.rollbackExternRecord(createdExternRecord);
              this.handleError(err);
            }
          );
        },
        (err) => {
          this.handleError(err);
        }
      );
    }
  }

  /** Löscht den erstellten ExternRecord auf dem Server, falls bspw. beim Fileupload ein Fehler aufgetreten ist */
  rollbackExternRecord(externRecordToDelete: ExternRecord) {
    this.externRecordService.delete(externRecordToDelete.id, ExternRecordComponent.SYS_EXTERN_RECORD_DELETE_TOKEN).subscribe(
      res => {
        this.reloadComponent();
      },
      (err) => {
        this.handleError(err);
      });
  }

  /** Setztden FileUploader zurück und ládt die Tabellendaten neu */
  private reloadComponent() {
    this.fileuploader.resetFileUploader();
    this.externRecordTable.loadVersions();
    this.isLoading = false;
  }
}
