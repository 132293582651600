import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormularHalItem } from '../../../../shared/hal/formular.hal.item';
import { FormServiceImpl } from '../../../../shared/services/form.service';

export enum EDeleteType {
  SOFT,
  HARD
}

@Component({
  selector: 'fes-formular-delete-modal',
  templateUrl: './formular-delete.component.html',
  styleUrls: ['./formular-delete.component.scss']
})
export class FormularDeleteComponent implements OnInit, OnDestroy {

  cancelDevVersionForm: FormGroup;
  loading = false;

  deleteType: EDeleteType = null;
  EDeleteType = EDeleteType;

  @Input()
  formular: FormularHalItem;

  @Input()
  visible = false;

  @Output()
  displayChange = new EventEmitter();

  @Output()
  formDeleted = new EventEmitter<EDeleteType>();

  securityTokenInput = '';

  constructor(private formService: FormServiceImpl,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
  }

  onClose() {
    // this.resetForms();
    this.displayChange.emit(false);
  }

  onAskForDelete() {
    // this.confirmationService.confirm({
    //   header: 'Wirklich löschen',
    //   message: 'Sind Sie sich sicher, dass sie das Formular <' + this.deleteType + '> löschen wollen?',
    //   acceptLabel: 'Löschen',
    //   rejectLabel: 'Abbrechen',
    //   accept: () => {
    this.onPerformDelete();
    //   }
    // });
  }

  onPerformDelete() {

    if (this.deleteType === EDeleteType.SOFT) {

      this.formService.deleteForm(this.formular.data).subscribe(result => {

        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: 'Das Formular ' + this.formular.data.articleNumber + ' wurde gelöscht'
        });

        if (this.formDeleted) {
          this.formDeleted.emit(this.deleteType);
        }

      }, error => {

        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: 'Das Formular ' + this.formular.data.articleNumber + ' konnte nicht gelöscht werden'
        });
      });

    } else if (this.deleteType === EDeleteType.HARD) {

      this.formService.deleteFormHard(this.formular.data, this.securityTokenInput).subscribe(result => {

        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: 'Das Formular ' + this.formular.data.articleNumber + ' wurde gelöscht'
        });

        if (this.formDeleted) {
          this.formDeleted.emit(this.deleteType);
        }

      }, error => {

        let errormsg = 'Das Formular ' + this.formular.data.articleNumber + ' konnte nicht gelöscht werden';

        if (error.error && error.error.logref) {
          switch (error.error.logref) {
            case 'SECURITY_TOKEN_INVALID':
              errormsg = 'Der Sicherheitstoken ist ungültig';
              break;
          }
        }

        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: errormsg
        });
      });

    }

  }

  ngOnDestroy() {
    this.displayChange.unsubscribe();
  }

  ngOnInit() {
  }

  toggleRadio($event, type) {
    this.deleteType = type;
  }
}
