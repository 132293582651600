import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {DsvsSort} from '@dsvs/dsvs-shared-ui-lib';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FormularHalPage} from '../../../../shared/hal/formular.hal.item';
import {FormServiceImpl} from '../../../../shared/services/form.service';
import {Suggestion} from '../../Suggestion';
import {FormularInformationService} from '../list/formular-information.service';
import {FesUserSettingsService} from '../../../../shared/services/fes-user-settings.service';

@Component({
  selector: 'fes-formular-search',
  templateUrl: './formular-search.component.html',
  styleUrls: ['./formular-search.component.scss']
})
export class FormularSearchComponent implements OnInit, OnDestroy {

  searchChangeObserver = new EventEmitter<string>();

  formularPage: FormularHalPage;

  searchTerm = '';

  sort: DsvsSort[];

  isLoading = false;

  suggestions: Suggestion[] = [];

  selectedSuggestion: Suggestion = null;

  @Output()
  selectedResult: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formularService: FormServiceImpl,
    private router: Router,
    private formInformationService: FormularInformationService,
    private settings: FesUserSettingsService
  ) {
    this.getAllFormulare(
      this.searchTerm
    );

  }

  ngOnInit() {
    this.searchChangeObserver.subscribe(this.onDebouncedSearch.bind(this));
  }

  ngOnDestroy() {
    this.searchChangeObserver.unsubscribe();
  }

  search(searchTerm: string) {
    if (!this.searchChangeObserver) {
      Observable.create(observer => {
        this.searchChangeObserver = observer;
      }).pipe(debounceTime(300)) // wait 300ms after the last event before emitting last event
      .pipe(distinctUntilChanged()); // only emit if value is different from previous value
    }

    this.searchChangeObserver.next(searchTerm);
  }

  onDebouncedSearch(searchTerm: string) {
    if (searchTerm != null) {
      this.searchTerm = searchTerm;
    }
    this.getAllFormulare(
      this.searchTerm
    );
  }

  onSelectForm() {
    this.router.navigate([this.formInformationService.formUrl + this.selectedSuggestion.id]).then(() => {
      this.selectedResult.emit();
    });
  }

  getAllFormulare(query: string) {
    this.isLoading = true;

    if (this.formInformationService.showWorkspaceFormsOnly) {
      this.formularService.searchCurrentWorkspace(query, this.settings.getActiveWorkspaceId()).subscribe((result) => {
        this.formularPage = result;
        this.suggestions = result.content.map(
          item =>
            ({
              id: item.data.id,
              displayName: item.data.displayName
            })
        );
        this.isLoading = false;
      });
    } else {

      this.formularService.search(query)
        .subscribe((result) => {
          this.formularPage = result;
          this.suggestions = result.content.map(
            item =>
              ({
                id: item.data.id,
                displayName: item.data.displayName
              })
          );
          this.isLoading = false;
        });
    }
  }
}

