import { Component, Input } from '@angular/core';
import { DevVersionHalItem } from '../../hal/devversion.hal.item';
import { EDevelopmentState } from '../../interfaces/dtos/enums/EDevelopmentState';
import { FesStatusComponent } from '../fes-status/fes-status.component';

@Component({
  selector: 'fes-devversion-status',
  templateUrl: './fes-devversion-status.component.html',
  styleUrls: ['./fes-devversion-status.component.scss']
})
export class FesDevversionStatusComponent {

  color: string = FesStatusComponent.COLOR_UNKNOWN;
  tag: string = null;
  _devversion: DevVersionHalItem = null;
  _state: EDevelopmentState = null;

  @Input() set version(version: DevVersionHalItem) {
    this._devversion = version;
    this.state = version.data.state;
  }

  get version(): DevVersionHalItem {
    return this._devversion;
  }

  @Input() set state(state: EDevelopmentState) {
    switch (state) {
      case EDevelopmentState.NEW:
        this.color = FesStatusComponent.COLOR_BLUE;
        this.tag = 'in Arbeit';
        break;
      case EDevelopmentState.UPLOADED:
        this.color = FesStatusComponent.COLOR_YELLOW;
        this.tag = 'hochgeladen';
        break;
      case EDevelopmentState.CANCELED:
        this.color = FesStatusComponent.COLOR_RED;
        this.tag = 'storniert';
        break;
      case EDevelopmentState.RELEASED:
        this.color = FesStatusComponent.COLOR_GREEN;
        this.tag = 'finalisiert';
        break;
    }
  }

  get state(): EDevelopmentState {
    return this._state;
  }

}
