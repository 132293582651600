import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DsvsPermissionService, FileUploadComponent} from '@dsvs/dsvs-shared-ui-lib';
import {MenuItem} from 'primeng/api';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable} from 'rxjs';
import {getExceptionMessages} from '../../../../../../helper/exception-helper';
import {FormularHalItem} from '../../../../../shared/hal/formular.hal.item';
import {ManuscriptHalItem} from '../../../../../shared/hal/manuscript.hal.item';
import {VersionHalItem} from '../../../../../shared/hal/version.hal.item';
import {Manuscript} from '../../../../../shared/interfaces/dtos/manuscript';
import {FesUserSettingsService} from '../../../../../shared/services/fes-user-settings.service';
import {FileServiceImpl} from '../../../../../shared/services/file.service';
import {FormServiceImpl} from '../../../../../shared/services/form.service';
import {ManuscriptServiceImpl} from '../../../../../shared/services/manuscript.service';
import {ScanpoolServiceImpl} from '../../../../../shared/services/scanpool.service';
import {WorkspaceServiceImpl} from '../../../../../shared/services/workspace.service';

@Component({
  selector: 'fes-process-detail-manuscripts-detail',
  templateUrl: './process-detail-manuscripts-detail.component.html',
  styleUrls: ['./process-detail-manuscripts-detail.component.scss']
})
export class ProcessDetailManuscriptsDetailComponent implements OnInit {

  file: any;
  manuscript: Manuscript;
  forms: FormularHalItem[] = [];
  isEditable = false;
  versionDownloadSplitButtonData: MenuItem[];
  activeVersion: VersionHalItem = null;
  @Output() showEditModalEvent = new EventEmitter<boolean>();
  @ViewChild('fileuploader') fileuploader: FileUploadComponent;

  constructor(
    private manuscriptService: ManuscriptServiceImpl,
    private workspaceService: WorkspaceServiceImpl,
    private scanpoolService: ScanpoolServiceImpl,
    private settings: FesUserSettingsService,
    private messageService: MessageService,
    private formService: FormServiceImpl,
    private fileService: FileServiceImpl,
    private permissionService: DsvsPermissionService
  ) {
  }

  _manuscriptHalItem: ManuscriptHalItem;

  get manuscriptHalItem(): ManuscriptHalItem {
    return this._manuscriptHalItem;
  }

  @Input()
  set manuscriptHalItem(manuscriptHalItem: ManuscriptHalItem) {
    if (manuscriptHalItem) {
      this.manuscript = manuscriptHalItem.data;
      this._manuscriptHalItem = manuscriptHalItem;
    }
  }

  ngOnInit() {
    this.isEditable = false;

    this.manuscriptService.canEdit(this.manuscript.id).subscribe(result => {
      if (result) {
        this.isEditable = true;
      }
    });

  }

  create() {
    this.manuscriptService.create(this.manuscriptHalItem.data).subscribe(
      result => {
        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: 'Die Anforderung wurde erstellt.'
        });
      }, error2 => {
        console.error(error2);
      });
  }

  saveData = (value, component): Observable<any> => {
    this._manuscriptHalItem.save(this.manuscript).subscribe((result: ManuscriptHalItem) => {
      this.manuscriptHalItem = result;
    });
    return Observable.of(null);
  };

  addFile(file) {
    this.file = file;
    this.manuscriptService.uploadFile(this.manuscript, this.file).subscribe(
      result => {
        this.file = null;
        this.fileuploader.resetUploader();
        this.manuscriptHalItem.reload();
        this.manuscript = this.manuscriptHalItem.data;
      },
      (err) => {
        getExceptionMessages(err).forEach(
          message => this.messageService.add(message)
        );
        this.fileuploader.resetUploader();
      }
    );
  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  deleteFile($event) {
    this.manuscriptService.deleteFile($event.linkData.href).subscribe(
      res => {
        this.manuscriptHalItem.reload();
      }
    );
  }

  save = () => {
    if (this.forms.length > 0) {
      return this.manuscriptService.create(this.manuscript).subscribe(
        manuscriptHalItem => {

          this._manuscriptHalItem = manuscriptHalItem;

          // this.updateForms(this.forms).subscribe(
          //   formularHalPage => {
          //     if (this.file) {
          //       this.manuscriptService.uploadFile(manuscriptHalItem.data, this.file).subscribe(
          //         file => {
          //           this.onCreationSuccess();
          //         },
          //         (err) => {
          //           getExceptionMessages(err).forEach(
          //             message => this.messageService.add(message)
          //           );
          //           this.fileuploader.resetUploader();
          //         }
          //       );
          //     } else {
          //       this.onCreationSuccess();
          //     }
          //   }
          // );
        }, error => {
          console.error('Error creating workspace - {}', error);
          this.cancelModal();
        });
    } else {
      this.messageService.add({
        severity: 'error',
        detail: 'Mindestens ein Formular muss gewählt werden.',
        summary: 'Fehler'
      });
    }
  };

  cancelModal() {
    this.showEditModalEvent.emit(false);
  }
}
