import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {germanCalenderTemplate} from '@dsvs/dsvs-shared-ui-lib';
import {ActionContextHalItem, WorkflowActionDataDto} from '@dsvs/workflow-generator-ui-lib';
import * as moment from 'moment';
import {MessageService} from 'primeng/components/common/messageservice';
import {ProcessHalItem} from '../../../../shared/hal/process.hal.item';
import {WGContextHalItem} from '../../../../shared/hal/wfg/wgcontext.hal.item';
import {FesWorkflowActionComponent} from '../fes-workflow-action';

@Component({
  selector: 'fes-workflow-action-returndate',
  templateUrl: './fes-workflow-action-returndate.component.html',
  styleUrls: ['./fes-workflow-action-returndate.component.scss']
})
export class FesWorkflowActionReturndateComponent extends FesWorkflowActionComponent implements OnInit {

  process: ProcessHalItem;
  actionDone = false;
  de = germanCalenderTemplate;
  minDateValue: Date;

  constructor(messageService: MessageService) {
    super(messageService);
  }

  ngOnInit() {
    this.inputForm = new FormGroup(
      {
        date: new FormControl(null, Validators.required)
      });
    this.loadProcess();
    this.minDateValue = new Date();
    this.minDateValue.setDate(this.minDateValue.getDate() + 1);
  }

  loadProcess() {
    (<WGContextHalItem>this.context).process.async.subscribe(
      process => {
        if (process && process.data.returndate) {
          this.inputForm.setValue({date: new Date(process.data.returndate)});
          this.actionDone = true;
        }
      }
    );
  }

  public collectActionData(): WorkflowActionDataDto {
    const date = moment(this.inputForm.value.date).format('YYYY-MM-DD');
    if (!this.inputForm.value.date) {
      this.actionDone = false;
    }
    return <WorkflowActionDataDto>{
      date: date
    };
  }

  public dateSelected(value) {
    this.inputForm.value.date = value;
    this.saveAction();
    if (!value) {
      this.inputForm.reset('date');
      this.actionDone = false;
    }

  }

  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
    this.actionDone = true;
  }

}
