import { Component, OnInit } from '@angular/core';
import { WorkflowActionHistoryComponent } from '@dsvs/workflow-generator-ui-lib';
import { FiReleaseDateHalItem } from '../../../../../shared/hal/fiReleaseDateHalItem';
import { FiReleaseDateServiceImpl } from '../../../../../shared/services/fi-release-date.service';

@Component({
  selector: 'fes-fes-workflow-action-fireleasedate-select-history',
  templateUrl: './fes-workflow-action-fireleasedate-select-history.component.html',
  styleUrls: ['./fes-workflow-action-fireleasedate-select-history.component.scss']
})
export class FesWorkflowActionFireleasedateSelectHistoryComponent extends WorkflowActionHistoryComponent implements OnInit {

  fiReleaseDate: FiReleaseDateHalItem = null;

  constructor(private fireleasedateService: FiReleaseDateServiceImpl) {
    super();

  }

  ngOnInit() {
    super.ngOnInit();

  }

  payloadChanged(payload: any): void {
    super.payloadChanged(payload);

    if (this.payload && this.payload.fireleasedateId) {

      this.fireleasedateService.getById(this.payload.fireleasedateId).subscribe(result => {
        this.fiReleaseDate = result;
      });
    }
  }

}
