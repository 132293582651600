import { Component, Input } from '@angular/core';
import { FormularHalItem } from '../../hal/formular.hal.item';
import { FesStatusComponent } from '../fes-status/fes-status.component';

@Component({
  selector: 'fes-formular-status',
  templateUrl: './fes-formular-status.component.html',
  styleUrls: ['./fes-formular-status.component.scss']
})
export class FesFormularStatusComponent {

  color: string = FesStatusComponent.COLOR_UNKNOWN;
  title: string = null;
  _formular: FormularHalItem = null;

  @Input() set formular(formular: FormularHalItem) {
    this._formular = formular;

    if (formular.data.deleted) {
      this.color = FesStatusComponent.COLOR_RED;
      this.title = 'Formular gelöscht';
    } else if (formular.data.activeVersionId) {
      this.color = FesStatusComponent.COLOR_GREEN;
      this.title = 'Aktive Version';
    } else {
      this.color = FesStatusComponent.COLOR_YELLOW;
      this.title = 'Keine aktive Version';
    }
  }

  get formular(): FormularHalItem {
    return this._formular;
  }

}
