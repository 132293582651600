import {Component, OnInit} from '@angular/core';
import {DsvsBreadcrumbService, DsvsPage, DsvsPermissionService, DsvsTableColumn, DsvsToolbarItem, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {MenuItem} from 'primeng/api';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable} from 'rxjs';
import {ZapPermissions} from '../../../../enums/zap-permissions.enum';
import {FiReleaseDateHalItem, FiReleaseDateHalPage} from '../../../shared/hal/fiReleaseDateHalItem';
import {ServiceupdateHalItem, ServiceupdateHalPage} from '../../../shared/hal/serviceupdate.hal.item';
import {EServiceupdateState} from '../../../shared/interfaces/dtos/enums/EServiceupdateState';
import {FesUserSettingsService} from '../../../shared/services/fes-user-settings.service';
import {FiReleaseDateServiceImpl} from '../../../shared/services/fi-release-date.service';
import {ServiceupdateServiceImpl} from '../../../shared/services/serviceupdate.service';

export enum ServiceupdateActions {
  CREATE_SERVICEUPDATE = 'CREATE_SERVICEUPDATE',
  CREATE_G2_PACKAGE = 'CREATE_G2_PACKAGE'
}

@Component({
  selector: 'fes-serviceupdate-search',
  templateUrl: './serviceupdate-search.component.html',
  styleUrls: ['./serviceupdate-search.component.scss']
})
export class ServiceupdateSearchComponent implements OnInit {

  loading = false;
  isNew = false;

  showSearchInput = false;
  searchValue = '';

  tableColumnOptions: DsvsTableColumn[] = [];

  editServiceupdate: ServiceupdateHalItem;
  showEditModal = false;
  showActivationModal = false;
  showLockModal = false;
  showPacketingModal = false;
  data: ServiceupdateHalPage;

  editableG2 = true;

  splitbuttonTitle = 'Bearbeiten';

  constructor(
    private serviceupdateService: ServiceupdateServiceImpl,
    private messageService: MessageService,
    private breadcrumbService: DsvsBreadcrumbService,
    private permissionService: DsvsPermissionService,
    public fiReleaseDateService: FiReleaseDateServiceImpl,
    private fesSettings: FesUserSettingsService
  ) {
  }

  ngOnInit() {

    this.editableG2 = this.permissionService.hasPermission([ZapPermissions.FES_SERVICEUPDATE_UPDATE]);

    this.initToolbar();
    this.tableColumnOptions = [
      {field: 'locked', header: '', width: '5%', format: 'LOCKED'},
      {field: 'displayName', sort: true, header: 'Nummer', width: '10%'},
      {field: 'publicationDate', header: 'Veröffentlichung', sort: true, format: 'DATE', centered: true, width: '10%'},
      {field: 'displayName', header: 'G2-Termin', sort: true, format: 'G2DATE', width: '10%'},
      {field: 'title', header: 'Titel', sort: true, width: '38%'},
      {field: 'status', header: 'Status', sort: true, format: 'STATE', width: '10%'},
      {field: '', header: 'Aufträge', format: 'PROCESSES', centered: true, width: '10%'},
      {field: '', header: '', format: 'ACTION', width: '7%'}
    ];

    this.fesSettings.onActiveWorkspaceChanged().subscribe(result => {
      this.onReloadData('');
    });

    if (!this.permissionService.hasPermission([ZapPermissions.FES_SERVICEUPDATE_UPDATE])) {
      this.splitbuttonTitle = 'Anzeigen';
    }
  }

  onReloadData(searchTerm: string, page?: DsvsPage) {
    this.loading = true;
    this.serviceupdateService.search(searchTerm, page).subscribe(result => {
      this.data = result;
      this.loading = false;
    }, error2 => {
      this.loading = false;
      console.error(error2);
    });
  }

  initToolbar(): void {
    this.breadcrumbService.setMenuItems([
      {label: 'Serviceupdates'}
    ]);
    this.breadcrumbService.setToolbarItems(this.getToolbarItems());
    this.breadcrumbService.setToolbarVisible(true);
  }

  getToolbarItems(): DsvsToolbarItem[] {
    return [
      {
        id: ServiceupdateActions.CREATE_SERVICEUPDATE,
        icon: 'note_add',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_SERVICEUPDATE_CREATE]),
        tooltip: 'Neues Serviceupdate',
        callback: () => this.showCreateDialog()
      },
      {
        id: ServiceupdateActions.CREATE_G2_PACKAGE,
        icon: 'folder_special',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_SERVICEUPDATE_G2_CREATE]),
        tooltip: 'G2-Paket erstellen',
        callback: () => this.showPackagingDialog()
      }
    ];
  }

  getSplitButtonItems(serviceupdateHalItem: ServiceupdateHalItem): MenuItem[] {
    const menuItems = [];

    if (this.permissionService.hasPermission([ZapPermissions.FES_SERVICEUPDATE_UPDATE])) {

      menuItems.push(
        {
          label: 'Bearbeiten',
          icon: 'fa ui-icon-edit',
          disabled: !this.permissionService.hasPermissionOR(
            [ZapPermissions.FES_SERVICEUPDATE_READ, ZapPermissions.FES_SERVICEUPDATE_OVERVIEW_READ]),
          command: () => {
            this.onRowDetailClicked(serviceupdateHalItem);
          }
        });
    }

    if (this.permissionService.hasPermission([ZapPermissions.FES_SERVICEUPDATE_ACTIVATE])) {
      menuItems.push(
        {
          label: 'Aktivieren',
          icon: 'fa ui-icon-check-circle',
          disabled: !(this.permissionService.hasPermission(
            [ZapPermissions.FES_SERVICEUPDATE_ACTIVATE]) && serviceupdateHalItem.data.status === EServiceupdateState.UNPUBLISHED),
          command: () => {
            this.activateServiceupdate(serviceupdateHalItem);
          }
        }
      );
    }

    if (this.permissionService.hasPermission([ZapPermissions.FES_SERVICEUPDATE_UPDATE])) {

      if (serviceupdateHalItem.data.locked) {
        menuItems.push({
          label: 'Entsperren',
          icon: 'fa ui-icon-lock-open',
          disabled: !(this.permissionService.hasPermission([ZapPermissions.FES_SERVICEUPDATE_UPDATE])),
          command: () => {
            this.lockServiceupdate(serviceupdateHalItem, false);
          }
        });
      } else {
        menuItems.push({
          label: 'Sperren',
          icon: 'fa ui-icon-lock-outline',
          disabled: serviceupdateHalItem.data.status === EServiceupdateState.PUBLISHED
            || !(this.permissionService.hasPermission([ZapPermissions.FES_SERVICEUPDATE_UPDATE])),
          command: () => {
            this.lockServiceupdateModal(serviceupdateHalItem);
          }
        });
      }
    }

    return menuItems;
  }

  fetchFiReleaseDates = ($event, inlineEdit): Observable<FiReleaseDateHalPage> => {
    return this.fiReleaseDateService.search($event.query);
  };

  updateFiReleaseDate = (value, component: InlineEditComponent): Observable<FiReleaseDateHalItem> => {
    return (<FiReleaseDateHalItem>value).serviceupdates.add(component.model);
  };

  showCreateDialog() {
    this.showEditModal = true;
    this.isNew = true;
    this.editServiceupdate = null;
  }

  showPackagingDialog() {
    this.showPacketingModal = true;
  }

  lockServiceupdate(serviceupdateHalItem: ServiceupdateHalItem, locked: boolean) {
    this.serviceupdateService.lock(serviceupdateHalItem.data.id, locked).subscribe(
      serviceupdateHalItemResult => {
        this.onReloadData('');
      }
    );
  }

  activateServiceupdate(serviceupdateHalItem: ServiceupdateHalItem) {
    if (this.permissionService.hasPermissionOR([ZapPermissions.FES_SERVICEUPDATE_ACTIVATE])) {
      this.editServiceupdate = serviceupdateHalItem;
      this.isNew = false;
      this.showActivationModal = true;
    }
  }

  lockServiceupdateModal(serviceupdateHalItem: ServiceupdateHalItem) {
    if (this.permissionService.hasPermissionOR([ZapPermissions.FES_SERVICEUPDATE_ACTIVATE])) {
      this.editServiceupdate = serviceupdateHalItem;
      this.isNew = false;
      this.showLockModal = true;
    }
  }

  onRowDetailClicked(serviceupdateHalItem: ServiceupdateHalItem) {
    if (this.permissionService.hasPermissionOR(
      [ZapPermissions.FES_SERVICEUPDATE_UPDATE, ZapPermissions.FES_SERVICEUPDATE_OVERVIEW_READ])) {
      this.editServiceupdate = serviceupdateHalItem;
      this.isNew = false;
      this.showEditModal = true;
    }
  }

  receiveShowEditModal($event) {
    this.showEditModal = $event;
    this.onReloadData('');
  }

  receiveShowActivateModal($event) {
    this.showActivationModal = $event;
    this.onReloadData('');
  }

  receiveShowLockModal($event) {
    this.showLockModal = $event;
    this.onReloadData('');
  }

  receiveShowPacketingModal($event) {
    this.showPacketingModal = $event;
    this.onReloadData('');
  }

  showSearch() {
    this.showSearchInput = true;
  }

  hideSearch() {
    this.showSearchInput = false;
    this.onReloadData('');
    this.searchValue = '';
  }

}
