import {Component, OnInit} from '@angular/core';
import {DsvsBreadcrumbService, DsvsSearchEvent, DsvsTableColumn} from '@dsvs/dsvs-shared-ui-lib';
import {Page} from '@dsvs/hal-client';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable} from 'rxjs';
import {getFilterData} from '../../../shared/helper/FilterDataHelper';
import {EScanpoolState} from '../../../shared/interfaces/dtos/enums/EScanpoolState';
import {Scanpool} from '../../../shared/interfaces/dtos/scanpool';
import {FormServiceImpl} from '../../../shared/services/form.service';
import {ScanpoolServiceImpl} from '../../../shared/services/scanpool.service';
import {UserServiceImpl} from '../../../shared/services/user.service';
import {ScanpoolStateNamePipe} from '../../pipes/scanpool-state-name.pipe';

@Component({
  selector: 'fes-scanpool-search',
  templateUrl: './scanpool-search.component.html',
  styleUrls: ['./scanpool-search.component.scss']
})
export class ScanpoolSearchComponent implements OnInit {

  loading = false;

  editScanpool: Scanpool;

  showAssignModal = false;
  showDetailModal = false;

  scanpools: any;

  tableColums: DsvsTableColumn[];

  filters: any[] = [];
  searchTerm = '';
  pageable: any = null;

  constructor(
    private scanpoolService: ScanpoolServiceImpl,
    private formService: FormServiceImpl,
    private messageService: MessageService,
    private breadcrumbService: DsvsBreadcrumbService,
    private userService: UserServiceImpl
  ) {
  }

  ngOnInit() {
    this.onReloadData();
    this.initToolbar();
    this.tableColums = this.getColumns();

  }

  initToolbar(): void {
    this.breadcrumbService.setMenuItems([
      {label: 'Scanpool'}
    ]);
    this.breadcrumbService.setToolbarItems([]);
    this.breadcrumbService.setToolbarVisible(true);
  }

  getScanpoolStates = () => {
    return Observable.of(Object.keys(EScanpoolState).map(
      k => ({
        'value': EScanpoolState[k as any],
        'label': ScanpoolStateNamePipe.prototype.transform(EScanpoolState[k as EScanpoolState])
      })
    ));
  };

  getUser = () => {
    return this.userService.search('');
  };

  private getColumns(): DsvsTableColumn[] {
    return [
      {field: 'target', header: 'Typ', sort: true, format: 'TYPE'},
      {field: 'fromEmail', header: 'Absender', sort: true},
      {
        field: 'createdByName', header: 'Einsteller', sort: true,
        filterable: true,
        filterDataService: this.getUser
      },
      {
        field: 'state', header: 'Status', sort: true, format: 'STATE',
        filterable: true,
        filterDataService: this.getScanpoolStates
      },
      {field: 'targetId', header: 'Zugewiesen zu', sort: true, format: 'ASSIGNED_TO'},
      {field: 'lastModifiedDate', header: 'Datum', sort: true, format: 'DATE'},
      {field: '', header: 'Aktion', sort: false, width: '10%', format: 'ACTION'}
    ];
  }

  onChange(searchterm: string, page: DsvsSearchEvent) {
    if (page) {
      this.pageable = {
        sort: page.sort,
        size: page.size,
        page: page.number,
        searchTerm: searchterm
      };
      this.onReloadData();
    }
  }

  filterData(searchTerm: string, data?: { pageRequest: Page, value: any }) {

    if (data.value.field === 'createdByName' && data.value.filterBy && data.value.filterBy.zapId) {
      data.value.filterBy = data.value.filterBy.zapId;
    }
    this.filters = getFilterData(data.value, this.filters);

    this.onReloadData();
  }

  onReloadData() {
    this.loading = true;
    this.scanpoolService.filter(
      this.searchTerm,
      this.pageable,
      JSON.stringify(this.filters)
    ).subscribe(result => {
      this.scanpools = result;
      this.loading = false;
    }, error2 => {
      this.loading = false;
      console.error(error2);
    });
  }

  onAssign(scanpool: Scanpool) {
    this.editScanpool = scanpool;
    this.showAssignModal = true;
  }

  onDetail(scanpool: Scanpool) {
    this.editScanpool = scanpool;
    this.showDetailModal = true;
  }

  receiveShowAssignModal($event) {
    this.showAssignModal = $event;
    this.onReloadData();
  }

  receiveShowDetailModal($event) {
    this.showDetailModal = $event;
    this.onReloadData();
  }

}
