import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DsvsPermissionService } from '@dsvs/dsvs-shared-ui-lib';
import { isNil } from 'lodash';
import { MessageService } from 'primeng/components/common/messageservice';
import { Observable } from 'rxjs';
import { ZapPermissions } from '../../../../../enums/zap-permissions.enum';
import { SelectOptions } from '../../../../../helper/select-helper';
import { TagHalItem } from '../../../../shared/hal/tag.hal.item';
import { Tag } from '../../../../shared/interfaces/dtos/tag';
import { TagServiceImpl } from '../../../../shared/services/tag.service';

@Component({
  selector: 'fes-tags-detail',
  templateUrl: './tags-detail.component.html',
  styleUrls: ['./tags-detail.component.scss']
})
export class TagsDetailComponent implements OnInit {

  tag: Tag;
  _tagHalItem: TagHalItem;
  categories: SelectOptions[] = [];

  isNew = false;
  isEditable = false;

  @Input()
  set tagHalItem(tagHalItem: TagHalItem) {
    if (tagHalItem) {
      this.tag = tagHalItem.data;
      this._tagHalItem = tagHalItem;
    }
  }

  get tagHalItem(): TagHalItem {
    return this._tagHalItem;
  }

  @Output() showEditModalEvent = new EventEmitter<boolean>();

  constructor(
    private tagService: TagServiceImpl,
    private messageService: MessageService,
    private permissionService: DsvsPermissionService
  ) {
  }

  ngOnInit() {

    this.isEditable = this.isNew || this.permissionService.hasPermission([ZapPermissions.FES_TAG_UPDATE]);

    if (isNil(this._tagHalItem)) {
      this.isNew = true;
      this.tag = <Tag>{
        id: '',
        displayName: '',
        name: '',
        description: ''
      };
    }
    this.tagService.categories().subscribe(res => this.categories = res.map(tagcategory => {
      return <SelectOptions>{value: tagcategory.id, label: tagcategory.title};
    }));
  }

  save = () => {
    if (!this.isNew) {
      return this._tagHalItem.save(this.tag);
    } else {
      return Observable.of(this.tag);
    }
  };

  saveButton = () => {
    return this.tagService.create(this.tag).subscribe(result => {
      this.cancelModal();
      this.messageService.add({
        severity: 'success',
        summary: 'Erfolg',
        detail: 'Der Tag wurde erstellt.'
      });
    }, error => {
      console.error('Error creating tag - {}', error);
      this.cancelModal();
    });
  };

  cancelModal() {
    this.showEditModalEvent.emit(false);
  }
}
