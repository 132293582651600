import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DsvsSearchEvent, DsvsSettings, DsvsTableColumn } from '@dsvs/dsvs-shared-ui-lib';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api';
import { resolveFileName } from '../../../../../helper/download-helper';
import { FormularHalItem } from '../../../../shared/hal/formular.hal.item';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { ServiceupdateHalItem } from '../../../../shared/hal/serviceupdate.hal.item';
import { VersionHalItem } from '../../../../shared/hal/version.hal.item';
import { EFileTypes } from '../../../../shared/interfaces/dtos/enums/EFileTypes';
import { FormServiceImpl } from '../../../../shared/services/form.service';
import { ProcessServiceImpl } from '../../../../shared/services/process.service';
import { ServiceupdateServiceImpl } from '../../../../shared/services/serviceupdate.service';
import { VersionServiceImpl } from '../../../../shared/services/version.service';

@Component({
  selector: 'fes-released-versions-table',
  templateUrl: './released-versions-table.component.html',
  styleUrls: ['./released-versions-table.component.scss']
})
export class ReleasedVersionsTableComponent implements OnInit {

  versions: any;

  _formular: FormularHalItem;

  @Input()
  set formular(formular: FormularHalItem) {
    this._formular = formular;
    if (formular) {
      this.loadVersions(formular);
    }
  }

  @Input()
  isLoading = false;

  @Input()
  isEditable = false;

  @Output()
  createDevelopmentVersion = new EventEmitter<VersionHalItem>();

  @Output()
  refreshData = new EventEmitter<void>();

  versionTableColums: DsvsTableColumn[];

  editServiceupdate: ServiceupdateHalItem;
  showEditModal = false;
  showInfoModal = false;
  infoVersion: VersionHalItem;

  processLoadingState = [];
  versionLoadingState = [];

  constructor(
    private fesSettings: DsvsSettings,
    private versionService: VersionServiceImpl,
    private messageService: MessageService,
    private router: Router,
    private formService: FormServiceImpl,
    private serviceupdateService: ServiceupdateServiceImpl,
    private processService: ProcessServiceImpl
  ) {
  }

  ngOnInit() {
    this.versionTableColums = this.getFormularColums();
  }

  onChange(page: DsvsSearchEvent) {
    if (this._formular) {

      this.isLoading = true;
      this.formService.getPubishedVersions(this._formular.data.id, page.number, page.size).subscribe(result => {
          this.isLoading = false;
          this.versions = result;
        },
        err => {
          console.error(err);
          this.isLoading = false;
        });

    }
  }

  loadVersions(formular: FormularHalItem) {

    this.isLoading = true;
    const initialSize = this.fesSettings.getOptionValue('DTROWS_FES', 10);
    this.formService.getPubishedVersions(formular.data.id, 0, initialSize).subscribe(result => {
        this.isLoading = false;
        this.versions = result;
      },
      err => {
        console.error(err);
        this.isLoading = false;
      });

  }

  private getFormularColums(): DsvsTableColumn[] {
    // Some Columns are defined in template! (hence sum(x%) < 100)
    return [
      {field: 'active', header: '', format: 'FIS', width: '5%'},
      {field: 'version', header: 'Version', format: 'VERSION', sort: false, width: '10%'},
      {field: 'fassung', header: 'Fassung', format: 'FASSUNG', width: '10%'},
      {field: 'comment', header: 'Änderungshinweis zum Serviceupdate', format: 'SUCOMMENT', sort: false, width: '40%'},
      {field: 'serviceupdate', header: 'Serviceupdate', format: 'SU', sort: false, width: '10%'},
      {field: 'process', header: 'Auftrag', format: 'PROCESS', width: '10%'},
      {field: '', header: '', format: 'DOWNLOAD', width: '5%'}
    ];
  }

  receiveShowEditModal($event) {
    this.showEditModal = $event;
  }

  onCloseInfoDialog() {
    this.showInfoModal = false;
    this.infoVersion = null;
  }

  showInfo(rowData: any) {

    this.versionService.getById(rowData.vorlage.versionId).subscribe(result => {
        this.showInfoModal = true;
        this.infoVersion = result;
      },
      error => {
        console.error(error);
      });

  }

  showServiceupdate(serviceupdateId: string) {
    this.serviceupdateService.getById(serviceupdateId).subscribe(serviceupdate => {

      this.editServiceupdate = serviceupdate;
      this.showEditModal = true;

    }, error => {
      console.error(error);
    });
  }

  onDownloadFisFile(rowData: any) {

    this.versionService.downloadVersion(rowData.vorlage.versionId, EFileTypes.PDF).subscribe(res => {
      saveAs(res.body, resolveFileName(res.headers));
    }, error1 => console.error(error1));
  }

  fisSplitButton(rowData: any) {
    return [
      {
        label: 'PDF',
        icon: 'fa ui-icon-file-download',
        command: () => {
          this.onDownloadFisFile(rowData);
        }
      }
    ];
  }

  splitButtonsForFesVersion(rowData: any) {
    return [
      {
        label: 'SU Doku',
        icon: 'fa ui-icon-file-download',
        command: () => {
          this.onDownloadDiffClicked(rowData);
        }
      }
    ];
  }

  getVersion(rowData: any): VersionHalItem {
    if (rowData.version) {
      return rowData.version;
    }
    if (this.versionLoadingState[rowData.vorlage.versionId] !== true) {
      this.versionLoadingState[rowData.vorlage.versionId] = true;
      this.versionService.getById(rowData.vorlage.versionId).subscribe(result => {

        rowData.version = result;
        this.versionLoadingState[rowData.vorlage.versionId] = false;

      }, error1 => {
        console.error(error1);
      });
    }

    return null;
  }

  getProcessForVersion(rowData: any): ProcessHalItem {
    if (rowData.process) {
      return rowData.process;
    }
    if (this.processLoadingState[rowData.vorlage.versionId] !== true) {
      this.processLoadingState[rowData.vorlage.versionId] = true;
      this.processService.searchByResultVersionId(rowData.vorlage.versionId).subscribe(result => {

        rowData.process = result.content[0];
        this.processLoadingState[rowData.vorlage.versionId] = false;

      }, error1 => {
        console.error(error1);
      });
    }

    return null;
  }

  onProcessDetail(process: any) {
    if (process && process.data && process.data.id) {
      this.router.navigate(['/processes/' + process.data.id]);
    }
  }

  onDownloadDiffClicked(version: VersionHalItem) {
    this.versionService.downloadVersionDiff(
      version.data.id
    ).subscribe(
      (res) => {
        saveAs(res.body, resolveFileName(res.headers));
      },
      (err) => {
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Fehler',
            detail: 'Beim Download ist ein Fehler aufgetreten.'
          }
        );
      }
    );
  }

}
