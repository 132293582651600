import { DsvsPage, DsvsSearchableReadEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient, HalItem, HalItemConstructor, HalPage, HalPageConstructor, Sort } from '@dsvs/hal-client';
import { TemplateParameters } from '@dsvs/traverson-ng';
import 'rxjs/add/observable/fromPromise';
import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs/observable/from';
import { Base } from '../interfaces/dtos/base';
import { HalRelation } from './hal.relation';

export class HalReadServiceImpl<T extends Base, R extends HalItem<T>, U extends HalPage<T, R>>
  implements DsvsSearchableReadEntityService<T> {

  constructor(protected readonly v1Client: Promise<HalClient>,
    protected readonly relation: HalRelation,
    protected readonly pageConstructor: HalPageConstructor<T, R, U>,
    protected readonly itemConstructor: HalItemConstructor<T, R>) {
  }

  protected execute<Y>(action: (HalClient) => Observable<Y>): Observable<Y> {
    return from(this.v1Client)
    .flatMap(action);
  }

  getById(id: string): Observable<R> {
    return this.execute((v1Client: HalClient) => v1Client
      .typedResource(this.itemConstructor, this.relation.single, <any>{entityId: id})
    );
  }

  // TODO: Remove getAll... Results should NEVER be unpaged...
  getAll(): Observable<U> {
    return this.execute((v1Client: HalClient) => v1Client
      .typedResources(this.pageConstructor, this.itemConstructor, this.relation.collection,
        <any>{size: 0x7fffffff /* Integer.MAX_VALUE */})
    );
  }

  search(searchTerm: string, options?: DsvsPage, view?: ViewParameter): Observable<U> {
    return this.execute((v1Client: HalClient) => v1Client
      .typedResources(this.pageConstructor, this.itemConstructor, this.relation.collection,
        this.convertPagedSearch(searchTerm, options, view))
    );
  }

  filter(searchTerm: string, options?: DsvsPage, filterTerm?: string): Observable<U> {
    return this.search(searchTerm, options, {name: 'filter', data: {'filters': filterTerm}});
  }

  protected convertPagedSearch = (searchTerm: string, options?: DsvsPage, view?: ViewParameter): TemplateParameters => {

    const params: TemplateParameters = {};

    if (searchTerm) {
      params['query'] = searchTerm;
    }

    if (options) {
      if (options.number !== undefined) {
        params['page'] = options.number;
      }
      if (options.size !== undefined) {
        params['size'] = options.size;
      }
      if (options.sort !== undefined) {
        const sortItems: Sort[] = options.sort;
        const convertedItems = [];
        for (let i = 0; i < sortItems.length; i++) {
          const sortItem: Sort = sortItems[i];
          convertedItems.push(sortItem.property + ',' + sortItem.order);
        }
        params['sort'] = convertedItems;
      }
    }

    if (view) {
      params['view'] = view.name;

      if (view.data) {
        // const helperParams = [];
        // for (const key in view.data) {
        //   const value = view.data[key];
        //   helperParams.push(key + ':' + value);
        // }
        params['viewData'] = JSON.stringify(view.data);
      }

    }
    return params;
  };
}

export interface ViewParameter {
  name: string;

  [data: string]: any;
}

