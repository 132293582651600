import { EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ValidationData } from '../../../../shared/interfaces/dtos/documents/validation-data';
import { ValidationDocument } from '../../../../shared/interfaces/dtos/documents/validation-document';
import { EDocumentTypes } from '../../../../shared/interfaces/dtos/enums/EDocumentTypes';
import { EFileTypes } from '../../../../shared/interfaces/dtos/enums/EFileTypes';
import { Formular } from '../../../../shared/interfaces/dtos/formular';
import { Version } from '../../../../shared/interfaces/dtos/version';
import { XdpData } from '../../../../shared/interfaces/dtos/xdpdata';

export abstract class VersionModalComponent<VERSION_TYPE, VALIDATION_TYPE extends ValidationData>
  implements OnDestroy {

  _validationDocument: ValidationDocument;

  formular: Formular;
  version: Version;
  xdpData: XdpData;
  type: EDocumentTypes;
  fileType: EFileTypes;
  missingFields: string[];
  validationData: VALIDATION_TYPE;

  selectedTab = 0;
  stepItems: MenuItem[];

  isNewForm = false;

  @Output()
  displayChange = new EventEmitter();

  @Output()
  sendData = new EventEmitter<VERSION_TYPE>();

  @Input()
  visible = false;

  @Input()
  set validationDocument(document: ValidationDocument) {
    this._validationDocument = document;

    this.type = document.type;
    this.fileType = document.fileType;

    this.formular = document.validationData.formular;
    this.version = document.validationData.version;
    this.xdpData = document.validationData.xdpData;
    this.missingFields = document.validationData.missingFields;
    this.validationData = <VALIDATION_TYPE>document.validationData;
    this.setNewForm();
    this.stepItems = this.initStepItems();
  }

  get validationDocument(): ValidationDocument {
    return this._validationDocument;
  }

  ngOnDestroy() {
    this.displayChange.unsubscribe();
    this.sendData.unsubscribe();
  }

  private setNewForm() {
    this.isNewForm = !(this.formular && this.formular.id);
  }

  openNext(event: Event) {
    this.selectedTab = this.selectedTab + 1;
  }

  openLast(event: Event) {
    this.selectedTab = this.selectedTab - 1;
  }

  abstract resetForms();

  abstract initStepItems(): MenuItem[];

  onClose() {
    this.resetForms();
    this.displayChange.emit(false);
  }

}
