import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { Note } from '../interfaces/dtos/note';

export class NoteHalItem extends HalItemImpl<Note> {
  constructor(data: Note,
              client: HalClient,
              factory: HalClientFactory) {
    super(data, client, factory);
  }
}

export class NoteHalPage extends HalPageImpl<Note, NoteHalItem> {

}

export class NoteRelationPage extends HalRelationPage<Note, NoteHalItem, NoteHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, NoteHalPage, NoteHalItem);
  }
}

export class NoteRelationItem extends HalRelationItem<Note, NoteHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, NoteHalItem);
  }
}
