import { Component, OnInit } from '@angular/core';
import { WorkflowActionHistoryComponent } from '@dsvs/workflow-generator-ui-lib';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/components/common/messageservice';
import { resolveFileName } from '../../../../../../helper/download-helper';
import { VersionServiceImpl } from '../../../../../shared/services/version.service';

@Component({
  selector: 'fes-fes-workflow-action-version-select-history',
  templateUrl: './fes-workflow-action-version-select-history.component.html',
  styleUrls: ['./fes-workflow-action-version-select-history.component.scss']
})
export class FesWorkflowActionVersionSelectHistoryComponent
  extends WorkflowActionHistoryComponent
  implements OnInit {

  files: any[] = [];

  constructor(
    private versionService: VersionServiceImpl,
    private messageService: MessageService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

  }

  payloadChanged(payload) {
    if (payload) {
      this.files = [{
        displayName: 'Version ' + payload.version,
        id: payload.versionId
      }];
    }
  }

  onDownloadClicked(event: any) {
    this.versionService.downloadVersion(
      event.id
    ).subscribe(
      (res) => this.downloadSuccess(res),
      (err) => this.downloadError(err)
    );
  }

  downloadSuccess(res) {
    saveAs(res.body, resolveFileName(res.headers));
  }

  downloadError(error: Error) {
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Fehler',
        detail: 'Beim Download ist ein Fehler aufgetreten.'
      }
    );
  }

}
