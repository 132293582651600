import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextHalItem, StepContextHalItem, StepHalItem } from '@dsvs/workflow-generator-dto';

@Component({
  selector: 'fes-process-detail-details',
  templateUrl: './process-detail-details.component.html',
  styleUrls: ['./process-detail-details.component.scss']
})
export class ProcessDetailDetailsComponent implements OnInit, OnDestroy {

  @Input()
  workflowContext: ContextHalItem;

  @Input()
  currentStepContext: StepContextHalItem;

  @Input()
  currentStep: StepHalItem;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
