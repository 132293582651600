import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class UpdateProcessDetailService {
  private _trigger = new Subject();
  trigger$ = this._trigger.asObservable();

  trigger(manuscriptsUpdated) {
    this._trigger.next(manuscriptsUpdated);
  }
}
