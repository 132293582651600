import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { HalCrudServiceImpl } from '../hal/hal.crud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { WorkspaceHalItem, WorkspaceHalPage } from '../hal/workspace.hal.item';
import { Workspace } from '../interfaces/dtos/workspace';

export interface WorkspaceService extends DsvsSearchableCrudEntityService<Workspace> {

}

@Injectable({
  providedIn: 'root'
})
export class WorkspaceServiceImpl
  extends HalCrudServiceImpl<Workspace, WorkspaceHalItem, WorkspaceHalPage>
  implements WorkspaceService {

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>) {
    super(
      v1Client,
      <HalRelation>{single: 'workspace', collection: 'workspaces'},
      WorkspaceHalPage,
      WorkspaceHalItem);
  }
}
