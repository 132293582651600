import { HalClient, HalClientFactory, HalItem, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { StepContextHalItem, WorkflowStepContext } from '@dsvs/workflow-generator-dto';
import { NoteRelationPage } from '../note.hal.item';
import { StepCommentRelationItem } from '../stepcomment.hal.item';

export class WGStepContextHalItem extends StepContextHalItem {
  constructor(data: WorkflowStepContext,
              client: HalClient,
              factory: HalClientFactory) {
    super(data, client, factory);

    this.notes = new NoteRelationPage('notes', this);
    this.stepComment = new StepCommentRelationItem('stepcomment', this);
  }

  public readonly notes: NoteRelationPage;
  public readonly stepComment: StepCommentRelationItem;
}

export class WGStepContextHalPage extends HalPageImpl<WorkflowStepContext, WGStepContextHalItem> {

}

export class WGStepContextRelationPage extends HalRelationPage<WorkflowStepContext, WGStepContextHalItem, WGStepContextHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, WGStepContextHalPage, WGStepContextHalItem);
  }
}

export class WGStepContextRelationItem extends HalRelationItem<WorkflowStepContext, WGStepContextHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, WGStepContextHalItem);
  }
}
