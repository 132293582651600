// Used until translations are implemented

const exceptions = {
  'FILER_FILE_ALREADY_EXISTS': errorMessage('Datei existiert bereits.'),
  'FILER_COULD_NOT_INITIALIZE': errorMessage('Fehler beim initialisieren des Filers.'),
  'FILER_FILE_COULD_NOT_READ': errorMessage('Datei konnte nicht gelesen werden.'),
  'FILER_COULD_NOT_READ': errorMessage('Datei konnte nicht gelesen werden.'),
  'FILER_STORE_PATH_NONEXISTANT': errorMessage('Beim erstellen ist ein Fehler aufgetreten.'),
  'FILER_STORE_COULD_NOT_WRITE': errorMessage('Beim erstellen ist ein Fehler aufgetreten.'),
  'UNSUPPORTED_FILE_TYPE_LOGREF': errorMessage('Dateityp nicht erlaubt.'),
  'NO_G2_DOCUMENTS_FOUND': errorMessage('Es konnten keine G2-Versionen in den gewählten Serviceupdates gefunden werden.'),
  'FILER_STORE_EMPTY_FILE': errorMessage('Beim erstellen ist ein Fehler aufgetreten.'),
  'FILER_STORE_WRONG_PATH': errorMessage('Beim erstellen ist ein Fehler aufgetreten.'),
  'FILER_FORM_DOES_NOT_EXIST': errorMessage('Formularordner existiert nicht.'),
  'FILER_FORM_FOLDER_WRITE_ERROR': errorMessage('Formularordner konnte nicht erstellt werden.'),
  'FILER_FORM_FOLDER_ALREADY_EXISTS': errorMessage('Formularordner existiert bereits.'),
  'FILER_COULD_NOT_STORE': errorMessage('Datei konnte nicht gespeichert werden.'),
  'XDP_FILE_NOT_FOUND': errorMessage('XDP Datei nicht gefunden.'),
  'DEV_VERSION_UPLOAD_FAILED': errorMessage('XDP Datei konnte nicht gespeichert werden.'),
  'DEV_VERSION_STATE_UPLOADED_OR_RELEASED_EXPECTED': errorMessage(
    'Falscher Entwicklerversions-Status: UPLOADED oder RELEASED erwartet.'),
  'DEV_VERSION_STATE_UPLOADED_OR_NEW_EXPECTED': errorMessage('Falscher Entwicklerversions-Status: UPLOADED oder NEW erwartet.'),
  'DEV_VERSION_STATE_UPLOADED_EXPECTED': errorMessage('Falscher Entwicklerversions-Status: UPLOADED erwartet.'),
  'VERSION_STATE_UPDATE_FAILED': errorMessage('Status konnte nicht geändert werden.'),
  'VERSION_UPLOAD_NO_FORM': errorMessage('Fehler beim Upload: referenziertes Formular konnte nicht gefunden werden.'),
  'VERSION_UPLOAD_FAILED': errorMessage('Fehler beim Upload in das Repository.'),
  'NO_COMMENT': errorMessage('Kommentar darf nicht leer sein.'),
  'NO_CORRESPONDING_VERSION': errorMessage('Vorgängerversion dieser Entwicklerversion nicht gefunden.'),
  'NO_CORRESPONDING_FORM': errorMessage('Formular dieser Entwicklerversion nicht gefunden. (f_Nummer prüfen)'),
  'NO_CORRESPONDING_DEVFORM': errorMessage('Entwicklerversion für diese XDP nicht vorhanden. ('),
  'NO_ARTICLENUMBER': errorMessage('XPD enthält keine Artikelnummer.'),
  'ARTICLENUMBER_DIFFERS': errorMessage('Artikelnummer in XDP unterscheidet sich von der Artikelnummer der Vorgängerversion.'),
  'NO_TITLE': errorMessage('XDP enthält keinen Titel.'),
  'NO_FASSUNG': errorMessage('XDP enthält keine Fassung.'),
  'ATTACHMENT_WRONG_FORMAT': errorMessage('Im Feld ATTACHMENT darf nur eine 1 oder eine 0 stehen.'),
  'COUNT_COPY_WRONG_FORMAT': errorMessage('Das Feld COUNT_COPY benötigt einen minimalen Wert von 1.'),
  'VERSION_WRONG_FORMAT': errorMessage('Das Feld VERSION ist im falschen Format (x.x oder x.x.x erlaubt).'),
  'VERSION_IS_DEV_VERSION': errorMessage('XDP ist eine Entwicklerversion.'),
  'PARENT_NOT_FOUND': errorMessage('In XDP referenzierte Vorgängerversion konnte nicht gefunden werden.'),
  'PARENT_FORM_CURRENT_FORM_DIFFER': errorMessage('XDP und Vorgängerversion referenzieren unterschiedliches Formular.'),
  'NO_PARENT_BUT_FORM_HAS_VERSIONS': errorMessage(
    'XDP ist eine Root-Version (besitzt keine Vorgängerversion); jedoch besteht bereits eine Root-Version für dieses Formular.'),
  'ARTICLENUMBER_WRONG_FORMAT': errorMessage(
    'Feld ARTICLE_NUMBER besitzt ein falsches Format. Erwartet: 9 Zeichen (numerisch) oder 5-15 Zeichen'),
  'ARTICLENUMBER_WRONG_FORMAT_2': errorMessage(
    'Feld ARTICLE_NUMBER besitzt ein falsches Format. Erwartet: 5-15 Zeichen oder 9 Zeichen (numerisch).')
};

export function errorMessage(detail, summary = 'Fehler') {
  return message('error', summary, detail);
}

export function warnMessage(detail, summary = 'Warnung') {
  return message('warn', summary, detail);
}

export function successMessage(detail, summary = 'Erfolg') {
  return message('success', summary, detail);
}

export function infoMessage(detail, summary = 'Information') {
  return message('info', summary, detail);
}

function message(severity, summary, detail) {
  return {
    severity: severity,
    summary: summary,
    detail: detail
  };
}

export function getExceptionMessages(vndError, severity?, summary?, detail?): object[] {
  const _severity = 'error';
  const _summary = 'Fehler';
  const _detail = 'Es ist ein unbekannter Fehler aufgetreten';
  const error = (vndError && vndError.error) ? vndError.error : null;

  if (error && error.message) {
    console.error(error.message);
  }

  if (severity && summary && detail) {

    return [message(severity, summary, detail)];
  } else if (error && error._embedded && error._embedded.errors && error._embedded.errors.length > 0) {

    return error._embedded.errors.map(err => exceptions[err.logref]);

  } else if (error && error.logref && exceptions[error.logref]) {

    return [exceptions[error.logref]];

  } else if (error && error.message) {

    return [errorMessage(error.message)];

  } else {

    return [message(_severity, _detail, _summary)];
  }
}
