import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { Manuscript } from '../interfaces/dtos/manuscript';
import { FileRelationItem } from './file.hal.item';

export class ManuscriptHalItem extends HalItemImpl<Manuscript> {
  constructor(data: Manuscript,
              client: HalClient,
              factory: HalClientFactory) {
    super(data, client, factory);
    this.file = new FileRelationItem('file', this);
  }

  readonly file: FileRelationItem;
}

export class ManuscriptHalPage extends HalPageImpl<Manuscript, ManuscriptHalItem> {

}

export class ManuscriptRelationPage extends HalRelationPage<Manuscript, ManuscriptHalItem, ManuscriptHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, ManuscriptHalPage, ManuscriptHalItem);
  }
}

export class ManuscriptRelationItem extends HalRelationItem<Manuscript, ManuscriptHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, ManuscriptHalItem);
  }
}
