import { Base } from './base';
import { EFileTypes } from './enums/EFileTypes';
import { EGroupTypes } from './enums/EGroupTypes';
import { ESignatureLevel } from './enums/ESignatureLevel';
import { Id } from './Id';
import { XdpData } from './xdpdata';

export class Version extends Base {

  constructor(
    public displayName: string,
    public version: string,
    public fileType: EFileTypes,
    public comment: string,
    public signature: string,
    public signatureLevel: ESignatureLevel,
    public active: boolean,
    public createdBy: string,
    public fileId: Id,
    public formularId: Id,
    public parentId: Id,
    public tags: string[],
    public group: EGroupTypes,
    public xdpData: XdpData,
    public deleted: boolean
  ) {
    super();
  }

}
