import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextHalItem, StepContextHalPage } from '@dsvs/workflow-generator-dto';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../../../../../services/communication-service';

@Component({
  selector: 'fes-process-detail-history',
  templateUrl: './process-detail-history.component.html',
  styleUrls: ['./process-detail-history.component.scss']
})
export class ProcessDetailHistoryComponent implements OnInit, OnDestroy {

  @Input() workflowContext: ContextHalItem;

  history: StepContextHalPage;

  loading = false;
  page = 0;
  rows = 10;
  expanded = [];

  private actionSubscriptionRef: Subscription = null;

  constructor(private communicationService: CommunicationService) {
  }

  ngOnInit() {
    this.actionSubscriptionRef = this.communicationService.messages.subscribe(
      (message) => {
        if (message.scope === 'ACTION' && this.history) {
          this.loading = true;
          this.history.content.forEach(
            (actionContext, index) => {
              if (this.expanded[index]) {
                actionContext.actioncontexts.reload();
              }
            });
          this.loading = false;
        }
      });
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.workflowContext.stepcontexts.setParams({
      sort: ['createdDate,DESC'],
      size: this.rows,
      page: this.page
    }).async.subscribe(
      stepcontextHalPage => {
        this.history = stepcontextHalPage;
        this.loading = false;
      },
      (error) => {
        console.error(error);
        this.loading = false;
      }
    );
  }

  changePagination(event) {
    this.page = event.page;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.actionSubscriptionRef.unsubscribe();
  }

}
