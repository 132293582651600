import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActionHalPage, ContextHalItem, StateHalItem, TransitionHalPage} from '@dsvs/workflow-generator-dto';
import {WorkflowStateServiceImpl} from '@dsvs/workflow-generator-ui-lib';
import {MenuItem} from 'primeng/api';
import {ProcessHalItem} from '../../../../shared/hal/process.hal.item';
import {WGStepContextHalItem} from '../../../../shared/hal/wfg/wgstepcontext.hal.item';

@Component({
  selector: 'fes-process-detail-sidebar',
  templateUrl: './process-detail-sidebar.component.html',
  styleUrls: ['./process-detail-sidebar.component.scss']
})
export class ProcessDetailSidebarComponent implements OnInit {

  activeIndex: number;

  _activeStatus: any;

  @Input()
  currentStepContext: WGStepContextHalItem;

  @Input()
  workflowContext: ContextHalItem;

  @Input()
  transitions: TransitionHalPage;

  @Input()
  actions: ActionHalPage;

  @Input()
  process: ProcessHalItem;

  statusItems: MenuItem[];

  _status: StateHalItem[] = [];

  @Input()
  open = false;

  @Input() set activeStatus(activeStatus: StateHalItem) {
    this._activeStatus = activeStatus;
    if (this._status && activeStatus) {
      this.activeIndex = this._status.findIndex(state => state.data.id === activeStatus.data.id);
    }
  }

  @Output()
  sidebarClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  changeStep: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  transitionSelected: EventEmitter<void> = new EventEmitter<void>();

  constructor(private workflowStateService: WorkflowStateServiceImpl) {

  }

  ngOnInit() {
    this.workflowStateService.getForWorkflowId(this.workflowContext.data.workflowId).subscribe(
      workflowStateHalItem => {
        this._status = workflowStateHalItem.content;
        this.statusItems = workflowStateHalItem.content.map(
          s => ({label: s.data.displayName})
        );
        if (this._activeStatus && this._status) {
          this.activeIndex = this._status.findIndex(state => state.data.id === this._activeStatus.data.id);
        }
      }, error => {
        console.error(error);
      });
  }

  sidebarClick() {
    this.sidebarClicked.emit();
  }

  onChangeStepClicked(step) {
    this.changeStep.emit(step);
  }

  onTransitionClicked(transition) {
    this.transitionSelected.emit(transition);
  }

}
