import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { germanCalenderTemplate } from '@dsvs/dsvs-shared-ui-lib';
import { ActionContextHalItem, WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import * as moment from 'moment';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { WGContextHalItem } from '../../../../shared/hal/wfg/wgcontext.hal.item';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-fes-workflow-action-completiondateexpected',
  templateUrl: './fes-workflow-action-completiondateexpected.component.html',
  styleUrls: ['./fes-workflow-action-completiondateexpected.component.scss']
})
export class FesWorkflowActionCompletiondateexpectedComponent extends FesWorkflowActionComponent implements OnInit {

  date: any;
  process: ProcessHalItem;
  actionDone = false;
  de = germanCalenderTemplate;

  ngOnInit() {
    this.inputForm = new FormGroup(
      {
        date: new FormControl(null, Validators.required)
      });
    this.loadProcess();
  }

  loadProcess() {
    (<WGContextHalItem>this.context).process.async.subscribe(
      process => {
        if (process && process.data.completiondateExpected) {
          this.inputForm.setValue({date: new Date(process.data.completiondateExpected)});
          this.actionDone = true;
        }
      }
    );
  }

  public collectActionData(): WorkflowActionDataDto {
    const date = moment(this.inputForm.value.date).format('YYYY-MM-DD');
    return <WorkflowActionDataDto>{
      date: date
    };
  }

  public dateSelected(value) {
    this.inputForm.value.date = value;
    this.saveAction();
  }

  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
    this.actionDone = true;
  }
}
