import { Pipe, PipeTransform } from '@angular/core';
import { EGroupTypes } from '../interfaces/dtos/enums/EGroupTypes';

@Pipe({
  name: 'groupname'
})
export class GroupNamePipe implements PipeTransform {

  transform(value: EGroupTypes): string {
    return value === EGroupTypes.NONE ? '-' : value;
  }
}
