import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HalCrudServiceImpl } from '../hal/hal.crud.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { VersionHalItem, VersionHalPage } from '../hal/version.hal.item';
import { DevVersion } from '../interfaces/dtos/dev-version';
import { ValidationData } from '../interfaces/dtos/documents/validation-data';
import { EFileTypes } from '../interfaces/dtos/enums/EFileTypes';
import { Version } from '../interfaces/dtos/version';

export interface VersionService extends DsvsSearchableCrudEntityService<Version> {
  uploadFormVersion(version: Version, versionFile: any, increaseMajor: boolean): Observable<Version>;

  validateXdp(versionFile: any): Observable<ValidationData>;

  activateVersion(versionId: string): Observable<Version>;

  downloadVersion(versionId: string): Observable<HttpResponse<Blob>>;

  createDevVersion(versionId: string, downloadComment: string): Observable<DevVersion>;
}

@Injectable({
  providedIn: 'root'
})
export class VersionServiceImpl extends HalCrudServiceImpl<Version, VersionHalItem, VersionHalPage> implements VersionService {

  private backend: string = environment.backend;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, private http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'version', collection: 'versions'},
      VersionHalPage,
      VersionHalItem
    );
  }

  uploadFormVersion(version: Version, versionFile: any, increaseMajor: boolean): Observable<Version> {
    const formdata: FormData = new FormData();
    const ver: Version = new Version(version.displayName, version.version, version.fileType, version.comment, version.signature,
      version.signatureLevel, version.active, version.createdBy, version.fileId, version.formularId, version.parentId,
      version.tags, version.group, version.xdpData, version.deleted);

    formdata.append('version', new Blob([JSON.stringify(ver)], {type: 'application/json'}));
    formdata.append('versionFile', versionFile);

    return this.http.post<Version>(
      this.backend + this.relation.collection + `?increaseMajor=${increaseMajor}`,
      formdata,
      {}
    );
  }

  validateXdp(versionFile: any): Observable<ValidationData> {
    const formdata: FormData = new FormData();
    formdata.append('versionFile', versionFile);
    return this.http.post<ValidationData>(this.backend + this.relation.collection + '/validate', formdata, {});
  }

  activateVersion(versionId: string): Observable<Version> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');

    const formdata: FormData = new FormData();
    formdata.append('active', JSON.stringify(true));
    return this.http.post<Version>(
      this.backend + this.relation.collection + `/${versionId}/state`,
      formdata,
      {headers}
    );
  }

  downloadVersion(versionId: string, fileType: EFileTypes = EFileTypes.XDP): Observable<HttpResponse<Blob>> {
    return this.http.get(
      this.backend + this.relation.collection + `/${versionId}/download?fileType=${fileType}`,
      {responseType: 'blob', observe: 'response'});
  }

  downloadVersionDiff(versionId: string): Observable<HttpResponse<Blob>> {
    return this.http.get(
      this.backend + this.relation.collection + `/${versionId}/downloaddiff`,
      {responseType: 'blob', observe: 'response'});
  }

  createDevVersion(versionId: string, tag?: string): Observable<DevVersion> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');

    const formdata: FormData = new FormData();
    if (tag) {
      formdata.append('tag', tag);
    }
    return this.http.post<DevVersion>(
      this.backend + this.relation.collection + `/${versionId}/download-dev`,
      formdata,
      {headers}
    );
  }

  restoreVersion(version: Version): Observable<HttpEvent<void>> {
    return this.http.put<HttpEvent<void>>(this.backend + this.relation.collection + '/' + version.id + '/restore', {},
      {headers: {}});
  }

}
