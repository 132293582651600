import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { Version } from '../interfaces/dtos/version';
import { DevVersionRelationPage } from './devversion.hal.item';
import { FormularRelationItem } from './formular.hal.item';
import { ProcessRelationPage } from './process.hal.item';
import { TagRelationPage } from './tag.hal.item';

/*tslint:disable:no-use-before-declare*/
export class VersionRelationPage extends HalRelationPage<Version, VersionHalItem, VersionHalPage> {
  constructor(
    relation: string,
    item: HalItem<any>
  ) {
    super(relation, item, VersionHalPage, VersionHalItem);
  }
}

export class VersionHalItem extends HalItemImpl<Version> {
  constructor(data: Version, client: HalClient, factory: HalClientFactory) {
    super(data, client, factory);
    this.devversions = new DevVersionRelationPage('devversions', this);
    this.parentversion = new VersionRelationItem('parentversion', this);
    this.processresults = new ProcessRelationPage('processes', this);
    this.tags = new TagRelationPage('tags', this);
    this.formular = new FormularRelationItem('formular', this);
  }

  readonly devversions: DevVersionRelationPage;
  readonly parentversion: VersionRelationItem;
  readonly processresults: ProcessRelationPage;
  readonly tags: TagRelationPage;
  readonly formular: FormularRelationItem;
}

export class VersionHalPage extends HalPageImpl<Version, VersionHalItem> {

}

export class VersionRelationItem extends HalRelationItem<Version, VersionHalItem> {
  constructor(
    relation: string,
    item: HalItem<any>
  ) {
    super(relation, item, VersionHalItem);
  }
}

/*tslint:enable:no-use-before-declare*/
