// src/app/timer/timer.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

export interface Message {
  scope: string;
  message: string;
  payload?: any;
}

@Injectable()
export class CommunicationService {
  private messageSource = new Subject<Message>();
  public messages = this.messageSource.asObservable();

  publishMessage(scope: string, message: string, payload?: any) {
    this.messageSource.next({scope: scope, message: message, payload: payload});
  }
}
