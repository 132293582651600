import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {DsvsPage, DsvsSearchableCrudEntityService} from '@dsvs/dsvs-shared-ui-lib';
import {HalClient} from '@dsvs/hal-client';
import {Observable} from 'rxjs';
import {HalFcrudServiceImpl} from '../hal/hal.fcrud.service';
import {HalRelation} from '../hal/hal.relation';
import {FES_V1_CLIENT_TOKEN} from '../hal/hal.token';
import {RequirementHalItem, RequirementHalPage} from '../hal/requirement.hal.item';
import {Requirement} from '../interfaces/dtos/requirement';

export interface RequirementService extends DsvsSearchableCrudEntityService<Requirement> {
  searchUnused(searchTerm: string, formId?: string, options?: DsvsPage): Observable<RequirementHalPage>;
}

@Injectable({
  providedIn: 'root'
})
export class RequirementServiceImpl
  extends HalFcrudServiceImpl<Requirement, RequirementHalItem, RequirementHalPage>
  implements RequirementService {

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'requirement', collection: 'requirements'},
      http,
      RequirementHalPage,
      RequirementHalItem
    );
  }

  searchUnused(searchTerm: string, formId?: string, options?: DsvsPage): Observable<RequirementHalPage> {
    return super.search(searchTerm, options, {name: 'unused', data: {'formId': formId}});
  }
}
