import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FesUserSettingsService } from '../../modules/shared/services/fes-user-settings.service';
import { MeServiceImpl } from '../../modules/shared/services/me.service';

@Component({
  selector: 'fes-workspace-select',
  templateUrl: './workspace-select.component.html',
  styleUrls: ['./workspace-select.component.scss']
})
export class WorkspaceSelectComponent implements OnInit {

  selectedWorkspace;
  selectableWorkspaces = [];

  constructor(private meService: MeServiceImpl, private fesUserSettings: FesUserSettingsService) {
  }

  ngOnInit() {

    this.meService.get().subscribe(me => {
      me.workspaces.async.subscribe(workspaces => {
        // Anpassung wegen WorkspaceSortierung
          this.selectableWorkspaces = workspaces.content.sort((a,b) =>a.data.name.localeCompare(b.data.name));

          const selectedWorkspaceIdFromSettings: string = this.fesUserSettings.getActiveWorkspaceId();

          if (selectedWorkspaceIdFromSettings) {
            this.selectedWorkspace = this.selectableWorkspaces.find(w => w.data.id === selectedWorkspaceIdFromSettings);
          }

          if (!this.selectedWorkspace && this.selectableWorkspaces.length > 0) {
            this.selectedWorkspace = this.selectableWorkspaces[0];
            this.fesUserSettings.setActiveWorkspaceId(this.selectedWorkspace.data.id);
          }

          if (!this.selectedWorkspace) {
            this.fesUserSettings.setActiveWorkspaceId(null);
          }

        }
      );
    }, error2 => {

    });
  }

  fetchWorkspaces = ($event, inlineEdit): Observable<any> => {
    return Observable.of(this.selectableWorkspaces);
  };

  saveWorkspace = (value, component): Observable<any> => {
    this.fesUserSettings.setActiveWorkspaceId(value.value.id);
    // window.location.reload();
    return Observable.of(value.value);
  };
}
