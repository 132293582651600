import { Component, OnInit } from '@angular/core';
import { WorkflowActionHistoryComponent } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/api';
import { FileServiceImpl } from '../../../../../shared/services/file.service';

@Component({
  selector: 'fes-fes-workflow-action-attachment-add-history',
  templateUrl: './fes-workflow-action-comment-add-history.component.html',
  styleUrls: ['./fes-workflow-action-comment-add-history.component.scss']
})
export class FesWorkflowActionCommentAddHistoryComponent extends WorkflowActionHistoryComponent implements OnInit {

  files: any[] = [];

  constructor(
    private messageService: MessageService,
    private fileService: FileServiceImpl
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  payloadChanged(payload) {
    if (payload && payload.originalFileName && payload.fileId) {
      this.files = [{
        displayName: payload.originalFileName,
        id: payload.fileId
      }];
    }
  }

  onDownloadClicked(event: any) {
    this.fileService.downloadFile(
      event.id
    );
  }
}
