import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DsvsPage, DsvsTableColumn} from '@dsvs/dsvs-shared-ui-lib';
import {WorkflowStateServiceImpl} from '@dsvs/workflow-generator-ui-lib';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable} from 'rxjs';
import {FiReleaseDateHalItem} from '../../../shared/hal/fiReleaseDateHalItem';
import {ProcessHalPage} from '../../../shared/hal/process.hal.item';
import {ServiceupdateHalItem} from '../../../shared/hal/serviceupdate.hal.item';
import {WorkspaceHalItem} from '../../../shared/hal/workspace.hal.item';
import {Serviceupdate} from '../../../shared/interfaces/dtos/serviceupdate';
import {FiReleaseDateServiceImpl} from '../../../shared/services/fi-release-date.service';
import {FileServiceImpl} from '../../../shared/services/file.service';
import {ServiceupdateServiceImpl} from '../../../shared/services/serviceupdate.service';
import {WorkspaceServiceImpl} from '../../../shared/services/workspace.service';

@Component({
  selector: 'fes-serviceupdate-lock',
  templateUrl: './serviceupdate-lock.component.html',
  styleUrls: ['./serviceupdate-lock.component.scss']
})
export class ServiceupdateLockComponent implements OnInit {

  @Input() isNew = false;

  @Input() showLockModal = false;

  @Output() showActivateModalEvent = new EventEmitter<boolean>();

  serviceupdate: Serviceupdate;
  _serviceupdateHalItem: ServiceupdateHalItem;

  file: any;

  releaseVersionsWithoutRelease = false;

  loading = false;
  activatableProcessHalPage: ProcessHalPage;
  notActivatableProcessHalPage: ProcessHalPage;

  workspace: WorkspaceHalItem;
  fiReleaseDate: FiReleaseDateHalItem;

  tableColumnOptions: DsvsTableColumn[] = [];

  @Input()
  // should be type ServiceupdateHalItem, but complains in template on
  // [(value)]="categoryHalItem.crew.sync.data "Data is a read-only property"
  set serviceupdateHalItem(serviceupdateHalItem: any) {
    if (serviceupdateHalItem) {
      this.serviceupdate = serviceupdateHalItem.data;
      this._serviceupdateHalItem = serviceupdateHalItem;
      this.onReloadActivatableProcesses('');
      this.onReloadNotActivatableProcesses('');
    }
  }

  get serviceupdateHalItem(): any {
    return this._serviceupdateHalItem;
  }

  constructor(
    private serviceupdateService: ServiceupdateServiceImpl,
    private workspaceService: WorkspaceServiceImpl,
    private fiReleaseDateService: FiReleaseDateServiceImpl,
    private messageService: MessageService,
    private wgStateService: WorkflowStateServiceImpl,
    private fileService: FileServiceImpl
  ) {
  }

  ngOnInit() {
    if (this._serviceupdateHalItem == null || this.isNew) {
      this.serviceupdate = <Serviceupdate>{
        displayName: '',
        title: ''
      };
    }

    this.tableColumnOptions = [
      {field: 'displayName', header: 'Formular', format: 'PRODUCT'},
      {field: 'fassung', header: 'Basisfassung', format: 'BASE_VERSION'},
      {field: 'displayName', header: 'Basisversion', format: 'BASE_VERSION'},
      {field: 'displayName', header: 'Ergebnisversion', format: 'RESULT_VERSION'},
      {field: 'displayName', header: 'Auftragsstatus', format: 'STEPNAME'},
      {field: 'displayName', header: 'Auftragsnummer', format: 'PROCESS_LINK'},
      {field: 'serviceupdateComment', header: 'Serviceupdatetext'},
      {field: 'group', header: 'Gruppe', format: 'VERSION'}
    ];
  }

  onReloadActivatableProcesses(searchTerm: string, page?: DsvsPage) {
    const stateItems = ['Zur Veröffentlichung'];
    this.onReloadProcesses(searchTerm, stateItems, page).subscribe(
      result => {
        this.activatableProcessHalPage = result;
        result.content.forEach(
          processHalItem => {
            if (!processHalItem.data.resultClientVersionId) {
              this.releaseVersionsWithoutRelease = true;
            }
          }
        );
        this.loading = false;
      }, error2 => {
        this.loading = false;
        console.error(error2);
      });
  }

  onReloadNotActivatableProcesses(searchTerm: string, page?: DsvsPage) {
    this.wgStateService.getAll().subscribe(
      wgStateHalPage => {
        const stateItems = wgStateHalPage.content
        .map(item => item.data.displayName)
        .filter(item => item !== 'Zur Veröffentlichung');

        this.onReloadProcesses(searchTerm, stateItems, page).subscribe(
          result => {
            this.notActivatableProcessHalPage = result;
            this.loading = false;
          }, error2 => {
            this.loading = false;
            console.error(error2);
          });
      }
    );
  }

  onReloadProcesses(searchTerm: string, wgStates: string[] = [], page?: DsvsPage): Observable<ProcessHalPage> {
    this.loading = true;
    this.serviceupdateHalItem.processes.reload();
    return this.serviceupdateHalItem.processes
    .setParams({
      sort: [],
      size: page ? page.size : 10,
      page: page ? page.number : 0,
      query: searchTerm,
      view: 'by-current-step-states',
      viewData: JSON.stringify({wgStates: wgStates})
    }).async;
  }

  lock() {
    this.serviceupdateService.lock(this.serviceupdate.id, true).subscribe(
      res => {
        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: 'Das Serviceupdate wurde erfolgreich gesperrt.'
        });
        this.showActivateModalEvent.emit(false);
      },
      err => this.messageService.add({
        severity: 'error',
        summary: 'Erfolg',
        detail: 'Fehler beim sperren des Serviceupdates.'
      })
    );
  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  cancelModal() {
    this.showActivateModalEvent.emit(false);
  }
}
