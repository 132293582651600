import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isNil } from 'lodash';
import { ScanpoolHalItem } from '../../../shared/hal/scanpool.hal.item';
import { EScanpoolTarget } from '../../../shared/interfaces/dtos/enums/EScanpoolTarget';
import { Scanpool } from '../../../shared/interfaces/dtos/scanpool';
import { FileServiceImpl } from '../../../shared/services/file.service';

@Component({
  selector: 'fes-scanpool-detail',
  templateUrl: './scanpool-detail.component.html',
  styleUrls: ['./scanpool-detail.component.scss']
})
export class ScanpoolDetailComponent {

  scanpool: Scanpool;

  selectedTargetEntity: any = null;
  selectedTargetType: EScanpoolTarget = null;

  _scanpoolHalItem: ScanpoolHalItem;

  @Input()
  set scanpoolHalItem(scanpoolHalItem: any) {
    if (scanpoolHalItem) {
      this.scanpool = scanpoolHalItem.data;
      this.selectedTargetType = this.scanpool.target;
      this._scanpoolHalItem = scanpoolHalItem;
    }
  }

  get scanpoolHalItem(): any {
    return this._scanpoolHalItem;
  }

  @Output() showEditModalEvent = new EventEmitter<boolean>();

  constructor(private fileService: FileServiceImpl) {
  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  cancelModal() {
    this.showEditModalEvent.emit(false);
  }

}
