import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TransitionHalPage} from '@dsvs/workflow-generator-dto';

@Component({
  selector: 'fes-process-detail-step-actions',
  templateUrl: './process-detail-step-actions.component.html',
  styleUrls: ['./process-detail-step-actions.component.scss']
})
export class ProcessDetailStepActionsComponent implements OnInit, OnDestroy {

  @Input()
  transitions: TransitionHalPage;

  @Output()
  transitionSelected: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  onTransitionClicked(transition) {
    this.transitionSelected.emit(transition);
  }
}
