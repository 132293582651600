import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DsvsPage, DsvsPermissionService, DsvsSearchEvent, DsvsSettings, DsvsTableColumn} from '@dsvs/dsvs-shared-ui-lib';
import {Page} from '@dsvs/hal-client';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {ZapPermissions} from '../../../../../enums/zap-permissions.enum';
import {DevVersionHalItem, DevVersionHalPage} from '../../../../shared/hal/devversion.hal.item';
import {FormularHalItem} from '../../../../shared/hal/formular.hal.item';
import {getFilterData} from '../../../../shared/helper/FilterDataHelper';
import {EDevelopmentState} from '../../../../shared/interfaces/dtos/enums/EDevelopmentState';
import {ETagCategory} from '../../../../shared/interfaces/dtos/enums/ETagCategory';
import {DevVersionServiceImpl} from '../../../../shared/services/dev-version.service';
import {TagServiceImpl} from '../../../../shared/services/tag.service';

@Component({
  selector: 'fes-dev-version-table',
  templateUrl: './dev-version-table.component.html',
  styleUrls: ['./dev-version-table.component.scss']
})
export class DevVersionTableComponent implements OnInit {

  EDevelopmentState = EDevelopmentState;

  devVersions: DevVersionHalPage = null;

  _formular: FormularHalItem;

  filters: any[] = [];
  searchTerm = '';
  pageable: any = null;

  @Input()
  set formular(formular: FormularHalItem) {
    this._formular = formular;
    if (formular) {
      const existingFilter = this.filters.find(filter => filter.field === 'formularId');
      if (existingFilter) {
        existingFilter.filterBy = this._formular.data.id;
      } else {
        this.filters.push({
          field: 'formularId',
          filterBy: this._formular.data.id
        });
      }
      this.loadDevVersions();
    }
  }

  @Input()
  isEditable = false;

  @Input()
  isLoading = false;

  @Output()
  createDevelopmentVersion = new EventEmitter<DevVersionHalItem>();

  @Output()
  cancelDevelopmentVersion = new EventEmitter<DevVersionHalItem>();

  @Output()
  publishDevelopmentVersion = new EventEmitter<DevVersionHalItem>();

  @Output()
  downloadDevelopmentVersion = new EventEmitter<DevVersionHalItem>();

  @Output()
  deleteDevelopmentVersion = new EventEmitter<DevVersionHalItem>();

  versionTableColums: DsvsTableColumn[];

  constructor(
    private tagService: TagServiceImpl,
    private devVersionService: DevVersionServiceImpl,
    private fesSettings: DsvsSettings,
    private permissionService: DsvsPermissionService,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit() {
    this.initializeFormTable();
    this.pageable = this.getInitialParams();
  }

  onChange(page: DsvsSearchEvent) {
    if (this._formular) {
      if (page) {
        this.pageable = {
          sort: page.sort,
          size: page.size,
          page: page.number
        };
      }
      this.loadDevVersions();
    }
  }

  getInitialParams() {
    return {
      sort: [{property: 'version', order: 'DESC'}],
      size: this.fesSettings.getOptionValue('DTROWS_FES', 10),
      page: 0
    };
  }

  loadDevVersions() {
    // TODO: use relationService instead of "native" service
    this.isLoading = true;
    this.devVersionService.filter(
      this.searchTerm,
      <any>this.pageable,
      JSON.stringify(this.filters)
    ).subscribe(result => {
        this.devVersions = result;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        console.error(err);
      });
  }

  onCreateDevelopmentVersionClicked(devVersion: DevVersionHalItem) {
    this.createDevelopmentVersion.emit(devVersion);
  }

  onCancelDevelopmentVersionClicked(devVersion: DevVersionHalItem) {
    this.cancelDevelopmentVersion.emit(devVersion);
  }

  onPublishDevelopmentVersionClicked(devVersion: DevVersionHalItem) {
    this.publishDevelopmentVersion.emit(devVersion);
  }

  onDeleteDevelopmentVersionClicked(devVersion: DevVersionHalItem) {
    this.confirmationService.confirm({
      header: 'Entwicklerversion löschen',
      message: 'Soll die Entwicklerversion ' + devVersion.data.version + ' gelöscht werden?',
      acceptLabel: 'Löschen',
      rejectLabel: 'Abbrechen',
      accept: () => {
        this.deleteDevelopmentVersion.emit(devVersion);
      }
    });
  }

  getSplitButtonItems(devVersion: DevVersionHalItem): MenuItem[] {
    if (this.isEditable) {

      const buttons = [
        {
          label: 'Entwicklerversion',
          icon: 'fa ui-icon-code',
          disabled: !this.canCreateDevVersion(devVersion),
          command: () => {
            this.onCreateDevelopmentVersionClicked(devVersion);
          }
        },
        {
          label: 'Finalisieren',
          icon: 'fa ui-icon-cloud-upload',
          disabled: devVersion.data && devVersion.data.state !== EDevelopmentState.UPLOADED,
          command: () => {
            this.onPublishDevelopmentVersionClicked(devVersion);
          }
        },
        {
          label: 'Stornieren',
          icon: 'fa ui-icon-cancel',
          disabled: devVersion.data && (
            devVersion.data.state === EDevelopmentState.RELEASED ||
            devVersion.data.state === EDevelopmentState.CANCELED
          ),
          command: () => {
            this.onCancelDevelopmentVersionClicked(devVersion);
          }
        }
      ];

      if (this.permissionService.hasPermission([ZapPermissions.FES_FORM_DELETE])) {
        buttons.push({
          label: 'Löschen',
          disabled: devVersion.data.state !== EDevelopmentState.NEW,
          icon: 'fa ui-icon-delete-forever',
          command: () => {
            this.onDeleteDevelopmentVersionClicked(devVersion);
          }
        });
      }

      return buttons;

    } else {
      return [];
    }
  }

  canCreateDevVersion(devVersion: DevVersionHalItem) {
    return devVersion.data && (
      devVersion.data.state !== EDevelopmentState.NEW &&
      devVersion.data.state !== EDevelopmentState.CANCELED
    );
  }

  initializeFormTable() {
    this.versionTableColums = this.getFormularColums();
  }

  getTagsForCategory = () => {
    return this.tagService.getTagsForCategories(
      [ETagCategory.TEMP_DEV_TASK],
      '',
      <DsvsPage>{sort: [], size: 0x7fffffff, page: 0}
    );
  };

  getStates = () => {
    return this.devVersionService.states();
  };

  private getFormularColums(): DsvsTableColumn[] {
    // Some Columns are defined in template! (hence sum(x%) < 100)
    return [
      {
        field: 'tag', header: 'Auftrag', sort: true, filter: '', hidden: false, filterable: true, width: '10%',
        // TODO: We need the possiblity for searching and paging here...
        filterDataService: this.getTagsForCategory
      },
      {field: 'version', header: 'Version', sort: true, filter: '', hidden: false, width: '5%'},
      {field: 'parentVersion', header: 'Vorgänger', sort: false, filter: '', hidden: false, width: '5%'},
      {field: 'releaseVersion', header: 'Final', sort: false, filter: '', hidden: false, width: '5%'},
      {
        field: 'state', header: 'Status', format: 'STATE', sort: true, filter: '', hidden: false, width: '15%',
        filterable: true, filterDataService: this.getStates
      },
      {field: 'createdByName', header: 'Heruntergeladen', sort: false, filter: '', hidden: false, width: '15%'},
      {
        field: 'downloadComment',
        header: 'Letzter Kommentar',
        format: 'COMMENT',
        sort: false,
        filter: '',
        hidden: false,
        width: '30%'
      },
      {field: 'lastModifiedDate', header: 'Datum', format: 'DATE', sort: true, filter: '', hidden: false, width: '8%'},
      {field: '', header: '', format: 'ACTION', filter: '', hidden: false, width: '7%'}
    ];
  }

  filterData(searchTerm: string, data?: { pageRequest: Page, value: any }) {
    this.filters = getFilterData(data.value, this.filters);
    this.loadDevVersions();
  }

}
