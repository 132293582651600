import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DsvsAuthorizationService } from '@dsvs/dsvs-shared-ui-lib';
import { MessageService } from 'primeng/components/common/messageservice';
import { DevVersion } from '../../../../../../shared/interfaces/dtos/dev-version';
import { DevVersionDocument } from '../../../../../../shared/interfaces/dtos/documents/files/dev-version-document';
import { VersionDocument } from '../../../../../../shared/interfaces/dtos/documents/files/version-document';
import { ValidationDocument } from '../../../../../../shared/interfaces/dtos/documents/validation-document';
import { EDocumentTypes } from '../../../../../../shared/interfaces/dtos/enums/EDocumentTypes';
import { Formular } from '../../../../../../shared/interfaces/dtos/formular';
import { Version } from '../../../../../../shared/interfaces/dtos/version';
import { VersionServiceImpl } from '../../../../../../shared/services/version.service';
import {
  AddOtherVersionFormularComponent,
  CreationType
} from '../../forms/add-other-version-formular/add-other-version-formular.component';

@Component(
  {
    selector: 'fes-unknown-create-modal',
    templateUrl: './unknown-create-modal.component.html',
    styleUrls: ['./unknown-create-modal.component.scss']
  }
)
export class UnknownCreateModalComponent implements OnInit {

  creationType: CreationType = CreationType.CREATE;

  @Input()
  visible = false;

  @Input()
  processId = null;

  @Input()
  title = 'Unbekannte Datei';

  @Input()
  submitText = 'Weiter';

  @Input()
  validationDocument: ValidationDocument;

  @Input()
  allowedCreationTypes: CreationType[] = [CreationType.CREATE, CreationType.SELECT, CreationType.DEVVERSION];

  @Output()
  displayChange = new EventEmitter();

  @Output()
  sendData = new EventEmitter<ValidationDocument>();

  @ViewChild(AddOtherVersionFormularComponent) addPdfFormular: AddOtherVersionFormularComponent;

  constructor(
    private authorizationService: DsvsAuthorizationService,
    private versionService: VersionServiceImpl,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.creationType = this.allowedCreationTypes[0];
  }

  creationTypeChange(creationType: CreationType) {
    this.creationType = creationType;
  }

  onSendData(event: Event) {
    if (this.addPdfFormular && this.addPdfFormular.unknownDataForm.status !== 'INVALID') {

      const unknownFormData = this.addPdfFormular.unknownDataForm.getRawValue();
      let data;
      if (this.creationType === CreationType.DEVVERSION) {
        data = this.createDevVersion(unknownFormData);
      } else {
        data = this.createVersion(unknownFormData);
      }
      this.sendData.emit(data);

    } else {
      this.messageService.add(
        {
          severity: 'error',
          summary: 'Formular-Fehler',
          detail: 'Das Formular enhält einen Fehler.'
        }
      );
    }
  }

  private createDevVersion(unknownFormData) {
    const validationDocument = (<ValidationDocument>this.validationDocument);
    const devVersionDocument = (<DevVersionDocument>this.validationDocument.validationData);
    const formular = <Formular>unknownFormData.formular.data;

    devVersionDocument.uploadComment = '';

    if (<DevVersion>unknownFormData.devVersion) {
      const devVersion = <DevVersion>unknownFormData.devVersion.data;
      devVersionDocument.devVersionId = devVersion.id;
      devVersionDocument.state = devVersion.state;
      devVersionDocument.userId = devVersion.userId;
      devVersionDocument.parentDevVersion = devVersion;
      devVersionDocument.xdpData = devVersion.xdpData;
      devVersionDocument.xdpData.version = devVersion.version;
    }

    devVersionDocument.formular = formular;
    validationDocument.validationData = devVersionDocument;
    validationDocument.type = EDocumentTypes.DEVVERSION;

    return validationDocument;
  }

  private createVersion(unknownFormData) {
    const validationDocument = (<ValidationDocument>this.validationDocument);
    const versionDocument = (<VersionDocument>this.validationDocument.validationData);
    if (this.creationType === CreationType.CREATE) {
      versionDocument.newForm = this.creationType === CreationType.CREATE;
      versionDocument.firstVersion = this.creationType === CreationType.CREATE;
    } else {
      const formular = <Formular>unknownFormData.formular.data;
      const version = <Version>unknownFormData.version.data;

      versionDocument.version = version;
      versionDocument.formular = formular;
      versionDocument.xdpData = version.xdpData;
      // versionDocument.parentVersion = version.xdpData;
      versionDocument.xdpData.articleNumber = formular.articleNumber;
    }
    validationDocument.validationData = versionDocument;
    validationDocument.type = EDocumentTypes.VERSION;
    return validationDocument;
  }

  onClose() {
    this.addPdfFormular.unknownDataForm.reset();
    this.displayChange.emit(false);
  }
}
