import { Input } from '@angular/core';
import { ProductHalItem, WorkflowHalItem } from '@dsvs/workflow-generator-dto';

export enum CreationType {
  CREATE = 'CREATE',
  SELECT = 'SELECT'
}

export interface ProcessWizzardData {
  form: ProductHalItem;
  workflow: WorkflowHalItem;
  creationType: CreationType;
}

export abstract class ProcessCreateWizzardItemComponent {

  @Input()
  public wizzardData: ProcessWizzardData;

  protected abstract isValid(): boolean;

  protected abstract displayErrors(): void;
}
