import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { DsvsAuthorizationService, DsvsFormHelper } from '@dsvs/dsvs-shared-ui-lib';
import { MessageService } from 'primeng/components/common/messageservice';
import { VersionDocument } from '../../../../../../shared/interfaces/dtos/documents/files/version-document';
import { ValidationDocument } from '../../../../../../shared/interfaces/dtos/documents/validation-document';
import { EFormularTypes } from '../../../../../../shared/interfaces/dtos/enums/EFormularTypes';
import { ESignatureLevel } from '../../../../../../shared/interfaces/dtos/enums/ESignatureLevel';
import { Formular } from '../../../../../../shared/interfaces/dtos/formular';
import { Version } from '../../../../../../shared/interfaces/dtos/version';
import { XdpData } from '../../../../../../shared/interfaces/dtos/xdpdata';
import { AddFormFormularComponent } from '../../forms/add-form-formular/add-form-formular.component';
import { AddVersionFormularComponent } from '../../forms/add-version-formular/add-version-formular.component';
import { AddXdpFormularComponent } from '../../forms/add-xdp-formular/add-xdp-formular.component';
import { VersionModalComponent } from '../../version-modal.component';

@Component(
  {
    selector: 'fes-version-create-modal',
    templateUrl: './version-create-modal.component.html',
    styleUrls: ['./version-create-modal.component.scss']
  }
)
export class VersionCreateModalComponent extends VersionModalComponent<ValidationDocument, VersionDocument> implements OnDestroy {

  @Input()
  title = 'Version hinzufügen';

  @Input()
  submitText = 'Abschicken';

  @Input()
  xdpReadOnly = false;

  @ViewChild(AddFormFormularComponent) addFormFormular: AddFormFormularComponent;
  @ViewChild(AddVersionFormularComponent) addVersionFormular: AddVersionFormularComponent;
  @ViewChild(AddXdpFormularComponent) addXdpFormular: AddXdpFormularComponent;

  constructor(
    private authorizationService: DsvsAuthorizationService,
    private messageService: MessageService
  ) {
    super();
  }

  initStepItems() {
    if (this.validationDocument) {
      return [
        {label: this.validationDocument.fileType + '-Daten Überprüfen'},
        {label: 'Neue Version'},
        {label: this.isNewForm ? 'Neues Formular' : 'Formular überprüfen'}
      ];
    } else {
      return [];
    }
  }

  onAddVersion(event: Event) {
    const isValid = (
      this.addVersionFormular && this.addVersionFormular.addVersionForm.status !== 'INVALID'
      && this.addXdpFormular && this.addXdpFormular.xdpDataForm.status !== 'INVALID'
      && (!this.isNewForm ||
        this.addFormFormular && this.addFormFormular.addFormularForm.status !== 'INVALID')
      // Remove this if version is not new!
    );

    if (isValid) {

      const versionFormData = this.addVersionFormular.addVersionForm.getRawValue();
      const xdpDataFormData = this.addXdpFormular.xdpDataForm.getRawValue();
      const formularDataFormData = this.addFormFormular.addFormularForm.getRawValue();
      const userData = this.authorizationService.getUserData();

      const formular = new Formular(
        formularDataFormData.articleNumber,
        formularDataFormData.articleNumber,
        (this.xdpData && this.xdpData.textModule) ? EFormularTypes.TEXTMODUL : EFormularTypes.FORMULAR,
        formularDataFormData.description,
        [],
        null,
        null,
        false
      );

      if (this.formular && this.formular.id) {
        formular.id = this.formular.id;
      }

      const xdpData = new XdpData(
        formularDataFormData.articleNumber,
        xdpDataFormData.title,
        xdpDataFormData.fassung,
        xdpDataFormData.owner,
        this.xdpData ? this.xdpData.versionHash : null,
        versionFormData.nextVersion,
        xdpDataFormData.attachment,
        xdpDataFormData.dynamisation,
        xdpDataFormData.shape,
        xdpDataFormData.countCopy,
        xdpDataFormData.copyRight,
        xdpDataFormData.signature,
        (this.xdpData && this.xdpData.textModule)
      );

      const version = new Version(
        versionFormData.nextVersion,
        versionFormData.nextVersion,
        this.fileType,
        versionFormData.comment,
        xdpDataFormData.signature,
        this.version ? this.version.signatureLevel : ESignatureLevel.NONE,
        false,
        userData.result.id,
        null,
        this.formular ? this.formular.id : null,
        this.version ? this.version.id : null,
        this.version ? this.version.tags : [],
        this.version ? this.version.group : null,
        xdpData,
        false
      );

      if (this.version && this.version.id) {
        version.id = this.version.id;
      }

      this.validationData.xdpData = xdpData;
      this.validationData.formular = formular;
      this.validationData.version = version;
      this.validationData.increaseMajor = versionFormData.increaseMajor;

      this.sendData.emit(this.validationDocument);

    } else {
      this.messageService.add(
        {
          severity: 'error',
          summary: 'Formular-Fehler',
          detail: 'Das Formular enhält einen Fehler.'
        }
      );
    }
  }

  openNext(event: Event) {

    switch (this.selectedTab) {
      case 0 : {
        if (this.addXdpFormular && this.addXdpFormular.xdpDataForm.status !== 'INVALID') {
          this.selectedTab = this.selectedTab + 1;
          if (this.addVersionFormular && this.validationData.parentFassung) {
            this.addVersionFormular.addVersionForm.patchValue(
              {increaseMajor: (this.validationData.parentFassung !== this.xdpData.fassung)}
            );
          }
        } else {
          DsvsFormHelper.validateAllFormFields(this.addXdpFormular.xdpDataForm);
        }
        break;
      }
      case 1 : {
        if (this.addVersionFormular.addVersionForm.value.formular) {
          this.isNewForm = false;
          this.addFormFormular.addFormularForm.patchValue({
            articleNumber: this.addVersionFormular.addVersionForm.value.formular.data.articleNumber,
            description: this.addVersionFormular.addVersionForm.value.formular.data.description
          });
        }
        if (this.addVersionFormular && this.addVersionFormular.addVersionForm.status !== 'INVALID') {
          this.selectedTab = this.selectedTab + 1;
        } else {
          DsvsFormHelper.validateAllFormFields(this.addVersionFormular.addVersionForm);
        }
        break;
      }
      case 2 : {
        if (this.addFormFormular && this.addFormFormular.addFormularForm.status !== 'INVALID') {
          this.selectedTab = this.selectedTab + 1;
        } else {
          DsvsFormHelper.validateAllFormFields(this.addFormFormular.addFormularForm);
        }
      }
    }
  }

  resetForms() {
    this.selectedTab = 0;
    if (this.addVersionFormular) {
      this.addVersionFormular.addVersionForm.reset();
    }
    if (this.addFormFormular) {
      this.addFormFormular.addFormularForm.reset();
    }
    if (this.addXdpFormular) {
      this.addXdpFormular.xdpDataForm.reset();
    }
  }
}
