import { HttpClient } from '@angular/common/http';
import { DsvsSearchableCrudEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient, HalItem, HalItemConstructor, HalPage, HalPageConstructor } from '@dsvs/hal-client';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { resolveFileName } from '../../../helper/download-helper';
import { Base } from '../interfaces/dtos/base';
import { HalCrudServiceImpl } from './hal.crud.service';
import { HalRelation } from './hal.relation';

export class HalFcrudServiceImpl<T extends Base, R extends HalItem<T>, U extends HalPage<T, R>>
  extends HalCrudServiceImpl<T, R, U>
  implements DsvsSearchableCrudEntityService<T> {

  private backendUrl: string = environment.backend;
  http: HttpClient;

  constructor(v1Client: Promise<HalClient>,
              relation: HalRelation,
              http: HttpClient,
              protected readonly pageConstructor: HalPageConstructor<T, R, U>,
              protected readonly itemConstructor: HalItemConstructor<T, R>) {
    super(v1Client, relation, pageConstructor, itemConstructor);
    this.http = http;
  }

  // TODO: Halify?
  downloadFile(path: string, success?, error?): any {
    return this.http.get(path,
      {responseType: 'blob', observe: 'response'}
    ).subscribe(
      (res) => {
        if (success) {
          success(res);
        }
        saveAs(res.body, resolveFileName(res.headers));
      },
      (err) => {
        if (error) {
          error(err);
        }
        console.error('Could not download file!');
        throw err;
      }
    );
  }

  uploadFile(object: T, file: any): Observable<R> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post<R>(
      this.backendUrl + this.relation.collection + '/' + object.id + '/files',
      formdata,
      {}
    );
  }

  deleteFile(filePath: string): Observable<any> {
    return this.http.delete(filePath);
  }
}
