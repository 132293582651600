import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DsvsPermissionService } from '@dsvs/dsvs-shared-ui-lib';
import { HalItem } from '@dsvs/hal-client';
import { Subscription } from 'rxjs';
import { ZapPermissions } from '../../../../../enums/zap-permissions.enum';
import { CommunicationService } from '../../../../../services/communication-service';
import { ActionType } from '../../../../fes-workflow/enums/action-type.enum';
import { ManuscriptHalItem } from '../../../../shared/hal/manuscript.hal.item';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { RequirementHalItem } from '../../../../shared/hal/requirement.hal.item';
import { Requirement } from '../../../../shared/interfaces/dtos/requirement';
import { FileServiceImpl } from '../../../../shared/services/file.service';
import { UpdateProcessDetailService } from '../process-detail-update.service';

@Component({
  selector: 'fes-process-detail-manuscripts',
  templateUrl: './process-detail-manuscripts.component.html',
  styleUrls: ['./process-detail-manuscripts.component.scss']
})
export class ProcessDetailManuscriptsComponent implements OnInit, OnDestroy {

  @ViewChild('scrollContainer') scrollContainer;

  @Input()
  process: ProcessHalItem;

  showScrollingIndicator = false;

  requirements: RequirementHalItem[] = [];
  manuscripts: ManuscriptHalItem[] = [];

  combinedList: (RequirementHalItem | ManuscriptHalItem)[] = [];
  canCheck = false;

  private actionSubscriptionRef: Subscription = null;

  editManuscript: Requirement;
  showEditModal = false;

  constructor(
    private communicationService: CommunicationService,
    private fileService: FileServiceImpl,
    private permissionService: DsvsPermissionService,
    private updateProcessService: UpdateProcessDetailService
  ) {
  }

  ngOnInit() {
    this.updateProcessService.trigger$.subscribe(() => {
      this.loadManuscripts();
    });
    this.loadManuscripts();
    this.loadRequirements();

    this.canCheck = this.permissionService.hasPermission([ZapPermissions.FES_PROCESS_REQUIREMENTS_CHECK]);

    this.actionSubscriptionRef = this.communicationService.messages.subscribe(
      (message) => {
        if (message.scope === 'ACTION') {
          if (message.message === ActionType.RequirementSelect) {
            this.loadRequirements();
          }
        }
        if (message.message === ActionType.ProcessAddManuscript) {
          this.loadManuscripts();

        }
      });
  }

  private loadManuscripts() {
    this.process.manuscripts.reload();
    this.process.manuscripts.setParams({size: 20000}).async.subscribe(result => {
      this.manuscripts = result.content;
      this.combinedList = [...this.requirements, ...this.manuscripts];
      this.sortCombinedList();

    });

  }

  private loadRequirements() {
    this.process.requirements.reload();
    this.process.requirements.setParams({size: 20000}).async.subscribe(result => {
      this.requirements = result.content;
      this.combinedList = [...this.requirements, ...this.manuscripts];
      this.sortCombinedList();
    });
  }

  sortCombinedList() {
    this.showScrollingIndicator = this.scrollContainer.nativeElement.offsetHeight < this.scrollContainer.nativeElement.scrollHeight;

    this.combinedList = this.combinedList.sort((a: HalItem<any>, b: HalItem<any>) =>
      new Date(b.data.createdDate).getTime() - new Date(a.data.createdDate).getTime()
    );
  }

  ngOnDestroy(): void {
    this.actionSubscriptionRef.unsubscribe();
  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  updateItem(item: HalItem<any>) {
    item.data.done = !item.data.done;
    item.save();
  }

  isManuscript(item: HalItem<any>) {
    return ManuscriptHalItem.name === item.constructor.name;
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    this.showScrollingIndicator = event.target.offsetHeight + event.target.scrollTop < event.target.scrollHeight;
  }

  checkManuscript(item, event) {
    event.stopPropagation();
    if (this.canCheck) {
      this.updateItem(item);
    }
  }

  displayEditModal(event, manuscript) {
    event.preventDefault();
    event.stopPropagation();
    this.editManuscript = manuscript;
    this.showEditModal = true;
  }

  receiveShowEditModal($event) {
    this.showEditModal = $event;
  }

}
