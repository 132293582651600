import { HalClient, HalClientFactory, HalItem, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { ContextHalItem, WorkflowContext } from '@dsvs/workflow-generator-dto';
import { ProcessRelationItem } from '../process.hal.item';
import { WGStepContextRelationItem, WGStepContextRelationPage } from './wgstepcontext.hal.item';

export class WGContextHalItem extends ContextHalItem {
  constructor(data: WorkflowContext,
              client: HalClient,
              factory: HalClientFactory) {
    super(data, client, factory);

    this.stepcontexts = new WGStepContextRelationPage('stepcontexts', this);
    this.currentstepcontext = new WGStepContextRelationItem('currentstepcontext', this);
    this.process = new ProcessRelationItem('process', this);
  }

  public readonly currentstepcontext: WGStepContextRelationItem;
  public readonly stepcontexts: WGStepContextRelationPage;
  public readonly process: ProcessRelationItem;
}

export class WGContextHalPage extends HalPageImpl<WorkflowContext, WGContextHalItem> {

}

export class WGContextRelationPage extends HalRelationPage<WorkflowContext, WGContextHalItem, WGContextHalPage> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, WGContextHalPage, WGContextHalItem);
  }
}

export class WGContextRelationItem extends HalRelationItem<WorkflowContext, WGContextHalItem> {
  constructor(relation: string,
              item: HalItem<any>) {
    super(relation, item, WGContextHalItem);
  }
}
