import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { RoleRelationItem } from '@dsvs/workflow-generator-dto';
import { MainRoleUser } from '../interfaces/dtos/mainroleuser';
import { UserRelationItem } from './user.hal.item';

export class MainRoleUserHalItem extends HalItemImpl<MainRoleUser> {
  constructor(
    data: MainRoleUser,
    client: HalClient,
    factory: HalClientFactory
  ) {
    super(data, client, factory);
    this.role = new RoleRelationItem('role', this);
    this.mainuser = new UserRelationItem('mainuser', this);
  }

  readonly role: RoleRelationItem;
  readonly mainuser: UserRelationItem;
}

export class MainRoleUserHalPage extends HalPageImpl<MainRoleUser, MainRoleUserHalItem> {

}

export class MainRoleUserRelationPage extends HalRelationPage<MainRoleUser, MainRoleUserHalItem, MainRoleUserHalPage> {
  constructor(
    relation: string,
    item: HalItem<any>
  ) {
    super(relation, item, MainRoleUserHalPage, MainRoleUserHalItem);
  }
}

export class MainRoleUserRelationItem extends HalRelationItem<MainRoleUser, MainRoleUserHalItem> {
  constructor(
    relation: string,
    item: HalItem<any>
  ) {
    super(relation, item, MainRoleUserHalItem);
  }
}
