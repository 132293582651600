import { Component, OnInit } from '@angular/core';
import {
  CategoryHalItem,
  CrewHalItem,
  WorkflowActionHistoryComponent,
  WorkflowCategoryServiceImpl,
  WorkflowCrewServiceImpl
} from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/components/common/messageservice';
import { FormularHalItem } from '../../../../../shared/hal/formular.hal.item';
import { FormServiceImpl } from '../../../../../shared/services/form.service';

@Component({
  selector: 'fes-fes-workflow-action-formular-create-history',
  templateUrl: './fes-workflow-action-formular-create-history.component.html',
  styleUrls: ['./fes-workflow-action-formular-create-history.component.scss']
})
export class FesWorkflowActionFormularCreateHistoryComponent
  extends WorkflowActionHistoryComponent
  implements OnInit {

  crewHalItem: CrewHalItem;
  categoryHalItem: CategoryHalItem;
  formularHalItem: FormularHalItem;

  constructor(
    private messageService: MessageService,
    private crewService: WorkflowCrewServiceImpl,
    private categoryService: WorkflowCategoryServiceImpl,
    private formularService: FormServiceImpl
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  payloadChanged(payload) {
    if (payload) {
      if (payload.crewId) {
        this.crewService.getById(payload.crewId).subscribe(
          crewHalItem => {
            this.crewHalItem = crewHalItem;
          }
        );
      }
      if (payload.categoryId) {
        this.categoryService.getById(payload.categoryId).subscribe(
          categoryHalItem => {
            this.categoryHalItem = categoryHalItem;
          }
        );
      }
      if (payload.formularId) {
        this.formularService.getById(payload.formularId).subscribe(
          formularHalItem => {
            this.formularHalItem = formularHalItem;
          }
        );
      }
    }
  }
}
