export enum ZapPermissions {

  LOGIN = 'LOGIN',
  FES_CONFIG = 'FES_CONFIG',
  FES_CONFIG_SETTINGS = 'FES_CONFIG_SETTINGS',
  FES_DEVTEST_NOTIFICATION_DUMMY = 'FES_DEVTEST_NOTIFICATION_DUMMY',

  /// //// /// /// ///

  PERMISSION_NOT_DEFINED = 'PERMISSION_NOT_DEFINED',

  FES_FIRELEASEDATE_OVERVIEW_READ = 'FES_FIRELEASEDATE_OVERVIEW_READ',
  FES_FIRELEASEDATE_CREATE = 'FES_FIRELEASEDATE_CREATE',
  FES_FIRELEASEDATE_READ = 'FES_FIRELEASEDATE_READ',
  FES_FIRELEASEDATE_UPDATE = 'FES_FIRELEASEDATE_UPDATE',
  FES_FIRELEASEDATE_DELETE = 'FES_FIRELEASEDATE_DELETE',

  FES_FORM_ADMIN = 'FES_FORM_ADMIN',
  FES_FORM_OVERVIEW_READ = 'FES_FORM_OVERVIEW_READ',
  FES_FORM_OVERVIEW_V0 = 'FES_FORM_OVERVIEW_V0',
  FES_FORM_CREATE = 'FES_FORM_CREATE',
  FES_FORM_DELETE = 'FES_FORM_DELETE',
  FES_FORM_WORKSPACE_UPDATE = 'FES_FORM_WORKSPACE_UPDATE',
  FES_FORM_TAG_UPDATE = 'FES_FORM_TAG_UPDATE',
  FES_FORM_FIGROUP_UPDATE = 'FES_FORM_FIGROUP_UPDATE',
  FES_FORM_GRUPPE_UPDATE = 'FES_FORM_GRUPPE_UPDATE',

  FES_FORMCOMPARE_CREATE = 'FES_FORMCOMPARE_CREATE',

  FES_PROCESS_OVERVIEW_READ = 'FES_PROCESS_OVERVIEW_READ',
  FES_PROCESS_CREATE = 'FES_PROCESS_CREATE',
  FES_PROCESS_READ = 'FES_PROCESS_READ',
  // FES_PROCESS_UPDATE = 'FES_PROCESS_UPDATE',
  // FES_PROCESS_DELETE = 'FES_PROCESS_DELETE',
  FES_PROCESS_REQUIREMENTS_CHECK = 'FES_PROCESS_REQUIREMENTS_CHECK',

  FES_PROJECT_CREATE = 'FES_PROJECT_CREATE',
  FES_PROJECT_READ = 'FES_PROJECT_READ',
  FES_PROJECT_UPDATE = 'FES_PROJECT_UPDATE',
  FES_PROJECT_DELETE = 'FES_PROJECT_DELETE',

  FES_REQUIREMENT_OVERVIEW_READ = 'FES_REQUIREMENT_OVERVIEW_READ',
  FES_REQUIREMENT_CREATE = 'FES_REQUIREMENT_CREATE',
  FES_REQUIREMENT_READ = 'FES_REQUIREMENT_READ',
  FES_REQUIREMENT_UPDATE = 'FES_REQUIREMENT_UPDATE',
  FES_REQUIREMENT_DELETE = 'FES_REQUIREMENT_DELETE',

  FES_SACHAKTE_CREATE = 'FES_SACHAKTE_CREATE',
  FES_SACHAKTE_READ = 'FES_SACHAKTE_READ',
  FES_SACHAKTE_DELETE = 'FES_SACHAKTE_DELETE',
  FES_SACHAKTE_UPDATE = 'FES_SACHAKTE_UPDATE',

  FES_SEARCH_OVERVIEW_READ = 'FES_SEARCH_OVERVIEW_READ',

  FES_SERVICEUPDATE_OVERVIEW_READ = 'FES_SERVICEUPDATE_OVERVIEW_READ',
  FES_SERVICEUPDATE_CREATE = 'FES_SERVICEUPDATE_CREATE',
  FES_SERVICEUPDATE_READ = 'FES_SERVICEUPDATE_READ',
  FES_SERVICEUPDATE_UPDATE = 'FES_SERVICEUPDATE_UPDATE',
  FES_SERVICEUPDATE_ACTIVATE = 'FES_SERVICEUPDATE_ACTIVATE',
  FES_SERVICEUPDATE_G2_CREATE = 'FES_SERVICEUPDATE_G2_CREATE',
  FES_SERVICEUPDATE_CHANGE_TEXT = 'FES_SERVICEUPDATE_CHANGE_TEXT',

  FES_TAG_OVERVIEW_READ = 'FES_TAG_OVERVIEW_READ',
  FES_TAG_CREATE = 'FES_TAG_CREATE',
  FES_TAG_READ = 'FES_TAG_READ',
  FES_TAG_UPDATE = 'FES_TAG_UPDATE',
  FES_TAG_ACTIVATE = 'FES_TAG_ACTIVATE',

  FES_USER_OVERVIEW_READ = 'FES_USER_OVERVIEW_READ',
  FES_USER_WORKSPACE_UPDATE = 'FES_USER_WORKSPACE_UPDATE',

  FES_WORKFLOW_OVERVIEW_READ = 'FES_WORKFLOW_OVERVIEW_READ',

  FES_WORKFLOW_CATEGORIE_CREATE = 'FES_WORKFLOW_CATEGORIE_CREATE',
  FES_WORKFLOW_CATEGORIE_UPDATE = 'FES_WORKFLOW_CATEGORIE_UPDATE',
  FES_WORKFLOW_CATEGORIE_READ = 'FES_WORKFLOW_CATEGORIE_READ',
  FES_WORKFLOW_CATEGORIE_DELETE = 'FES_WORKFLOW_CATEGORIE_DELETE',

  FES_WORKFLOW_WORKFLOW_CREATE = 'FES_WORKFLOW_WORKFLOW_CREATE',
  FES_WORKFLOW_WORKFLOW_UPDATE = 'FES_WORKFLOW_WORKFLOW_UPDATE',
  FES_WORKFLOW_WORKFLOW_READ = 'FES_WORKFLOW_WORKFLOW_READ',
  FES_WORKFLOW_WORKFLOW_DELETE = 'FES_WORKFLOW_WORKFLOW_DELETE',

  FES_WORKFLOW_CREW_CREATE = 'FES_WORKFLOW_CREW_CREATE',
  FES_WORKFLOW_CREW_UPDATE = 'FES_WORKFLOW_CREW_UPDATE',
  FES_WORKFLOW_CREW_READ = 'FES_WORKFLOW_CREW_READ',
  FES_WORKFLOW_CREW_DELETE = 'FES_WORKFLOW_CREW_DELETE',

  FES_WORKFLOW_GROUP_CREATE = 'FES_WORKFLOW_GROUP_CREATE',
  FES_WORKFLOW_GROUP_UPDATE = 'FES_WORKFLOW_GROUP_UPDATE',
  FES_WORKFLOW_GROUP_READ = 'FES_WORKFLOW_GROUP_READ',
  FES_WORKFLOW_GROUP_DELETE = 'FES_WORKFLOW_GROUP_DELETE',

  FES_WORKFLOW_ROLE_CREATE = 'FES_WORKFLOW_ROLE_CREATE',
  FES_WORKFLOW_ROLE_UPDATE = 'FES_WORKFLOW_ROLE_UPDATE',
  FES_WORKFLOW_ROLE_READ = 'FES_WORKFLOW_ROLE_READ',
  FES_WORKFLOW_ROLE_DELETE = 'FES_WORKFLOW_ROLE_DELETE',

  FES_WORKSPACE_OVERVIEW_READ = 'FES_WORKSPACE_OVERVIEW_READ',
  FES_WORKSPACE_CREATE = 'FES_WORKSPACE_CREATE',
  FES_WORKSPACE_READ = 'FES_WORKSPACE_READ',
  FES_WORKSPACE_UPDATE = 'FES_WORKSPACE_UPDATE',
  FES_WORKSPACE_ACTIVATE = 'FES_WORKSPACE_ACTIVATE',

  FES_SCANPOOL_OVERVIEW_READ = 'FES_SCANPOOL_OVERVIEW_READ',
  FES_SCANPOOL_CREATE = 'FES_SCANPOOL_CREATE',
  FES_SCANPOOL_READ = 'FES_SCANPOOL_READ',
  FES_SCANPOOL_UPDATE = 'FES_SCANPOOL_UPDATE',
  FES_SCANPOOL_DELETE = 'FES_SCANPOOL_DELETE',
}
