import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {DsvsHalAsyncLoaderModule, DsvsInlineEditModule, DsvsSharedModule, DsvsTableModule} from '@dsvs/dsvs-shared-ui-lib';
import {WorkflowGeneratorModule} from '@dsvs/workflow-generator-ui-lib';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {PanelModule} from 'primeng/panel';
import {FileUploadModule, InputTextModule, SplitButtonModule, TooltipModule} from 'primeng/primeng';
import {SharedModule} from '../shared/shared.module';
import {SearchResultItemComponent} from './components/search-result-item/search-result-item.component';
import {SearchResultTableComponent} from './components/search-result-table/search-result-table.component';
import {SearchComponent} from './components/search.component';
import {SearchCategoryNamePipe} from './pipes/search-category-name.pipe';
import {ServiceupdatesModule} from '../serviceupdates/serviceupdates.module';
import {ProcessModule} from '../process/process.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    PanelModule,
    DialogModule,
    ButtonModule,
    FileUploadModule,
    WorkflowGeneratorModule,
    DsvsTableModule,
    DsvsSharedModule,
    DsvsInlineEditModule,
    DsvsHalAsyncLoaderModule,
    InputTextModule,
    SplitButtonModule,
    ServiceupdatesModule,
    ProcessModule,
    TooltipModule
  ],
  declarations: [
    SearchComponent,
    SearchResultTableComponent,
    SearchResultItemComponent,
    SearchCategoryNamePipe,
  ]
})
export class SearchModule {

  static getRoutes(): Routes {
    return [
      {path: '', component: SearchComponent},
      {path: ':value', component: SearchComponent}
    ];
  }
}

