import { Component, OnInit } from '@angular/core';
import {
  WorkflowActionHistoryComponent,
  WorkflowRoleUserServiceImpl
} from '@dsvs/workflow-generator-ui-lib';
import { UserServiceImpl } from '../../../../../shared/services/user.service';

/**
 * Historyclass für den Action Juristzustandigkeit auswahlen.
 * Hierdrin werden nur die verscheidene Tatigkeiten bezuglich diese Action protokoliert.
 * d.h. Bei jeder Auswahl wird der JuristName und der Actionverarbeiter protokoliert.
 */
@Component({
  selector: 'fes-fes-workflow-action-jurist-moderator',
  templateUrl: './fes-workflow-action-jurist-moderator.component.html',
  styleUrls: ['./fes-workflow-action-jurist-moderator.component.scss']
})
export class FesWorkflowActionJuristModeratorComponent extends WorkflowActionHistoryComponent
  implements OnInit {

  private juristname: any;
  private mainuser: any;


  constructor(
    private  userService: UserServiceImpl,
    private roleuserService: WorkflowRoleUserServiceImpl
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * wird aufgerufen, wenn Anderungen wahrend der Protokollierung stattfinden.
   * ansonsten hat keine Wirkung am Aktion selbst.
   * @param payload
   */
  payloadChanged(payload) {

    if (payload) {

      if (payload.juristId) {
        this.roleuserService.getById(payload.juristId).subscribe(
          jurist => {
            this.mainuser = jurist.getData().mainUserId;
            // console.log(this.mainuser);
            if (this.mainuser) {
              this.userService.getById(this.mainuser).subscribe(
                 juristn => {
                   this.juristname = juristn.getData().displayName;
                 }
              );
            }
          }
        );
      }

    }
  }

}
