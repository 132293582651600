import { Base } from './base';
import { EDevelopmentState } from './enums/EDevelopmentState';
import { EFileTypes } from './enums/EFileTypes';
import { EGroupTypes } from './enums/EGroupTypes';
import { Id } from './Id';
import { XdpData } from './xdpdata';

export class DevVersion extends Base {
  constructor(
    public fileType: EFileTypes,
    public version: string,
    public articleNumber: string,
    public userId: number,
    public versionId: Id,
    public formularId: Id,
    public fileId: Id,
    public tag: string,
    public tagId: Id,
    public parentDevVersionId: Id,
    public parentVersion: string,
    public state: EDevelopmentState,
    public releaseVersion: string,
    public releaseVersionId: Id,
    public downloadComment: string,
    public uploadComment: string,
    public releaseComment: string,
    public cancelComment: string,
    public group: EGroupTypes,
    public processId: Id,
    public xdpData: XdpData
  ) {
    super();
  }
}
