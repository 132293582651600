import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  ActionContextHalPage,
  ActionHalItem,
  ActionHalPage,
  ContextHalItem,
  StepContextHalItem,
  StepHalItem,
  TransitionHalItem,
  WorkflowStepContext
} from '@dsvs/workflow-generator-dto';
import {
  WorkflowActionContextServiceImpl,
  WorkflowActionFactoryComponent,
  WorkflowStepContextServiceImpl
} from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/components/common/messageservice';
import { CommunicationService } from '../../../../services/communication-service';
import { ActionType } from '../../enums/action-type.enum';

@Component({
  selector: 'fes-workflow-transition-dialog',
  templateUrl: './workflow-transition-dialog.component.html',
  styleUrls: ['./workflow-transition-dialog.component.scss']
})
export class WorkflowTransitionDialogComponent {

  @ViewChild('noteActionFactory') noteActionFactory: WorkflowActionFactoryComponent;

  _transition: TransitionHalItem;

  @Input() visible: boolean;
  @Input() context: ContextHalItem;
  @Input() stepContext: StepContextHalItem;

  @Input() set transition(transition: TransitionHalItem) {
    this._transition = transition;
    this.loadMissingActions(transition);
    this.loadMissingStep(transition);
  }

  get transition(): TransitionHalItem {
    return this._transition;
  }

  @Output() displayChange = new EventEmitter();
  @Output() transitionSucessful = new EventEmitter();

  loading = false;
  actions: ActionHalPage = null;
  actionContexts: ActionContextHalPage = null;
  missingActions: ActionHalItem[] = [];
  doneActions: ActionHalItem[] = [];
  addCommentAction: ActionHalItem = null;
  step: StepHalItem = null;

  constructor(
    private actionContextService: WorkflowActionContextServiceImpl,
    private messageService: MessageService,
    private communicationService: CommunicationService,
    private workflowStepContextService: WorkflowStepContextServiceImpl
  ) {
  }

  onClose() {
    this.displayChange.emit();
  }

  onPerformTransactionClicked($event) {
    this.loading = true;
    this.saveActionData($event);
  }

  loadMissingStep(transitionHalItem: TransitionHalItem, success?) {
    this.loading = true;
    transitionHalItem.step.reload();
    transitionHalItem.step.async.subscribe(
      stepHalItem => {
        this.step = stepHalItem;
        console.log('Name des náchsten Schrittes: ' + stepHalItem.data.displayName);
        if (success) {
          success(stepHalItem);
        } else {
          this.loading = false;
        }
      }
    );
  }

  loadMissingActions(transitionHalItem: TransitionHalItem, success?) {
    this.loading = true;
    transitionHalItem.actions.reload();
    transitionHalItem.actions.async.subscribe(
      actionHalPage => {
        this.actions = actionHalPage;
        this.stepContext.actioncontexts.reload();
        this.stepContext.actioncontexts.async.subscribe(
          actionContextHalPage => {
            this.actionContexts = actionContextHalPage;
            this.missingActions = this.getMissingActions(this.actions.content);
            this.doneActions = this.getDoneActions(this.actions.content);
            this.addCommentAction = this.getCommentAddAction(this.actions.content);

            if (success) {
              success(actionContextHalPage);
            } else {
              this.loading = false;
            }
          }
        );
      }
    );
  }

  private getMissingActions(actions: ActionHalItem[]): ActionHalItem[] {
    return actions.filter(
      actionHalItem =>
        !this.actionAlreadyExecuted(this.actionContexts, actionHalItem)
        && actionHalItem.data.alias !== ActionType.CommentAdd
    );
  }

  private getDoneActions(actions: ActionHalItem[]): ActionHalItem[] {
    return actions.filter(
      actionHalItem =>
        this.actionAlreadyExecuted(this.actionContexts, actionHalItem)
        && actionHalItem.data.alias !== ActionType.CommentAdd
    );
  }

  private getCommentAddAction(actions: ActionHalItem[]): ActionHalItem {
    return actions.find(
      actionHalItem => actionHalItem.data.alias === ActionType.CommentAdd
    );
  }

  saveActionData(transition: TransitionHalItem) {
    if (this.addCommentAction) {
      if (this.noteActionFactory && !this.noteActionFactory.getInputInstance().isValid()) {
        this.noteActionFactory.getInputInstance().validate();
        this.loading = false;
        return;
      }
      this.noteActionFactory.getInputInstance().saveAction();
      this.noteActionFactory.success.subscribe(
        () => {
          this.loadMissingActions(
            transition,
            (actionContextHalItem) => {
              this.transitionToNextStep(transition);
            });
        }
      );
      this.noteActionFactory.error.subscribe((error) => {
        this.loading = false;

        this.messageService.add({
          severity: 'error',
          summary: 'Aktionsfehler',
          detail: error.error.message
        });

      });
    } else {

      this.transitionToNextStep(transition);
    }
  }

  private transitionToNextStep(transition: TransitionHalItem) {
    if (this.missingActions.length > 0) {
      this.onActionValidationError();
    } else {
      this.onChangeStepClicked(transition);
    }
  }

  private onActionValidationError() {
    this.loading = false;
    this.messageService.add({
      severity: 'error',
      summary: 'Aktionsfehler',
      detail: 'Aktionsdaten nicht gültig.'
    });
  }

  private actionAlreadyExecuted(actionContextHalPage: ActionContextHalPage, actionHalItem: ActionHalItem) {
    let actionExecutedInStep = false;
    actionContextHalPage.content.forEach(
      actionContextHalItem => {
        if (actionHalItem.data.id === actionContextHalItem.data.actionId) {
          actionExecutedInStep = true;
        }
      }
    );
    return actionExecutedInStep;
  }

  onChangeStepClicked(transition: TransitionHalItem) {
    const workflowStepContext = this.getInitialWorkflowStepContext(transition);
    this.workflowStepContextService.create(workflowStepContext).subscribe(
      (res) => this.onTransitionSuccess(transition),
      (err) => this.onTransitionError()
    );
  }

  private getInitialWorkflowStepContext(transition: TransitionHalItem) {
    return <WorkflowStepContext>{
      transitionId: transition.data.id,
      contextId: this.context.data.id,
      stepId: null,
      order: 0
    };
  }

  private onTransitionSuccess(transition: TransitionHalItem) {
    this.transitionSucessful.emit(transition);
  }

  private onTransitionError() {
    this.loading = false;
    this.messageService.add({
      severity: 'error',
      summary: 'Aktionsfehler',
      detail: 'Nicht alle erforderlichen Aktionen für diesen Schritt wurden ausgeführt.'
    });
  }
}
