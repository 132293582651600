import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { getExceptionMessages } from '../../../../../../helper/exception-helper';
import { RepositoryService } from '../../../../../../services/repository-service';
import { XdpFileUploadComponent } from '../../../../../shared/components/xdp-file-upload/xdp-file-upload.component';
import { DevVersion } from '../../../../../shared/interfaces/dtos/dev-version';
import { ValidationDocument } from '../../../../../shared/interfaces/dtos/documents/validation-document';
import { Version } from '../../../../../shared/interfaces/dtos/version';
import { DevVersionServiceImpl } from '../../../../../shared/services/dev-version.service';
import { FormServiceImpl } from '../../../../../shared/services/form.service';
import { VersionServiceImpl } from '../../../../../shared/services/version.service';
import { FormularCreateModalComponent } from '../formular-create-modal/formular-create-modal.component';

@Component({
  selector: 'fes-upload-formular',
  templateUrl: './upload-formular.component.html',
  styleUrls: ['./upload-formular.component.css']
})
export class UploadFormularComponent {

  @ViewChild(XdpFileUploadComponent) xdpUploadForm: XdpFileUploadComponent;
  @ViewChild(FormularCreateModalComponent) formularCreateModal: FormularCreateModalComponent;

  @Input()
  xdpReadOnly = false;

  xdpErrors = [];

  isValidating = false;
  isLoading = false;
  versionFile: File = null;

  formularCreateModalVisible = false;
  validationDocument: ValidationDocument = null;

  @Output()
  uploadSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  uploadError: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private repositoryService: RepositoryService,
    private versionService: VersionServiceImpl,
    private devVersionService: DevVersionServiceImpl,
    private formularService: FormServiceImpl
  ) {
  }

  onDisplayChange(isVisible: boolean) {
    this.formularCreateModalVisible = isVisible;
    this.xdpUploadForm.fileuploader.clear();
    this.xdpUploadForm.uploadFileForm.reset();
  }

  onFileChanged() {
    this.versionFile = this.xdpUploadForm.uploadFileForm.value.versionFile;
    this.onGetXDPData(this.versionFile);
  }

  onGetXDPData(versionFile: any): void {
    this.isValidating = true;
    this.versionService.validateXdp(
      versionFile
    ).subscribe({
      next: this.onGetXDPDataSuccess.bind(this),
      error: this.onGetXDPDataError.bind(this)
    });
  }

  onSendData(validationDocument: ValidationDocument) {
    this.formularCreateModalVisible = false;
    const version = validationDocument.validationData.version;
    const formular = validationDocument.validationData.formular;
    const increaseMajor = validationDocument.validationData.increaseMajor;
    if (!version.formularId) {
      this.formularService.create(formular).subscribe(
        formularHalItem => {
          version.formularId = formularHalItem.data.id;
          this.onUploadFile(version, increaseMajor);
        }
      );
    } else {
      this.onUploadFile(version, increaseMajor);
    }

  }

  onSendDevVersionData(devVersion: DevVersion) {
    this.formularCreateModalVisible = false;
    this.onUploadDevFile(devVersion);
  }

  onUploadFile(version: Version, increaseMajor: boolean): void {
    this.isLoading = true;
    this.versionService.uploadFormVersion(
      version,
      this.versionFile,
      increaseMajor
    ).subscribe({
      next: this.onAddVersionSuccess.bind(this),
      error: this.onAddVersionError.bind(this)
    });
  }

  onUploadDevFile(devVersion: DevVersion): void {
    this.isLoading = true;
    this.devVersionService.uploadDevVersion(
      devVersion,
      this.versionFile
    ).subscribe({
      next: this.onAddVersionSuccess.bind(this),
      error: this.onAddVersionError.bind(this)
    });
  }

  onAddVersionSuccess(result): void {
    this.isLoading = false;
    this.xdpUploadForm.fileuploader.clear();
    this.xdpUploadForm.uploadFileForm.reset();
    this.xdpErrors = [];
    this.xdpErrors.push(
      {
        severity: 'success',
        summary: 'Version-Upload',
        detail: 'Version erfolgreich angelegt.'
      }
    );
    this.uploadSuccess.emit(result);
  }

  onAddVersionError(error): void {
    this.isLoading = false;
    this.xdpUploadForm.fileuploader.clear();
    this.xdpUploadForm.uploadFileForm.reset();
    this.xdpErrors = [];
    this.xdpErrors.push(...getExceptionMessages(error));

    this.uploadError.emit(error);
  }

  onGetXDPDataSuccess(result: ValidationDocument): void {
    this.isValidating = false;
    this.validationDocument = result;
    this.formularCreateModalVisible = true;
  }

  onGetXDPDataError(error): void {

    this.xdpUploadForm.fileuploader.clear();
    this.xdpUploadForm.uploadFileForm.reset();

    this.isValidating = false;
    this.xdpErrors = [];
    this.xdpErrors.push(...getExceptionMessages(error));
  }

  onFileRemoved() {
  }
}
