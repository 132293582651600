import { Component, OnInit } from '@angular/core';
import { WorkflowActionHistoryComponent } from '@dsvs/workflow-generator-ui-lib';

@Component({
  selector: 'fes-fes-workflow-action-deliverydateexpected-history',
  templateUrl: './fes-workflow-action-deliverydateexpected-history.component.html',
  styleUrls: ['./fes-workflow-action-deliverydateexpected-history.component.scss']
})
export class FesWorkflowActionDeliverydateexpectedHistoryComponent extends WorkflowActionHistoryComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
