import { Pipe, PipeTransform } from '@angular/core';
import { EScanpoolTarget } from '../../shared/interfaces/dtos/enums/EScanpoolTarget';

@Pipe({
  name: 'scanpoolTargetTypeName'
})
export class ScanpoolTargetTypeNamePipe implements PipeTransform {

  transform(value: EScanpoolTarget): string {
    switch (value) {
      case EScanpoolTarget.PROCESS:
        return 'Auftrag';
      case EScanpoolTarget.REQUIREMENT:
        return 'Anforderung';
      default:
        return 'Unbekannt';
    }
  }
}
