import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActionContextHalItem} from '@dsvs/workflow-generator-dto';
import {WorkflowActionDataDto} from '@dsvs/workflow-generator-ui-lib';
import {MessageService} from 'primeng/components/common/messageservice';
import {ManuscriptHalItem, ManuscriptHalPage} from '../../../../shared/hal/manuscript.hal.item';
import {ProcessHalItem} from '../../../../shared/hal/process.hal.item';
import {WGContextHalItem} from '../../../../shared/hal/wfg/wgcontext.hal.item';
import {FileServiceImpl} from '../../../../shared/services/file.service';
import {FesWorkflowActionComponent} from '../fes-workflow-action';

@Component({
  selector: 'fes-workflow-action-process-add-manuscript',
  templateUrl: './fes-workflow-action-process-add-manuscript.component.html',
  styleUrls: ['./fes-workflow-action-process-add-manuscript.component.scss']
})
export class FesWorkflowActionProcessAddManuscriptComponent extends FesWorkflowActionComponent implements OnInit {

  @Output() fileChanged = new EventEmitter<any>();
  @Output() fileRemoved = new EventEmitter<any>();
  @ViewChild('fileuploader') fileuploader: any;

  process: ProcessHalItem;
  manuscripts: ManuscriptHalPage;
  files = [];

  constructor(
    messageService: MessageService,
    private fileService: FileServiceImpl
  ) {
    super(messageService);
  }

  ngOnInit() {
    this.inputForm = new FormGroup(
      {
        text: new FormControl(''),
        files: new FormControl(null)
      });
    this.loadProcess();
  }

  loadProcess() {
    (<WGContextHalItem>this.context).process.async.subscribe(
      process => {
        this.process = process;
        this.process.manuscripts.async.subscribe(
          manuscripts => {
            this.manuscripts = manuscripts;
          }
        );
      }
    );
  }

  downloadFile(path: string) {
    this.fileService.downloadFileUrl(path);
  }

  public collectActionData(): WorkflowActionDataDto {
    if (this.inputForm.value.files != null) {
      return <WorkflowActionDataDto>{
        text: this.inputForm.value.text,
        file1: (this.inputForm.value.files && this.inputForm.value.files.length > 0) ? this.inputForm.value.files[0] : null,
        file2: (this.inputForm.value.files && this.inputForm.value.files.length > 1) ? this.inputForm.value.files[1] : null,
        file3: (this.inputForm.value.files && this.inputForm.value.files.length > 2) ? this.inputForm.value.files[2] : null,
        file4: (this.inputForm.value.files && this.inputForm.value.files.length > 3) ? this.inputForm.value.files[3] : null,
        file5: (this.inputForm.value.files && this.inputForm.value.files.length > 4) ? this.inputForm.value.files[4] : null
      };
    } else {
      return <WorkflowActionDataDto>{
        text: this.inputForm.value.text
      };
    }
  }

  updateManuscript(manuscriptHalItem: ManuscriptHalItem) {
    manuscriptHalItem.save();
  }

  isValid() {
    return this.inputForm.value.text.length > 0;
  }

  onFileChange(target) {
    this.files.push(target);
    this.inputForm.get('files').setValue(this.files);
    if (this.fileChanged != null) {
      this.fileChanged.emit();
    }
    this.fileuploader.fileuploader.clear();
  }

  onFileRemoved(file) {
    const index = this.files.indexOf(file);
    if (index !== -1) {
      this.files.splice(index, 1);
    }
  }

  resetForm() {
    this.files = [];
    this.fileuploader.fileuploader.clear();
    this.inputForm.reset();
  }

  onSuccess(actionContextItem: ActionContextHalItem) {
    super.onSuccess(actionContextItem);
    this.resetForm();
    this.process.manuscripts.reload();
    this.loadProcess();
  }

  add() {
    this.saveAction();
  }

}
