import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionContextHalItem } from '@dsvs/workflow-generator-dto';
import { WorkflowActionDataDto } from '@dsvs/workflow-generator-ui-lib';
import { MessageService } from 'primeng/primeng';
import { ManuscriptHalPage } from '../../../../shared/hal/manuscript.hal.item';
import { ProcessHalItem } from '../../../../shared/hal/process.hal.item';
import { FesWorkflowActionComponent } from '../fes-workflow-action';

@Component({
  selector: 'fes-fes-workflow-action-manuscript-add-formulardetails',
  templateUrl: './fes-workflow-action-manuscript-add-formulardetails.component.html',
  styleUrls: ['./fes-workflow-action-manuscript-add-formulardetails.component.scss']
})
export class FesWorkflowActionManuscriptAddFormulardetailsComponent extends FesWorkflowActionComponent implements OnInit {

  process: ProcessHalItem;
  manuscript: ManuscriptHalPage;
  requireEnable: boolean;
  statusPro: boolean;

  constructor(messageService: MessageService) {
    super(messageService);

  }

  ngOnInit() {
    this.statusPro = true;
    this.requireEnable = true
    this.inputForm = new FormGroup(
      {
        title: new FormControl('',Validators.required),
        fassung: new FormControl('', Validators.required),
        eigentuemer: new FormControl('', Validators.required)
      });
    this.loadProcess();
  }

  loadProcess(){

    this.context.process.async.subscribe(
      processHalItem => {
        this.inputForm.patchValue({title: processHalItem.product.sync.data.displayName,
          fassung:processHalItem.resultversion.sync.data.comment,
        eigentuemer: processHalItem.activemainuser.sync.data.displayName});
      });

  }


  checkFassung() {
    if (this.inputForm.controls.fassung && this.inputForm.controls.fassung.value && this.inputForm.controls.fassung.value.length < 0 ){
      this.onError("bitte Fasuung Eingeben");
    }
  }

  checkTitle() {

  }

  checkOwner() {

  }

  collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      title: this.inputForm.value.title,
      fassung: this.inputForm.value.fassung,
      eigentuemer: this.inputForm.value.eigentuemer
    };
  }

  isValid(): boolean {
    return (this.inputForm.value.title.length > 0 && this.inputForm.value.fassung.length > 0 && this.inputForm.value.eigentuemer.length > 0);
  }

  resetForm() {
    super.resetForm();
  }

  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
  }

  add() {
    this.saveAction();
  }



}
