import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DsvsBreadcrumbService, InlineEditComponent} from '@dsvs/dsvs-shared-ui-lib';
import {ActionHalPage, ProductHalItem, StateHalItem, StepHalItem, TransitionHalItem, TransitionHalPage, WorkflowTransition} from '@dsvs/workflow-generator-dto';
import {WorkflowContextServiceImpl, WorkflowStepContextServiceImpl} from '@dsvs/workflow-generator-ui-lib';
import {MessageService} from 'primeng/components/common/messageservice';
import {Observable, Subscription} from 'rxjs';
import {CommunicationService} from '../../../../services/communication-service';
import {ActionType} from '../../../fes-workflow/enums/action-type.enum';
import {ProcessHalItem} from '../../../shared/hal/process.hal.item';
import {VersionHalItem} from '../../../shared/hal/version.hal.item';
import {WGContextHalItem} from '../../../shared/hal/wfg/wgcontext.hal.item';
import {WGStepContextHalItem} from '../../../shared/hal/wfg/wgstepcontext.hal.item';
import {ProcessServiceImpl} from '../../../shared/services/process.service';
import {UpdateProcessDetailService} from './process-detail-update.service';

@Component({
  selector: 'fes-process-detail',
  templateUrl: './process-detail.component.html',
  styleUrls: ['./process-detail.component.scss']
})

export class ProcessDetailComponent implements OnInit, OnDestroy {
  public currentStepContext: WGStepContextHalItem;
  public currentStep: StepHalItem;
  public actions: ActionHalPage;
  public transitions: TransitionHalPage;
  public product: ProductHalItem;
  public state: StateHalItem;
  public process: ProcessHalItem;
  public workflowContext: WGContextHalItem = null;
  public version: VersionHalItem = null;

  public sidebarOpen = true;
  public showTransitionDialog: boolean;
  public showHistoryDialog: boolean;
  public showNotesDialog: boolean;
  public showDownloadsDialog: boolean;
  public showContactDialog: boolean;
  public showRequirementDialog: boolean;

  public selectedTransition: WorkflowTransition;

  private actionSubscriptionRef: Subscription = null;

  private canAddNote = false;

  constructor(private route: ActivatedRoute,
              private processService: ProcessServiceImpl,
              private messageService: MessageService,
              private workflowContextService: WorkflowContextServiceImpl,
              private workflowStepContextService: WorkflowStepContextServiceImpl,
              private breadcrumbService: DsvsBreadcrumbService,
              private communicationService: CommunicationService,
              private updateManuscriptsService: UpdateProcessDetailService
  ) {

  }

  ngOnInit() {
    this.reloadData();
    this.initToolbar();
    this.subscribeActions();
  }

  ngOnDestroy(): void {
    this.actionSubscriptionRef.unsubscribe();
  }

  subscribeActions() {
    this.actionSubscriptionRef = this.communicationService.messages.subscribe(
      (message) => {
        if (message.scope === 'ACTION') {

          switch (message.message) {
            case ActionType.VersionSelect:
            case ActionType.FinalVersionSelect: {
              // this.reloadBaseVersion();
              this.reloadProcess();
              break;
            }
            case ActionType.ServiceupdateSelect:
            case ActionType.RequirementAdd:
            case ActionType.ReturnDate:
            case ActionType.ProcessAddManuscript:
            case ActionType.DevVersionFinalization: {
              this.reloadProcess();
              break;
            }
          }
        }
      });
  }

  private reloadProcess() {
    this.processService.getById(this.process.data.id).subscribe(
      processHalItem => {
        this.process = processHalItem;
        this.reloadBaseVersion();
      }
    );
  }

  private reloadBaseVersion() {
    if (this.process.baseversion.async) {
      this.process.baseversion.reload();
      this.process.baseversion.async.subscribe(
        versionHalPage => this.version = versionHalPage
      );
    }
  }

  initToolbar(): void {
    this.breadcrumbService.setMenuItems([
      {label: 'Aufträge'},
      {label: this.process.data.displayName}
    ]);
  }

  // Uses 'hard' reload!
  reloadData() {
    const process = this.route.snapshot.data['process'];

    if (process !== undefined) {
      this.process = process;

      if (this.process.data.baseClientVersionId) {
        this.process.baseversion.reload();
        this.process.baseversion.async.subscribe(
          versionHalPage => {
            this.version = versionHalPage;
          }
        );
      }
      if (this.process.data.productId) {
        this.process.product.reload();
        this.process.product.async.subscribe(
          productHalPage => {
            this.product = productHalPage;
          }
        );
      }

      if (this.process.manuscripts) {
        this.process.manuscripts.reload();
        this.updateManuscriptsService.trigger(true);
      }

      if (this.process.data.workflowContextId) {
        this.workflowContextService.getById(this.process.data.workflowContextId).subscribe(
          workflowContext => {
            this.workflowContext = <WGContextHalItem>workflowContext;
            this.workflowContext.currentstepcontext.reload();
            this.workflowContext.currentstepcontext.async.subscribe(
              currentstepcontext => {
                this.currentStepContext = currentstepcontext;

                this.currentStepContext.step.reload();
                this.currentStepContext.step.async.subscribe(
                  step => {
                    this.currentStep = step;

                    this.currentStepContext.executableactions.reload();
                    this.currentStepContext.executableactions.setParams({size: 2000}).async.subscribe(
                      actions => {
                        this.actions = actions;

                        this.canAddNote = false;
                        actions.content.forEach(value => {
                          if (value.data.alias === ActionType.NoteAdd) {
                            this.canAddNote = true;
                          }
                        });
                      }
                    );

                    this.currentStepContext.executabletransitions.reload();
                    this.currentStepContext.executabletransitions.setParams({size: 2000}).async.subscribe(
                      transitions => this.transitions = transitions
                    );

                    this.currentStep.state.reload();
                    this.currentStep.state.async.subscribe(
                      state => this.state = state
                    );
                  }
                );
              }
            );
          });

      } else {
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Fehler',
            detail: 'Der Auftrag hat keinen WorkflowContext'
          });
      }

    }

  }

  onSidebarClicked(event: any) {
    this.sidebarOpen = !this.sidebarOpen;
  }

  /********************************************
   * Transition
   *******************************************/

  onTransitionSelected(transition: WorkflowTransition) {
    this.selectedTransition = transition;
    this.showTransitionDialog = true;
  }

  /********************************************
   * Step
   *******************************************/

  transitionSucessful(transition: TransitionHalItem) {
    this.showTransitionDialog = false;
    this.reloadData();
  }

  /********************************************
   * Inline-Editing
   *******************************************/
  save = (value, component: InlineEditComponent): Observable<any> => {
    return Observable.of(null);
  };

  showHistory() {
    this.showHistoryDialog = true;
  }

  onCloseHistoryDialog() {
    this.showHistoryDialog = false;
  }

  showNotes() {
    this.showNotesDialog = true;
  }

  onCloseNotesDialog() {
    this.showNotesDialog = false;
  }

  showDownloads() {
    this.showDownloadsDialog = true;
  }

  onCloseDownloadsDialog() {
    this.showDownloadsDialog = false;
  }

  showContact() {
    this.showContactDialog = true;
  }

  onCloseContactDialog() {
    this.showContactDialog = false;
  }


  showRequirements() {
    this.showRequirementDialog = true;
  }


  onCloseRequirementDialog() {
    this.showRequirementDialog = false;
  }

}
