import {Component, OnInit} from '@angular/core';
import {Page} from '@dsvs/hal-client';
import {WorkflowActionDataDto} from '@dsvs/workflow-generator-ui-lib';
import {MessageService} from 'primeng/api';
import {FiReleaseDateHalItem, FiReleaseDateHalPage} from '../../../../shared/hal/fiReleaseDateHalItem';
import {ProcessHalItem} from '../../../../shared/hal/process.hal.item';
import {WGContextHalItem} from '../../../../shared/hal/wfg/wgcontext.hal.item';
import {FiReleaseDateServiceImpl} from '../../../../shared/services/fi-release-date.service';
import {FesWorkflowActionComponent} from '../fes-workflow-action';

@Component({
  selector: 'fes-fes-workflow-action-fireleasedate-select',
  templateUrl: './fes-workflow-action-fireleasedate-select.component.html',
  styleUrls: ['./fes-workflow-action-fireleasedate-select.component.scss']
})
export class FesWorkflowActionFireleasedateSelectComponent extends FesWorkflowActionComponent implements OnInit {

  loading: boolean;

  cols: any[];

  processHalItem: ProcessHalItem;

  fireleasedateHalPage: FiReleaseDateHalPage;

  selectedFireleasedate: FiReleaseDateHalItem;

  pageSize = 5;
  releasePage: Page = <Page>{
    sort: [
      {
        order: 'DESC',
        property: 'releaseDate'
      }
    ],
    size: this.pageSize,
    page: 0,
    number: 0,
    totalElements: 0,
    totalPages: 0
  };

  constructor(
    private fireleasedateService: FiReleaseDateServiceImpl,
    messageService: MessageService
  ) {
    super(messageService);

    this.cols = [
      {field: 'displayName', header: 'FI-Termin', sort: true},
      {field: 'releaseDate', header: 'Datum', sort: true, format: 'DATE'}
    ];
  }

  ngOnInit() {
    this.onReloadData('', this.releasePage);
  }

  loadPreviousReleases() {
    this.releasePage.number--;
    this.onReloadData('', this.releasePage);
  }

  loadNextReleases() {
    this.releasePage.number++;
    this.onReloadData('', this.releasePage);
  }

  onReloadData(searchTerm: string, page?: Page) {
    this.loading = true;
    (<WGContextHalItem>this.context).process.async.subscribe(
      process => {

        this.processHalItem = process;

        process.fireleasedate.reload();

        if (process.fireleasedate.async) {
          process.fireleasedate.async.subscribe(fireleasedate => this.selectedFireleasedate = fireleasedate);
        }

        if (!page) {
          page = <Page>{
            number: 0,
            size: 2000,
            sort: [
              {
                order: 'ASC',
                property: 'releaseDate'
              }
            ]
          };
        }

        this.fireleasedateService.search(searchTerm, page, {name: 'selectable-for-processes'}).subscribe(
          fireleasedates => {
            this.fireleasedateHalPage = fireleasedates;
            this.releasePage.totalElements = fireleasedates.page.totalElements;
            this.releasePage.totalPages = fireleasedates.page.totalPages;
            this.loading = false;
          },
          err => {
            console.error(err);
            this.loading = false;
          }
        );
      }
    );
  }

  isValid(): boolean {
    return !!(this.selectedFireleasedate);
  }

  collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      fireleasedateId: this.selectedFireleasedate.data.id
    };
  }

  disableControls() {
    this.loading = true;
  }

  enableControls() {
    this.loading = false;
  }

  resetForm() {
    this.selectedFireleasedate = null;
  }

  public selectionChange(value) {
    this.saveAction();
  }

  checkIfReleaseIsSelected(release) {
    if (this.selectedFireleasedate) {
      if (release.data.id === this.selectedFireleasedate.data.id) {
        return true;
      }
    }
    return false;
  }

  toggleRadio($event, release) {
    if ($event.target.checked) {
      if (this.selectedFireleasedate && this.selectedFireleasedate.data.id === release.data.id) {
        this.selectedFireleasedate = null;
        this.processHalItem.data['fiReleaseDateId'] = null;
        this.processHalItem.save().subscribe(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Aktion',
            detail: 'Aktion erfolgreich ausgeführt'
          });
        }, error => {
          console.error(error);
        });
      } else {
        this.selectedFireleasedate = release;
      }
      this.saveAction();
    }
  }
}
