import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserHalItem} from '../../../../../shared/hal/user.hal.item';
import {CrewHalItem, RoleUserHalPage} from '@dsvs/workflow-generator-dto';

@Component({
  selector: 'fes-users-list-crews',
  templateUrl: './users-list-crews.component.html',
  styleUrls: ['./users-list-crews.component.scss']
})
export class UsersListCrewsComponent implements OnInit {

  @Input() rowData: UserHalItem;

  @Output()
  crewSelected: EventEmitter<any> = new EventEmitter<any>();

  crews: string[] = [];
  singleCrew: CrewHalItem;

  constructor() {
  }

  ngOnInit() {
    this.rowData.roleUsers.async.subscribe((result: RoleUserHalPage) => {
      for (let i = 0; i < result.content.length; i++) {
        result.content[i].crew.async.subscribe((crew: CrewHalItem) => {
          this.singleCrew = crew;
          if (this.crews.indexOf(crew.data.displayName) === -1) {
            this.crews.push(crew.data.displayName);
            if (this.crews.length > 1) {
              this.singleCrew = null;
            }
          }
        });
      }
    });
  }

  onCrewSelect(event) {
    this.crewSelected.emit(event);
  }

}
