import { Component, Input, OnInit } from '@angular/core';
import { ManuscriptHalItem } from '../../../../../shared/hal/manuscript.hal.item';
import { ManuscriptServiceImpl } from '../../../../../shared/services/manuscript.service';

@Component({
  selector: 'fes-process-detail-manuscripts-icon',
  templateUrl: './process-detail-manuscripts-icon.component.html',
  styleUrls: ['./process-detail-manuscripts-icon.component.scss']
})
export class ProcessDetailManuscriptsIconComponent implements OnInit {

  @Input()
  manuscript: ManuscriptHalItem;
  isEditable = false;

  constructor(
    private manuscriptService: ManuscriptServiceImpl
  ) {
  }

  ngOnInit() {
    this.manuscriptService.canEdit(this.manuscript.data.id).subscribe(result => {
      if (result) {
        this.isEditable = true;
      }
    });
  }
}
