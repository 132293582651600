import { HttpClient, HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DsvsPage, DsvsSearchableReadEntityService } from '@dsvs/dsvs-shared-ui-lib';
import { HalClient } from '@dsvs/hal-client';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FormularHalItem, FormularHalPage } from '../hal/formular.hal.item';
import { HalReadServiceImpl } from '../hal/hal.read.service';
import { HalRelation } from '../hal/hal.relation';
import { FES_V1_CLIENT_TOKEN } from '../hal/hal.token';
import { Formular } from '../interfaces/dtos/formular';
import { Id } from '../interfaces/dtos/Id';
import { NextVersion } from '../interfaces/dtos/nextversion';

export interface FormService extends DsvsSearchableReadEntityService<Formular> {
  update(formular: Formular): Observable<FormularHalItem>;

  create(formular: Formular): Observable<FormularHalItem>;

  nextVersion(formularId: Id, version: string, parentVersion: string, increaseMajor: boolean): Observable<NextVersion>;
}

@Injectable({
  providedIn: 'root'
})
export class FormServiceImpl extends HalReadServiceImpl<Formular, FormularHalItem, FormularHalPage> implements FormService {

  private backend: string = environment.backend;

  constructor(@Inject(FES_V1_CLIENT_TOKEN) v1Client: Promise<HalClient>, private http: HttpClient) {
    super(
      v1Client,
      <HalRelation>{single: 'form', collection: 'forms'},
      FormularHalPage,
      FormularHalItem);
  }

  update(formular: Formular): Observable<FormularHalItem> {
    return this.execute<FormularHalItem>((v1Client: HalClient) => v1Client
      .typedPut<Formular, FormularHalItem>(this.itemConstructor, formular, this.relation.single, <any>{entityId: formular.id})
    );
  }

  create(formular: Formular): Observable<FormularHalItem> {
    return this.execute<FormularHalItem>((v1Client: HalClient) => v1Client
      .typedPost<Formular, FormularHalItem>(this.itemConstructor, formular, this.relation.single)
    );
  }

  deleteForm(formular: Formular): Observable<void> {
    return this.http.delete<void>(this.backend + this.relation.collection + '/' + formular.id);
  }

  deleteFormHard(formular: Formular, securitytoken: string): Observable<void> {
    return this.http.delete<void>(this.backend + this.relation.collection + '/' + formular.id + '/hard',
      {headers: {'X-Form-Delete-Token': securitytoken}});
  }

  restoreForm(formular: Formular): Observable<HttpEvent<void>> {
    return this.http.put<HttpEvent<void>>(this.backend + this.relation.collection + '/' + formular.id + '/restore', {},
      {headers: {}});
  }

  nextVersion(formularId: Id, version: string, parentVersion: string, increaseMajor: boolean): Observable<NextVersion> {
    return this.http.get<NextVersion>(
      this.backend
      + this.relation.collection
      + '/'
      + formularId
      + `/next-version?parentVersion=${parentVersion}&version=${version}&increaseMajor=${increaseMajor}`);
  }

  searchCurrentWorkspace(searchTerm: string, workspaceId?: string, options?: DsvsPage): Observable<FormularHalPage> {
    return super.search(searchTerm, options, {name: 'current-workspace', data: {'workspaceId': workspaceId}});
  }

  getPubishedVersions(formularId: string, page: number, size: number): Observable<any> {
    return this.http.get<any>(this.backend + 'forms/' + formularId + '/publishedversions?page=' + page + '&size=' + size);
  }

}
