import { Component, Input } from '@angular/core';

@Component({
  selector: 'fes-status',
  templateUrl: './fes-status.component.html',
  styleUrls: ['./fes-status.component.scss']
})
export class FesStatusComponent {

  static readonly COLOR_UNKNOWN: string = '#000000';
  static readonly COLOR_GREEN: string = '#7ED321';
  static readonly COLOR_YELLOW: string = '#F3E401';
  static readonly COLOR_RED: string = '#FC3A28';
  static readonly COLOR_BLUE: string = '#42A5F5';

  _color = FesStatusComponent.COLOR_UNKNOWN;

  @Input() tag: string = null;

  @Input() title: string = null;

  // TODO: Replace with colors from SASS
  @Input() set color(color: string) {
    this._color = color;
  }

  get color(): string {
    return this._color;
  }

}
